import styles from './ErrorPage.css';
import cn from 'classnames';
import Button, { ButtonSize, ButtonType } from 'src/components/Button';
import React from 'react';
import MURSION_SUPPORT_INFO from 'src/app/data/support/supportInfo';
import TranslateMessage from '../../i18n/TranslateMessage';
import { useIntl } from 'react-intl';

const ErrorPage = () => {

  const onReload = () => {
    window.location.reload();
  };

  const intl = useIntl();
  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <i className={cn('fas', 'fa-tools', styles.icon)}/>
        <h1 className={styles.header}>{TranslateMessage('MursionPortal.Error.SomethingWentWrong')}</h1>
        <p>{intl.formatMessage({ id: 'MursionPortal.Error.ReloadingPage' })}
          <br/>{intl.formatMessage({ id: 'MursionPortal.Error.WorkingHard' })}</p>

        <div className={styles.info}>
          <p>
            {intl.formatMessage({ id: 'MursionPortal.Error.TechnicalAssistance' })}<br/>{intl.formatMessage({ id: 'MursionPortal.At' })}
            <a
            href={`tel:${MURSION_SUPPORT_INFO.phone}`}>{MURSION_SUPPORT_INFO.phone}</a> {intl.formatMessage({id:'MursionPortal.Or'})} <a
            href={`mailto:${MURSION_SUPPORT_INFO.email}`}>{MURSION_SUPPORT_INFO.email}</a>.
          </p>
          <p>{intl.formatMessage({ id: 'MursionPortal.Error.SupportAvailable' })}<br/>{MURSION_SUPPORT_INFO.hours}</p>
        </div>

        <Button
          btnSize={ButtonSize.NORMAL}
          btnType={ButtonType.SPECIAL_PURPLE}
          onClick={onReload}
        >
          {TranslateMessage('MursionPortal.Button.Reload')}
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;