import { IAppState } from 'src/app/redux/store/reducers';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import IRestError, { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import { parseErrorMessage } from 'src/app/data/common/utils/errorUtils';

export const getUsersList = (state: IAppState): ICompanyUser[] => state.company.users.list.items;
export const getDashboardUsersList = (state: IAppState): ICompanyUser[] => state.company.users.listForDashboard.items;

export const getUsersListPage = (state: IAppState): number => state.company.users.list.page || 0;
export const getUsersListSizePerPage = (state: IAppState): number => state.company.users.list.sizePerPage || 0;
export const getUsersListTotalSize = (state: IAppState): number => state.company.users.list.totalSize || 0;
export const getUsersListRefreshError = (state: IAppState): IRestError | null => state.company.users.list.refreshError;
export const isUsersListFetched = (state: IAppState): boolean => state.company.users.list.fetched;
export const isUsersListRefreshing = (state: IAppState): boolean => state.company.users.list.refreshing;
export const getErrorMessage = (state: IAppState): string => parseErrorMessage(state.company.users.error);
export const getValidationErrors = (state: IAppState): IErrorValidation[] => state.company.users.error.validations;

export const getUsersForSessionList = (state: IAppState): ICompanyUser[] => state.company.users.listForEditSession.items;
export const getUsersForSessionListPage = (state: IAppState): number => state.company.users.listForEditSession.page || 0;
export const getUsersForSessionListSizePerPage = (state: IAppState): number => state.company.users.listForEditSession.sizePerPage || 0;
export const getUsersForSessionListTotalSize = (state: IAppState): number => state.company.users.listForEditSession.totalSize || 0;
export const getUsersForSessionListRefreshError = (state: IAppState): IRestError | null => state.company.users.listForEditSession.refreshError;
export const isUsersForSessionListFetched = (state: IAppState): boolean => state.company.users.listForEditSession.fetched;
export const isUsersForSessionListRefreshing = (state: IAppState): boolean => state.company.users.listForEditSession.refreshing;
