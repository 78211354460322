import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import createListActions from "src/app/redux/utils/createListActions";
import avatarsRest from './rest';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';

export default {
  clearError: createClearEntityErrorAction('AVATAR'),
  createAvatar: actionFromPromise(avatarsRest.createAvatar, 'AVATAR'),
  deleteAvatar: actionFromPromise(avatarsRest.deleteAvatar, 'AVATAR', true),
  restoreAvatar: actionFromPromise(avatarsRest.restoreAvatar, 'AVATAR'),
  deprecated__fetchAvatarsList: actionFromPromise(avatarsRest.fetchAvatarsList),
  fetchAvatarById: actionFromPromise(avatarsRest.fetchAvatarById),
  fetchAvatarsList: createListActions('AVATARS', avatarsRest.fetchAvatarsList),
  fetchAvatarsListByEnvironment: createListActions('AVATARS_BY_ENVIRONMENT', avatarsRest.fetchAvatarsListByEnvironment),
  updateAvatar: actionFromPromise(avatarsRest.updateAvatar, "AVATAR", true),
};
