import RestService from 'src/app/services/rest/RestService';
import { IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import {
  IClientContractItem,
  IClientContractProjects,
  IClientContractScenarios,
  IClientContractSelfService,
  IClientContractSummary,
  IContractLearnerBreakdown,
  IContractOverviewBreakdownResponse,
  IContractOverviewPayload
} from 'src/app/data/client/interfaces/contract';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import { IListDataExport, IListDataFetch } from 'src/app/data/common/interfaces/fetch';
import { fetchDummyData } from 'src/app/data/common/utils/fetch-dummy-data';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';

export interface IClientContractSummariesQuery {
  clientId?: string;
  contractId?: string;
  endDate?: number;
  providerId?: string;
  searchQuery?: string;
  startDate?: number;
  timeZone?: string;
}

export interface IClientOverviewSimulationQuery {
  clientId?: string;
  contractId?: string[];
  fromDate?: number;
  projectId?: string;
  providerId?: string;
  scenarioId?: string;
  toDate?: number;
  weekdayFlag?: boolean;
  timeZone?: string; // not ready in the API
  startDate?: number | string;
  endDate?: number | string;
  projectIds?: string[];
  contractIds?: string[];
  archive?: TListPageArchiveOption | null;
}

export interface IScenarioActivityItemsQuery {
  clientId?: string;
  contractId?: string;
  fromDate?: number;
  projectId?: string;
  providerId?: string;
  scenarioId?: string;
  toDate?: number;
  weekdayFlag?: boolean;
  timeZone?: string; // not ready in the API
  startDate?: number | string;
  endDate?: number | string;
}

export interface IClientContractProgressQuery {
  clientId?: string;
  contractId?: string;
  endDate?: number;
  providerId?: string;
  searchQuery?: string;
  startDate?: number;
  timeZone?: string;
}

export interface IClientContractSelfServiceQuery {
  providerId?: string;
  startDate?: number;
  endDate?: number;
  timeZone?: string;
}


const fetchClientContractSummaries: IListDataFetch<IClientContractSummariesQuery, IClientContractSummary> = (params) => {
  const { pageData, query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/summaries`, {
    body: JSON.stringify({ ...pageData, ...query }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};


const exportClientContractSummaries: IListDataExport<IClientContractSummariesQuery> = (params) => {
  const { query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/summaries/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};


/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

const fetchClientContractItems: IListDataFetch<IClientContractProgressQuery, IClientContractItem> = (params) => {
  const { pageData, query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/items`, {
    body: JSON.stringify({ ...pageData, ...query }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};


const exportClientContractItems: IListDataExport<IClientContractProgressQuery> = (params) => {
  const { query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/items/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};


const fetchClientContractProjects: IListDataFetch<IClientContractProgressQuery, IClientContractProjects> = (params) => {
  const { pageData, query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/projects`, {
    body: JSON.stringify({ ...pageData, ...query }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};

const exportClientContractProjects: IListDataExport<IClientContractProgressQuery> = (params) => {
  const { query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/projects/export`, {
    body: JSON.stringify({ ...query, size: 10 }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};

const fetchClientContractScenarios: IListDataFetch<IClientContractProgressQuery, IClientContractScenarios> = (params) => {
  const { pageData, query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/scenario`, {
    body: JSON.stringify({ ...pageData, ...query }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};

const exportClientContractScenarios: IListDataExport<IClientContractProgressQuery> = (params) => {
  const { query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/scenario/export`, {
    body: JSON.stringify({ ...query, size: 10 }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};

const fetchOverviewSimBreakdown = (params: object): Promise<any> => {
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/performance/report/simBreakDown`, {
    body: JSON.stringify(params),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST'
  });
};

const fetchContractLearnerBreakdown = (params: object): Promise<IContractLearnerBreakdown> => {
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/performance/report/learnerBreakDown`, {
    body: JSON.stringify(params),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST'
  });
};

const fetchClientContractSelfService: IListDataFetch<IClientContractSelfServiceQuery, IClientContractSelfService> = (params) => {
  return fetchDummyData<IClientContractSelfService>({
    ...params,

  }, (item) => ({
    provider: item.licenseeName,
    totalHoursUtilized: 12.34,
    hoursCompleted: 12.34,
    hoursMissed: 12.34,
    hoursErrored: 12.34,
    simulationsComplete: 23,
    simulationsMissed: 23,
    simulationsErrored: 23,
  }));
};

const exportClientContractSelfService: IListDataExport<IClientContractProgressQuery> = () => {
  return Promise.resolve();
};

type IClientContractRes = any;  
const fetchClientContractsWithOptions = (params: IPageRequestParams): Promise<IListDataResponse<IClientContractRes>> => {
  const { pageData, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/client/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchClientScenariosContract = (params: object): Promise<any> =>{
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/scenario/activity`, {
    body: JSON.stringify(params),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST'
  });
};

const fetchContractOverviewBreakdown = (params: IContractOverviewPayload): Promise<IContractOverviewBreakdownResponse> => {
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/client/contract/performance/report/contractBreakDown`, {
    body: JSON.stringify(params),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST'
  });
};

export default {
  fetchClientContractSummaries,
  fetchOverviewSimBreakdown,
  fetchClientContractItems,
  fetchClientContractProjects,
  fetchClientContractScenarios,
  fetchClientContractSelfService,

  exportClientContractSummaries,
  exportClientContractItems,
  exportClientContractProjects,
  exportClientContractScenarios,
  exportClientContractSelfService,

  fetchClientContractsWithOptions,
  fetchClientScenariosContract,
  fetchContractLearnerBreakdown,
  fetchContractOverviewBreakdown
};
