export const froalaKey = 'BWC6D-16D3B1F1G2H1A3A4wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B4C6E2D1F4G1F1==';
export const froalaDefaultToolbarOptions = ['paragraphFormat', 'bold', 'italic', 'underline', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'outdent', 'indent', 'formatOL', 'formatUL', 'html', 'codeView', 'copyButton', 'insertLink', 'fontFamily', 'fontSize', 'textColor'];
export const froalaToolbarOptions: string[] = ['paragraphFormat', 'bold', 'italic', 'underline', 'formatOL', 'formatUL', 'insertLink'];
export const froalaCustomToolbarOptions: string[] = ['paragraphFormat', 'bold', 'italic', 'underline', 'alignCenter', 'alignJustify', 'alignLeft', 'alignRight', 'formatOL', 'formatUL', 'copyButton', 'insertLink', 'fontFamily', 'fontSize', 'textColor'];

export const paragraphFormat = {
  P: 'Paragraph',
  H1: 'Heading 1',
  H2: 'Heading 2',
  H3: 'Heading 3',
  H4: 'Heading 4',
  H5: 'Heading 5',
  H6: 'Heading 6',
  PRE: 'Preformatted',
};

export const froalaEditorColors = [
  '#11234F',
  '#172C62',
  '#49599B',
  '#77A3D2',
  '#1F67F4',
  '#F8C539',
  '#34A4A0',
  '#4BB8F5',
  '#FF615C',
  '#FB902D',
  '#000000',
  '#06817D',
  '#7B91F1',
  'REMOVE',
];

export const froalaEditorFontFamilyFormats: string = 'Nunito';
export const froalaEditorFontSizeInputDefaultUnit: string = 'px';
export const froalaEditorFontSizeFormats: string[] = ['14', '16', '18', '20', '22', '24', '26', '28', '30', '32'];
