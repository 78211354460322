import cn from 'classnames';
import queryString from 'query-string';
import * as React from 'react';
import { FormControl } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import getUrlParamValue from 'src/app/data/common/utils/queryParamsAccessor';
import { PARAM_SEARCH } from './index';
import styles from './SearchField.css';
import { PARAM_PAGE } from 'src/components/Pagination/Pagination';
import _debounce from 'lodash/debounce';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export interface ISearchFieldProps extends WrappedComponentProps{
  className?: string;
  id: string;
  onChange?: (text: string) => void;
  value?: string;
  autoFocus?: boolean;
  ariaLabel?: string;
  searchDebounceTime?: number;
}

const DEBOUNCE_TIME = 200;

interface ISearchFieldState {
  showClearButton: boolean;
  query: string;
}

class SearchField extends React.Component<ISearchFieldProps & RouteComponentProps<any>, ISearchFieldState> {

  public state: ISearchFieldState = {
    showClearButton: false,
    query: '',
  };

  private delayedChanges: any = null;

  public componentDidMount(): void {
    const query = this.props.value || !this.props.onChange && getUrlParamValue(PARAM_SEARCH, this.props.location) || this.state.query;
    const showClearButton = !!query;

    this.setState({ query, showClearButton, });
  }

  public componentWillMount(): void {

    this.delayedChanges = _debounce(() => {

      const { history, location, onChange } = this.props;
      const text = this.state.query;

      if (onChange) {
        return onChange(text);
      }

      const params = queryString.parse(location.search);
      const page = getUrlParamValue(PARAM_PAGE, location);
      const query: any = {
        ...params,
        [PARAM_SEARCH]: text || undefined
      };

      if (page) {
        query.page = 0; // if the page is defined then go to the first page
      }

      location.search = queryString.stringify(query);

      history.push(location);

    }, this.props.searchDebounceTime ?? DEBOUNCE_TIME);
  }

  public render() {
    const value = this.state.query;
    const { intl, ariaLabel } = this.props;

    return (
      <div className={cn(styles.inputGroup, this.props.className)} aria-label={intl.formatMessage({ id: 'Session.Search' })}>
        <FormControl
          className={styles.input}
          id={this.props.id}
          placeholder={intl.formatMessage({ id: 'Session.Search' })}
          type="search"
          onChange={this.onChange}
          value={value}
          autoFocus={this.props.autoFocus}
          aria-label={ariaLabel}
        />
        {
          this.state.showClearButton && (
            <div className={styles.clearButton}>
              <i className='fas fa-times' onClick={this.clearInput}/>
            </div>
          )
        }
      </div>
    );
  }

  private clearInput = () => {
    this.changeSearchText('');
  }

  private onChange = (event: any) => {
    this.changeSearchText(event.target.value);
  }

  private changeSearchText = (query: string) => {
    const showClearButton = !!query;

    this.setState({ query, showClearButton, });
    this.delayedChanges();
  }
}

export default injectIntl(withRouter(SearchField));
