import { Theme } from 'react-select/dist/declarations/src/types';
import { INITIAL_STYLES, INITIAL_THEME, ISelectorTheme } from 'src/components/Selector/themes/SelectorThemes';

const darkTheme: ISelectorTheme = {

  customStyles: {
    menu: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menu,
      color: 'white',
      backgroundColor: '#4a6173',
    }),
    menuPortal: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menuPortal,
      zIndex: 1100,
    }),
    option: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.option,
      backgroundColor: state.isFocused ? 'white' : '#4A6173',
      color: state.isFocused ? 'black' : 'white'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.singleValue,
      backgroundColor: '#4A6173',
      color: 'white',
    }),
    control: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.control,
      backgroundColor: '#4a6173',
      color: 'white',
      border: 0,
      boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.1)',
    }),
    indicatorSeparator: () => INITIAL_STYLES.indicatorSeparator,
  },

  customTheme: (theme: Theme): Theme => ({
    ...theme,
    ...INITIAL_THEME,
    colors: {
      ...theme.colors,
      ...INITIAL_THEME.colors,
    },
  })
};

export default darkTheme;