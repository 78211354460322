import { ISelectorOption } from 'src/components/Selector/Selector';
import { GroupBase, Options } from 'react-select';
import React from 'react';
import { Option } from 'react-select/src/filters';

interface ISelectedLabelProps {
  value: ISelectorOption | ISelectorOption[];
  options: Array<GroupBase<Option>> | Options<any> | undefined;
  disabled?: boolean;
  placeholder: string;
}

const SelectedLabel = (props: ISelectedLabelProps) => {
  const { value, options, disabled, placeholder } = props;
  let selected = placeholder;
  let title = placeholder;

  if (!disabled) {
    if (
      Array.isArray(value) && value?.length
      && options && (options.length === 1 || value.length < options.length)
    ) {
      selected = value
        .map((x) => (value.length > 1 && x.label.length > 12) ? `${x.label.substr(0, 8)}...` : x.label)
        .join(', ');

      title = value
        .map((x) => x.label)
        .join(',\n');

    } else if (!Array.isArray(value) && value?.value !== undefined && value?.value !== null) {
      selected = title = value?.label;
    }
  }

  return <span title={title}>{selected}</span>;
};

export default SelectedLabel;
