import { useState, useEffect } from "react";
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from "react-redux";
import selectors from "src/app/redux/selectors";

export const useLaunchDarkly = (featureFlag: string) => {
    
    const [ isLaunchDarklyFeatureFlag, setIsLaunchDarklyFeatureFlag ] = useState<boolean>(false);
    const ldClient = useLDClient();

    const clientId = useSelector(selectors.profile.getClientId);

    async function fetchLaunchDarklyConfig(){
        if(ldClient && featureFlag){
            const response = await ldClient.identify({ key: clientId });
            if(response && response[featureFlag]){
                setIsLaunchDarklyFeatureFlag(response[featureFlag]);
            }
        }
    }

    useEffect(() => {
        fetchLaunchDarklyConfig();
    }, [featureFlag]);

    return isLaunchDarklyFeatureFlag;
};