import React, { ChangeEvent, createContext, FunctionComponent, PropsWithChildren, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './RadioGroup.css';
import { SelectorTheme } from "../Selector/themes";
import cn from 'classnames';
import Tooltip, { TooltipPlacement, TooltipTheme } from 'src/components/Tooltip/Tooltip';


export interface IRadioGroupBaseProps<T> {
  name: string;
  selectedValue: T;
  onChange: (val: T) => void;
}

export interface IRadioGroupProps<T> extends IRadioGroupBaseProps<T> {
  label?: string;
  labelId?: string;
  disabled?: boolean;
  tooltipText?: string;
}

const RadioGroupContext = createContext<IRadioGroupBaseProps<any> | null>(null);

export const Radio: FunctionComponent<{
  value: any, label: string, disabled?: boolean,  themeType?: SelectorTheme, tooltipText?: string }> = (props) => {

  const { value, label, disabled,tooltipText } = props;

  const radioGroup = useContext(RadioGroupContext);

  if (!radioGroup) {
    return null;
  }

  const { name, selectedValue, onChange } = radioGroup;
  const id = uuid();

  const onChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Tooltip
        placement={TooltipPlacement.TOP}
        text={tooltipText ? tooltipText : '' }
        theme={TooltipTheme.COBALT}
      >
    <div className={cn(styles.radioInputContainer, (props.themeType === SelectorTheme.Cobalt) && styles.cobalt)}>
      <input
        {...props}
        disabled={disabled}
        id={id}
        type="radio"
        name={name}
        checked={selectedValue !== undefined && value === selectedValue}
        onChange={onChangeInputValue}
      />
      <label htmlFor={id}><span>{label}</span></label>
    </div>
    </Tooltip>
  );
};


export function RadioGroup<T>(props: PropsWithChildren<IRadioGroupProps<T>>) {

  const { name, selectedValue, onChange, label, children, ...rest } = props;
  const labelId = props.labelId || uuid();

  return (
    <div {...rest} role="group" aria-labelledby={props.labelId || labelId}>
      {
        !props.labelId && !!label && <div id={labelId}>{label}</div>
      }
      <RadioGroupContext.Provider
        value={{
          name,
          selectedValue,
          onChange,
        }}
      >
        {children}
      </RadioGroupContext.Provider>
    </div>
  );
}