import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Froalaeditor from 'froala-editor';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { useIntl } from 'react-intl';
import csvInjectionProtector from 'src/app/data/common/utils/csvInjectionProtector';
import stripHtml from 'src/app/data/common/utils/stripHtml';
import styles from 'src/components/FroalaEditor/FroalaEditor.css';
import {
  froalaKey,
  froalaEditorColors,
  froalaEditorFontFamilyFormats,
  froalaEditorFontSizeFormats,
  froalaEditorFontSizeInputDefaultUnit,
  paragraphFormat,
  froalaDefaultToolbarOptions,
} from 'src/components/FroalaEditor/FroalaEditorUtil';
import cn from 'classnames';
import 'froala-editor/css/froala_style.min.css?raw';
import 'froala-editor/css/froala_editor.pkgd.min.css?raw';

import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/font_family.min.js';

import 'froala-editor/css/plugins/code_view.min.css';
import 'froala-editor/css/plugins/colors.min.css';

export interface IFroalaEditorProps {
  width: number | string;
  height: number;
  value?: string;
  onChange: (value: string, length?: number, isInputValid?: boolean) => void;
  csvProtected?: boolean;
  placeholder: string;
}

function useFroalaEditorConfig(props: IFroalaEditorProps) {
  const { height, width, placeholder } = props;
  const intl = useIntl();

  return useMemo(() => ({
    height,
    width,
    key: froalaKey,
    placeholderText: placeholder,
    pluginsEnabled: ['paragraphFormat', 'lists', 'image', 'link', 'align', 'codeView', 'colors', 'fontFamily', 'fontSize'],
    toolbarButtons: froalaDefaultToolbarOptions,
    paragraphFormat,
    fontFamilySelection: true,
    fontFamily: {
      'Nunito,Open Sans,sans-serif': froalaEditorFontFamilyFormats,
    },
    fontSize: froalaEditorFontSizeFormats,
    fontSizeSelection: true,
    fontSizeUnit: froalaEditorFontSizeInputDefaultUnit,
    paragraphFormatSelection: true,
    imagePaste: false,
    attribution: false,
    colorsText: froalaEditorColors,
    linkStyles: {
      [styles.linkGreen]: intl.formatMessage({ id: 'MursionPortal.RTF.DropDown.Text.Green' }),
      [styles.linkStrong]: intl.formatMessage({ id: 'MursionPortal.RTF.DropDown.Text.Thick' }),
    },
  }), [height, width, placeholder]);
}

const FroalaEditor: FunctionComponent<IFroalaEditorProps> = (props) => {
  const { value, onChange, csvProtected } = props;

  const intl = useIntl();
  const [editorContent, setEditorContent] = useState<string>('');
  const config = useFroalaEditorConfig(props);

  useEffect(() => {
    if (value !== editorContent) {
      setEditorContent(value || '');
    }
  }, [value]);

  const handleEditorChange = (textValue: string) => {
    setEditorContent(textValue);
    const editorContentWithOutHTML = stripHtml(textValue);
    if (csvProtected) {
      textValue = csvInjectionProtector(textValue);
    }

    const isInputValid = editorContentWithOutHTML.trim().length === 0 ? false : true;
    onChange(textValue, editorContentWithOutHTML.length, isInputValid);
  };

  async function copyToClipboard(selectedText: string) {
    try {
      await navigator.clipboard.writeText(selectedText);
    } catch (err) {
      console.error(err);
    }
  }

  Froalaeditor.DefineIcon('copyButton', {
    NAME: 'copy',
    PATH: 'M19.5 16.5L19.5 4.5L18.75 3.75H9L8.25 4.5L8.25 7.5L5.25 7.5L4.5 8.25V20.25L5.25 21H15L15.75 20.25V17.25H18.75L19.5 16.5ZM15.75 15.75L15.75 8.25L15 7.5L9.75 7.5V5.25L18 5.25V15.75H15.75ZM6 9L14.25 9L14.25 19.5L6 19.5L6 9Z'
  });

  Froalaeditor.RegisterCommand('copyButton', {
    title: intl.formatMessage({ id: 'MursionPortal.Copy.Tooltip.Text' }),
    focus: false,
    refreshAfterCallback: true,
    callback() {
      const selectedText = this.selection.text();
      if (selectedText) {
        copyToClipboard(selectedText);
      }
    }
  });

  const EditorElement = useMemo(() => {
    return (
      <FroalaEditorComponent
        tag='textarea'
        model={editorContent}
        onModelChange={handleEditorChange}
        config={config}
      />
    );
  }, [props, editorContent]);

  return (
    <div className={cn(styles.floaraTextEditorWrap, 'floaraTextCobalt')}>
      {EditorElement}
    </div>
  );
};

export default FroalaEditor;
