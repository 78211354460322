import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import createListActions from 'src/app/redux/utils/createListActions';
import createItemActions from 'src/app/redux/utils/createItemActions';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';
import rest from 'src/app/redux/modules/clients/rest';
import users from 'src/app/redux/modules/clients/users/actions';
import contract from 'src/app/redux/modules/clients/contract/actions';


export default {
  users,
  contract,
  refreshList: createListActions('CLIENTS', rest.fetchClientsList),
  clearError: createClearEntityErrorAction('CLIENT'),
  createNewClient: actionFromPromise(rest.createNewClient, 'CLIENT'),
  deleteClient: actionFromPromise(rest.deleteClient, 'CLIENT', true),
  getClientDetail: actionFromPromise(rest.getClientDetail, 'CLIENT', true),
  restoreClient: actionFromPromise(rest.restoreClient, 'CLIENT'),
  fetchClient: actionFromPromise(rest.fetchClient),
  fetchClientName: actionFromPromise(rest.fetchClientName),
  fetchClientProjects: actionFromPromise(rest.fetchClientProjects),
  fetchProjectsCount: createItemActions('CLIENT_PROJECTS_COUNT', rest.fetchProjectsCount),
  fetchClientsList: actionFromPromise(rest.fetchClientsList),
  fetchDashboardClient: createItemActions('DASHBOARD_CLIENT', rest.fetchClient),
  updateClient: actionFromPromise(rest.updateClient, 'CLIENT', true),
  updateClientDomains: actionFromPromise(rest.updateClientDomains, 'CLIENT'),

  fetchClientWithOptions: actionFromPromise(rest.fetchClientWithOptions),
  fetchClientsListWithOptions: actionFromPromise(rest.fetchClientsListWithOptions),
  fetchClientsListWithoutSsoWithOptions: actionFromPromise(rest.fetchClientsListWithoutSsoWithOptions),
  fetchProjectsCountWithOptions: actionFromPromise(rest.fetchProjectsCountWithOptions),
  fetchClientsCountWithOptions: actionFromPromise(rest.fetchClientsCountWithOptions),
  fetchClientNamesListWithOptions: actionFromPromise(rest.fetchClientNamesListWithOptions),
  scenarioSessionScheduleInvitation: actionFromPromise(rest.sendScenarioSessionScheduleInvitation),
  projectSessionScheduleInvitation: actionFromPromise(rest.projectSessionScheduleInvitation),
  updateSessionScheduleInvitation: actionFromPromise(rest.updateSessionScheduleInvitation),
  invitationLastSentDate: actionFromPromise(rest.fetchInvitationLastSentDate),
  updateCustomizeEmailSetting: actionFromPromise(rest.updateCustomizeEmailSetting),
  fetchCustomizeEmailMessagesMetadata: actionFromPromise(rest.fetchCustomizeEmailMessagesMetadata),
  fetchEmailPreview: actionFromPromise(rest.fetchEmailPreview),
  getEulaList: actionFromPromise(rest.getEulaList),
  fetchEulaContent: actionFromPromise(rest.fetchEulaContent),
  fetchClientShortList: actionFromPromise(rest.fetchClientShortList),
  refreshClientShortList: createListActions('CLIENTS', rest.fetchClientShortList),
};
