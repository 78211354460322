import classNames from 'classnames';
import * as React from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';
import IconButton from 'src/components/IconButton';
import styles from './RemovableLabel.css';
import { injectIntl, WrappedComponentProps } from 'react-intl';
export interface IRemovableLabelProps extends React.HTMLProps<any> {
  removeArgument?: any;
  showRemoveButton?: boolean;
  iconClassName?: string;
  onRemove?: (removeArgument: any) => void;
}

class RemovableLabel extends React.Component<IRemovableLabelProps & BadgeProps& WrappedComponentProps> {
  public render() {
    const {
      className,
      iconClassName,
      children,
      removeArgument,
      showRemoveButton,
      onRemove,
      intl,
      ...rest
    } = this.props;
    const labelClassNames = classNames([
      className,
      showRemoveButton && styles.withRemoveButton,
    ]);

    return (
      <Badge className={labelClassNames} {...rest} bg={'light'}>
        <div className={styles.text}>{children}</div>
        {
          showRemoveButton
          && <IconButton iconName={'times'} aria-label={intl.formatMessage({ id: 'MursionPortal.AriaLabel.Remove' })}
                         onClick={this.onRemove} iconClassName={iconClassName}/>
        }
      </Badge>
    );
  }

  private onRemove = () => {
    if (typeof this.props.onRemove !== 'function') {
      return;
    }

    this.props.onRemove(this.props.removeArgument);
  };
}

export default injectIntl(RemovableLabel);
