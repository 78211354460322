import { Theme } from 'react-select/dist/declarations/src/types';
import { CUSTOM_STYLES, INITIAL_STYLES, INITIAL_THEME, ISelectorTheme } from 'src/components/Selector/themes/SelectorThemes';

const cobaltTheme: ISelectorTheme = {

  customStyles: {
    menu: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menu,
      color: '#000',
      backgroundColor: '#fff',
      boxShadow: 'none',
      position: 'relative',
      border: 0,
      padding: 0,
      margin: 0,
      borderBottomLeftRadius: '9px',
      borderBottomRightRadius: '9px',
    }),
    menuList: (provided) => ({
      ...provided,
      ...CUSTOM_STYLES.menuListStyle
    }),
    menuPortal: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menuPortal,
      // width: 350,
      backgroundColor: '#fff',
      border: '1px solid #2741ae',
      ...CUSTOM_STYLES.menuPortalStyle
    }),
    option: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.option,
      backgroundColor: state.isFocused ? '#d3e2ff' : '#fff',
      ...CUSTOM_STYLES.optionStyle
    }),
    singleValue: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.singleValue,
      color: '#2741ae',
      ...CUSTOM_STYLES.singleValueStyle
    }),
    placeholder: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.placeholder,
      color: '#2741ae',
      ...CUSTOM_STYLES.placeholderStyle
    }),
    input: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.input,
      ...CUSTOM_STYLES.inputStyle
    }),
    container: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.container,
      ...CUSTOM_STYLES.containerStyle
    }),
    control: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.control,
      backgroundColor: '#f4f6fb',
      fontWeight: 'normal',
      color: '#2741ae',
      boxShadow: 'none',
      padding: '0',
      cursor: 'pointer',
      borderRadius: '28px',
      height: '28px',
      minHeight: 'unset',
      borderWidth: '0px',
      marginRight: '0',
    }),
    indicatorSeparator: () => INITIAL_STYLES.indicatorSeparator,
    dropdownIndicator: (base) => ({
      ...base,
      width: '28px',
      height: '28px',
      borderRadius: '28px',
      backgroundColor: '#e0e4f2',
      fontSize: '12px',
      ...base,
      padding: '9px',
    }),
  },

  customTheme: (theme: Theme): Theme => ({
    ...theme,
    ...INITIAL_THEME,
    colors: {
      ...theme.colors,
      ...INITIAL_THEME.colors,
    },
  })
};

export default cobaltTheme;
