export const ApiKey: string = '1sn41yill7xmnu7munhmxphpg0bxsa8e47chlhi8cdkr3eue';
export const plugins: string = 'link image code lists'; // for adding image
export const listPlugins: string = 'lists advlist code link';
export const DefaultToolbarOptions: string = 'casechange blocks bold italic underline alignleft aligncenter alignright alignjustify outdent indent bullist numlist code copy link fontfamily fontsize forecolor';
export const toolbarWithImage: string = `${DefaultToolbarOptions} image link lists`;
export const toolbarOptions: string = 'casechange blocks bold italic underline bullist numlist link';
export const customToolbarOptions: string = 'casechange blocks bold italic underline aligncenter alignjustify alignleft alignright bullist numlist copy link fontfamily fontsize forecolor';
export const portalFontFamilyFormats: string = 'Nunito';
export const fontSizeInputTinyMceDefaultUnit = 'px';
export const tinyMceFontSizeFormats: string = `16px 18px 20px 22px 24px 26px 28px 30px 32px`;
export const tinyMceColorMap: string[] = [
    '#11234F', 'Midnight',
    '#172C62', 'Indigo',
    '#49599B', 'Dusk',
    '#77A3D2', 'Slate',
    '#1F67F4', 'Blue',
    '#F8C539', 'Yellow',
    '#34A4A0', 'Sea Green',
    '#4BB8F5', 'Sky Blue',
    '#FF615C', 'Musrion Red',
    '#FB902D', 'Orange',
    '#000000', 'Black',
    '#06817D', 'Green',
    '#7B91F1', 'Light Purple'
];
export const tinyMceContentStyle = `@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');body { font-family: Nunito; }`;
