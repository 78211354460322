import * as React from 'react';
import cn from 'classnames';
import { DEFAULT_LOGO_PATH } from 'src/app/data/client/data';
import LoadingOverlayWrapper from 'react-loading-overlay';
import _noop from 'lodash/noop';
import styles from './LazyImage.css';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export interface IImageProps extends WrappedComponentProps{
  className?: string;
  src: string;
  altText?: string;
  circle?: boolean;
  onLoad?: () => void;
  onError?: () => void;
  fallback?: string;
  fetchLoadingResponse?: (flag: boolean) => void;
  imageAutoWidth?: boolean;
}

interface IImageState {
  fetched: boolean;
  error: boolean;
  fallback: string;
  imgUploaded: boolean;
}

class LazyImage extends React.Component<IImageProps & any, IImageState> {
  public fileUploadStatusRef: React.RefObject<HTMLLabelElement> | null = React.createRef();
  public state: IImageState = {
    fetched: false,
    error: false,
    fallback: '',
    imgUploaded: false,
  };

  private readonly img: HTMLImageElement;

  constructor(props: IImageProps) {
    super(props);

    this.img = new Image();
    this.img.onload = this.onLoad;
    this.img.onerror = this.onError;
    this.img.src = props.src;
  }

  public setFetchLoadingResponse = (flag: boolean) => {
    if(this.props?.fetchLoadingResponse){
      this.props.fetchLoadingResponse(flag);
    }
    
  }

  public componentDidUpdate(prevProps: IImageProps) {
    if (prevProps.src !== this.props.src) {
      this.setState({
        fetched: false,
        error: false,
        imgUploaded: true
      }, () => {
        this.img.src = this.props.src;
        this.fileUploadStatusRef?.current?.focus();
      });
      this.setFetchLoadingResponse(false);
    }
  }

  public componentWillUnmount() {
    if (!this.img) {
      return;
    }
    this.img.onload = _noop;
    this.img.src = '';
  }

  public render() {
    const {src, className, fallback, circle, intl, imageAutoWidth, altText, ...props } = this.props;

    const loadedImage = (this.props.src && !this.state.error)
      ? this.props.src
      : this.state.fallback;

    return (
      <>
        <LoadingOverlayWrapper
          {...props}
          classNamePrefix={''}
          active={!this.state.fetched}
          spinner={true}
          className={cn(this.props.className, imageAutoWidth && styles.imageAutoWidthWrapper)}
          styles={{
            overlay: (base: any) => ({
              ...base,
              borderRadius: circle ? '50%' : '0%',
              zIndex: 0,
            }),
            spinner: (base: any) => ({
              ...base,
              width: '20px',
            }),
            wrapper: (base: any) => ({
              ...base,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }),
          }}
        >
          <div
            className={circle ? cn(styles.imageWrapper, styles.imageWrapperCircle) : cn(styles.imageWrapper, styles.imageWrapperDefault)}>
            <img src={loadedImage} className={className} {...props} alt={altText}/>
            <label tabIndex={this.state.imgUploaded ? 0 : -1} role="status" aria-live="polite" className="visibilityHidden" ref={this.fileUploadStatusRef}> {intl.formatMessage({ id: 'MursionPortal.Label.VisibilityHidden.FileUploaded' })}</label>
          </div>
        </LoadingOverlayWrapper>
      </>
    );
  }

  private onLoad = () => {
    this.setState({
      fetched: true,
      error: false,
    });
    if (this.props.onLoad) {
      this.props.onLoad();
    }
    this.setFetchLoadingResponse(true);
  };

  private onError = () => {
    const fallbackImage = this.props.fallback || DEFAULT_LOGO_PATH;
    if (this.props.onError) {
      this.props.onError();
    }
    this.setState({
      fetched: true,
      error: true,
      fallback: fallbackImage,
    });
    this.setFetchLoadingResponse(true);
  };
}

export default injectIntl(LazyImage);
