import LoadingOverlayWrapper from 'react-loading-overlay';
import * as React from 'react';
import { ReactNode } from 'react';
import loaderStyles from './LoadingOverlay.css';
import cn from 'classnames';
export interface ILoadingOverlayStyles {
  content?: (base: React.CSSProperties) => React.CSSProperties;
  overlay?: (base: React.CSSProperties) => React.CSSProperties;
  spinner?: (base: React.CSSProperties) => React.CSSProperties;
  wrapper?: (base: React.CSSProperties) => React.CSSProperties;
}

export interface ILoadingOverlayProps {
  active: boolean;
  fadeSpeed?: number;
  onClick?: () => void;
  className?: string;
  classNamePrefix?: string;
  spinner?: boolean | ReactNode;
  text?: ReactNode;
  styles?: ILoadingOverlayStyles;
  children?: any;
  isFallback?: boolean;
  showFullScreenOverlay?: boolean;
}

const LoadingOverlay = (props: ILoadingOverlayProps) => {
  const { children, styles, isFallback, showFullScreenOverlay, ...rest } = props;

  const customStyles: ILoadingOverlayStyles = {
    spinner: (base) => ({
      ...base,
      '& svg circle': {
        stroke: '#FF615C',
      }
    }),
    overlay: (base) => ({
      ...base,
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, .8)',
    }),
    wrapper: (base) => ({
      ...base,
      height: isFallback ? '100%' : 'auto',
    }),
    ...styles,
  };

  return (
    <LoadingOverlayWrapper
      styles={customStyles}
      className={cn(loaderStyles.loaderWrap, props.spinner && loaderStyles.zIndexHigh, showFullScreenOverlay && loaderStyles.fullLoaderWrap)}
      {...rest}
    >
      {children}
    </LoadingOverlayWrapper>
  );
};

export default LoadingOverlay;
