import IScenario, { ICopyEventAndScoringIds } from 'src/app/data/projects/interfaces/IScenario';
import RestService from 'src/app/services/rest/RestService';
import { IBuildingBlockSelectorParam, IEventScoringItem } from 'src/app/redux/modules/scenarios/scenarioBankEvents/interfaces/IEventScoring';
import { intl } from 'src/i18n/createIntl';
import { NextGenScenarioSelectorType } from 'src/layouts/mursion/ScenarioBankContainer/utils/nextGenScenarioBankUtils';
import { IEventResponse } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioEvents/interface';

const fetchNextGenScenarioEventScoring = (scenarioId: string): Promise<IScenario> => {
    return RestService.fetch(`${RestService.REST_URL}/nextgen/eventScoring/${scenarioId}/get-events`, {
      headers: RestService.generateHeaders(),
      method: 'GET',
    });
};

const createNextGenScenarioEventScoring = (data: IEventScoringItem): Promise<IScenario> => {
    return RestService.fetch(`${RestService.REST_URL}/nextgen/eventScoring/create`, {
        body: JSON.stringify(data),
        headers: RestService.generateHeaders({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      });
};

const updateNextGenScenarioEventScoring = (data: IEventScoringItem): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/eventScoring/update`, {
      body: JSON.stringify(data),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    });
};

const fetchNextGenScenarioEventScoringDetails = (scenarioId: string): Promise<IScenario> => {
    return RestService.fetch(`${RestService.REST_URL}/nextgen/eventScoring/${scenarioId}/get`, {
      headers: RestService.generateHeaders(),
      method: 'GET',
    });
};

const fetchBuildingBlockList = (params: IBuildingBlockSelectorParam & { blockType: NextGenScenarioSelectorType }): Promise<IScenario> => {
  const { blockType, signal, type, archived, scenarioId } = params;

  if (!blockType) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.buildingBlock'}));
  }

  const requestUrl = `${RestService.REST_URL}/nextGenScenario/list/buildingBlock/${blockType}`;

  function request(){
    if(blockType === NextGenScenarioSelectorType.SKILL || blockType === NextGenScenarioSelectorType.AVATAR){
      return `${requestUrl}?scenarioId=${scenarioId}`;
    }else if(type){
      return `${requestUrl}?type=${type}&archived=${archived}`;
    }
    return requestUrl;
  }

  return RestService.fetch(request(), {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchEventDetailList = (scenarioId: string): Promise<IEventResponse> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/events/list/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const createNextGenEventDetail = (data: any): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/events/create`, {
      body: JSON.stringify(data),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
};

const updateNextGenEventDetail = (data: any): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/events/update`, {
      body: JSON.stringify(data),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    });
};

const deleteEvent = (scenarioId: string): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/events/archive/${scenarioId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const copyEventAndScoringIds = (data: ICopyEventAndScoringIds): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/eventsScoring/copy`, {
      body: JSON.stringify(data),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
};

const mindsetArchive = (mindsetId: string): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/mindset/archive/${mindsetId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const assessmentArchive = (assessmentId: string): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/assessment/archive/${assessmentId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const updateNextgenEventSequence = (params: object): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/events/sequence`, {
    body: JSON.stringify(params),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'PATCH',
  });
};

export default {
    fetchNextGenScenarioEventScoring,
    createNextGenScenarioEventScoring,
    fetchNextGenScenarioEventScoringDetails,
    fetchBuildingBlockList,
    updateNextGenScenarioEventScoring,
    fetchEventDetailList,
    createNextGenEventDetail,
    deleteEvent,
    updateNextGenEventDetail,
    copyEventAndScoringIds,
    mindsetArchive,
    assessmentArchive,
    updateNextgenEventSequence,
};