import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import { IAppState } from 'src/app/redux/store/reducers';
import IRestError from 'src/app/data/common/interfaces/IRestError';

export const getClientConfig = (state: IAppState): IClientConfig | null => state.clientsConfig.data.item;
export const getDashboardClientConfig = (state: IAppState): IClientConfig | null => state.clientsConfig.dataForDashboard.item;

export const getClientConfigForEditSession = (state: IAppState): IClientConfig | null => state.clientsConfig.dataForEditSession.item;

export const isClientConfigForEditSessionFetched = (state: IAppState): boolean => state.clientsConfig.dataForEditSession.fetched;
export const getClientConfigForEditSessionRefreshError = (state: IAppState): IRestError | null => state.clientsConfig.dataForEditSession.fetchError;
export const isClientConfigForEditSessionRefreshing = (state: IAppState): boolean => state.clientsConfig.dataForEditSession.fetching;
