import * as React from 'react';
import FieldGroup, { IFieldGroupProps } from 'src/components/FieldGroup/FieldGroup';

export interface INumericFieldProps extends IFieldGroupProps {
  disabled?: boolean;
  min?: number;
  value: number|undefined|null;
}

class NumericField extends React.Component<INumericFieldProps> {

  public render() {
    const { disabled, min, value, ...props } = this.props;

    return (
      <FieldGroup
        {...props}
        disabled={disabled}
        min={min}
        type="number"
        value={value}
        onChange={this.onChange}
        onKeyDown={this.checkNumericInput}
      />
    );
  }

  private onChange = (event: any) => {
    let { value } = event.target;

    if (/^0\d+$/.test(value)) { // remove the leading 0
      value = value.substring(1, value.length);
    }

    if (!this.props.onChange) {
      return;
    }

    this.props.onChange({
      ...event,
      target: { ...event.target, value }
    });
  }

  private checkNumericInput = (event: any) => {
    const { key } = event;

    if (key.length === 1 && !/\d+/.test(key)) {
      event.preventDefault();
    }
  }
}

export default NumericField;
