import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { ISelectorOnChange, ISelectorOption, SelectorTheme, } from 'src/components/Selector';
import { namedEntryToSelectOption, selectOptionToNamedEntry, ValueType } from 'src/app/data/common/utils';
import isEqual from 'lodash/isEqual';
import { EntitySelectorValue, IEntitySelectorBaseProps } from 'src/components/EntitySelectors/EntitySelectorBase/EntitySelectorBaseProps';
import Selector from 'src/components/Selector/Selector';

export type ValueToOptionMapper<Item, OptionValueType = string | number> = (item: Item) => ISelectorOption<OptionValueType>;
export type OptionToValueMapper<Item, OptionValueType = string | number> = (option: ISelectorOption<OptionValueType>) => Item;

export interface IItemsInfoType<Item> {
  items: Item[];
  total?: number;
  refreshing: boolean;
}

interface IEntitySelectorProps<Item> extends IEntitySelectorBaseProps<Item> {
  /** info from ajax call, use to get selector options
   *  if `items` param passed, `items` will be used instead `itemsInfo`
   */
  itemsInfo?: IItemsInfoType<Item>;
  /** options for selector, `itemsInfo` will be disabled if `items` param passed */
  items?: Item[];
  valueToOption?: ValueToOptionMapper<Item>;
  optionToValue?: OptionToValueMapper<Item>;
  hasScenarioChanged?: boolean;
  setHasScenarioChanged?: (value: boolean) => void;
  ignoreCachedValue?: boolean;
  isValueLineContainer?: boolean;
  allowSelectionOfCachedValue?: boolean;
}


const EntitySelector: FunctionComponent<IEntitySelectorProps<any>> = (props) => {
  const {
    value,
    defaultValue,
    itemsInfo,
    items,
    valueToOption = namedEntryToSelectOption,
    optionToValue = selectOptionToNamedEntry,
    disabled,
    themeType,
    isMulti,
    clearOnOptionsChange,
    preSelectedValue,
    hasScenarioChanged,
    setHasScenarioChanged,
    ignoreCachedValue = false,
    allowSelectionOfCachedValue,
  } = props;

  const [cachedValue, setCachedValue] = useState<EntitySelectorValue>(value);

  useEffect(() => {
    setCachedValue(value);
  }, [value]);


  const onChange = useCallback(((selected) => {
    const newValue = mapSelectedOptionsToValues(optionToValue, selected, isMulti || false);
    if (isEqual(cachedValue, newValue) && !ignoreCachedValue) {
      return;
    }

    const selectedAll = Array.isArray(selected) && (selected.length === (items ? items?.length : itemsInfo?.total) || selected.length === 0);
    setCachedValue(newValue);
    props.onChange(newValue, selectedAll);
  }) as ISelectorOnChange, [props.onChange]);


  const optionsInfo = useMemo(() => {
    const { options, total } = props.hasOwnProperty('items') ? {
      options: items,
      total: items?.length,
    } : {
      options: itemsInfo?.items,
      total: itemsInfo?.total || itemsInfo?.items?.length,
    };

    return {
      options: (options || []).map(valueToOption),
      total: total || 0,
    };
  }, [itemsInfo, items]);

  const selectorValue = useMemo(
    () => {
      if (props.hasOwnProperty("value")) {
        return mapValuesToSelectorOptions(valueToOption, value) || null;
      }
        return preSelectedValue
        ? optionsInfo.options.find((option) => {
            return option.value === preSelectedValue;
          })
        : undefined;
    },
    [value, preSelectedValue, optionsInfo.options]
  );

  return <Selector
    {...props}
    isMulti={isMulti}
    themeType={themeType || SelectorTheme.Cobalt}
    options={optionsInfo.options}
    defaultValue={mapValuesToSelectorOptions(valueToOption, defaultValue)}
    value={selectorValue}
    onChange={onChange}
    total={optionsInfo.total}
    loading={props.loading || (!disabled && itemsInfo?.refreshing)}
    clearOnOptionsChange={!!clearOnOptionsChange}
    hasScenarioChanged={hasScenarioChanged}
    setHasScenarioChanged={setHasScenarioChanged}
    allowSelectionOfCachedValue={allowSelectionOfCachedValue}
  />;
};

export function mapSelectedOptionsToValues<Item>(optionToValue: OptionToValueMapper<Item>, options: ValueType<ISelectorOption>, isMulti: boolean): ValueType<Item> {
  if (isMulti === undefined) {
    isMulti = Array.isArray(options);
  }

  if (isMulti) {
    const fallbackValues = Array.isArray(options) ? options : []; // do not accept single value if it's multi selector
    if (!fallbackValues.length) {
      return null;
    } else {
      return fallbackValues.map(optionToValue);
    }
  } else {
    const fallbackValues = Array.isArray(options) ? null : options; // do not accept multi value if it's single selector
    return fallbackValues ? optionToValue(fallbackValues) : null;
  }
}

export function mapValuesToSelectorOptions<Item>(valueToOption: ValueToOptionMapper<Item>, values: ValueType<Item>): ValueType<ISelectorOption<any>> {
  if (Array.isArray(values)) {
    return values.map(valueToOption);
  } else {
    return values && valueToOption(values);
  }
}


export default EntitySelector;
