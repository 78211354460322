import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import styles from '../../../Selector.css';
import { components } from 'react-select';
import React from 'react';
import cn from 'classnames';

const SingleSelectorOption = (optionProps: OptionProps<any, any>) => {

  const isArchived = !!optionProps.data.archived;
  const label = `${optionProps.label} ${isArchived ? '(archived)' : ''}`;

  return (
    <components.Option {...optionProps}>
      <div
        title={label}
        className={cn(
          styles.optionLabel,
          isArchived && styles.archived,
          optionProps.isSelected && styles.optionLabelChecked
        )}>{label}</div>
    </components.Option>
  );
};

export default SingleSelectorOption;
