import IUserProfile, { IUserProfileUpdate } from 'src/app/data/profile/interfaces/IUserProfile';
import RestService from 'src/app/services/rest/RestService';
import { generateFormDataForParts } from 'src/app/data/common/utils/generateFormData';
import { IAuthSchema } from 'src/app/redux/modules/auth/interfaces/IAuthData';
import { IMappedSSOClaimNames } from 'src/components/NewUserForm/NewUserFormUtils';

const fetchUserProfile = (token?: string): Promise<IUserProfile> => {
  return RestService.fetch(`${RestService.REST_URL}/profile`, {
    headers: token ? {
      Authorization: 'Bearer ' + token,
    } : RestService.generateHeaders(),
    method: 'GET',
  });
};

const passTutorial = (): Promise<IUserProfile> => {
  return RestService.fetch(`${RestService.REST_URL}/profile/tutorial/pass`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const passAppInstallation = (): Promise<IUserProfile> => {
  return RestService.fetch(`${RestService.REST_URL}/profile/installation/pass`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const accentEULA = () => {
  return RestService.fetch(`${RestService.REST_URL}/profile/eula/accept`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const updateProfileTimezone = (timezoneId: string) => {
  return RestService.fetch(`${RestService.REST_URL}/profile/timezone`, {
    body: timezoneId,
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const updateProfileLanguage = (language: string) => {
  return RestService.fetch(`${RestService.REST_URL}/profile/lang`, {
    body: language,
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const saveUserProfile = async (profileId: string, userProfile: IUserProfileUpdate, picture: File | null, password?: string): Promise<IUserProfile> => {
  // Send all fields excluding `roles`.
  const {
    roles,
    ...rest
  } = userProfile;

  try {
    return await RestService.fetch(`${RestService.REST_URL}/profile/${profileId}`, {
      body: generateFormDataForParts({
        profile: {
          ...rest,
        },
        password: {
          password,
        },
      }, picture),
      headers: RestService.generateHeaders(),
      method: 'PUT',
    });
  } catch (e) {
    throw e;
  }
};

const fetchUserProfileLinks = (): Promise<IAuthSchema[]> => {
  return RestService.fetch(`${RestService.REST_URL}/profile/schemas`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const unlinkSsoProfile = (ssoId: string): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/profile/schemas/${ssoId}/link`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const fetchMappedSSOClaimNames = (ssoId: string): Promise<IMappedSSOClaimNames> => {
  return RestService.fetch(`${RestService.REST_URL}/sso/userProfileInfo/${ssoId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const userOnboard = () => {
  return RestService.fetch(`${RestService.REST_URL}/profile/user/onboard`, {
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const validateTOTP = (totp: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/profile/validateTotp/${totp}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const resendTOTP = (): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/profile/resend/otp`, {
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

export default {
  fetchUserProfile,
  fetchUserProfileLinks,
  passTutorial,
  passAppInstallation,
  saveUserProfile,
  accentEULA,
  updateProfileTimezone,
  updateProfileLanguage,
  unlinkSsoProfile,
  fetchMappedSSOClaimNames,
  userOnboard,
  validateTOTP,
  resendTOTP,
};
