import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import RestService from 'src/app/services/rest/RestService';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import { IProjectActivityDashboardItem } from 'src/app/data/dashboard/IProjectDashboard';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';

export interface IDashboardVideosQuery extends Partial<IAbortableRequestParams> {
  licenseeIds?: { value: string[] | null, exclude?: boolean };
  clientIds?: { value: string[] | null, exclude?: boolean };
  ssIds?: { value: string[] | null, exclude?: boolean };
  scenarioVersions?: ScenarioVersion[] | null;

  asc?: boolean | null;
  startDate?: number | null;
  endDate?: number | null;
  filter?: string | null;
}

export interface IDashboardVideosSimspecialistsQuery extends Partial<IAbortableRequestParams> {
  licenseeIds?: string[] | null;
  filter?: string;
  archive?: TListPageArchiveOption;
}

const fetchDashboardVideosNotUploaded = (params: IPageRequestParams & { videosQuery: IDashboardVideosQuery }): Promise<IListDataResponse<IProjectActivityDashboardItem>> => {
  const { pageData, videosQuery, signal } = params;

  const query = {
    ...pageData,
    ...videosQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simulation/video/not_uploaded`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchDashboardVideosSimspecialists = (params: IPageRequestParams & { simSpecialistsQuery: IDashboardVideosSimspecialistsQuery }): Promise<IListDataResponse<INamedEntry>> => {
  const { pageData, simSpecialistsQuery, signal } = params;

  const query = {
    ...pageData,
    ...{
      ...simSpecialistsQuery,
      licenseeIds: { value: simSpecialistsQuery.licenseeIds }
    }
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simulation/video/list/simspecialist/short`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportDashboardVideosNotUploaded = (params: IAbortableRequestParams & { videosQuery: IDashboardVideosQuery }): Promise<any> => {
  const { videosQuery, signal } = params;

  const query = {
    ...videosQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simulation/video/not_uploaded/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};


export default {
  fetchDashboardVideosNotUploaded,
  exportDashboardVideosNotUploaded,
  fetchDashboardVideosSimspecialists,
};
