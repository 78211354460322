import * as queryString from 'querystring';
import { IAssetSettings } from 'src/app/data/client/interfaces/IAsset';
import IProject from 'src/app/data/projects/interfaces/IProject';
import IScenario from "src/app/data/projects/interfaces/IScenario";
import RestService from 'src/app/services/rest/RestService';
import { intl } from 'src/i18n/createIntl';
import { ISessionWizardTinyItem, ISessionWizardTinyScenario, ITimeBlockOptions } from 'src/app/redux/modules/sessionWizard/rest';
import ITimeblocks from 'src/app/data/common/interfaces/ITimeblocks';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { ISession, ITrainingSessionCreate } from 'src/app/data/session/interfaces/ISession';

interface IOptions {
    method: string;
    signal: AbortSignal | null;
    headers: { [headerName: string]: string } | undefined;
    body: string | null;
}

const defaultOptions: IOptions = {
    method: 'GET',
    signal: null,
    headers: undefined,
    body: null,
};

const postMethodCommonOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    signal: null,
};

const fetchResource = <T>(url: string, options: IOptions = defaultOptions, params: Partial<ITimeBlockOptions> | null = null): Promise<T> => {
    const queryStringParams = params ? `?${queryString.stringify(params)}` : '';
    return RestService.fetch(`${RestService.REST_URL}${url}${queryStringParams}`, {
        method: options.method,
        headers: RestService.generateHeaders(options?.headers),
        signal: options.signal,
        body: options.body,
    });
};

const fetchPracticeSessionProject = (projectID: string): Promise<IProject> => {
    return fetchResource(`/project/practice/${projectID}`);
};

const fetchPracticeSessionScenario = (scenarioId: string): Promise<IScenario> => {
    if (!scenarioId) {
        throw new Error('fetchScenario: scenario id must be specified');
    }

    return fetchResource(`/scenario/practice/${scenarioId}`);
};

const fetchAssetSettingsForPracticeSession = (scenarioId: string): Promise<IAssetSettings[]> => {
    if (!scenarioId) {
        throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified' }));
    }

    return fetchResource(`/asset/settings/practice/scenario/${scenarioId}`);
};

const fetchPracticeScenarioAvailableTimeBlocks = (timeBlockOptions: ITimeBlockOptions): Promise<ITimeblocks> => {
    const { scenarioId, isTraining, startTime, endTime, timezone, simAvailabilityOnly } = timeBlockOptions;
    const params = {
        training: isTraining || false,
        from: startTime,
        to: endTime,
        timezone,
        simAvailabilityOnly,
    };

    return fetchResource(`/scenario/practice/${scenarioId}/timeblocks/ext`, defaultOptions, params);
};

const fetchPracticeSessionProjectsWithActiveScenarios = (pageData: IListPageData, clientId: number | string, filterParams: { training: boolean, joinNow: boolean }): Promise<IListDataResponse<ISessionWizardTinyItem>> => {
    const projectData = {
        ...filterParams,
        nameOnly: true,
        order: 'name',
        ...pageData,
    };

    const options = {
        body: JSON.stringify(projectData),
        ...postMethodCommonOptions
    };

    return fetchResource(`/project/list/practice/${clientId}/withActiveScenario`, options);
};

const fetchAllActivePracticeScenariosList = (pageData: IListPageData, projectId: number | string): Promise<IListDataResponse<ISessionWizardTinyScenario>> => {

    const scenarioData = {
        nameOnly: true,
        order: 'name',
        includeDraft: false,
        training: false,
        ...pageData,
    };

    const options = {
        body: JSON.stringify(scenarioData),
        ...postMethodCommonOptions
    };

    return fetchResource(`/scenario/list/active/project/${projectId}/practice`, options);
};

const createPracticeSession = (sessionData: ITrainingSessionCreate): Promise<ISession> => {
    const options = {
        body: JSON.stringify(sessionData),
        ...postMethodCommonOptions
    };
    return fetchResource(`/training/practice`, options);
};

export default {
    fetchPracticeSessionProject,
    fetchPracticeSessionScenario,
    fetchAssetSettingsForPracticeSession,
    fetchPracticeSessionProjectsWithActiveScenarios,
    fetchPracticeScenarioAvailableTimeBlocks,
    fetchAllActivePracticeScenariosList,
    createPracticeSession,
};
