import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from 'src/app/redux/modules/supplyManagement/rest';

export default {
    fetchDashboardsSupplyManagementSimRoster: actionFromPromise(rest.fetchDashboardsSupplyManagementSimRoster),
    exportDashboardSupplyManagementSimRoster: actionFromPromise(rest.exportDashboardSupplyManagementSimRoster),
    fetchDashboardsSupplyManagementClientRoster: actionFromPromise(rest.fetchDashboardsSupplyManagementClientRoster),
    exportDashboardSupplyManagementClientRoster: actionFromPromise(rest.exportDashboardSupplyManagementClientRoster),
    fetchDashboardsSupplyManagementScheduling: actionFromPromise(rest.fetchDashboardsSupplyManagementScheduling),
    exportDashboardSupplyManagementScheduling: actionFromPromise(rest.exportDashboardSupplyManagementScheduling),
    fetchDashboardsSupplyManagementSimView: actionFromPromise(rest.fetchDashboardsSupplyManagementSimView),
    exportDashboardSupplyManagementSimViewData: actionFromPromise(rest.exportDashboardSupplyManagementSimViewData),
    fetchDashboardsSupplyManagementSchedulingView: actionFromPromise(rest.fetchDashboardsSupplyManagementSchedulingView),
    exportDashboardSupplyManagementSchedulingViewData: actionFromPromise(rest.exportDashboardSupplyManagementSchedulingViewData),
};
