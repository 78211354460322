import IRestError from "src/app/data/common/interfaces/IRestError";
import { IAppState } from 'src/app/redux/store/reducers';

export const getListByClient = (state: IAppState) => state.certificates.listByClient.items;
export const isListByClientFetched = (state: IAppState): boolean => state.certificates.listByClient.fetched;
export const getListByClientRefreshError = (state: IAppState): IRestError | null => state.certificates.listByClient.refreshError;
export const isListByClientRefreshing = (state: IAppState): boolean => state.certificates.listByClient.refreshing;

export const getListByLicensee = (state: IAppState) => state.certificates.listByLicensee.items;
export const isListByLicenseeFetched = (state: IAppState): boolean => state.certificates.listByLicensee.fetched;
export const getListByLicenseeRefreshError = (state: IAppState): IRestError | null => state.certificates.listByLicensee.refreshError;
export const isListByLicenseeRefreshing = (state: IAppState): boolean => state.certificates.listByLicensee.refreshing;
