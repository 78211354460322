import IWebSocketNotification from 'src/app/data/common/interfaces/IWebSocketNotification';
import { IAppState } from '../../store/reducers';
import { WSConnectionStatus } from 'src/app/data/common/interfaces/WSConnectionStatus';
import IWebSocketEntity from 'src/app/data/common/interfaces/IWebSocketEntity';

const getConnectionStatus = (state: IAppState): WSConnectionStatus => {
  return state.ws.connectionStatus;
};

const getNotificationsList = (state: IAppState): IWebSocketNotification[] => {
  return state.ws.notificationsList || [];
};

const getEntitiesList = (state: IAppState): IWebSocketEntity[] => {
  return state.ws.entitiesList || [];
};

export {
  getConnectionStatus,
  getNotificationsList,
  getEntitiesList,
};
