import IRestError from 'src/app/data/common/interfaces/IRestError';
import { IAppState } from 'src/app/redux/store/reducers';
import AuthStatus from 'src/app/redux/modules/auth/interfaces/AuthStatus';
import { IAuthSchema } from "src/app/redux/modules/auth/interfaces/IAuthData";
import IAuthToken from 'src/app/data/auth/IAuthToken';

const getAuthErrors = (state: IAppState): IRestError[] | null => {
  return state.auth.errors;
};

const getAuthStatus = (state: IAppState): AuthStatus => {
  return state.auth.status;
};

const getAuthToken = (state: IAppState): string | null => {
  return state.auth.token ? state.auth.token.access_token : null;
};

const isLoggedIn = (state: IAppState): boolean => {
  return getAuthStatus(state) === AuthStatus.AUTHENTICATION_SUCCESSFUL;
};

const getAuthSchemas = (state: IAppState): IAuthSchema[] => {
  return state.auth.schemas || [];
};
const notifyTokenTimeout = (state: IAppState): boolean => {
  return state.auth.refreshTokenTimeout;
};

const getPortalVersion = (state: IAppState): number => {
  return state.auth.portalVersion;
};

const getAuthData = (state: IAppState): IAuthToken | null => {
  return state.auth.token;
};

export {
  getAuthErrors,
  getAuthStatus,
  getAuthToken,
  isLoggedIn,
  getAuthSchemas,
  notifyTokenTimeout,
  getPortalVersion,
  getAuthData,
};
