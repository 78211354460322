import React, { FunctionComponent, useEffect, useRef } from 'react';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import { useIntl } from 'react-intl';

// Keyboard accessible single date range selector

const SingleDateSelector: FunctionComponent<SingleDatePickerShape> = (props) => {
  const intl = useIntl();
  const singleDateSelectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const inputField = singleDateSelectorRef.current?.getElementsByTagName('input');
    const button = singleDateSelectorRef.current?.getElementsByTagName('button');
    if (inputField) {
      inputField[0].setAttribute('tabindex', '-1');
    }
    if (button) {
      button[0].setAttribute('aria-label', intl.formatMessage({ id: 'MursionPortal.Calendar' }));
    }
  }, [singleDateSelectorRef.current]);


  return (
    <div ref={singleDateSelectorRef}>
      <SingleDatePicker
        {...props}
        customInputIcon={<i className={'fa fa-calendar-alt'}/>}
      />
    </div>
  );
};

export default SingleDateSelector;
