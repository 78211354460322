import cn from 'classnames';
import * as React from 'react';
import styles from './IconButton.css';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export interface IIconButtonProps extends WrappedComponentProps {
  iconName: string;
  iconClassName?: string;
  label?: string | JSX.Element;
}

class IconButton extends React.Component<IIconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> {
  public render() {
    const {
      label,
      intl,
      className,
      iconName,
      iconClassName,
      ...rest
    } = this.props;
    const buttonClassName = cn([
      styles.iconButton,
      className,
    ]);

    return (
      <button className={buttonClassName} {...rest} tabIndex={0}>
        <i className={cn(`fas fa-${iconName}`, iconClassName)}/>
        <span className={styles.label}>{label}</span>
      </button>
    );
  }
}

export default injectIntl(IconButton);
