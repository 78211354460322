import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import { IAppState } from 'src/app/redux/store/reducers';
import IClientUser from 'src/app/data/client/interfaces/IClientUser';
import IRestError, { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import { parseErrorMessage } from 'src/app/data/common/utils/errorUtils';

export const getClientUsersList = (state: IAppState): IClientUser[] => state.clients.users.list.items;
export const getClientUsersListPage = (state: IAppState): number => state.clients.users.list.page || 0;
export const getClientUsersListSizePerPage = (state: IAppState): number => state.clients.users.list.sizePerPage || 0;
export const getClientUsersListTotalSize = (state: IAppState): number => state.clients.users.list.totalSize || 0;
export const getClientUserListRefreshError = (state: IAppState): IRestError | null => state.clients.users.list.refreshError;
export const isClientUserListFetched = (state: IAppState): boolean => state.clients.users.list.fetched;
export const isClientUserListRefreshing = (state: IAppState): boolean => state.clients.users.list.refreshing;
export const getErrorMessage = (state: IAppState): string => parseErrorMessage(state.clients.users.error);
export const getValidationErrors = (state: IAppState): IErrorValidation[] => state.clients.users.error.validations;

export const getClientLearnersList = (state: IAppState): ILearnerExtended[] => state.clients.users.listForClient.items;
export const getClientLearnersListPage = (state: IAppState): number => state.clients.users.listForClient.page || 0;
export const getClientLearnersListSizePerPage = (state: IAppState): number => state.clients.users.listForClient.sizePerPage || 0;
export const getClientLearnersListTotalSize = (state: IAppState): number => state.clients.users.listForClient.totalSize || 0;
export const getClientLearnerListRefreshError = (state: IAppState): IRestError | null => state.clients.users.listForClient.refreshError;
export const isClientLearnerListFetched = (state: IAppState): boolean => state.clients.users.listForClient.fetched;
export const isClientLearnerListRefreshing = (state: IAppState): boolean => state.clients.users.listForClient.refreshing;
export const getClientLearnerErrorMessage = (state: IAppState): string => parseErrorMessage(state.clients.users.listForClientError);
