import { useHistory, useLocation } from 'react-router';
import getUrlParamValue, { updateUrlParamsValue } from 'src/app/data/common/utils/queryParamsAccessor';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import ROUTE_PATHS from 'src/routing/paths';
import { useEffect, useState } from 'react';
import { SESSION_TYPE_PARAMS } from 'src/app/redux/modules/downloads/rest';
import { isNonCurrentPortalVersionForLearner } from 'src/app/data/common/portalVersion';
import { redirectionOnPortal } from 'src/app/data/common/utils/redirectionOnPortal';

export interface IUseRoleLinkParams {
  refreshing: boolean;
}

/**
 * Hook to switch a user role by params in url
 */

export const useRoleLinkHook = (): IUseRoleLinkParams => {
  const location = useLocation();
  const history = useHistory();
  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const authData = useSelector(selectors.auth.getAuthData);
  const roleParamValue = getUrlParamValue('role', location);
  const sessionType = getUrlParamValue(SESSION_TYPE_PARAMS, location);
  const scenarioId = getUrlParamValue('scenarioId', location);
  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState<boolean>(true);

  const switchRoleFromLink = async (roleId: string) => {
    try {
      setRefreshing(true);

      const from = location.state || { from: { pathname: ROUTE_PATHS.HOME } };
      if (scenarioId) {
        updateUrlParamsValue({ role: undefined, scenarioId }, from, history);
      } else {
        updateUrlParamsValue({ role: undefined, sessionType }, from, history);
      }

      await dispatch(actions.profile.clearUserData());
      await dispatch(actions.auth.switchRole(roleId));
      await dispatch(actions.profile.getUserProfile());

    } catch (e) {
      dispatch(actions.auth.logOut());
    }

    setRefreshing(false);
  };

  useEffect(() => {
    setRefreshing(false);

    if (roleParamValue && (!userRole || userRole.id !== roleParamValue)) {
      switchRoleFromLink(roleParamValue);
    }
  }, [location, userRole, roleParamValue]);

  useEffect(() => {
    if (isNonCurrentPortalVersionForLearner(authData?.portal_version, userRole?.id)) {
      redirectionOnPortal();
      dispatch(actions.auth.logOut());
    }
  }, [authData]);

  return {
    refreshing
  };
};

const UseRoleLink = (props: any) => {
  const childParams: IUseRoleLinkParams = useRoleLinkHook();

  return props.children(childParams);
};

export default UseRoleLink;
