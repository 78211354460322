import React, { FunctionComponent, useState } from 'react';
import styles from 'src/layouts/common/ClientUsers/components/NewClientUsers/components/AssignTeamsContainer/AssignTeamsContainer.css';
import cn from 'classnames';
import ITeam, { ITeamShort } from 'src/app/data/client/interfaces/ITeam';
import { useIntl } from 'react-intl';
import SearchField from 'src/components/SearchField';
import PlusIcon from 'src/layouts/common/ClientUsers/components/NewClientUsers/Images/plus.svg';
import EditIcon from 'src/layouts/common/ClientUsers/components/NewClientUsers/Images/edit.svg';
import CloseBlueIcon from 'src/layouts/common/ClientUsers/components/NewClientUsers/Images/crossBlue.svg';
import ErrorMessage from 'src/components/ErrorMessage/ErrorMessage';
import Tooltip, { TooltipPlacement, TooltipTheme } from 'src/components/Tooltip/Tooltip';
import RefreshIcon from 'src/layouts/common/ClientUsers/components/NewClientUsers/Images/refresh.svg';
import { IScenarioShort } from 'src/app/data/projects/interfaces/IScenario';
import { ISelectedPathways, ISelectedScenariosWithProject } from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/PersonalizationCommunication';
import { IItemBankList } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/filter/ItemBankFilters';
import { IProjectShort } from 'src/app/data/projects/interfaces/IProject';

export interface IAssignItem<T = ITeam | IScenarioShort | IItemBankList | IProjectShort> {
  isItemUpdationNotAllowed?: boolean;
  title: string;
  buttonTitle: string;
  searchTitle: string;
  searchText: string;
  onSearch: (text: string) => void;
  availableItems: T[];
  restAvailableItems: T[];
  selectedItems?: ITeamShort[];
  add: (itemId: string, itemName?: string,nameCustomized?:string|undefined) => () => void;
  remove: (itemId: string) => () => void;
  noItemAdded: string;
  error?: string;
  refreshItem?: () => void;
  className?: string;
  noItemAvailableToAddText?: string;
  type?: AssignItemType;
  subTypedSelectedItems?: ISelectedScenariosWithProject[] | ISelectedPathways[];
  toggleEditingAllow?: boolean;
  label?: string;
  createNewButtonText?: string; 
  onClickCreateNewBtn?: () => void;
}



export enum AssignItemType {
  DEFAULT = 'DEFAULT',
  SUBTYPE = 'SUBTYPE',
}

const AssignItem: FunctionComponent<IAssignItem> = (props) => {
  const {
    isItemUpdationNotAllowed,
    title,
    buttonTitle,
    searchTitle,
    searchText,
    onSearch,
    availableItems,
    restAvailableItems,
    selectedItems,
    add,
    remove,
    noItemAdded,
    error,
    refreshItem,
    className,
    noItemAvailableToAddText,
    type,
    subTypedSelectedItems,
    toggleEditingAllow = true,
    label,
    createNewButtonText,
    onClickCreateNewBtn,
  } = props;
  
  const [editMode, setEditMode] = useState(!toggleEditingAllow);
  const intl = useIntl();

  const toggleEditing = () => {
    if(refreshItem){
      refreshItem();
    }
    setEditMode(true);
  };
  const searchItem = (text: string) => {
    onSearch(text);
  };
  const addItem = (itemId: string, itemName: string,nameCustomized:string|undefined) => () => {
    add(itemId, itemName,nameCustomized)();
  };
  const removeItem = (itemId: string) => () => {
    remove(itemId)();
  };

  const renderSelectedItemsByType = (selected?: ITeamShort[]) => {
    return type === AssignItemType.SUBTYPE ? renderSubtypeSelectedItems() : renderDefaultSelectedItems(selected);
  };

  const renderSubtypeSelectedItems = () => {
    return (
      <>
        {subTypedSelectedItems?.map((item) => (
          <div key={`item-${item.id}`}>{item.name} {renderDefaultSelectedItems(item.scenarios)}</div>
        ))}
      </>
    );
  };

  function renderCreateNewBtnContainer() {
    if (createNewButtonText && onClickCreateNewBtn) {
      return <button onClick={onClickCreateNewBtn} aria-label={createNewButtonText} className={styles.createNewSkillBtn}>{createNewButtonText}</button>;
    }

    return null;
  }

  const renderDefaultSelectedItems = (selected?: ITeamShort[]) => {
    return (
      <>
        {selected && selected.length > 0 ? (
          <div className={styles.assignAddTeamUsers}>
            {selected.map((item) => (
              <div className={styles.assignAddTeamUserItem} key={`item-${item.id}`}>
                <div className={styles.assignAddTeamUserItemIn}>
                  <div className={styles.assignAddTeamUsertitle}>{item.nameCustomized?item.nameCustomized:item.name}</div>
                  {!isItemUpdationNotAllowed && editMode && (
                    <button
                      className={styles.assignAddTeamUserbtn}
                      aria-label={intl.formatMessage({ id: 'MursionPortal.AriaLabel.Remove' })}
                      onClick={removeItem(item.id)}
                    >
                      <img src={CloseBlueIcon} alt={intl.formatMessage({ id: 'MursionPortal.AriaLabel.Remove' })} />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>{noItemAdded}</div>
        )}
      </>
    );
  };

  return (
    <div className={cn(styles.assignTeamUserCard, className)}>
      <div className={styles.assignTeamUserCardIn}>
        {!isItemUpdationNotAllowed && (
          <div className={styles.assignTeamUserCardHeader}>
            <h3>{title}</h3>
            {label}
            {editMode && refreshItem && (
              <button className={styles.assignTeamRefreshbtn} aria-label={buttonTitle} onClick={refreshItem}>
                <Tooltip
                  placement={TooltipPlacement.TOP}
                  text={intl.formatMessage({ id: 'MursionPortal.Button.Refresh' })}
                  theme={TooltipTheme.COBALT}
                  className={styles.teamUserRefreshTooltip}
                >
                  <img src={RefreshIcon} alt={buttonTitle} />
                </Tooltip>
              </button>
            )}
              {toggleEditingAllow && <button className={styles.assignTeamUserbtn} aria-label={buttonTitle} onClick={toggleEditing}>
                <img src={EditIcon} alt={buttonTitle} />
                {buttonTitle}
              </button>
              }
          </div>
        )}
        {!isItemUpdationNotAllowed && editMode && (
          <div className={styles.assignTeamUserCardBody}>
            {(restAvailableItems.length > 0 || !!searchText) && (
              <div className={styles.assignTeamUserSearch}>
                <div className={cn(styles.assignTeamUserHeader, 'd-flex gap-2 flex-column-reverse flex-sm-row mb-2 justify-content-start justify-content-sm-between')}>
                  <div className={styles.searchSelectSimHeading}>{searchTitle}</div>
                  {renderCreateNewBtnContainer()}
                </div>
                <SearchField id={'search-team'} onChange={searchItem} value={searchText} />
              </div>
            )}

            {!isItemUpdationNotAllowed && !!availableItems &&
            (restAvailableItems.length ? (
              <div className={styles.assignTeamUsers}>
                {restAvailableItems.map((item) => (
                  <button
                    key={`item-${item.id}`}
                    className={styles.assignTeamUserItem}
                    aria-label={intl.formatMessage({ id: 'MursionPortal.AriaLabel.Add' })}
                    onClick={addItem(item.id, item.name,item.nameCustomized)}
                  >
                    <div className={styles.assignTeamUserItemIn}>
                      <div className={cn(styles.bodyContent, styles.sim)}>
                        <div> {item.nameCustomized?item.nameCustomized:item.name}</div>
                      </div>
                      <div className={cn(styles.bodyContent, styles.add)}>
                        <img src={PlusIcon} alt={intl.formatMessage({ id: 'MursionPortal.AriaLabel.Add' })} />
                      </div>
                    </div>
                  </button>
                ))}
              </div>
              ) : (
                noItemAvailableToAddText
            ))}
          </div>
        )}
        <div className={styles.assignTeamUserCardFooter}>
          {renderSelectedItemsByType(selectedItems)}
          <ErrorMessage className={styles.error} message={error} />
        </div>
      </div>
    </div>
  );
};

export default AssignItem;
