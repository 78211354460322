import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';
import { createClearFieldValidationErrorAction } from 'src/app/redux/utils/createClearFieldValidationErrorAction';
import rest from './rest';

export default {
  fetchLTISchemasList: actionFromPromise(rest.fetchLTISchemasList),
  createNewLtiSchema: actionFromPromise(rest.createNewLtiSchema, 'LTI', true),
  clearError: createClearEntityErrorAction('LTI'),
  clearFieldValidationError: createClearFieldValidationErrorAction('LTI'),
  updateLtiSchema: actionFromPromise(rest.updateLtiSchema, 'SSO', true),
  deleteLtiSchema: actionFromPromise(rest.deleteLtiSchema),
  deleteLtiRole: actionFromPromise(rest.deleteLtiRole),
  fetchLtiSchemaWithOptions: actionFromPromise(rest.fetchLtiSchemaWithOptions),
  createInstructorMapping: actionFromPromise(rest.createInstructorMapping),
  fetchInstructorMapping: actionFromPromise(rest.fetchInstructorMapping),
  checkInstructorMapping: actionFromPromise(rest.checkInstructorMapping),
};
