import SessionType from 'src/app/data/session/interfaces/SessionType';
import { IAppState } from 'src/app/redux/store/reducers';
import ITimeline from 'src/app/data/common/interfaces/ITimeline';
import IUser from 'src/app/data/common/interfaces/IUser';
import { EntityFilter } from 'src/app/data/calendar/interfaces/EntityFilter';
import ICalendarRange from 'src/layouts/common/Calendar/components/common/ICalendarRange';

export const getAvailableLearnersCount = (state: IAppState): number => state.calendar.availableLearnersCount;
export const getCalendarTimezoneId = (state: IAppState): string => state.calendar.timezoneId;
export const getCertificateId = (state: IAppState): string => state.calendar.certificateId;
export const getDeliveryMode = (state: IAppState): SessionType | '' => state.calendar.deliveryMode;
export const getScenarioTimeline = (state: IAppState): ITimeline => state.calendar.scenarioTimeline;
export const getSelectedLearners = (state: IAppState): IUser[] => [...state.calendar.selectedLearners];
export const getSessionLength = (state: IAppState): number => state.calendar.sessionLength;
export const getSelectedClientsIds = (state: IAppState): string[] => [...state.calendar.clientIds];
export const getSelectedProjectsIds = (state: IAppState): string[] => [...state.calendar.projectIds];
export const getSelectedFiltersIds = (state: IAppState) => state.calendar.filterIds;
export const getSelectedSimspecialistIds = (state: IAppState): string[] => [...state.calendar.simspecialistIds];
export const getCurrentClientId = (state: IAppState): string => state.calendar.currentClientId;
export const getCurrentSimSpecialistId = (state: IAppState): string => state.calendar.currentSimSpecialistId;
export const getCurrentEntityFilter = (state: IAppState): EntityFilter | null => state.calendar.currentFilter;
export const getCurrentProjectId = (state: IAppState): string => state.calendar.currentProjectId;
export const getCurrentScenarioId = (state: IAppState): string => state.calendar.currentScenarioId;
export const getShowOverviewPanel = (state: IAppState): boolean =>
  !!(getCurrentClientId(state) || getCurrentProjectId(state) || getCurrentSimSpecialistId(state));
export const isSchedulingBlocked = (state: IAppState): boolean => state.calendar.schedulingBlocked;
export const isEventViewDialogOpened = (state: IAppState): boolean => state.calendar.isEventViewDialogOpened;
export const getRange = (state: IAppState): ICalendarRange => state.calendar.range;
export const getDate = (state: IAppState): number | null => state.calendar.selectedDate;
export const getEntityIndustriesFilter = (state: IAppState): string[] | null => state.calendar.entityIndustriesFilter;
export const getEntitySearchFilter = (state: IAppState): string => {
  if (!state.calendar.currentFilter) {
    return '';
  }

  return state.calendar.entitySearchFilters[state.calendar.currentFilter];
};
