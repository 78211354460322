import IClient from 'src/app/data/client/interfaces/IClient';
import { IAppState } from 'src/app/redux/store/reducers';
import IRestError, { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import IProjectsCount from 'src/app/data/client/interfaces/IProjectsCount';
import { parseErrorMessage } from 'src/app/data/common/utils/errorUtils';
import { getEntityIndustriesFilter } from 'src/app/redux/selectors/calendar/calendarSelectors';

export const getClientsList = (state: IAppState): IClient[] => {
  const entityIndustriesFilter = getEntityIndustriesFilter(state);

  if (!entityIndustriesFilter) {
    return state.clients.list.items;
  }

  return state.clients.list.items.filter(client => entityIndustriesFilter.includes(client.industryId));
};
export const getClientsListPage = (state: IAppState): number => state.clients.list.page || 0; // TODO make the property required
export const getClientsListRefreshError = (state: IAppState): IRestError | null => state.clients.list.refreshError;
export const getClientsListSizePerPage = (state: IAppState): number => state.clients.list.sizePerPage || 0; // TODO make the property required
export const getClientsListTotalSize = (state: IAppState): number => state.clients.list.totalSize || 0; // TODO make the property required
export const isClientsListFetched = (state: IAppState): boolean => state.clients.list.fetched;
export const isClientsListRefreshing = (state: IAppState): boolean => state.clients.list.refreshing;
export const getDashboardClient = (state: IAppState): IClient | null => state.clients.clientForDashboard.item;
export const isDashboardClientLoading = (state: IAppState): boolean => state.clients.clientForDashboard.fetching;
export const getProjectsCount = (state: IAppState): IProjectsCount | null => state.clients.projectsCount.item;
export const getClientValidationErrors = (state: IAppState): IErrorValidation[] => state.clients.error.validations;
export const getErrorMessage = (state: IAppState): string => parseErrorMessage(state.clients.error);
