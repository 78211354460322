import IRestError from "src/app/data/common/interfaces/IRestError";
import { IAppState } from 'src/app/redux/store/reducers';


export const getAssetProject = (state: IAppState) => state.assets.assetProject.item;
export const isAssetProjectFetched = (state: IAppState): boolean => state.assets.assetProject.fetched;
export const getAssetProjectRefreshError = (state: IAppState): IRestError | null => state.assets.assetProject.fetchError;
export const isAssetProjectRefreshing = (state: IAppState): boolean => state.assets.assetProject.fetching;

export const getList = (state: IAppState) => state.assets.assetProjectList.items;
export const getListPage = (state: IAppState): number => state.assets.assetProjectList.page || 0;
export const getListSizePerPage = (state: IAppState): number => state.assets.assetProjectList.sizePerPage || 0;
export const getListTotalSize = (state: IAppState): number => state.assets.assetProjectList.totalSize || 0;
export const isListFetched = (state: IAppState): boolean => state.assets.assetProjectList.fetched;
export const getListRefreshError = (state: IAppState): IRestError | null => state.assets.assetProjectList.refreshError;
export const isListRefreshing = (state: IAppState): boolean => state.assets.assetProjectList.refreshing;

export const getSettings = (state: IAppState) => state.assets.settings.item;
export const isSettingsFetched = (state: IAppState): boolean => state.assets.settings.fetched;
export const getSettingsRefreshError = (state: IAppState): IRestError | null => state.assets.settings.fetchError;
export const isSettingsRefreshing = (state: IAppState): boolean => state.assets.settings.fetching;

export const getSettingsByScenarioList = (state: IAppState) => state.assets.settingsListByScenario.items;
export const getSettingsByScenarioListPage = (state: IAppState): number => state.assets.settingsListByScenario.page || 0;
export const getSettingsByScenarioListSizePerPage = (state: IAppState): number => state.assets.settingsListByScenario.sizePerPage || 0;
export const getSettingsByScenarioListTotalSize = (state: IAppState): number => state.assets.settingsListByScenario.totalSize || 0;
export const isSettingsByScenarioListFetched = (state: IAppState): boolean => state.assets.settingsListByScenario.fetched;
export const getSettingsByScenarioListRefreshError = (state: IAppState): IRestError | null => state.assets.settingsListByScenario.refreshError;
export const isSettingsByScenarioListRefreshing = (state: IAppState): boolean => state.assets.settingsListByScenario.refreshing;

export const getSettingsByScenarioTemplateList = (state: IAppState) => state.assets.settingsListByScenarioTemplate.items;
export const getSettingsByScenarioTemplateListPage = (state: IAppState): number => state.assets.settingsListByScenarioTemplate.page || 0;
export const getSettingsByScenarioTemplateListSizePerPage = (state: IAppState): number => state.assets.settingsListByScenarioTemplate.sizePerPage || 0;
export const getSettingsByScenarioTemplateListTotalSize = (state: IAppState): number => state.assets.settingsListByScenarioTemplate.totalSize || 0;
export const isSettingsByScenarioTemplateListFetched = (state: IAppState): boolean => state.assets.settingsListByScenarioTemplate.fetched;
export const getSettingsByScenarioTemplateListRefreshError = (state: IAppState): IRestError | null => state.assets.settingsListByScenarioTemplate.refreshError;
export const isSettingsByScenarioTemplateListRefreshing = (state: IAppState): boolean => state.assets.settingsListByScenarioTemplate.refreshing;
