import RestService from "src/app/services/rest/RestService";
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import ITier from 'src/app/redux/modules/company/interfaces/ITier';
import { intl } from 'src/i18n/createIntl';

const fetchConfig = (licenseeId: string = ''): Promise<ICompanyConfig> => {
  if (!licenseeId){
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/licensee/${licenseeId}/config`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateConfig = (companyConfig: ICompanyConfig, licenseeId: string = ''): Promise<ICompanyConfig> => {
  if (!licenseeId){
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/licensee/${licenseeId}/config`, {
    body: JSON.stringify(companyConfig),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const testConfig = (companyConfig: ICompanyConfig, licenseeId: string = ''): Promise<ICompanyConfig> => {
  if (!licenseeId){
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/licensee/${licenseeId}/config/test`, {
    body: JSON.stringify(companyConfig),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const fetchLicenseeTiers = (licenseeId: string): Promise<ITier[]> => {
  if (!licenseeId){
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/licensee/${licenseeId}/sstiers`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateLicenseeTiers = (licenseeId: string, list: ITier[]): Promise<ITier[]> => {
  if (!licenseeId){
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/licensee/${licenseeId}/sstiers`, {
    body: JSON.stringify(list),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};


export default {
  fetchConfig,
  testConfig,
  updateConfig,
  fetchLicenseeTiers,
  updateLicenseeTiers,
};
