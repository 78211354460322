import { AnyAction } from 'redux';
import createReducer, { RESET_STORE } from 'src/app/redux/utils/createReducer';
import IProfileState from './interfaces/IProfileState';
import * as types from './types';

const INITIAL_STATE: IProfileState = null;

export default createReducer(INITIAL_STATE, {

  [types.CLEAR_USER_DATA]: (): symbol => RESET_STORE,

  [types.RECEIVED_USER_PROFILE]: (state: IProfileState, action: AnyAction): IProfileState => (action.profile),

});
