import { combineReducers } from 'redux';
import { ICalendarReduxState } from 'src/app/redux/modules/calendar/reducer';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import { IMessagePanelReduxState } from 'src/app/redux/modules/messagePanel/reducer';
import { IScenariosReduxState } from 'src/app/redux/modules/scenarios/reducer';
import IAuthState from '../modules/auth/interfaces/IAuthState';
import auth from '../modules/auth/reducer';
import avatars, { IAvatarsReduxState } from '../modules/avatars/reducer';
import calendar from '../modules/calendar/reducer';
import calendarEvents, { ICalendarEventsReduxState } from '../modules/calendarEvents/reducer';
import createEventDialog, { ICreateEventDialogReduxState } from '../modules/createEventDialog/reducer';
import clients, { IClientsState } from '../modules/clients/reducer';
import clientsConfig, { IClientsConfigState } from '../modules/clientsConfig/reducer';
import company, { ICompanyState } from '../modules/company/reducer';
import companyConfig, { ICompanyConfigState } from '../modules/companyConfig/reducer';
import config, { IConfigState } from '../modules/config/reducer';
import environments, { IEnvironmentReduxState } from '../modules/environments/reducer';
import entityHistory, { IEntityHistoryReduxState } from '../modules/entityHistory/reducer';
import errors from '../modules/errors/reducer';
import industries, { IIndustriesReduxState } from '../modules/industries/reducer';
import layout, { ILayoutReduxState } from '../modules/layout/reducer';
import assets, { IAssetReduxState } from '../modules/assets/reducer';
import messagePanel from '../modules/messagePanel/reducer';
import IProfileState from '../modules/profile/interfaces/IProfileState';
import profile from '../modules/profile/reducer';
import projects, { IProjectsReduxState } from '../modules/projects/reducer';
import scenarios from '../modules/scenarios/reducer';
import teams, { ITeamsReduxState } from '../modules/teams/reducer';
import IWebSocketState from '../modules/ws/interfaces/IWebSocketState';
import ws from '../modules/ws/reducer';
import { ISessionState } from 'src/app/redux/modules/session/reducer';
import certificates, { ICertificatesReduxState } from '../modules/certificates/reducer';
import sessions from '../modules/session/reducer';
import sessionWizard, { ISessionWizardState } from '../modules/sessionWizard/reducer';
import sso, { ISsoState } from 'src/app/redux/modules/sso/reducer';
import lti, { ILtiState } from 'src/app/redux/modules/integrations/lti/reducer';
import contracts,{ IContractsReduxState } from '../modules/contract/reducer';

export interface IAppState {
  auth: IAuthState;
  avatars: IAvatarsReduxState;
  calendar: ICalendarReduxState;
  certificates: ICertificatesReduxState;
  calendarEvents: ICalendarEventsReduxState;
  createEventDialog: ICreateEventDialogReduxState;
  clients: IClientsState;
  clientsConfig: IClientsConfigState;
  company: ICompanyState;
  companyConfig: ICompanyConfigState;
  config: IConfigState;
  environments: IEnvironmentReduxState;
  entityHistory: IEntityHistoryReduxState;
  errors: IErrorState;
  industries: IIndustriesReduxState;
  layout: ILayoutReduxState;
  assets: IAssetReduxState;
  messagePanel: IMessagePanelReduxState;
  projects: IProjectsReduxState;
  profile: IProfileState;
  scenarios: IScenariosReduxState;
  sessions: ISessionState;
  sessionWizard: ISessionWizardState;
  sso: ISsoState;
  lti: ILtiState;
  teams: ITeamsReduxState;
  ws: IWebSocketState;
  contracts:IContractsReduxState;
}

export default combineReducers({
  auth,
  avatars,
  calendar,
  certificates,
  calendarEvents,
  createEventDialog,
  clients,
  clientsConfig,
  company,
  companyConfig,
  config,
  environments,
  entityHistory,
  errors,
  industries,
  layout,
  assets,
  messagePanel,
  profile,
  projects,
  scenarios,
  sessions,
  sessionWizard,
  sso,
  lti,
  teams,
  ws,
  contracts
});
