import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';

export default {
    fetchNextGenScenarioEventScoring: actionFromPromise(rest.fetchNextGenScenarioEventScoring, 'SCORING', true),
    createNextGenScenarioEventScoring: actionFromPromise(rest.createNextGenScenarioEventScoring),
    fetchNextGenScenarioEventScoringDetails: actionFromPromise(rest.fetchNextGenScenarioEventScoringDetails, 'SCORING', true),
    fetchBuildingBlockList: actionFromPromise(rest.fetchBuildingBlockList),
    updateNextGenScenarioEventScoring: actionFromPromise(rest.updateNextGenScenarioEventScoring),
    fetchEventDetailList: actionFromPromise(rest.fetchEventDetailList),
    createNextGenEventDetail: actionFromPromise(rest.createNextGenEventDetail),
    updateNextGenEventDetail: actionFromPromise(rest.updateNextGenEventDetail),
    deleteEvent: actionFromPromise(rest.deleteEvent),
    copyEventAndScoringIds: actionFromPromise(rest.copyEventAndScoringIds),
    mindsetArchive: actionFromPromise(rest.mindsetArchive),
    assessmentArchive: actionFromPromise(rest.assessmentArchive),
    updateNextgenEventSequence: actionFromPromise(rest.updateNextgenEventSequence),
};