import { ControlProps } from 'react-select/dist/declarations/src/components/Control';
import { components } from 'react-select';
import React from 'react';

export interface IControlProps {
  onToggleMenu: () => void;
  controlProps: ControlProps<any, any>;
}

const SelectorControl = (props: IControlProps) => {

  const { onToggleMenu, controlProps } = props;

  const onLabelClick = () => onToggleMenu();

  return (
    <div onClick={onLabelClick}>
      <components.Control {...controlProps}>
        {controlProps.children}
      </components.Control>
    </div>
  );
};

export default SelectorControl;