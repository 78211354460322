exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".RemovableLabel__removableLabel___2QMWJ,.RemovableLabel__withRemoveButton___1EpRm{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-wrap:nowrap;flex-wrap:nowrap}.RemovableLabel__withRemoveButton___1EpRm{padding-right:0;background:var(--color-gray-12)}.RemovableLabel__withRemoveButton___1EpRm:focus-within{outline:1px dotted var(--color-gray-13)!important}.RemovableLabel__text___1Yala{display:-ms-inline-flexbox;display:inline-flex;-ms-flex-align:center;align-items:center;margin-top:1px;color:var(--color-black)}.RemovableLabel__iconRemove___3YPZS{color:var(--color-white)}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/RemovableLabel/RemovableLabel.css"],"names":[],"mappings":"AASA,kFARE,oBAAqB,AACrB,aAAc,AACd,sBAAuB,AACnB,mBAAoB,AACxB,qBAAsB,AAClB,gBAAkB,CAYvB,AATD,0CACE,gBAAiB,AAOjB,+BAAiC,CAClC,AACD,uDACE,iDAAoD,CACrD,AAGD,8BACE,2BAA4B,AAC5B,oBAAqB,AACrB,sBAAuB,AACnB,mBAAoB,AACxB,eAAgB,AAChB,wBAA0B,CAC3B,AAED,oCACE,wBAA0B,CAC3B","file":"RemovableLabel.css","sourcesContent":[".removableLabel {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-wrap: nowrap;\n      flex-wrap: nowrap;\n}\n\n.withRemoveButton {\n  padding-right: 0;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-wrap: nowrap;\n      flex-wrap: nowrap;\n  background: var(--color-gray-12);\n}\n.withRemoveButton:focus-within{\n  outline: 1px dotted var(--color-gray-13) !important;\n}\n\n\n.text {\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -ms-flex-align: center;\n      align-items: center;\n  margin-top: 1px;\n  color: var(--color-black);\n}\n\n.iconRemove {\n  color: var(--color-white);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"removableLabel": "RemovableLabel__removableLabel___2QMWJ",
	"withRemoveButton": "RemovableLabel__withRemoveButton___1EpRm",
	"text": "RemovableLabel__text___1Yala",
	"iconRemove": "RemovableLabel__iconRemove___3YPZS"
};