import createItemActions from "src/app/redux/utils/createItemActions";
import rest from './rest';
import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';

export default {
  clearError: createClearEntityErrorAction('COMPANY_CONFIG'),
  fetchConfig: createItemActions('COMPANY_CONFIG', rest.fetchConfig),
  testConfig: actionFromPromise(rest.testConfig, 'COMPANY_CONFIG'),
  updateConfig: actionFromPromise(rest.updateConfig, 'COMPANY_CONFIG'),
};
