import React, { forwardRef } from 'react';
import styles from './TimeRangeSlider.css';
import { Handle } from 'rc-slider';
import moment from 'moment-timezone';
import { getTimeFormat } from 'src/app/data/common/utils/dateUtil';

const TimeRangeSliderHandle = forwardRef((props: any, ref) => {

  const { value, dragging, index, tzId, type, ...rest } = props;

  return (
    <Handle value={value} className={styles.handle} {...rest}>
      <div className={styles.handleValue}>{moment.tz(value, tzId).format(getTimeFormat())}</div>
    </Handle>
  );
});

export default TimeRangeSliderHandle;