import React, { Dispatch, RefObject, SetStateAction, useCallback, useMemo } from 'react';
import { ContainerProps, ValueContainerProps } from 'react-select/dist/declarations/src/components/containers';
import { ControlProps } from 'react-select/dist/declarations/src/components/Control';
import { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu';
import { ISelectorBaseProps } from 'src/components/Selector/ISelectorProps';
import SelectorDropdownIndicator from 'src/components/Selector/components/common/SelectorDropdownIndicator/SelectorDropdownIndicator';
import SingleSelectorOption from 'src/components/Selector/components/singleselect/SingleSelectorOption/SingleSelectorOption';
import MultiSelectorOption from 'src/components/Selector/components/multiselect/MultiSelectorOption/MultiSelectorOption';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import SelectorValueContainer from 'src/components/Selector/components/multiselect/SelectorValueContainer/SelectorValueContainer';
import SelectorControl from 'src/components/Selector/components/multiselect/SelectorControl/SelectorControl';
import SelectorMenuPortal from 'src/components/Selector/components/multiselect/SelectorMenuPortal/SelectorMenuPortal';
import ValueLineContainer from 'src/components/Selector/components/common/ValueLineContainer/ValueLineContainer';
import { ISelectorOption } from 'src/components/Selector/Selector';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

type StateProp<S> = [S, Dispatch<SetStateAction<S>>];

type UseSelectorComponents = (
  props: ISelectorBaseProps,
  selectorRef: RefObject<any>,
  inputValueState: StateProp<string>,
  menuIsOpenState: StateProp<boolean>,
  onMenuClose: () => void,
) => Partial<SelectComponents<any, any, any>>;

const useCustomSelector: UseSelectorComponents = (
  props,
  selectorRef,
  [inputValue, setInputValue],
  [menuIsOpen, setMenuIsOpen],
  onMenuClose,
) => {

  const {
    disabled,
    defaultValue,
    isMulti,
    themeType = SelectorTheme.Default,
    placeholder,
    searchPlaceholder,
    valuePlaceholder,
    isValueLineContainer = false,
    total,
    isSelectAll = false,
  } = props;

  const showValueLine = useMemo(() => themeType === SelectorTheme.Cobalt, [themeType]);

  const isSelectAllOptions = useMemo(() => {
    const { value, itemsInfo } = props;
    if(Array.isArray(value) && itemsInfo && itemsInfo.items.length > 0){
      return value.length === itemsInfo.items.length;
    }
    return false;
  }, [props.value]);

  const clearSelectedItems = useCallback(() => {
    if (!selectorRef.current) {
      return;
    }

    if (!isMulti) {
      const defaultSingleValue: ISelectorOption<any> = defaultValue as ISelectorOption<any> || undefined;
      selectorRef.current.setValue(defaultSingleValue, 'set-value');
      props.onChange(defaultSingleValue);
    }

    selectorRef.current.clearValue();
    props.onChange([]);
  }, [selectorRef.current]);

  const onToggleMenu = () => setMenuIsOpen(!menuIsOpen);

  const ValueContainer = useCallback((valueContainerProps: ValueContainerProps<any, any, any>) => (
    <SelectorValueContainer
      disabled={!!disabled}
      placeholder={placeholder}
      valueContainerProps={valueContainerProps}
      ariaExpanded={menuIsOpen}
      onToggleMenu={onToggleMenu}
      ariaDisabled={disabled}
      showSelectedValues={!showValueLine}
    />
  ), [disabled, placeholder, menuIsOpen]);

  const Control = useCallback((controlProps: ControlProps<any, any>) => (
    <SelectorControl
      onToggleMenu={onToggleMenu}
      controlProps={controlProps}
    />
  ), [menuIsOpen]);

  const selectAllItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { itemsInfo } = props;
    if(event.target.checked && itemsInfo){
      const fetchValues = [...itemsInfo?.items] || [];
      const dataV = [...fetchValues.map((v: any) => ({value: v.id, label: v.name}))];
      selectorRef.current.setValue(dataV, 'set-value');
      props.onChange(dataV);
    }else{
      selectorRef.current.clearValue();
      props.onChange([]);
    }
  };

  const MenuPortal = useCallback((menuProps: MenuPortalProps<any, any, any>) => (
    <SelectorMenuPortal
      searchPlaceholder={searchPlaceholder}
      total={isMulti ? total : 0}
      menuProps={menuProps}
      search={inputValue}
      onSearchChange={setInputValue}
      onMenuClose={onMenuClose}
      showSelected={isMulti ? themeType === SelectorTheme.Cobalt : false}
      onClearSelectedItems={clearSelectedItems}
      inputAriaLabel={placeholder}
      themeType={themeType}
      onSelectAllItems={selectAllItems}
      isMulti={isMulti}
      isSelectAll={isSelectAll}
      isSelectAllOptions={isSelectAllOptions}
    />
  ), [
    isMulti, searchPlaceholder, total,
    /*inputValue,*/ setInputValue,
    onMenuClose, themeType,
    clearSelectedItems, placeholder, themeType, isSelectAllOptions
  ]);

  const WithValueLineContainer = useCallback((containerProps: ContainerProps<any, any, any>) => (
    <ValueLineContainer
      containerProps={containerProps}
      disabled={!!disabled}
      clearSelectedItems={clearSelectedItems}
      valuePlaceholder={valuePlaceholder}
      isValueLineContainer={isValueLineContainer}
    />
  ), [disabled, placeholder, valuePlaceholder, isValueLineContainer]);

  const MultiSelectorOptionCb = useCallback((optionProps: OptionProps<any, any>) => (
    <MultiSelectorOption
      optionProps={optionProps}
    />
  ), []);


  let selectComponents: Partial<SelectComponents<any, any, any>> = {
    DropdownIndicator: SelectorDropdownIndicator,
    Option: SingleSelectorOption,
  };

  if (isMulti) {
    selectComponents = {
      ...selectComponents,
      MenuPortal,
      Control,
      ValueContainer,
      Option: MultiSelectorOptionCb,
    };
  }

  if (showValueLine) {
    selectComponents = {
      ...selectComponents,
      MenuPortal,
      ValueContainer,
      SelectContainer: WithValueLineContainer,
    };
  }

  return selectComponents;
};

export default useCustomSelector;
