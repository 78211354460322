import { IAppState } from 'src/app/redux/store/reducers';
import ICalendarEvent from 'src/app/data/calendar/interfaces/ICalendarEvent';
import { parseErrorMessage } from 'src/app/data/common/utils/errorUtils';
import IRestError from 'src/app/data/common/interfaces/IRestError';

export const getDashboardEvents = (state: IAppState): ICalendarEvent[] => state.calendarEvents.listForDashboard.items;

export const getEditSessionEvents = (state: IAppState): ICalendarEvent[] => state.calendarEvents.listForEditSession.items;
export const getEditSessionListPage = (state: IAppState): number => state.calendarEvents.listForEditSession.page || 0;
export const getEditSessionListSizePerPage = (state: IAppState): number => state.calendarEvents.listForEditSession.sizePerPage || 0;
export const getEditSessionListTotalSize = (state: IAppState): number => state.calendarEvents.listForEditSession.totalSize || 0;
export const getEditSessionListRefreshError = (state: IAppState): IRestError | null => state.calendarEvents.listForEditSession.refreshError;
export const isEditSessionListFetched = (state: IAppState): boolean => state.calendarEvents.listForEditSession.fetched;
export const isEditSessionListRefreshing = (state: IAppState): boolean => state.calendarEvents.listForEditSession.refreshing;


export const getErrorMessage = (state: IAppState): string => parseErrorMessage(state.calendarEvents.error);
