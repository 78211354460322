import IRestError, { IErrorValidation } from "src/app/data/common/interfaces/IRestError";
import { IAppState } from 'src/app/redux/store/reducers';
import ITeamClientView from 'src/app/data/client/interfaces/ITeamClientView';
import ITeam from 'src/app/data/client/interfaces/ITeam';

export const getList = (state: IAppState): ITeam[] => state.teams.list.items;
export const getListPage = (state: IAppState): number => state.teams.list.page || 0;
export const getListSizePerPage = (state: IAppState): number => state.teams.list.sizePerPage || 0;
export const getListTotalSize = (state: IAppState): number => state.teams.list.totalSize || 0;
export const isListFetched = (state: IAppState): boolean => state.teams.list.fetched;
export const getListRefreshError = (state: IAppState): IRestError | null => state.teams.list.refreshError;
export const isListRefreshing = (state: IAppState): boolean => state.teams.list.refreshing;

export const getListForClientView = (state: IAppState): ITeamClientView[] => state.teams.listForClientView.items;
export const getListForClientViewPage = (state: IAppState): number => state.teams.listForClientView.page || 0;
export const getListForClientViewSizePerPage = (state: IAppState): number => state.teams.listForClientView.sizePerPage || 0;
export const getListForClientViewTotalSize = (state: IAppState): number => state.teams.listForClientView.totalSize || 0;
export const isListForClientViewFetched = (state: IAppState): boolean => state.teams.listForClientView.fetched;
export const getListForClientViewRefreshError = (state: IAppState): IRestError | null => state.teams.listForClientView.refreshError;
export const isListForClientViewRefreshing = (state: IAppState): boolean => state.teams.listForClientView.refreshing;

export const getTeamValidationErrors = (state: IAppState): IErrorValidation[] => state.teams.error.validations;
export const getErrorMessage = (state: IAppState): string => state.teams.error.message;
