import IClient, { IClientExt, IClientUpdate, INewClient } from 'src/app/data/client/interfaces/IClient';
import IProject from 'src/app/data/projects/interfaces/IProject';
import RestService from 'src/app/services/rest/RestService';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import IProjectsCount from 'src/app/data/client/interfaces/IProjectsCount';
import IClientsCount from 'src/app/data/client/interfaces/IClientsCount';
import generateFormData from 'src/app/data/common/utils/generateFormData';
import IEntityQuery from 'src/app/data/common/interfaces/IEntityQuery';
import stringifyEntityQuery from 'src/app/data/common/utils/stringifyEntityQuery';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { intl } from 'src/i18n/createIntl';
import queryString from 'querystring';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import { IClientUsersQuery } from 'src/app/redux/modules/clients/users/rest';
import { ICustomizeEmailSettings, ICustomizeLearnerInvitationsMsg } from 'src/layouts/common/InviteToSchedulePage/components/CustomizeLearnerInvitations/CustomizeLearnerInvitations';
import { ICustomizedEmailSettingMetaData } from 'src/layouts/common/Engagement/components/EmailSettings/EmailSettingHistory';
import { IEmailPreview } from 'src/layouts/common/Engagement/components/EmailSettings/EmailSettingsData';
import { IEulaOption } from 'src/layouts/common/EULA/EULA';
import { IUserProfile } from 'src/app/data/profile/interfaces';

export interface IScheduleInvite {
  id: string;
  learnersCount: number;
  triggerBy: string;
  triggerDate: number;
  userRole: string;
  processedDate: string;
  scheduledDate: string;
}

const createNewClient = (clientData: INewClient, logo: File | null): Promise<IClient> => {
  const updatedClientData = { ...clientData, timezoneId: clientData.timezoneId || null };

  return RestService.fetch(`${RestService.REST_URL}/client`, {
    body: generateFormData(updatedClientData, 'client', logo),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const deleteClient = (clientID: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/client/${clientID}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const getClientDetail = (clientID: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/client/${clientID}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateClientDomains = (clientID: string, domains: string[]): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/client/${clientID}/domain`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: JSON.stringify({ domains })
  });
};

const restoreClient = (clientID: string): Promise<IClient> => {
  return RestService.fetch(`${RestService.REST_URL}/client/restore/${clientID}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchClient = (clientId: string, query?: IEntityQuery | null, signal: AbortSignal | null = null): Promise<IClient> => {
  return fetchClientWithOptions({ signal, clientId, query });
};

const fetchClientName = (clientID: string): Promise<string> => {
  return fetchClient(clientID, { nameOnly: true }).then(client => client.name || '');
};

export interface IClientProjectsListQuery {
  clientId: string;
  page: number;
  size: number;
  archive?: 'all' | 'active' | 'archived';
  asc?: boolean; // true if undefined
  filter?: string;
  order?: 'id' | 'name' | 'client' | 'hours' | 'accManagerCount' | 'billable' | 'contractId' | 'cancellationWindow' | 'startDate' | 'endDate' |
    'scenarioVersion' | 'createdBy' | 'createdDate' | 'lastModifiedBy' | 'lastModifiedDate' | 'licenseeName' | 'clientName';
  status?: 'active' | 'completed';
  learnerDashboard?: boolean;
}

const fetchClientProjects = (query: IClientProjectsListQuery): Promise<IProject[]> => {
  const  {
    clientId,
    page,
    size,
    ...rest
  } = query;

  if (!clientId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  const restParams = _omitBy(rest, _isUndefined);

  // todo: change entry point after backend implementation
  return RestService.fetch(`${RestService.REST_URL}/project/list/${clientId}/${page}/${size}?${queryString.stringify(restParams)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((listResponse) => {
    return listResponse.content || [];
  });
};

const fetchProjectsCount = (clientID: string): Promise<IProjectsCount> => {
  if (!clientID) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/client/${clientID}/projectCount`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchClientProjectsResponse = (clientID?: string): Promise<IListDataResponse<IProject>> => {
  // todo: change entry point after backend implementation
  return RestService.fetch(`${RestService.REST_URL}/project/list/${clientID ? clientID + '/' : ''}0/1000`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchClientsList = (pageData: IListPageData, hasPrivateSso?: boolean): Promise<IListDataResponse<IClientExt>> => {
  return RestService.fetch(`${RestService.REST_URL}/client/list${getPageDataUrl(pageData, { hasPrivateSso })}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchClientNamesListWithOptions = (params: IPageRequestParams & {
  licenseeIds?: string[] | null,
  clientName?: string,
}): Promise<IListDataResponse<INamedEntry>> => {
  const { pageData, licenseeIds, clientName, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/client/list/short`, {
    body: JSON.stringify({
      clientName,
      licenseeIds: { value: licenseeIds },
      ...pageData
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal
  });
};

const updateClient = (clientID: string, clientData: IClientUpdate, logo: File | null): Promise<IClient> => {
  const updatedClientData = { ...clientData, timezoneId: clientData.timezoneId || null };

  return RestService.fetch(`${RestService.REST_URL}/client/${clientID}`, {
    body: generateFormData(updatedClientData, 'client', logo),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const fetchClientsListWithOptions = (params: IPageRequestParams & { hasPrivateSso?: boolean, practiceSession?: boolean }): Promise<IListDataResponse<IClientExt>> => {
  const { pageData, hasPrivateSso, signal, practiceSession } = params;

  return RestService.fetch(`${RestService.REST_URL}/client/list${getPageDataUrl(pageData, { hasPrivateSso, practiceSession })}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchClientsListWithoutSsoWithOptions = (params: { size: number, filter: string }): Promise<INamedEntry[]> => {
  const { size, filter } = params;

  return RestService.fetch(`${RestService.SSO_REST_URL}/client/list?${queryString.stringify({ size, filter })}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchClientsCountWithOptions = (params: IAbortableRequestParams): Promise<IClientsCount> => {
  const { signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/licensee/clientCount`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchProjectsCountWithOptions = (params: IAbortableRequestParams & { clientId: string }): Promise<IProjectsCount> => {
  const { clientId, signal } = params;

  if (!clientId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/client/${clientId}/projectCount`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchClientWithOptions = (params: IAbortableRequestParams & { clientId: string, query?: IEntityQuery | null }): Promise<IClient> => {
  const { clientId, query, signal } = params;

  if (!clientId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/client/${clientId}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const projectSessionScheduleInvitation = (projectId: string, requestTableFilters?: IClientUsersQuery, learners?: string[], customizeInvitationData?: ICustomizeLearnerInvitationsMsg): Promise<undefined> => {
  
  const fetchExcludedLearners = {
    ...requestTableFilters,
    exclude: learners,
    invitationSenderName: customizeInvitationData?.senderName,
    invitationSubject: customizeInvitationData?.subject,
    invitationIntroMessage: customizeInvitationData?.introMsg,
    sendDate: customizeInvitationData?.sendDate?.valueOf(),
    ctaButtonText: customizeInvitationData?.customizeBtnText,
  };
  
  return RestService.fetch(`${RestService.REST_URL}/project/sessionScheduleInvitation/${projectId}`, {
    body: JSON.stringify(fetchExcludedLearners),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json ',
    }),
    method: 'POST',
  });
};

const updateSessionScheduleInvitation = (
  inviteId: string,
  requestTableFilters?: IClientUsersQuery,
  learners?: string[],
  customizeInvitationData?: ICustomizeLearnerInvitationsMsg): Promise<undefined> => {

  const fetchExcludedLearners = {
    ...requestTableFilters,
    exclude: learners,
    invitationSenderName: customizeInvitationData?.senderName,
    invitationSubject: customizeInvitationData?.subject,
    invitationIntroMessage: customizeInvitationData?.introMsg,
    sendDate: customizeInvitationData?.sendDate?.valueOf(),
    ctaButtonText: customizeInvitationData?.customizeBtnText,
  };
  
  return RestService.fetch(`${RestService.REST_URL}/inviteToSchedule/updateInvitationsDetails/${inviteId}`, {
    body: JSON.stringify(fetchExcludedLearners),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json ',
    }),
    method: 'POST',
  });
};

const sendScenarioSessionScheduleInvitation = (scenarioId: string, requestTableFilters?: IClientUsersQuery, pageData?: IListPageData, learners?: string[], customizeInvitationData?: ICustomizeLearnerInvitationsMsg): Promise<void> => {
  const fetchExcludedLearners = {
    ...pageData,
    ...requestTableFilters,
    exclude: learners,
    invitationSenderName: customizeInvitationData?.senderName,
    invitationSubject: customizeInvitationData?.subject,
    invitationIntroMessage: customizeInvitationData?.introMsg,
    sendDate: customizeInvitationData?.sendDate?.valueOf(),
    ctaButtonText: customizeInvitationData?.customizeBtnText,
  };

  return RestService.fetch(`${RestService.REST_URL}/scenario/sessionScheduleInvitation/${scenarioId}`, {
    body: JSON.stringify(fetchExcludedLearners),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json ',
    }),
    method: 'POST',
  });
};

const fetchInvitationLastSentDate = (ids:string[]): Promise<IScheduleInvite[]> => {
  return RestService.fetch(`${RestService.REST_URL}/project/lastSentDate`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(ids),
    method: 'POST',
  });
};

const updateCustomizeEmailSetting = (clientId: string, emailData: ICustomizeEmailSettings): Promise<ICustomizeEmailSettings> => {
  return RestService.fetch(`${RestService.REST_URL}/client/${clientId}/emailCustomization`, {
    body: JSON.stringify(emailData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const fetchCustomizeEmailMessagesMetadata = (
  pageData: IListPageData,
  id: string
): Promise<IListDataResponse<ICustomizedEmailSettingMetaData>> => {
  const query = {
    ...pageData,
    id
  };

  return RestService.fetch(`${RestService.REST_URL}/client/auditList`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
  });
};

const fetchEmailPreview = (clientId: string, emailTypeId: string): Promise<IEmailPreview> => {
  return RestService.fetch(`${RestService.REST_URL}/emailPreview/${clientId}/${emailTypeId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  });
};

const getEulaList = (): Promise<IEulaOption[]> => {
  return RestService.fetch(`${RestService.REST_URL}/client/getEulaList`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ archived: false }),
    method: 'POST',
  });
};

const fetchEulaContent = (userProfile: IUserProfile | null, language: string): Promise<string> => {
  const { type, clientId, licenseeId } = userProfile || {};
  const query = {
    userType: type ?? ``,
    lang: language ?? `en-US`,
    id: clientId ?? licenseeId,
  };
  return RestService.fetch(`${RestService.REST_URL}/client/getEulaContent`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
  });
};

const fetchClientShortList = (params: IPageRequestParams & { hasPrivateSso?: boolean, practiceSession?: boolean }): Promise<IListDataResponse<IClientExt>> => {
  const { pageData, hasPrivateSso, signal, practiceSession } = params;

  return RestService.fetch(`${RestService.REST_URL}/client/clientShortListByRole${getPageDataUrl(pageData, { hasPrivateSso, practiceSession })}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

export default {
  createNewClient,
  deleteClient,
  getClientDetail,
  fetchClient,
  fetchClientName,
  fetchClientProjects,
  fetchClientProjectsResponse,
  fetchProjectsCount,
  fetchClientsList,
  updateClient,
  updateClientDomains,
  restoreClient,

  fetchClientWithOptions,
  fetchClientsListWithOptions,
  fetchProjectsCountWithOptions,
  fetchClientsCountWithOptions,
  fetchClientNamesListWithOptions,
  fetchClientsListWithoutSsoWithOptions,
  projectSessionScheduleInvitation,
  updateSessionScheduleInvitation,
  sendScenarioSessionScheduleInvitation,
  fetchInvitationLastSentDate,
  updateCustomizeEmailSetting,
  fetchCustomizeEmailMessagesMetadata,
  fetchEmailPreview,
  getEulaList,
  fetchEulaContent,
  fetchClientShortList,
};
