import classnames from 'classnames';
import * as React from 'react';
import styles from './Page.css';

export interface IPageProps {
  className?: string;
  noBreadCrumbs?: boolean;
  bgColor?: string;
}

class Page extends React.Component<IPageProps> {
  public render() {
    const className = classnames([
      styles.page,
      {[styles.hasBreadcrumbs]: !this.props.noBreadCrumbs},
      this.props.className,
    ]);

    return (
      <div className={className} style={{backgroundColor: this.props.bgColor || '#f1efee'}}>
        <div className={styles.content}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Page;
