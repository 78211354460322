import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import RestService from 'src/app/services/rest/RestService';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';

const fetchTeamLearners = (pageData: IListPageData, teamId: string): Promise<IListDataResponse<ILearnerExtended>> => {
  return RestService.fetch(`${RestService.REST_URL}/user/learner/listForTeam/${teamId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const deprecatedFetchTeamLearners = (teamId: string): Promise<ILearnerExtended[]> => {
  return RestService.fetch(`${RestService.REST_URL}/user/learner/listForTeam/${teamId}/0/1000`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((response) => response.content || []);
};

export default {
  deprecatedFetchTeamLearners,
  fetchTeamLearners,
};
