exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".InfoTooltip__icon___r4YZM{font-size:.8rem;vertical-align:middle;margin:0 3px}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/InfoTooltip/InfoTooltip.css"],"names":[],"mappings":"AAAA,2BACE,gBAAiB,AACjB,sBAAuB,AACvB,YAAc,CACf","file":"InfoTooltip.css","sourcesContent":[".icon {\n  font-size: .8rem;\n  vertical-align: middle;\n  margin: 0 3px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"icon": "InfoTooltip__icon___r4YZM"
};