import moment from 'moment-timezone';
import { IDateRangePreset } from 'src/components/EntitySelectors';

export const getDefaultPresets = (tzId: string): IDateRangePreset[] => ([{
  text: 'Filters.Date.LastMonth',
  start: moment.tz(tzId).subtract(1, 'month'),
  end: moment.tz(tzId)
},
  {
    text: 'Filters.Date.LastSevenDays',
    start: moment.tz(tzId).subtract(1, 'week'),
    end: moment.tz(tzId).endOf('d').subtract(1, 'day')
  },
  {
    text: 'Filters.Date.Yesterday',
    start: moment.tz(tzId).subtract(1, 'd').startOf('d'),
    end: moment.tz(tzId).subtract(1, 'd').endOf('d')
  },
  {
    text: 'Filters.Date.Today',
    start: moment.tz(tzId).startOf('d'),
    end: moment.tz(tzId).endOf('d')
  },
  {
    text: 'Filters.Date.Tomorrow',
    start: moment.tz(tzId).add(1, 'd').startOf('d'),
    end: moment.tz(tzId).add(1, 'd').endOf('d')
  },
  {
    text: 'Filters.Date.NextSevenDays',
    start: moment.tz(tzId).startOf('d').add(1, 'day'),
    end: moment.tz(tzId).add(1, 'week').endOf('d')
  },
  {
    text: 'Filters.Date.NextMonth',
    start: moment.tz(tzId).startOf('d'),
    end: moment.tz(tzId).add(1, 'month').endOf('d')
  },
  {
    text: 'Filters.Date.ThisYear',
    start: moment.tz(tzId).startOf('y'),
    end: moment.tz(tzId).endOf('y')
  }
]);
