import { IAppState } from 'src/app/redux/store/reducers';
import { ISession, ISessionForList } from 'src/app/data/session/interfaces/ISession';
import IRestError from 'src/app/data/common/interfaces/IRestError';

export const getSessionList = (state: IAppState): Array<ISession | ISessionForList> => state.sessions.list.items;
export const getSessionListRefreshError = (state: IAppState): IRestError | null => state.sessions.list.refreshError;
export const getSessionListPage = (state: IAppState): number => state.sessions.list.page || 0;
export const getSessionListSizePerPage = (state: IAppState): number => state.sessions.list.sizePerPage || 0;
export const getSessionListTotalSize = (state: IAppState): number => state.sessions.list.totalSize || 0;
export const isSessionListRefreshing = (state: IAppState): boolean => state.sessions.list.refreshing;
export const isSessionListFetched = (state: IAppState): boolean => state.sessions.list.fetched;

export const getSessionListForClient = (state: IAppState): ISession[] => state.sessions.listForClient.items;
export const getSessionListForClientRefreshError = (state: IAppState): IRestError | null => state.sessions.listForClient.refreshError;
export const getSessionListForClientPage = (state: IAppState): number => state.sessions.listForClient.page || 0;
export const getSessionListForClientSizePerPage = (state: IAppState): number => state.sessions.listForClient.sizePerPage || 0;
export const getSessionListForClientTotalSize = (state: IAppState): number => state.sessions.listForClient.totalSize || 0;
export const isSessionListForClientRefreshing = (state: IAppState): boolean => state.sessions.listForClient.refreshing;
export const isSessionListForClientFetched = (state: IAppState): boolean => state.sessions.listForClient.fetched;

export const getSessionListForLearner = (state: IAppState): ISession[] => state.sessions.listForLearner.items;
export const getSessionListForLearnerRefreshError = (state: IAppState): IRestError | null => state.sessions.listForLearner.refreshError;
export const getSessionListForLearnerPage = (state: IAppState): number => state.sessions.listForLearner.page || 0;
export const getSessionListForLearnerSizePerPage = (state: IAppState): number => state.sessions.listForLearner.sizePerPage || 0;
export const getSessionListForLearnerTotalSize = (state: IAppState): number => state.sessions.listForLearner.totalSize || 0;
export const isSessionListForLearnerRefreshing = (state: IAppState): boolean => state.sessions.listForLearner.refreshing;
export const isSessionListForLearnerFetched = (state: IAppState): boolean => state.sessions.listForLearner.fetched;

export const getSessionTimezoneId = (state: IAppState): string => state.sessions.timezone.id;