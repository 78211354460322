exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Tokienizer__container___12_hS{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-line-pack:stretch;align-content:stretch}.Tokienizer__label___1bbTz{font-size:1rem;color:#848484;margin-bottom:1rem}.Tokienizer__itemsContainer___1egQN{display:-ms-flexbox;display:flex;-ms-flex-flow:row wrap;flex-flow:row wrap;-ms-flex-line-pack:start;align-content:flex-start}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/Tokenizer/Tokienizer.css"],"names":[],"mappings":"AAAA,+BACE,oBAAqB,AACrB,aAAc,AACd,0BAA2B,AACvB,sBAAuB,AAC3B,oBAAqB,AACjB,2BAA4B,AAChC,2BAA4B,AACxB,qBAAuB,CAC5B,AAED,2BACE,eAAgB,AAChB,cAAe,AACf,kBAAoB,CACrB,AAED,oCACE,oBAAqB,AACrB,aAAc,AACd,uBAAwB,AACpB,mBAAoB,AACxB,yBAA0B,AACtB,wBAA0B,CAC/B","file":"Tokienizer.css","sourcesContent":[".container {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-pack: start;\n      justify-content: flex-start;\n  -ms-flex-line-pack: stretch;\n      align-content: stretch;\n}\n\n.label {\n  font-size: 1rem;\n  color: #848484;\n  margin-bottom: 1rem;\n}\n\n.itemsContainer {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-flow: row wrap;\n      flex-flow: row wrap;\n  -ms-flex-line-pack: start;\n      align-content: flex-start;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "Tokienizer__container___12_hS",
	"label": "Tokienizer__label___1bbTz",
	"itemsContainer": "Tokienizer__itemsContainer___1egQN"
};