exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ErrorPage__container___wFden{position:absolute;top:0;bottom:0;left:0;right:0;text-align:center;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;background:#f5f5f5}.ErrorPage__icon___1fMO9{font-size:5rem;padding:2rem;color:#ff615c}.ErrorPage__header___34E0M{font-size:2rem}.ErrorPage__message___2nUz1{position:absolute;top:100px;max-width:600px}.ErrorPage__info___1y0oC{background-color:#ffe8ba61;padding:1rem;margin:1rem 0}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/ErrorPage/ErrorPage.css"],"names":[],"mappings":"AAAA,8BACE,kBAAmB,AACnB,MAAO,AACP,SAAU,AACV,OAAQ,AACR,QAAS,AACT,kBAAmB,AACnB,oBAAqB,AACrB,aAAc,AACd,qBAAsB,AAClB,uBAAwB,AAC5B,kBAAoB,CACrB,AAED,yBACE,eAAgB,AAChB,aAAc,AACd,aAAe,CAChB,AAED,2BACE,cAAgB,CACjB,AAED,4BACE,kBAAmB,AACnB,UAAW,AACX,eAAiB,CAClB,AAED,yBACE,2BAA4B,AAC5B,aAAc,AACd,aAAe,CAChB","file":"ErrorPage.css","sourcesContent":[".container {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  text-align: center;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  background: #f5f5f5;\n}\n\n.icon {\n  font-size: 5rem;\n  padding: 2rem;\n  color: #FF615C;\n}\n\n.header {\n  font-size: 2rem;\n}\n\n.message {\n  position: absolute;\n  top: 100px;\n  max-width: 600px;\n}\n\n.info {\n  background-color: #ffe8ba61;\n  padding: 1rem;\n  margin: 1rem 0;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "ErrorPage__container___wFden",
	"icon": "ErrorPage__icon___1fMO9",
	"header": "ErrorPage__header___34E0M",
	"message": "ErrorPage__message___2nUz1",
	"info": "ErrorPage__info___1y0oC"
};