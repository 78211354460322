import React, { CSSProperties, PropsWithChildren } from 'react';
import styles from './Pills.css';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import cn from 'classnames';

type IProps = PropsWithChildren<{
  value: INamedEntry;
  disabled?: boolean;
  onClick?: (value: INamedEntry) => void;
  withButton?: boolean;
  buttonDisabled?: boolean;
  onButtonClick?: (value: INamedEntry) => void;
  className?: string;
  maxWidth?: number;
}>;

export const Pill = (props: IProps) => {
  const {
    value,
    disabled,
    onClick,
    withButton,
    buttonDisabled,
    onButtonClick,
    className,
    maxWidth = 120,
  } = props;

  const maxWidthStyle: CSSProperties = {
    maxWidth: maxWidth - 10,
    marginRight: 10,
  };

  const handleClick = () => (onClick && !disabled) && onClick(value);
  const handleButtonClick = () => (onButtonClick && !buttonDisabled) && onButtonClick(value);

  return (
    <span className={cn(
      styles.pill,
      disabled && styles.pillDisabled,
      withButton && styles.pillWithButton,
      className
    )}
      onClick={handleClick}
      style={maxWidthStyle}
    >
      <span>{value.name}</span>
      {withButton && <button className={styles.clearValue} disabled={buttonDisabled} onClick={handleButtonClick} />}
    </span>
  );
};
