import { components } from 'react-select';
import cn from 'classnames';
import React from 'react';
import { DropdownIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';

const SelectorDropdownIndicator = (ddIndicatorProps: DropdownIndicatorProps<any, any, any>) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...ddIndicatorProps}>
        <i className={cn('fas dropdownIndicatorIcon', ddIndicatorProps.selectProps.menuIsOpen ? 'fa-caret-up' : 'fa-caret-down')}/>
      </components.DropdownIndicator>
    )
  );
};

export default SelectorDropdownIndicator;
