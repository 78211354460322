import { AnyAction, Dispatch } from 'redux';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import AuthService from 'src/app/services/auth/AuthService';
import calendarAction from 'src/app/redux/modules/calendar/actions';
import wsAction from 'src/app/redux/modules/ws/actions';
import rest from 'src/app/redux/modules/auth/rest';
import * as types from 'src/app/redux/modules/auth/types';
import IAuthToken from 'src/app/data/auth/IAuthToken';
import { IAuthSchema } from 'src/app/redux/modules/auth/interfaces/IAuthData';
import { IAppState } from 'src/app/redux/store/reducers';
import selectors from 'src/app/redux/selectors';
import RestService from 'src/app/services/rest/RestService/RestService';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import { clearTOTPAuthValue, setTOTPAuthValue } from 'src/layouts/common/TOTPAuthentication/TOTPAuthenticationUtils';
import { intl } from 'src/i18n/createIntl';
/**
 * Check if a user has been already authenticated.
 */
const authenticationCheck = () => async (dispatch: Dispatch) => {
  const token = AuthService.getTokenContainer();

  if (token) {
    dispatch(authenticationTokenUpdate(token));
    dispatch(authenticationSuccessful(token));
  } else {
    dispatch(notAuthenticated());
  }
};

const authenticationProcess = (): AnyAction => ({
  type: types.AUTHENTICATION_PROCESS,
});

/**
 * @param token - JWT access and refresh token container
 */
const authenticationSuccessful = (token: IAuthToken): AnyAction => ({
  token,
  type: types.AUTHENTICATION_SUCCESSFUL,
});

const authenticationTokenUpdate = (token: IAuthToken): AnyAction => ({
  token,
  type: types.AUTHENTICATION_TOKEN_UPDATE,
});

const doClearAuthData = (dispatch: Dispatch) => {
  AuthService.clearToken();
  dispatch(calendarAction.calendarReset());
  dispatch(notAuthenticated());
};

const clearAuthData = () => async (dispatch: Dispatch) => {
  doClearAuthData(dispatch);
};

/**
 * Try to log in user with the passed credentials.
 * @param {string} email
 * @param {string} password
 * @param {string} roleID
 * @param captchaToken
 * @param {function} onFailure
 */
const logIn = (email: string, password: string, roleID?: string, captchaToken?: string, onFailure?: (error: any) => void) => async (dispatch: Dispatch) => {
  dispatch(authenticationProcess());

  try {
    const token = await rest.login(email, password, roleID, captchaToken);
    // @ts-ignore
    dispatch(saveToken(token));
    dispatch({ type: types.PORTAL_VERSION, portalVersion: token.portal_version });
    setTOTPAuthValue(token?.token_verification_required);
  } catch (e) {
    if (onFailure) {
      onFailure(e);
    }
    dispatch(notAuthenticated([e]));
  }
};

const externalLogout = (userProfile: IUserProfile | null, authSchemas: IAuthSchema[]) => {
  // if the user is external then get the first unshared SSO schema (assuming that an external user cannot have multiple unshared SSO configs)
  // and if the schema allows SLO then log the user out of the external system
  if (!!userProfile && userProfile.external) {
    const authSchema = authSchemas.find(s => !s.shared && s.singleLogoutEnabled);

    if (authSchema) {
      window.location.replace(`${RestService.SSO_LOGOUT_URL}/${authSchema.id}`);
    }
  }
};

/**
 * Log out user.
 */
const logOut = () => (dispatch: Dispatch, getState: () => IAppState) => {
  // todo: think about all possible cases with token
  const state = getState();
  const authSchemas = selectors.auth.getAuthSchemas(state);
  const userProfile = selectors.profile.getUserProfile(state);

  // @ts-ignore
  dispatch(wsAction.closeWebSocketConnection());
  dispatch({ type: types.LOG_OUT });
  dispatch(notifyTokenTimeout(false));

  rest.logout();

  doClearAuthData(dispatch);

  externalLogout(userProfile, authSchemas);
  clearTOTPAuthValue();
};


const notifyTokenTimeout = (value: boolean) => {
  return {
    type: types.REFRESH_TIMEOUT_TOKEN,
    payload: value
  };
};

const updateRefreshTimeoutToken = () => {
  return {
    type: types.UPDATE_REFRESH_TIMEOUT_TOKEN,
    refresh_token_exp_duration: AuthService.getTokenExpirationDuration(),
  };
};

const wsLogOut = () => async (dispatch: Dispatch, getState: () => IAppState) => {
  const state = getState();
  const authSchemas = selectors.auth.getAuthSchemas(state);
  const userProfile = selectors.profile.getUserProfile(state);

  // @ts-ignore
  dispatch(wsAction.closeWebSocketConnection());
  dispatch(notifyTokenTimeout(false));
  dispatch({ type: types.LOG_OUT });
  doClearAuthData(dispatch);
  externalLogout(userProfile, authSchemas);
  clearTOTPAuthValue();
};

const notAuthenticated = (errors: IRestError[] | null = null): AnyAction => ({
  errors,
  type: types.NOT_AUTHENTICATED,
});

const saveToken = (token: IAuthToken, skipAuthSuccess?: boolean) => async (dispatch: Dispatch) => {
  AuthService.saveToken(token);

  dispatch(authenticationTokenUpdate(token));

  if (!skipAuthSuccess) {
    dispatch(authenticationSuccessful(token));
  }
};

const clearErrors = () => (dispatch: Dispatch) => {
  dispatch({
    type: types.CLEAR_ERRORS,
  });
};


const switchRole = (roleID: string) => async (dispatch: Dispatch) => {
  dispatch({ type: types.SWITCH_ROLE });

  const token = await rest.switchRole(roleID);
  // @ts-ignore
  dispatch(saveToken(token));
  return token;
};

const refreshAuthSchemas = (email: string) => async (dispatch: Dispatch) => {
  const schemas = await rest.fetchAuthSchemas(email);
  // @ts-ignore
  dispatch(updateAuthSchemas(schemas));
};

const updateAuthSchemas = (schemas: IAuthSchema[]) => (dispatch: Dispatch) => {
  dispatch({ type: types.AUTHENTICATION_SCHEMAS_UPDATE, schemas });
};

export default {
  authenticationCheck,
  clearAuthData,
  clearErrors,
  logIn,
  logOut,
  saveToken,
  refreshAuthSchemas,
  updateAuthSchemas,
  updateRefreshTimeoutToken,
  // tslint:disable-next-line:object-literal-sort-keys
  authByInvitation: actionFromPromise(rest.authByInvitation, 'INVITATION', true),
  validateInvitation: actionFromPromise(rest.validateInvitation),
  fetchAuthSchemas: actionFromPromise(rest.fetchAuthSchemas),
  fetchOAuthData: actionFromPromise(rest.fetchOAuthData),
  login: actionFromPromise(rest.login),
  logout: actionFromPromise(rest.logout),
  restorePassword: actionFromPromise(rest.restorePassword, 'RESTORE_PASSWORD', true),
  switchRole,
  wsLogOut,
  notifyTokenTimeout,
  isRegisteredUser: actionFromPromise(rest.isRegisteredUser),
  createNonRegisteredUser: actionFromPromise(rest.createNonRegisteredUser),
  unRegisteredUser: actionFromPromise(rest.unRegisteredUser),
  fetchLatestToken: actionFromPromise(rest.fetchLatestToken),
  changePassword: actionFromPromise(rest.changePassword),
  getToken: actionFromPromise(rest.getToken, 'GET_TOKEN', false, intl().formatMessage({ id: 'MursionPortal.ErrorMessage.UrlIsNotValid' })),
};
