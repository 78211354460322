import { AnyAction, Dispatch, Middleware, Store } from 'redux';


export type PartialMiddleware = (store: Store, next: Dispatch, action: AnyAction) => any;

/**
 * Middleware common creator
 * @param middlewareMap
 * @param [initialize] - Function which makes possible to add some additional logic during middleware creation.
 *                                Calls with Redux Store as the first argument.
 */

export default function createMiddleware(middlewareMap: {[type: string]: PartialMiddleware}, initialize?: (store: Store) => void): Middleware {
  return (store: Store) => {
    if (typeof initialize === 'function') {
      initialize(store);
    }

    return (next) => (action: AnyAction) => {
      if (middlewareMap[action.type]) {
        return middlewareMap[action.type](store, next, action);
      }

      return next(action);
    };
  };
}
