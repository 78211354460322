import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';

export default {
  fetchTechRestrictionsList: actionFromPromise(rest.fetchTechRestrictionsList),
  fetchTechRestrictionsByPeriod: actionFromPromise(rest.fetchTechRestrictionsByPeriod),
  updateTechRestriction: actionFromPromise(rest.updateTechRestriction),
  deleteTechRestriction: actionFromPromise(rest.deleteTechRestriction),
  deleteTechRestrictionProviders: actionFromPromise(rest.deleteTechRestrictionProviders),
  createTechRestriction: actionFromPromise(rest.createTechRestriction)
};