import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';
import usersRest from 'src/app/redux/modules/users/rest';
import createListActions from 'src/app/redux/utils/createListActions';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';

export default {
  refreshList: createListActions('COMPANY_USERS', rest.fetchUsersList),
  clearError: createClearEntityErrorAction('COMPANY_USER'),
  createNewUser: actionFromPromise(rest.createNewUser, 'COMPANY_USER'),
  deleteUser: actionFromPromise(rest.deleteUser, 'COMPANY_USER', true),
  restoreUser: actionFromPromise(rest.restoreUser, 'COMPANY_USER'),
  fetchFullClientProjectsList: actionFromPromise(rest.fetchFullClientProjectsList),
  fetchClientList: actionFromPromise(rest.fetchClientList),
  fetchUser: actionFromPromise(rest.fetchUser),
  fetchUsersList: actionFromPromise(rest.fetchUsersList),
  fetchDashboardUsersList: createListActions('DASHBOARD_USERS', rest.fetchUsersList),
  reInvite: actionFromPromise(usersRest.reInviteUser, 'COMPANY_USER', true),
  updateUser: actionFromPromise(rest.updateUser, 'COMPANY_USER', true),
  fetchUserSummary: actionFromPromise(rest.fetchUserSummary),
  fetchUserAvailableTime: actionFromPromise(rest.fetchUserAvailableTime),
  fetchListForEditSession: createListActions('SESSION_USERS', rest.fetchUsersList),
  updatePracticeSims: actionFromPromise(rest.updatePracticeSims),
};
