import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';
import usersRest from './../../users/rest';
import createListActions from 'src/app/redux/utils/createListActions';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';

export default {
  refreshList: createListActions('CLIENT_USERS', rest.fetchClientUsers),
  clearError: createClearEntityErrorAction('CLIENT_USER'),
  createNewBuyer: actionFromPromise(rest.createNewBuyer, 'CLIENT_USER'),
  createNewClientUser: actionFromPromise(rest.createNewClientUser, 'CLIENT_USER', true),
  deleteClientUser: actionFromPromise(rest.deleteClientUser, 'CLIENT_USER', true),
  restoreClientUser: actionFromPromise(rest.restoreClientUser, 'CLIENT_USER'),
  fetchClientLearners: createListActions('CLIENT_LEARNERS', rest.fetchClientLearners),
  fetchClientUser: actionFromPromise(rest.fetchClientUser, 'CLIENT_USER', true),
  fetchClientUsers: actionFromPromise(rest.fetchClientUsers),
  fetchClientUsersExt: actionFromPromise(rest.fetchClientUsersExt),
  exportClientUsersExtWithOptions: actionFromPromise(rest.exportClientUsersExtWithOptions),
  exportClientTeamExtWithOptions: actionFromPromise(rest.exportClientTeamExtWithOptions),
  fetchClientUsersWithOptions: actionFromPromise(rest.fetchClientUsersWithOptions),
  fetchClientUsersExtWithOptions: actionFromPromise(rest.fetchClientUsersExtWithOptions),
  fetchClientLearnersWithOptions: actionFromPromise(rest.fetchClientLearnersWithOptions),
  fetchTeamLearnersWithOptions: actionFromPromise(rest.fetchTeamLearnersWithOptions),
  parseLearnersFromCsv: actionFromPromise(rest.parseLearnersFromCsv),
  parseExternalUsersFromCsv: actionFromPromise(rest.parseExternalUsersFromCsv),
  fetchImportTemplateUrl: actionFromPromise(rest.fetchImportTemplateUrl),
  reInvite: actionFromPromise(usersRest.reInviteUser, 'CLIENT_USER', true),
  updateClientUser: actionFromPromise(rest.updateClientUser, 'CLIENT_USER', true),
  updateClientUserTeams: actionFromPromise(rest.updateClientUserTeams),
  fetchDashboardClientUsersScheduling: actionFromPromise(rest.fetchDashboardClientUsersScheduling),
  exportDashboardClientUsersScheduling: actionFromPromise(rest.exportDashboardClientUsersScheduling),
  fetchDashboardClientUserScenariosList: actionFromPromise(rest.fetchDashboardClientUserScenariosList),
  fetchImportProcessStatus: actionFromPromise(rest.fetchImportProcessStatus),
  fetchClientImportProcessStatus : actionFromPromise(rest.fetchClientImportProcessStatus),
  importExternalUser : actionFromPromise(rest.uploadExternalUser, 'CLIENT_USER', true),
  importInternalUser : actionFromPromise(rest.uploadInternalUser, 'CLIENT_USER', true),
  editTeamMembership : actionFromPromise(rest.editTeamMembership, 'CLIENT_USER', true),
  validateEmails : actionFromPromise(rest.validateEmails),
  createClientUser : actionFromPromise(rest.createClientUser),
  fetchScenarioLearnersWithOptions : actionFromPromise(rest.fetchScenarioLearnersWithOptions),
  fetchLearnersActivityWithOptions: actionFromPromise(rest.fetchLearnersActivityWithOptions),
  fetchLearnersActivity: actionFromPromise(rest.fetchLearnersActivity),
};
