import { IAppState } from 'src/app/redux/store/reducers';
import IProject from 'src/app/data/projects/interfaces/IProject';
import IRestError, { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';

export const getProjectsList = (state: IAppState): IProject[] => state.projects.list.items;
export const getProjectsListPage = (state: IAppState): number => state.projects.list.page || 0;
export const getProjectsListSizePerPage = (state: IAppState): number => state.projects.list.sizePerPage || 0;
export const getProjectsListTotalSize = (state: IAppState): number => state.projects.list.totalSize || 0;
export const getProjectsListRefreshError = (state: IAppState): IRestError | null => state.projects.list.refreshError;
export const isProjectsListFetched = (state: IAppState): boolean => state.projects.list.fetched;
export const isProjectsListRefreshing = (state: IAppState): boolean => state.projects.list.refreshing;

export const getCurrentProject = (state: IAppState): IProject | null => state.projects.currentProject.item;
export const isCurrentProjectFetching = (state: IAppState): boolean => state.projects.currentProject.refreshing;

export const getProjectsForClientList = (state: IAppState): IProject[] => state.projects.listForClient.items;
export const getProjectsForClientListPage = (state: IAppState): number => state.projects.listForClient.page || 0;
export const getProjectsForClientListSizePerPage = (state: IAppState): number => state.projects.listForClient.sizePerPage || 0;
export const getProjectsForClientListTotalSize = (state: IAppState): number => state.projects.listForClient.totalSize || 0;
export const getProjectsForClientListRefreshError = (state: IAppState): IRestError | null => state.projects.listForClient.refreshError;
export const isProjectsForClientListFetched = (state: IAppState): boolean => state.projects.listForClient.fetched;
export const isProjectsForClientListRefreshing = (state: IAppState): boolean => state.projects.listForClient.refreshing;

export const getProjectValidationErrors = (state: IAppState): IErrorValidation[] => state.projects.error.validations;
export const getErrorMessage = (state: IAppState): string => state.projects.error.message;
export const getShowArchived = (state: IAppState): boolean => state.projects.showArchivedScenarios;
