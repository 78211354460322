import IRestError from '../../data/common/interfaces/IRestError';

export default class RestError extends Error implements IRestError {

  public code: number | undefined;

  public field: string | undefined;

  public attempts: number | undefined;

  constructor(message: string, code?: number, field?: string, attempts?: number) {
    super(message);

    // fix Error inheritance (see https://github.com/babel/babel/issues/3083)
    this.constructor = RestError;
    // @ts-ignore
    this.__proto__ = RestError.prototype;

    this.code = code;
    this.field = field;
    this.attempts = attempts;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      // @ts-ignore
      this.stack = (new Error()).stack;
    }
  }
}
