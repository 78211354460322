import * as React from 'react';
import { FunctionComponent } from 'react';
import backIcon from "../CompletedSimulations/images/back.svg";
import styles from "./BackButton.css";
import { useIntl } from "react-intl";

export interface IBackButtonProps {
  label?: string;
  onClick?: () => void;
}

const BackButton: FunctionComponent<IBackButtonProps> = (props) => {
  const intl = useIntl();
  return (
    <>
      <button onClick={props.onClick} className={styles.backBtn} aria-label={props.label}><img src={backIcon} alt={intl.formatMessage({ id: "Session.Edit.Modal.Back" })} />{props.label}</button>
    </>
  );
};

export default BackButton;
