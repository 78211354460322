import { AnyAction } from 'redux';
import createReducer from 'src/app/redux/utils/createReducer';
import AuthStatus from './interfaces/AuthStatus';
import IAuthState from './interfaces/IAuthState';
import * as types from './types';

const INITIAL_STATE: IAuthState = {
  errors: null,
  status: AuthStatus.AUTHENTICATION_CHECKING,
  token: null,
  schemas: [],
  refreshTokenTimeout: false,
  portalVersion: 0,
};

export default createReducer(INITIAL_STATE, {
  [types.AUTHENTICATION_CHECKING]: (state: IAuthState): IAuthState => ({
    ...state,
    status: AuthStatus.AUTHENTICATION_CHECKING,
  }),
  [types.AUTHENTICATION_PROCESS]: (state: IAuthState): IAuthState => ({
    ...state,
    errors: null,
    status: AuthStatus.AUTHENTICATION_PROCESS,
  }),
  [types.SWITCH_ROLE]: (state: IAuthState): IAuthState => ({
    ...state,
    errors: null,
    status: AuthStatus.SWITCHING_ROLE,
  }),
  [types.AUTHENTICATION_SUCCESSFUL]: (state: IAuthState, action: AnyAction): IAuthState => {
    return {
      ...state,
      status: AuthStatus.AUTHENTICATION_SUCCESSFUL,
      token: action.token,
    };
  },
  [types.AUTHENTICATION_SCHEMAS_UPDATE]: (state: IAuthState, action: AnyAction): IAuthState => {
    return {
      ...state,
      schemas: action.schemas,
    };
  },
  [types.NOT_AUTHENTICATED]: (state: IAuthState, action: AnyAction): IAuthState => ({
    ...state,
    errors: action.errors || null,
    status: AuthStatus.NOT_AUTHENTICATED,
    token: null,
  }),
  [types.CLEAR_ERRORS]: (state: IAuthState): IAuthState => ({
    ...state,
    errors: null,
  }),
  [types.REFRESH_TIMEOUT_TOKEN]: (state: IAuthState, action: AnyAction): IAuthState => ({
    ...state,
    refreshTokenTimeout: action.payload
  }),
  [types.PORTAL_VERSION]: (state: IAuthState, action: AnyAction): IAuthState => {
    return {
      ...state,
      portalVersion: action.portalVersion,
    };
  },
  [types.UPDATE_REFRESH_TIMEOUT_TOKEN]: () => INITIAL_STATE,
});
