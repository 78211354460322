// auth
import * as authSelectors from './auth/authSelectors';
// avatars
import * as avatarsList from './avatars/avatarsSelectors';
// calendar
import * as calendarSelectors from './calendar/calendarSelectors';
// calendarEvents
import * as calendarEventsSelectors from './calendarEvents/calendarEventsSelectors';
import * as createEventDialogSelectors from './createEventDialog/createEventDialogSelectors';
// clients
import * as clientsList from './clients/clientsSelectors';
import * as clientUsersList from './clients/users/clientUsersListSelectors';
// certificates
import * as certificatesList from './certificates/certificatesSelectors';
// clients config
import * as clientsConfigSelectors from './clientsConfig/clientsConfigSelectors';
// company
import * as companyUsersSelectors from './company/companyUsersSelectors';
import * as companySelectors from './company/companySelectors';
// company config
import * as companyConfigSelectors from './companyConfig/companyConfigSelectors';
// config
import * as configSelectors from './config/configSelectors';
// environments
import * as environmentsList from './environments/environmentsSelectors';
import * as entityHistory from './entityHistory/entityHistorySelectors';
import * as errorSelectors from './errors/errorsSelectors';
// industriesList
import * as industriesList from './industries/industriesSelectors';
// layout
import * as layoutSelectors from './layout/layoutSelector';
// assetsList
import * as assetsList from './assets/assetsSelectors';
// message panel
import * as messagePanelSelectors from './messagePanel/messagePanelSelectors';
// profile
import * as profileSelectors from './profile/profileSelectors';
// projects
import * as projectsList from './projects/projectsSelectors';
import * as projectLearnersList from './projects/learners/learnersSelectors';
// scenarios
import * as scenariosList from './scenarios/scenariosSelectors';
import * as scenarioLearnersList from './scenarios/learners/learnersSelectors';
// scenarioTemplates
import * as scenariosTemplatesList from './scenarios/scenarioTemplatesSelectors';
// sessions
import * as sessionsList from './session/sessionSelectors';
import * as teamLearnersList from './teams/learners/learnersSelectors';
// sso
import * as sso from './sso/ssoSelectors';
// integrations
import * as integrations from './integrations/integrationsSelectors';
// teams
import * as teamsList from './teams/teamsSelectors';
// sessionWizard
import * as sessionWizardSelectors from './sessionWizard/sessionWizardSelectors';
// ws
import * as wsSelectors from './ws/wsSelectors';

import * as contractSelectors from './contract/contractSelectors';

export default {
  auth: authSelectors,

  calendar: calendarSelectors,
  createEventDialog: createEventDialogSelectors,

  calendarEvents: calendarEventsSelectors,

  certificates: certificatesList,

  clients: {
    ...clientsList,
    users: clientUsersList,
  },

  clientsConfig: {
    ...clientsConfigSelectors
  },

  company: {
    ...companySelectors,
    ...companyUsersSelectors,
  },

  config: {
    ...configSelectors,
  },

  avatars: avatarsList,

  companyConfig: companyConfigSelectors,

  environments: environmentsList,

  entityHistory,

  industries: industriesList,
  layout: layoutSelectors,
  assets: assetsList,

  errors: errorSelectors,

  messagePanel: messagePanelSelectors,

  projects: {
    ...projectsList,
    learners: projectLearnersList,
  },

  profile: profileSelectors,

  scenarios: {
    ...scenariosList,
    learners: scenarioLearnersList,
  },

  scenarioTemplates: scenariosTemplatesList,

  sessions: sessionsList,

  sso,

  integrations,

  teams: {
    ...teamsList,
    learners: teamLearnersList,
  },

  sessionWizard: sessionWizardSelectors,

  ws: wsSelectors,
  contracts: contractSelectors,
};
