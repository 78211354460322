exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Separator__separator___2Ia0G{border-top:1px solid #d7e1eb;margin-top:15px;margin-bottom:15px;width:100%}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/Separator/Separator.css"],"names":[],"mappings":"AAAA,8BACE,6BAA8B,AAC9B,gBAAiB,AACjB,mBAAoB,AACpB,UAAY,CACb","file":"Separator.css","sourcesContent":[".separator {\n  border-top: 1px solid #D7E1EB;\n  margin-top: 15px;\n  margin-bottom: 15px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"separator": "Separator__separator___2Ia0G"
};