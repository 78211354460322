import RestService from 'src/app/services/rest/RestService';
import { IPublicSessionData, INonRegUserAttendanceData, INonRegUserAttendanceParam } from 'src/app/redux/modules/publicSession/interfaces/IPublicSession';

const verifyPublicSession = (sessionId: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.GET_PUBLIC_URL}/public/session/external/${sessionId}/validate`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'GET',
  });
};

const getPublicSessionDetails = (sessionId: string): Promise<IPublicSessionData> => { 
  return RestService.fetch(`${RestService.GET_PUBLIC_URL}/public/session/${sessionId}/config`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
};

const saveNonRegUserAttendance = (params: INonRegUserAttendanceParam): Promise<INonRegUserAttendanceData> => { 
  return RestService.fetch(`${RestService.GET_PUBLIC_URL}/public/session/save/attendance`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({...params}),
    method: 'POST',
  });
};

export default {
  verifyPublicSession,
  getPublicSessionDetails,
  saveNonRegUserAttendance
};