import classNames from 'classnames';
import * as React from 'react';
import styles from './ErrorMessage.css';

export interface IErrorMessageProps {
  className?: string;
  message?: string | null;
  focusOnChange?: boolean;
  isErrorDark?: boolean;
}

// isDarkText={true} 
class ErrorMessage extends React.Component<IErrorMessageProps> {

  private elementRef: any;

  public componentDidUpdate(prevProps: IErrorMessageProps) {
    if (this.props.focusOnChange && this.props.message && prevProps.message !== this.props.message) {
      this.elementRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  public render() {
    const {
      className,
      children,
      message,
      isErrorDark = false
    } = this.props;

    if (!message && !children) {
      return null;
    }



    const containerClassName = classNames([
      isErrorDark ? styles.errorDark :  styles.error,
      className,
    ]);
    
    return (
      <div className={containerClassName} ref={(el) => (this.elementRef = el)} aria-live="assertive" role="alert">
        {children ? children : message}
      </div>
    );
  }
}

export default ErrorMessage;
