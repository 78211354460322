import { AnyAction } from 'redux';
import IItemDataState from 'src/app/data/common/interfaces/IItemDataState';
import createReducer, { RESET_STORE } from 'src/app/redux/utils/createReducer';
import getItemTypes from 'src/app/redux/utils/getItemTypes';
import * as authActionTypes from 'src/app/redux/modules/auth/types';

const createItemReducer = <T>(itemName: string) => {

  const INITIAL_STATE: IItemDataState<T> = {
    fetched: false,
    item: null,
    refreshError: null,
    refreshing: false,
  };

  const types = getItemTypes(itemName);

  return createReducer(INITIAL_STATE, {
    [types.REFRESH_ITEM_ERROR]: (state: IItemDataState<T>, action: AnyAction): IItemDataState<T> => ({
      ...state,
      fetched: true,
      refreshError: action.error,
      refreshing: false,
    }),

    [types.REFRESHING_ITEM_TYPE]: (state: IItemDataState<T>): IItemDataState<T> => ({
      ...state,
      refreshing: true,
    }),

    [types.RECEIVED_ITEM_TYPE]: (state: IItemDataState<T>, action: AnyAction): IItemDataState<T> => ({
      ...state,
      fetched: true,
      item: action.item,
      refreshError: null,
      refreshing: false,
    }),
    [types.RESET_ITEM]: () => INITIAL_STATE,
    [authActionTypes.SWITCH_ROLE]: () => RESET_STORE,
    [authActionTypes.LOG_OUT]: () => RESET_STORE,
  });
};

export default createItemReducer;
