import _sortBy from 'lodash/sortBy';
import IRestError, { IErrorValidation } from "src/app/data/common/interfaces/IRestError";
import { IAppState } from 'src/app/redux/store/reducers';
import { parseErrorMessage } from "src/app/data/common/utils/errorUtils";

export const getList = (state: IAppState) => _sortBy(state.industries.list.items, ['name']);
export const isListFetched = (state: IAppState): boolean => state.industries.list.fetched;
export const getListRefreshError = (state: IAppState): IRestError | null => state.industries.list.refreshError;
export const isListRefreshing = (state: IAppState): boolean => state.industries.list.refreshing;
export const getErrorMessage = (state: IAppState): string => parseErrorMessage(state.industries.error);
export const getValidationErrors = (state: IAppState): IErrorValidation[] => state.industries.error.validations;


export const getCurrentIndustry = (state: IAppState) => state.industries.currentIndustry.item;
export const isCurrentIndustryFetched = (state: IAppState): boolean => state.industries.currentIndustry.fetched;
export const getCurrentIndustryRefreshError = (state: IAppState): IRestError | null => state.industries.currentIndustry.fetchError;
export const isCurrentIndustryRefreshing = (state: IAppState): boolean => state.industries.currentIndustry.fetching;

export const isRefreshing = (state: IAppState) => isListRefreshing(state) || isCurrentIndustryRefreshing(state);
