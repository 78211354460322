import { IAppState } from 'src/app/redux/store/reducers';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import IScenarioConfig from 'src/app/data/companyConfig/interfaces/IScenarioConfig';
import IEventConfig from 'src/app/data/companyConfig/interfaces/IEventConfig';
import { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import { parseErrorMessage } from 'src/app/data/common/utils/errorUtils';

export const getCompanyConfig = (state: IAppState): ICompanyConfig | null => state.companyConfig.data.item;
export const isCompanyConfigFetching = (state: IAppState): boolean => state.companyConfig.data.refreshing;
export const isCompanyConfigFetched = (state: IAppState): boolean => state.companyConfig.data.fetched;
export const getAvailabilityConfig = (state: IAppState): IEventConfig | null => {
  const companyConfig = getCompanyConfig(state);

  return companyConfig ? companyConfig.availability : null;
};
export const getCompanyEventConfig = (state: IAppState): IEventConfig | null => {
  const companyConfig = getCompanyConfig(state);

  return companyConfig ? companyConfig.licenseeEvent : null;
};
export const getScenarioConfig = (state: IAppState): IScenarioConfig | null => {
  const companyConfig = getCompanyConfig(state);

  return companyConfig ? companyConfig.scenario : null;
};
export const getZendeskKey = (state: IAppState): string | null => {
  const companyConfig = getCompanyConfig(state);

  return companyConfig ? companyConfig.zendeskKey : null;
};
export const getSessionSmartMetrics = (state: IAppState): boolean => {
  const companyConfig = getCompanyConfig(state);

  return companyConfig ? companyConfig.sessionSmartMetrics : false;
};
export const getCompanyConfigError = (state: IAppState): string => parseErrorMessage(state.companyConfig.error);
export const getCompanyConfigValidationErrors = (state: IAppState): IErrorValidation[] => state.companyConfig.error.validations;
