import { combineReducers } from 'redux';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import createListReducer from 'src/app/redux/utils/createListReducer';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import IClientUser from 'src/app/data/client/interfaces/IClientUser';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';

export interface IClientUsersState {
  list: IListDataState<IClientUser>;
  listForClient: IListDataState<ILearnerExtended>;
  listForClientError: IErrorState;
  error: IErrorState;
}

export default combineReducers({
  list: createListReducer('CLIENT_USERS'),
  listForClient: createListReducer('CLIENT_LEARNERS'),
  listForClientError: createListReducer('CLIENT_LEARNERS_ERROR'),
  error: createEntityErrorReducer('CLIENT_USER'),
});
