import { AnyAction } from 'redux';
import * as types from './types';

const changeClientHeader = (headerName: string): AnyAction => ({
  payload: headerName,
  type: types.CHANGE_CLIENT_HEADER,
});

const changeCompanyHeader = (headerName: string): AnyAction => ({
  payload: headerName,
  type: types.CHANGE_COMPANY_HEADER,
});

const changeProjectHeader = (headerName: string): AnyAction => ({
  payload: headerName,
  type: types.CHANGE_PROJECT_HEADER,
});

const changeTeamHeader = (headerName: string): AnyAction => ({
  payload: headerName,
  type: types.CHANGE_TEAM_HEADER,
});

const changeSsoSchemaHeader = (headerName: string): AnyAction => ({
  payload: headerName,
  type: types.CHANGE_SSO_SCHEMA_HEADER,
});

const resetHeaders = ():AnyAction => ({
  type: types.RESET_ALL_HEADERS,
});

export default {
  changeClientHeader,
  changeCompanyHeader,
  changeTeamHeader,
  changeProjectHeader,
  changeSsoSchemaHeader,
  resetHeaders,
};
