import createReducer from 'src/app/redux/utils/createReducer';
import * as types from './types';
import { AnyAction } from 'redux';

export interface IMessagePanelUIState {
  show: boolean;
  newMessageCount: number;
}

const INITIAL_STATE: IMessagePanelUIState = {
  show: false,
  newMessageCount: -1,
};

export default createReducer(INITIAL_STATE, {
  [types.TOGGLE_MESSAGE_PANEL]: (state: IMessagePanelUIState, action) => ({
    ...state,
    show: !state.show,
  }),
  [types.HIDE_MESSAGE_PANEL]: (state: IMessagePanelUIState, action) => ({
    ...state,
    show: false,
  }),
  [types.SHOW_MESSAGE_PANEL]: (state: IMessagePanelUIState, action) => ({
    ...state,
    show: true,
  }),
  [types.SET_NEW_MESSAGE_COUNT]: (state: IMessagePanelUIState, { newMessageCount }: AnyAction) => ({
    ...state,
    newMessageCount,
  }),
});
