import { combineReducers } from 'redux';
import IRestDataInfo from 'src/app/data/common/interfaces/IRestItemInfo';
import createItemReducer from 'src/app/redux/utils/createItemReducer';
import IConfig from 'src/app/data/config/interfaces/IConfig';

export interface IConfigState {
  data: IRestDataInfo<IConfig>;
}

export default combineReducers({
  data: createItemReducer('CONFIG'),
});
