import { AnyAction } from 'redux';
import * as types from './types';
import { getEntityErrorType } from 'src/app/redux/utils/getEntityErrorType';

const throwError = (error: Error): AnyAction => ({
  payload: error,
  type: types.THROW_ERROR,
});

const throwEntityError = (entityName: string, error: Error): AnyAction => {
  const entityErrorTypes = getEntityErrorType(entityName);

  return {
    payload: error,
    type: entityErrorTypes.THROW_ERROR,
  };
};

const clearError = (): AnyAction => ({
  type: types.CLEAR_ERROR,
});

export default {
  clearError,
  throwError,
  throwEntityError,
};
