import React, { FunctionComponent, useRef } from 'react';
import Tooltip, { TooltipPlacement, TooltipTheme } from 'src/components/Tooltip/Tooltip';
import cn from 'classnames';
import styles from 'src/components/InfoTooltip/InfoTooltip.css';
import { useIntl } from 'react-intl';

export interface IInfoTooltipProps {
  placement: TooltipPlacement;
  text: string;
  theme?: TooltipTheme;
  classNames?: string;
  ariaLabel?: string;
  faClassName?: string;
}


const InfoTooltip: FunctionComponent<IInfoTooltipProps> = (props) => {
  const infoIconRef = useRef<HTMLElement>(null);
  const { placement, text, theme, classNames, ariaLabel, faClassName } = props;
  const onInfoIconHover = () => {
    infoIconRef.current?.focus();
  };
  const onInfoIconHoverLeave = () => {
    infoIconRef.current?.blur();
  };
  const intl = useIntl();
  return (
    <Tooltip placement={placement} text={text} theme={theme}>
      <i className={cn(faClassName || 'fas fa-info-circle', styles.icon, classNames)} tabIndex={0} role="button" aria-label={ariaLabel + " " + intl.formatMessage({ id: 'SessionReports.SessionInformation.Hover.Information' }) || intl.formatMessage({ id: 'SessionReports.SessionInformation.Hover.Information' }) } ref={infoIconRef}
        onMouseEnter={onInfoIconHover}
        onMouseLeave={onInfoIconHoverLeave}/>
    </Tooltip>
  );
};

export default InfoTooltip;
