import { Theme } from 'react-select/dist/declarations/src/types';
import { INITIAL_THEME, ISelectorTheme } from 'src/components/Selector/themes/SelectorThemes';

const greyTheme: ISelectorTheme = {

  customStyles: {
    indicatorSeparator: () => ({ display: 'none' }),
    input: (provided) => ({
      ...provided,
      fontSize: '1rem',
      lineHeight: '1.6rem',
      letterSpacing: 'normal',
      display: 'inline-block',
      textAlign: 'center',
      margin: '0',
      padding: '0',
      overflow: 'visible',
      border: 'none',
      borderRadius: '0 !important',
    }),
    dropdownIndicator: (provided) => ({ ...provided, display: 'flex' }),
  },

  customTheme: (theme: Theme): Theme => ({
    ...theme,
    ...INITIAL_THEME,
    colors: {
      ...theme.colors,
      primary: '#777',
      primary25: '#efefef',
      primary50: '#efefef',
      primary75: '#efefef',
    },
  })
};

export default greyTheme;
