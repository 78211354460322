import users from './users/actions';
import createItemActions from 'src/app/redux/utils/createItemActions';
import rest from './rest';
import companyConfigRest from 'src/app/redux/modules/companyConfig/rest';
import createListActions from 'src/app/redux/utils/createListActions';
import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import {createClearEntityErrorAction} from 'src/app/redux/utils/createClearEntityErrorAction';

export default {
  clearError: createClearEntityErrorAction('COMPANY'),
  createNewCompany: actionFromPromise(rest.createNewCompany, 'COMPANY'),
  updateCompany: actionFromPromise(rest.updateCompany, 'COMPANY'),
  deleteCompany: actionFromPromise(rest.deleteCompany, 'COMPANY', true),
  fetchCompany: actionFromPromise(rest.fetchCompany),
  fetchCompanyData: createItemActions('COMPANY_NAME', rest.fetchCompany),
  fetchConfig: actionFromPromise(companyConfigRest.fetchConfig),
  refreshList: createListActions('COMPANY_LIST', rest.fetchCompanyList),
  fetchProjectsCount: createItemActions('COMPANY_PROJECTS_COUNT', rest.fetchProjectsCount),
  fetchCompanyListWithOptions: actionFromPromise(rest.fetchCompanyListWithOptions),
  fetchLicenseeTiers: actionFromPromise(companyConfigRest.fetchLicenseeTiers),
  updateLicenseeTiers: actionFromPromise(companyConfigRest.updateLicenseeTiers),
  users,
};
