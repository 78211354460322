import React, { FunctionComponent } from 'react';
import { useLaunchDarkly } from 'src/hooks/useLaunchDarkly';
import { LaunchDarklyType } from 'src/layouts/common/LaunchDarkly/LaunchDarklyType';
import TinyMceEditor, { ITinyMceEditor } from 'src/components/TinyMceEditor/TinyMceEditor';
import FroalaEditor, { IFroalaEditorProps } from 'src/components/FroalaEditor/FroalaEditor';

interface IRTFContainer {
    tinyMceEditorProps: ITinyMceEditor;
    froalaEditorProps: IFroalaEditorProps;
}

const FroalaRTFContainer: FunctionComponent<IFroalaEditorProps> = ({ ...froalaEditorProps }) => (
    <FroalaEditor {...froalaEditorProps} />
);

const TinyMceRTFContainer: FunctionComponent<ITinyMceEditor> = ({ ...tinyMceEditorProps }) => (
    <TinyMceEditor {...tinyMceEditorProps} />
);

const RTFContainer: FunctionComponent<IRTFContainer> = (props: IRTFContainer) => {

    const { tinyMceEditorProps, froalaEditorProps } = props;
    const isFroalaRTF = useLaunchDarkly(LaunchDarklyType.MP_12968_Froala_RTF);

    return isFroalaRTF
        ? <FroalaRTFContainer {...froalaEditorProps} />
        : <TinyMceRTFContainer {...tinyMceEditorProps} />;
};

export default RTFContainer;
