import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import RestService from 'src/app/services/rest/RestService';
import { IAbortableRequestParams } from 'src/app/data/common/interfaces/IRestRequest';

const createIndustry = (industry: INamedEntry): Promise<INamedEntry> => {
  return RestService.fetch(`${RestService.REST_URL}/industry`, {
    body: JSON.stringify(industry),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchIndustry = (industryId: string): Promise<INamedEntry> => {
  return RestService.fetch(`${RestService.REST_URL}/industry/${industryId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchIndustriesList = (): Promise<INamedEntry[]> => {
  return RestService.fetch(`${RestService.REST_URL}/industry/list`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateIndustry = (industryId: string, industry: INamedEntry): Promise<INamedEntry> => {
  return RestService.fetch(`${RestService.REST_URL}/industry/${industryId}`, {
    body: JSON.stringify(industry),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const deleteIndustry = (industryId: string): Promise<INamedEntry> => {
  return RestService.fetch(`${RestService.REST_URL}/industry/${industryId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const restoreIndustry = (industryId: string): Promise<INamedEntry> => {
  return RestService.fetch(`${RestService.REST_URL}/industry/restore/${industryId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchIndustriesListWithOptions = (params: IAbortableRequestParams): Promise<INamedEntry[]> => {
  const { signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/industry/list`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

export default {
  createIndustry,
  fetchIndustry,
  fetchIndustriesList,
  updateIndustry,
  deleteIndustry,
  restoreIndustry,
  fetchIndustriesListWithOptions,
};
