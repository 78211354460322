import { AnyAction, Dispatch } from 'redux';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import errorsActions from "src/app/redux/modules/errors/actions";
import getItemTypes from "src/app/redux/utils/getItemTypes";
import RestError from 'src/app/services/rest/RestError';

// @ts-ignore
const createItemActions = <W, T, U extends any[]>(itemName: string, request: (...args: U) => Promise<W<T>>) => {
  const types = getItemTypes(itemName);

  // @ts-ignore
  const refreshItem = (...args: U) => async (dispatch: Dispatch) => {
    dispatch(refreshingItem());

    try {
      const itemResponse = await request(...args);

      dispatch(receivedItem(itemResponse));
    } catch (e) {
      if (e instanceof RestError) {
        dispatch(refreshItemError(e));
      } else {
        throw e;
      }
      dispatch(errorsActions.throwError(e));
    }
  };

  const refreshItemError = (error: IRestError): AnyAction => ({
    error,
    type: types.REFRESH_ITEM_ERROR,
  });

  const refreshingItem = (): AnyAction => ({
    type: types.REFRESHING_ITEM_TYPE,
  });

  const receivedItem = (item: T): AnyAction => ({
    item,
    type: types.RECEIVED_ITEM_TYPE,
  });

  return refreshItem;
};

export default createItemActions;
