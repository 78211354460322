import moment from 'moment-timezone';
import * as React from 'react';
import { FunctionComponent } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { convertDateToMoment, convertMomentToDate } from 'src/app/data/common/utils/dateUtil';
import selectors from 'src/app/redux/selectors';
import styles from './CalendarInput.css';
export interface ICalendarInputProps extends React.HTMLProps<HTMLInputElement> {
  onDateChange: (date: moment.Moment | any) => void;
  onMonthChange: (date: moment.Moment | any) => void;
  minDate: Date;
  maxDate: Date;
  selectedDate: null | moment.Moment;
  timezoneId: string;
  disabledDates: Date[];
}

const CalendarInput: FunctionComponent<ICalendarInputProps> = (props) => {

  const { onDateChange, onMonthChange, minDate, maxDate, selectedDate, timezoneId, disabledDates} = props;
  
  const locale = useSelector(selectors.profile.getUserLanguage);

  const getDayClassName = (date: Date): string => {
    const isCurrentDate = convertDateToMoment(date, timezoneId).isSame(moment().tz(timezoneId), "day");
    if (isCurrentDate) {
      return styles.currentDate;
    } else {
      return '';
    }
  };

  const handleDateChange = (newDate: Date) => {
    // date returns from calendar as start of the day in the timezone of browser
    // convert it to calendar timezone
    const date = convertDateToMoment(newDate, timezoneId);
    onDateChange(date);
  };

  const selectedDateLocal = selectedDate ? convertMomentToDate(selectedDate) : null;

  const formatDayName = (nameOfDay: string) => (nameOfDay.substr(0, 3));
  
  const handleMonthChange = (month: Date) => {
    const date = convertDateToMoment(month, timezoneId);
    onMonthChange(date);
  };

  return (
    <div className={styles.reactCalendar}>
      <ReactDatePicker
        onMonthChange={handleMonthChange}
        locale={locale}
        inline={true}
        selected={selectedDateLocal}
        onChange={handleDateChange}
        minDate={minDate}
        maxDate={maxDate}
        dayClassName={getDayClassName}
        excludeDates={disabledDates}
        formatWeekDay={formatDayName}
      />
    </div>
  );
};

export default CalendarInput;
