import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import styles from 'src/components/Selector/Selector.css';
import { ISelectorBaseProps, ISelectorOnChange, ISelectorOnChangeParams } from 'src/components/Selector/ISelectorProps';
import useCustomSelector from 'src/components/Selector/useCustomSelector';
import {
  cobaltTheme,
  darkTheme,
  defaultTheme,
  filterTheme,
  INITIAL_STYLES,
  INITIAL_THEME,
  ISelectorTheme,
  SelectorTheme,
  blueTheme,
} from 'src/components/Selector/themes/index';
import { isEqual } from 'lodash';

export interface ISelectorOption<T=string | number>{
  value: T;
  label: string;
  archived?: boolean;
  isDisabled?: boolean;
  description?: string;
}

const Selector = (props: ISelectorBaseProps) => {

  const {
    disabled,
    loading,
    options,
    defaultValue,
    defaultMenuIsOpen,
    isMulti,
    isSearchable,
    onInputChange,
    themeType = SelectorTheme.Default,
    className,
    placeholder,
    total,
    disabledTitle,
    value,
    showSelectedValue,
    clearOnOptionsChange,
    ariaLabel,
    inputId,
    classNamePrefix,
    onMenuScrollToBottom,
    onMenuOpen,
    hasScenarioChanged,
    setHasScenarioChanged,
    allowSelectionOfCachedValue,
    hasClearRef,
    removeMenuPortalTarget,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [val, setVal] = useState(value);

  const selectorRef = useRef<any>(null);
  const wrapperEl = useRef<HTMLDivElement>(null);

  const DROPDOWN_MIN_MAX_HEIGHT = 300;

  /* region caching to prevent duplicate events */
  /** use caching variable to prevent submit handleChange with the same value twice */
  const [cachedValue, setCachedValue] = useState<ISelectorOnChangeParams>({
    value,
    selectedAll: Array.isArray(value) && (value.length === total || value.length === 0)
  });

  const handleChange: ISelectorOnChange = useCallback((newValue) => {
    if (!options.length || !newValue) {
      return;
    }

    let newParams: ISelectorOnChangeParams;

    if (!Array.isArray(newValue)) {
      newParams = { value: newValue };
    } else {
      const newValueFallback: ISelectorOption[] = newValue || [];
      const selectedAll = newValueFallback.length === total || newValueFallback.length === 0;
      newParams = { value: newValueFallback, selectedAll };
    }

    if (isEqual(cachedValue, newParams) && !allowSelectionOfCachedValue) {
      return;
    }

    setCachedValue(newParams);
    props.onChange(newParams.value, newParams.selectedAll);
    setVal(newValue);
  }, [props.onChange, options, setVal]);

  /* endregion caching to prevent duplicate events */

  const handleInputValueChange = useMemo(() => (newValue: string) => {
    setInputValue(newValue);
    if (onInputChange) {
      onInputChange(newValue);
    }
  }, [setInputValue]);

  useEffect(() => {
    if (!wrapperEl.current || !props.required) {
      return;
    }
    const input = (wrapperEl.current as HTMLElement).getElementsByTagName('input');
    input[0].setAttribute('required', props.required);

  }, [wrapperEl.current]);

  useEffect(() => {
    if(!hasClearRef && !value && !!selectorRef?.current?.clearValue || hasClearRef === 'Ref'){
      selectorRef.current.clearValue();
    }
  }, [props]);

  useEffect(() => {
    if (!disabled && options?.length && !clearOnOptionsChange || !selectorRef.current?.clearValue) {
      return;
    }
    selectorRef.current.clearValue();
    setVal(isMulti ? [] : undefined);

  }, [disabled, selectorRef, options?.length]);


  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(val)) {
      setVal(value);
    }
  }, [value]);

  /* for single selector */
  useEffect(() => {
    if (!options.length || !val || Array.isArray(val)) {
      return;
    }
    handleChange(val);
  }, [val]);

  /* for multi selector*/
  useEffect(() => {
    if (hasScenarioChanged) {
      selectorRef?.current?.clearValue();
      if (setHasScenarioChanged) {
        setHasScenarioChanged(false);
      }
    }
    if (!options.length || !val || !Array.isArray(val)) {
      return;
    }
    const selectedAll = val.length === 0 || val.length === total;
    handleChange(val, selectedAll);

  }, [val, options]);


  const onMenuClose = useCallback(() => {
    setMenuIsOpen(false);

    if (props?.onMenuClose) {
      props.onMenuClose();
    }
  }, [props.onMenuClose]);


  const handleMenuOpen = () => {
    if (onMenuOpen) {
      onMenuOpen();
    }
    if(isMulti){
      setInputValue(" "); // Added a space for rerender the dropdown component.
    }
    setMenuIsOpen(true);
  };
  
  const onValueChange = useCallback((selectedOption: ISelectorOption<any> | Array<ISelectorOption<any>> | null | undefined) => {
    if (selectedOption) {
      setVal(selectedOption);
      return;
    }
    setCachedValue({value: null});
  }, [JSON.stringify(options), handleChange]);


  const selectComponents = useCustomSelector(
    props,
    selectorRef,
    [inputValue, handleInputValueChange],
    [menuIsOpen, handleMenuOpen],
    onMenuClose,
  );

  const theme: ISelectorTheme = useMemo(() => getTheme(themeType), [themeType]);

  /** this callback works only with single select */
  const handleSingleSelectInputValueChange = (newValue: string): void => {
    if (!isMulti) {
      setInputValue(newValue);
    }

    if (onInputChange) {
      onInputChange(newValue);
    }
  };

  const onKeyDown = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    // This is implemented to match the essentials guidelines of a11y, earlier the
    // dropdown used to give extra info on while navigating through the options,
    // so now the info message is reduced
    // Note: This is not a perfect solution, because A11yText component in react-select lib is not customizable
    setTimeout(() => {
      // get default react-select aria-context element with message to reduce in aria-live
      const ariaContextEl: HTMLElement | null | undefined =
        wrapperEl.current?.querySelector(`[id='aria-context']`);

      if (!ariaContextEl) {
        return;
      }

      // prevent screen-readers from announcing full text
      ariaContextEl.setAttribute('aria-hidden', 'true');

      // take first two sentences of default aria-context text
      const updatedText = ariaContextEl.innerText.split('.', 2).join('.') || '';

      // try to get a custom aria element if it exists
      // or create a new one
      let customAriaContextEl: HTMLElement | null | undefined =
        wrapperEl.current?.querySelector(`[id='select-aria-context-custom']`);

      if (!customAriaContextEl) {
        customAriaContextEl = document.createElement('p');
        customAriaContextEl.setAttribute('id', 'select-aria-context-custom');
        ariaContextEl.insertAdjacentElement('afterend', customAriaContextEl);
      }
      customAriaContextEl.innerText = updatedText;
    });

    if (keyboardEvent.key === 'Enter' && !menuIsOpen) {
      keyboardEvent.preventDefault();
      setMenuIsOpen(true);
    }
  };
  const intl = useIntl();
  const noOptionMessage = () => intl.formatMessage({ id: 'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions' });
  const containerClasses = cn(
    styles.container,
    themeType === SelectorTheme.Cobalt && styles.cobalt,
    themeType === SelectorTheme.Blue && styles.blueTheme,
    isMulti && styles.multi,
    disabled && styles.disabled,
    className
  );

  return (
    <div
      className={containerClasses}
      title={disabled ? disabledTitle : ''}
      tabIndex={-1}
      role={'button'}
      ref={wrapperEl}
    >
      <Select
        onKeyDown={onKeyDown}
        ref={selectorRef}
        inputValue={inputValue}
        onInputChange={handleSingleSelectInputValueChange}
        placeholder={placeholder}
        isLoading={loading}
        defaultValue={defaultValue}
        onChange={onValueChange}
        options={options}
        noOptionsMessage={noOptionMessage}
        hideSelectedOptions={!isMulti && !showSelectedValue}
        isClearable={false}
        isMulti={isMulti}
        backspaceRemovesValue={!isMulti}
        menuIsOpen={menuIsOpen}
        closeMenuOnSelect={!isMulti}
        isSearchable={isSearchable}
        defaultMenuIsOpen={defaultMenuIsOpen}
        isDisabled={disabled}
        onMenuOpen={handleMenuOpen}
        onMenuClose={onMenuClose}
        menuPortalTarget={removeMenuPortalTarget ? null : document.body}
        styles={theme.customStyles}
        theme={theme.customTheme}
        onBlur={onMenuClose}
        components={selectComponents}
        value={val ?? value}
        aria-label={ariaLabel}
        inputId={inputId}
        classNamePrefix={cn(classNamePrefix, isMulti && 'multi', themeType === SelectorTheme.Blue && 'blueTheme')}
        onMenuScrollToBottom={onMenuScrollToBottom}
        minMenuHeight={DROPDOWN_MIN_MAX_HEIGHT}
        maxMenuHeight={DROPDOWN_MIN_MAX_HEIGHT}
        menuShouldScrollIntoView={true}
      />
    </div>
  );
};

const getTheme = (themeType: SelectorTheme) => {
  switch (themeType) {
    case (SelectorTheme.Initial):
      return {
        customStyles: INITIAL_STYLES,
        customTheme: () => INITIAL_THEME,
      };
    case (SelectorTheme.Dark):
      return darkTheme;
    case (SelectorTheme.Filter):
      return filterTheme;
    case (SelectorTheme.Cobalt):
      return cobaltTheme;
    case (SelectorTheme.Blue):
      return blueTheme;
  }
  return defaultTheme;
};

export default React.memo(Selector);
