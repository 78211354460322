import { IAppState } from 'src/app/redux/store/reducers';
import IProjectsCount from 'src/app/data/client/interfaces/IProjectsCount';
import IRestError, {IErrorValidation} from 'src/app/data/common/interfaces/IRestError';
import {ICompany} from 'src/app/data/licensee/interfaces/ICompany';
import {parseErrorMessage, ERROR_CODE_VALIDATION, parseErrorValidation, parseErrorCodeToMessage} from 'src/app/data/common/utils/errorUtils';

export const getProjectsCount = (state: IAppState): IProjectsCount | null => state.company.projectsCount.item;

export const getCompanyList = (state: IAppState): ICompany[] => state.company.list.items;
export const getCompanyListPage = (state: IAppState): number => state.company.list.page || 0;
export const getCompanyListRefreshError = (state: IAppState): IRestError | null => state.company.list.refreshError;
export const getCompanyListSizePerPage = (state: IAppState): number => state.company.list.sizePerPage || 0;
export const getCompanyListTotalSize = (state: IAppState): number => state.company.list.totalSize || 0;
export const isCompanyListFetched = (state: IAppState): boolean => state.company.list.fetched;
export const isCompanyListRefreshing = (state: IAppState): boolean => state.company.list.refreshing;
export const getCompanyData = (state: IAppState): ICompany | null => state.company.data.item;

export const getCompanyErrorMessage = (state: IAppState): string => parseErrorMessage(state.company.error);
export const getCompanyValidationErrors = (state: IAppState): IErrorValidation[] => state.company.error.validations;
export const getCompanyUsersErrorMessage = (state: IAppState): string => (state.company.users.error.code === ERROR_CODE_VALIDATION)
  ? parseErrorValidation(state.company.users.error.validations[0]) : parseErrorCodeToMessage(state.company.users.error);
