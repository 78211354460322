import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';
import { ProjectStatus } from 'src/app/data/projects/interfaces/IProject';
import { EntitySelector, IEntitySelectorBaseProps } from 'src/components/EntitySelectors';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { CLIENTS_ROLES, getRoleById } from 'src/app/data/common/roles';
import getUserName from 'src/app/data/common/utils/getUserName';
import {
    usePagedListFetching,
  } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import actions from 'src/app/redux/store/actions';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { selectors } from 'src/app/redux';
import { useSelector } from 'react-redux';
import { FacilitatorLearnersListType } from 'src/app/data/clientConfig/interfaces/FacilitatorLearnersList';
import { isUserAOAndF, isCurrentUserFacilitator, isUserFAndL } from 'src/app/data/common/utils/userRoleUtils';

export interface IProjectSelectorProps extends IEntitySelectorBaseProps {
  startDate?: number;
  endDate?: number;
  active: boolean;
  statuses?: ProjectStatus[];
  archive: TListPageArchiveOption;
  clientIds: string[];
  onFetchAccountData: (data:INamedEntry[])=> void;
}

const SelectAccountSelector: FunctionComponent<IProjectSelectorProps> = (props) => {

  const {
    clientIds,
    onChange,
    disabledTitle,
    onFetchAccountData
  } = props;

  const intl = useIntl();
  const [ accountList, setAccountList ] = useState<any>();
  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  const clientConfig = useSelector(selectors.clientsConfig.getClientConfig);
  const allRolesOfUser = useSelector(selectors.profile.getAllRolesOfUser);
  const isFacilitatorTeamLevel = useMemo(() => {
    return clientConfig?.facilitatorLearnersListType === FacilitatorLearnersListType.FACILITATOR_TEAM;
  }, [clientConfig]);

  const getRoleIds = () => {
    if (userRole?.id === RoleID.FACILITATOR) {
      if (isUserAOAndF(allRolesOfUser)) {
        return [RoleID.BUYER];
      } else if (isUserFAndL(allRolesOfUser) || isFacilitatorTeamLevel) {
        return [RoleID.FACILITATOR];
      }
    }
    return [RoleID.BUYER, RoleID.FACILITATOR];
  };

  const requestTableFilters = {
    clientIds,
    roleIds: getRoleIds()
  };
  const requestParams=[requestTableFilters];

  const itemsInfo  = usePagedListFetching(actions.clients.users.fetchClientUsersExt)({
    order: 'firstName',
    page: 0,
    size: 9999,
  }, ...requestParams)();


  useEffect(() => {
    if (!itemsInfo.items.length && !itemsInfo.refreshing) {
      onChange(null);
    }
    const response = {
        ...itemsInfo,
        refreshing: false,
        items: fetchOptionList(itemsInfo.items),
    };
    setAccountList(response);
    onFetchAccountData(response.items);
  }, [itemsInfo.items.length]);

    const roleSort = (a1: string, a2: string) => {
      if ( a1 < a2 ) {
        return -1;
      }
      if (a1 > a2) {
        return 1;
      }
      return 0;
    };

    const getRoleId = (roles: RoleID[]) => {
        if(!Array.isArray(roles)){
            return '';
        }
        const [ roleId ] = [...roles].sort(roleSort);
      if (isCurrentUserFacilitator(userRole) && (isFacilitatorTeamLevel || isUserFAndL(allRolesOfUser)) && !isUserAOAndF(allRolesOfUser)) {
        return CLIENTS_ROLES[RoleID.FACILITATOR]?.shortName;
      }
        return getRoleById(roleId)?.shortName;
    };

    const fetchOptionList = (options: any) => {
        if(!Array.isArray(options)){
            return [];
        }
        return options.map((user: any) => ({
            id: user.email,
            name: `${getUserName(user)} - ${getRoleId(user.roles)}`,
        }));
    };

  return (
    <EntitySelector
      {...props}
      themeType={props.themeType || SelectorTheme.Blue}
      isMulti={props.isMulti !== false}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'Dashboard.Report.Dropdown.Select.Placeholder' })}
      searchPlaceholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.Search' })}
      disabledTitle={disabledTitle || intl.formatMessage({ id: 'Dashboard.Report.SelectAccountOwner.Label' })}
      itemsInfo={accountList}
      loading={false}
    />
  );
};

export default React.memo(SelectAccountSelector);
