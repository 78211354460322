import * as React from 'react';
import { FunctionComponent } from 'react';
import { SoftwareType } from 'src/app/data/projects/interfaces/IProject';
import { Radio, RadioGroup } from 'src/components/RadioGroup/RadioGroup';
import InfoTooltip from 'src/components/InfoTooltip/InfoTooltip';
import { TooltipPlacement } from 'src/components/Tooltip/Tooltip';
import { IntlShape, useIntl } from 'react-intl';
import { IOptionType } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';

interface ISoftwareTypeOption extends IOptionType {
  tooltipText?: string;
}

export interface IScenarioTypeSelectProps {
  editMode: boolean;
  labelId: string;
  selectedValue?: SoftwareType;
  onChange: (type: SoftwareType) => void;
  softwareTypeOptions?: ISoftwareTypeOption[];
}

const getSoftwareTypes = (intl: IntlShape): ISoftwareTypeOption[] => {
  return [
    {
      label: intl.formatMessage({ id: 'MursionPortal.Label.WebOnly' }),
      value: SoftwareType.WEB,
      tooltipText: intl.formatMessage({ id: 'MursionPortal.Label.SoftwareType.Tooltip' }),
    }
  ];
};

const getSoftwareTypeLabel = (type: SoftwareType, intl: IntlShape) => {

  switch (type) {
    case SoftwareType.WEB:
      return intl.formatMessage({ id: 'MursionPortal.Label.WebOnly' });
    case SoftwareType.DESKTOP:
      return intl.formatMessage({ id: 'MursionPortal.Label.DesktopOnly' });
    case SoftwareType.WEB_DESKTOP:
      return intl.formatMessage({ id: 'MursionPortal.Label.DesktopAndWeb' });
  }

  return intl.formatMessage({ id: 'MursionPortal.Label.DesktopOnly' });
};

const SoftwareTypeSelect: FunctionComponent<IScenarioTypeSelectProps> = (props) => {
  const { editMode, selectedValue, labelId, softwareTypeOptions } = props;
  const intl = useIntl();

  if (!editMode) {
    return <strong>{selectedValue ? getSoftwareTypeLabel(selectedValue, intl) : 'n/a'}</strong>;
  }

  const softwareType = softwareTypeOptions ?? getSoftwareTypes(intl);
  return (
    <RadioGroup name={'softwareType'} onChange={props.onChange} selectedValue={selectedValue} labelId={labelId}>
      {softwareType.map((type) => (
        <>
          <Radio value={type.value} label={type.label} />
          <div>
            {type.tooltipText ? (
              <InfoTooltip
                text={intl.formatMessage({ id: 'MursionPortal.Label.SoftwareType.Tooltip' })}
                placement={TooltipPlacement.TOP}
              />
            ) : null}
          </div>
        </>
      ))}
    </RadioGroup>
  );
};

export default SoftwareTypeSelect;
