import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';

export default {
  createNewGlobalUser: actionFromPromise(rest.createNewGlobalUser),
  updateGlobalUser: actionFromPromise(rest.updateGlobalUser),
  deleteGlobalUser: actionFromPromise(rest.deleteGlobalUser),
  restoreGlobalUser: actionFromPromise(rest.restoreGlobalUser),
  fetchGlobalUser: actionFromPromise(rest.fetchGlobalUser),
  fetchGlobalUsers: actionFromPromise(rest.fetchGlobalUsers),
  fetchGlobalUsersExt: actionFromPromise(rest.fetchGlobalUsersExt),
};
