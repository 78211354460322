import styles from './ButtonSpinner.css';
import React from 'react';

const ButtonSpinner = () =>{
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinner}/>
    </div>
  );
};

export default ButtonSpinner;