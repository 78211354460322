import { combineReducers } from 'redux';
import IListDataState from "src/app/data/common/interfaces/IListDataState";
import createListReducer from "src/app/redux/utils/createListReducer";
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';
import IAssetProject, { IAssetSettings } from "src/app/data/client/interfaces/IAsset";
import createItemReducer from "src/app/redux/utils/createItemReducer";
import IRestDataInfo from "src/app/data/common/interfaces/IRestItemInfo";

export interface IAssetReduxState {
  assetProject: IRestDataInfo<IAssetProject>;
  assetProjectList: IListDataState<IAssetProject>;

  settings: IRestDataInfo<IAssetSettings>;
  settingsListByScenario: IListDataState<IAssetSettings>;
  settingsListByScenarioTemplate: IListDataState<IAssetSettings>;

  assetProjectError: IErrorState;
  assetSettingsError: IErrorState;
}

export default combineReducers({
  assetProject: createItemReducer('ASSETS_PROJECTS_ITEM'),
  assetProjectList: createListReducer('ASSETS_PROJECTS'),

  settings: createItemReducer('ASSETS_SETTINGS'),
  settingsListByScenario: createListReducer('ASSETS_SETTINGS_BY_SCENARIO'),
  settingsListByScenarioTemplate: createListReducer('ASSETS_SETTINGS_BY_SCENARIO_TEMPLATE'),

  assetProjectError: createEntityErrorReducer('ASSETS_PROJECTS'),
  assetSettingsError: createEntityErrorReducer('ASSETS_SETTINGS'),
});
