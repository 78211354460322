import moment from 'moment-timezone';
import RestError from 'src/app/services/rest/RestError';
import RestService from 'src/app/services/rest/RestService';
import IAuthToken, { ISSOAuthToken } from 'src/app/data/auth/IAuthToken';
import { IAuthSchema } from 'src/app/redux/modules/auth/interfaces/IAuthData';
import IOAuthData from 'src/app/redux/modules/auth/interfaces/IOAuthData';
import { intl } from 'src/i18n/createIntl';
import { getDateFormat, getTimeFormat } from 'src/app/data/common/utils/dateUtil';
import { EULA_FORM_ID } from 'src/app/redux/modules/auth/config';
import { IRestorePassword } from 'src/layouts/unauthorized/RestorePassword/RestorePassword';


export interface IAuthError {
  code: string;
  message: string;
  validations: any[];
  properties: {
    info: {
      attempts: number;
      lockTimeSec: number;
      blocked: boolean;
    }
  };
}

interface INonRegUser {
  firstName: string;
  lastName: string;
  email?: string;
  ipAddress?: string;
}

export interface IOauthRequest {
  client_id: string;
  code_challenge: string;
  redirect_uri: string;
  response_type: string;
  state: string;
  code_challenge_method?: string;
  scope?: string;
}

export interface IEulaCancellationRecord {
  email: string;
  clientId: string;
  userName: string;
  userRole: string;
}

const login = (email: string, password: string, role?: string, captchaToken?: string | null): Promise<IAuthToken> => {
  return new Promise((resolve, reject) => {
    fetch(`${RestService.REST_URL}/auth/login/portal`, {
      body: JSON.stringify({
        password,
        username: email,
        role,
        captchaToken,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then(async (response) => {
        if (response.ok) {
          const token: IAuthToken = await response.json();

          if (!token) {
            throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty' }));
          }

          return resolve(token);
        }

        const responseBody = await response.json() as IAuthError;

        if (response.status === 400 && responseBody) {
          throw responseBody;
        }
        let message = intl().formatMessage({ id: 'MursionPortal.ErrorMessage.AuthenticationFailed' });

        if (responseBody && responseBody.properties) {
          const { attempts, blocked, lockTimeSec } = responseBody.properties.info;
          message = responseBody.message ? responseBody.message + '.' : message;

          if (responseBody.code === 'validation') {
            message = intl().formatMessage({ id: 'MursionPortal.ErrorMessage.AuthenticationFailed' }) + ' ' + intl().formatMessage({ id: 'MursionPortal.ErrorMessage.InvalidCaptcha' });
          } else if (blocked) {
            const blockedUntil = moment().add(lockTimeSec, 'seconds');

            message += ' ' + intl().formatMessage({ id: 'MursionPortal.ErrorMessage.AccountBlocked' }, { blockedDate: blockedUntil.format(`${getDateFormat()} ${getTimeFormat()}`) });
          } else if (attempts) {

            message += ' ' + intl().formatMessage({ id: 'MursionPortal.ErrorMessage.AttemptsRemaining' }, { attempts });
          } else {
            message += ' ' + intl().formatMessage({ id: 'MursionPortal.ErrorMessage.SomethingWrongWithResponse' });
          }
        } else {
          message += ' ' + intl().formatMessage({ id: 'MursionPortal.ErrorMessage.UnsupportedResponseFormat' });
        }
        reject(new RestError(message, response.status, '',responseBody.properties.info.attempts));
      }).catch((e) => {
      console.error(e); // tslint:disable-line
      reject(e.code ? e : {
        message: intl().formatMessage({ id: 'MursionPortal.ErrorMessage.TechnicalIssueMsg' }),
      });
    });

  });
};

const refreshToken = async (refreshTkn: string): Promise<IAuthToken> => {

  try {
    const response = await fetch(`${RestService.REST_URL}/auth/refresh`, {
      body: refreshTkn,
      headers: {
        'Content-Type': 'text/plain',
      },
      method: 'POST'
    });

    if (response.ok) {
      const token: IAuthToken = await response.json();

      if (!token) {
        throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty' }));
      }

      return token;
    }

    throw new RestError(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.CannotRefreshAccessToken' }), response.status);
  } catch (e) {
    console.error(e); // tslint:disable-line
  
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.TechnicalIssueMsg' }));
  }
};



const logout = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(`${RestService.REST_URL}/profile/logout`, {
      headers: RestService.generateHeaders(),
      method: 'POST'
    })
      .catch((e) => {
        console.error(e); // tslint:disable-line
        reject({
          message: intl().formatMessage({ id: 'MursionPortal.ErrorMessage.TechnicalIssueMsg' })
        });
      });

  });
};

const extractTokenFromResponseHeader = async (response: Response): Promise<IAuthToken> => {
  let responseJson;
  try {
    responseJson = await response.json();
  } catch (e) {
    // do nothing
  }
  if (response.ok) {
    const token: IAuthToken = responseJson;

    if (!token.access_token) {
      throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty' }));
    }

    return token;
  } else {
    if (responseJson) {
      throw responseJson;
    }
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.SomethingWrongWithResponse' }));
  }
};

const authByInvitation = (token: string, password: string): Promise<IAuthToken> => {
  return fetch(`${RestService.REST_URL}/auth/invite`, {
    body: JSON.stringify({ token, password }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(extractTokenFromResponseHeader);
};

const validateInvitation = (inviteToken: string): Promise<boolean> => {
  return fetch(`${RestService.REST_URL}/auth/invite/validate`, {
    body: inviteToken,
    headers: {
      'Content-Type': 'text/plain',
    },
    method: 'POST',
  }).then(async (response: Response) => {
    let responseJson;
    try {
      responseJson = await response.json();
    } catch (e) {
      // do nothing
    }

    return !!responseJson?.valid;
  });
};

const fetchLatestToken = (userId: string): Promise<string | null> => {
  return RestService.fetch(`${RestService.REST_URL}/auth/token/user?userId=${userId}`, {
    headers: {
      'Content-Type': 'text/plain',
    },
    method: 'GET',
  }).then((response: string) => {
    return response;
  });
  
};

const switchRole = (roleID: string): Promise<IAuthToken> => {
  return RestService.fetch(`${RestService.REST_URL}/profile/roleNew`, {
    body: roleID,
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const restorePassword = (restorePasswordPayload: IRestorePassword): Promise<number> => {
  return RestService.fetch(`${RestService.REST_URL}/auth/restorePassword`, {
    body:  JSON.stringify(restorePasswordPayload),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
};

const fetchAuthSchemas = (email: string): Promise<IAuthSchema[]> => {
  return RestService.fetch(`${RestService.REST_URL}/auth/schemas`, {
    body: email,
    method: 'POST',
  });
};

const fetchOAuthData = (state: string): Promise<IOAuthData> => {
  return RestService.fetch(`${RestService.REST_URL}/auth/oauth/code?state=${state}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const isRegisteredUser = (params: INonRegUser): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/auth/validate/unregisteredUser`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...params }),
    method: 'POST',
  });
};

const createNonRegisteredUser = (params: INonRegUser): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/auth/create/unregisteredUser`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...params }),
    method: 'POST',
  });
};

const unRegisteredUser = (token: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/auth/verify/unRegisteredUser/${token}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
};

export const recordUserEulaCancellation = (params: IEulaCancellationRecord) => {
  const { email, userName, userRole, clientId } = params;
  const curentDateTime = new Date();
  const year = curentDateTime.getFullYear();
  const month = curentDateTime.getMonth() + 1;
  const day = curentDateTime.getDay();
  const hour = curentDateTime.getHours();
  const minutes = curentDateTime.getMinutes();
  const env =  new URL(window.location.href).host;
  const url = `https://docs.google.com/forms/d/${EULA_FORM_ID}/formResponse?entry.1492694442=${userName}&entry.1862285076=${userRole}&entry.560169630=${email}&entry.655372619=${clientId}&entry.1044940708_year=${year}&entry.1044940708_month=${month}&entry.1044940708_day=${day}&entry.1343391634_hour=${hour}&entry.1343391634_minute=${minutes}&entry.1566336169=${env}`;
   fetch(url, {
    method: "GET",
    mode: "no-cors",
  });
};

const changePassword = (oldPassword: string, newPassword: string) => {
  return RestService.fetch(`${RestService.REST_URL}/auth/changePassword`, {
    body: JSON.stringify({ oldPassword, newPassword }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then((response) => {
    return response;
  }).catch(error => {
    return error;
  });
};

const getToken = (stateId: string): Promise<ISSOAuthToken> => {
  return RestService.fetch(`sso/auth/getToken/${stateId} `, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
};

export default {
  authByInvitation,
  validateInvitation,
  fetchAuthSchemas,
  fetchOAuthData,
  login,
  logout,
  restorePassword,
  refreshToken,
  switchRole,
  isRegisteredUser,
  createNonRegisteredUser,
  unRegisteredUser,
  recordEulaCancellation: recordUserEulaCancellation,
  fetchLatestToken,
  changePassword,
  getToken,
};
