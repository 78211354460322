import RestService from 'src/app/services/rest/RestService';
import IClientAppInfo from './interfaces/IClientAppInfo';

export enum Platform {
  MAC = 'MAC',
  WIN = 'WIN',
  LINUX = 'LINUX',
  IPHONE = 'IPHONE',
}

export const SESSION_TYPE_PARAMS = 'sessionType';
export const SESSION_REDIRECT_PARAMS = 'redirectFrom';

export interface IDeepLinkQuery {
  sessionId: string;
}

const getClientAppInfo = (platform: Platform, signal: AbortSignal | null = null): Promise<IClientAppInfo> => {

  return RestService.fetch(`${RestService.REST_URL}/downloads/app/${platform}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'text/plain',
    }),
    method: 'GET',
    signal,
  });
};

const getClientAppInfoBySession = (platform: Platform, sessionId?: string, signal: AbortSignal | null = null): Promise<IClientAppInfo> => {

  return RestService.fetch(`${RestService.REST_URL}/downloads/app/${platform}/session/${sessionId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'text/plain',
    }),
    method: 'GET',
    signal,
  });
};


export default {
  getClientAppInfo,
  getClientAppInfoBySession,
};
