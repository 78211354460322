import IRestError, { IErrorValidation } from "src/app/data/common/interfaces/IRestError";
import { IAppState } from 'src/app/redux/store/reducers';

export const getScenarioToUpdate = (state: IAppState): boolean => state.scenarios.scenarioToUpdate;

export const getScenarioCurrentInfo = (state: IAppState) => state.scenarios.scenarioCurrent;
export const getScenarioCurrent = (state: IAppState) => state.scenarios.scenarioCurrent.item;
export const isCurrentScenarioFetching = (state: IAppState) => state.scenarios.scenarioCurrent.refreshing;

export const getListByClient = (state: IAppState) => state.scenarios.listByClient.items;
export const isListByClientFetched = (state: IAppState): boolean => state.scenarios.listByClient.fetched;
export const getListByClientRefreshError = (state: IAppState): IRestError | null => state.scenarios.listByClient.refreshError;
export const isListByClientRefreshing = (state: IAppState): boolean => state.scenarios.listByClient.refreshing;

export const getListByProject = (state: IAppState) => state.scenarios.listByProject.items;
export const isListByProjectFetched = (state: IAppState): boolean => state.scenarios.listByProject.fetched;
export const getListByProjectRefreshError = (state: IAppState): IRestError | null => state.scenarios.listByProject.refreshError;
export const isListByProjectRefreshing = (state: IAppState): boolean => state.scenarios.listByProject.refreshing;

export const getListByTeam = (state: IAppState) => state.scenarios.listByTeam.items;
export const isListByTeamFetched = (state: IAppState): boolean => state.scenarios.listByTeam.fetched;
export const getListByTeamRefreshError = (state: IAppState): IRestError | null => state.scenarios.listByTeam.refreshError;
export const isListByTeamRefreshing = (state: IAppState): boolean => state.scenarios.listByTeam.refreshing;

export const getScenarioValidationErrors = (state: IAppState): IErrorValidation[] => state.scenarios.error.validations;
export const getErrorMessage = (state: IAppState): string => state.scenarios.error.message;
