import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import RestService from 'src/app/services/rest/RestService';
import { IAbortableRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import queryString from 'querystring';
import ITimeInterval from 'src/app/data/common/interfaces/ITimeInterval';

export interface IRestrictionLicenseeDetails {
    id: string;
    licenseeId: string;
    licenseeName: string;
}
export interface ITechRestriction {
  id?: string;
  startDate: number;
  endDate: number;
  tag: string;
  licenseeIds:string[];
  restrictionLicenseeDetails?:IRestrictionLicenseeDetails[];
  timezoneId: string;
}

const fetchTechRestrictionsList = (pageData: IListPageData): Promise<IListDataResponse<ITechRestriction>> => {
  const { page, size } = pageData;
  return RestService.fetch(`${RestService.REST_URL}/restriction/tech/list/${page}/${size}`, {
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'GET',
  });
};

const createTechRestriction = (restriction: ITechRestriction): Promise<ITechRestriction> => {
  return RestService.fetch(`${RestService.REST_URL}/restriction/tech`, {
    body: JSON.stringify(restriction),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
  });
};

const updateTechRestriction = (id: string, restriction: ITechRestriction): Promise<ITechRestriction> => {
  return RestService.fetch(`${RestService.REST_URL}/restriction/tech/${id}`, {
    body: JSON.stringify(restriction),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
  });
};

const deleteTechRestriction = (id: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/restriction/tech/${id}`, {
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'DELETE',
  });
};

const fetchTechRestrictionsByPeriod = (params: IAbortableRequestParams & { startTime: number, endTime: number }): Promise<ITimeInterval[]> => {
  const { startTime, endTime, signal } = params;

  const query = queryString.stringify({
    from: startTime,
    to: endTime,
  });


  return RestService.fetch(`${RestService.REST_URL}/restriction/tech/timeblocks?${query}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const deleteTechRestrictionProviders = (licenseesIds: string[]): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/restriction/tech/licensees`, {
    body: JSON.stringify(licenseesIds),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'DELETE',
  });
};
export default {
  fetchTechRestrictionsList,
  fetchTechRestrictionsByPeriod,
  createTechRestriction,
  updateTechRestriction,
  deleteTechRestriction,
  deleteTechRestrictionProviders
};