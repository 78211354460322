import { Theme, StylesConfig } from "react-select";

export interface ISelectorTheme {
  customStyles: StylesConfig<any, any>;
  customTheme: (theme: Theme) => Theme;
}

export enum SelectorTheme {
  Initial,
  Default,
  Dark,
  Filter,
  Cobalt,
  Blue,
}

export const INITIAL_STYLES: any = {
  menu: {},
  menuPortal: {
    zIndex: 1100,
  },
  option: {},
  singleValue: {
    fontWeight: 'normal',
  },
  control: {
    fontWeight: 600,
    borderRadius: '8px',
  },
  indicatorSeparator: { display: 'none' },
};

export const CUSTOM_STYLES = {
  menuListStyle: {
    borderBottomLeftRadius: '9px',
    borderBottomRightRadius: '9px',
  },
  menuPortalStyle: {
    borderRadius: '9px',
    overflow: 'hidden',
    boxShadow: '1px 1px 6px 1px rgba(160, 160, 160, 0.2)',
  },
  singleValueStyle: {
    transform: 'none',
    display: 'inline-block',
    fontWeight: 'normal',
    position: 'relative',
    maxWidth: 300,
  },
  placeholderStyle: {
    position: 'relative',
    transform: 'none',
  },
  inputStyle: {
    maxWidth: 300,
    position: 'fixed',
    color: 'inherit',
  },
  containerStyle: {
    border: 0,
    display: 'block',
  },
  optionStyle: {
    color: '',
    fontWeight: '',
    width: '100%',
    display: 'flex',
    padding: ' 9px 12px',
  }
};

export const INITIAL_THEME: Theme = {
  borderRadius: 8,
  spacing: {
    baseUnit: 4,
    controlHeight: 38,
    menuGutter: 8
  },
  colors: {
    primary: 'hsl(0,0%,70%)',
    primary75: '#4C9AFF',
    primary50: '#B2D4FF',
    primary25: '#DEEBFF',

    danger: '#DE350B',
    dangerLight: '#FFBDAD',

    neutral0: 'hsl(0, 0%, 100%)',
    neutral5: 'hsl(0, 0%, 95%)',
    neutral10: 'hsl(0, 0%, 90%)',
    neutral20: 'hsl(0, 0%, 80%)',
    neutral30: 'hsl(0, 0%, 70%)',
    neutral40: 'hsl(0, 0%, 60%)',
    neutral50: 'hsl(0, 0%, 50%)',
    neutral60: 'hsl(0, 0%, 40%)',
    neutral70: 'hsl(0, 0%, 30%)',
    neutral80: 'hsl(0, 0%, 20%)',
    neutral90: 'hsl(0, 0%, 10%)',
  },
};
