import RestService from 'src/app/services/rest/RestService';
import generateFormData from 'src/app/data/common/utils/generateFormData';

const submitFeedback = (data: any, picture: File | null): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/feedback/mursion`, {
    body: generateFormData(data, 'feedback', picture),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchZenDeskToken = (): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/feedback/zendesk/token`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  submitFeedback,
  fetchZenDeskToken,
};
