import * as React from 'react';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import getScenarioVersionLabel, { getScenarioBankVersionLabel } from 'src/app/data/projects/utils/getScenarioVersionLabel';
import IScenarioConfig from 'src/app/data/companyConfig/interfaces/IScenarioConfig';
import ToggleButton from 'src/components/ToggleButton';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { EntitySelector } from 'src/components/EntitySelectors';
import { getOptionValue } from 'src/app/data/common/utils/entry';
import { ISelectorOption } from 'src/components/Selector';

export interface IScenarioVersionSelectProps {
  className?: string;
  scenarioConfig: IScenarioConfig | null;
  editMode: boolean;
  value?: ScenarioVersion;
  onChange?: (value: ScenarioVersion) => void;
  isBankScenario?: boolean;
  themeType?: SelectorTheme;
  isDropdown?: boolean;
}

const sortMursionMeetToEnd = (a: ScenarioVersion, b: ScenarioVersion) => {
  if (a === ScenarioVersion.V3meet) {
    return 1;
  } else if (b === ScenarioVersion.V3meet) {
    return -1;
  }
  return 0;
};

class ScenarioVersionSelect extends React.Component<IScenarioVersionSelectProps> {

  public render() {// nosonar
    const { editMode, scenarioConfig, isBankScenario, isDropdown } = this.props;
    let value = scenarioConfig ? this.props.value : null; // do not show the selected value if the scenario config is not ready
    const allowedScenarioVersions = scenarioConfig ? [...scenarioConfig.allowedScenarioVersions] : [];

    const options = isBankScenario ? [] : allowedScenarioVersions;
    if (scenarioConfig && isBankScenario) {
      if (allowedScenarioVersions.includes(ScenarioVersion.V2)) {
        options.push(ScenarioVersion.V2);
      }
      if (
        allowedScenarioVersions.includes(ScenarioVersion.V3) ||
        allowedScenarioVersions.includes(ScenarioVersion.V3meet) ||
        allowedScenarioVersions.includes(ScenarioVersion.V3z)
      ) {
        options.push(ScenarioVersion.V3);
      }
      if (value === ScenarioVersion.V3 || value === ScenarioVersion.V3meet || value === ScenarioVersion.V3z) {
        value = ScenarioVersion.V3;
      }
    }

    const onToggleChange = (val: ScenarioVersion) => {
      if (this.props.onChange) {
        this.props.onChange(val);
      }
    };

    const sortedOptions = [...options].sort(sortMursionMeetToEnd);
    const scenarioVersionSelectOptions = sortedOptions.map((scenarioVersion: ScenarioVersion) => {
      const labelValue = isBankScenario ? getScenarioBankVersionLabel(scenarioVersion) : getScenarioVersionLabel(scenarioVersion);
      return { label: labelValue, value: scenarioVersion, role: 'tab' };
    });
    const valueToOption = (scenarioComposition: ISelectorOption) => {
      return {
        value: scenarioComposition.value,
        label: scenarioComposition.label
      };
    };

    const renderScenarioVersionSelection = () => {
      if (editMode && options.length > 1) {
        return <>
          {value &&
            (!isDropdown ?
              <ToggleButton value={value} onChange={onToggleChange} options={scenarioVersionSelectOptions}
                            themeType={this.props.themeType}/> :
              <EntitySelector
                preSelectedValue={value}
                valueToOption={valueToOption}
                optionToValue={getOptionValue}
                themeType={SelectorTheme.Blue}
                items={scenarioVersionSelectOptions}
                onChange={onToggleChange}
                isMulti={false}
              />)
          }
        </>;
      }
      return <strong>{value && (isBankScenario ? getScenarioBankVersionLabel(value) : getScenarioVersionLabel(value))}</strong>;
    };

    return renderScenarioVersionSelection();
  }
}

export default ScenarioVersionSelect;
