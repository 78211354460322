import { combineReducers } from 'redux';
import IListDataState from "src/app/data/common/interfaces/IListDataState";
import INamedEntry from "src/app/data/common/interfaces/INamedEntry";
import createListReducer from "src/app/redux/utils/createListReducer";
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';
import IRestDataInfo from 'src/app/data/common/interfaces/IRestItemInfo';
import createItemReducer from 'src/app/redux/utils/createItemReducer';

export interface IIndustriesReduxState {
  currentIndustry: IRestDataInfo<INamedEntry>;
  list: IListDataState<INamedEntry>;
  error: IErrorState;
}

export default combineReducers({
  currentIndustry: createItemReducer('INDUSTRY'),
  list: createListReducer('INDUSTRIES'),
  error: createEntityErrorReducer('INDUSTRY'),
});
