import React, { memo, useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import IRestCollectionInfo from 'src/app/data/common/interfaces/IRestCollectionInfo';
import ItemPickerListComponent from 'src/components/ItemPicker/components/ItemPickerListComponent';
import { IItemPickerListItemProps, IPickerItem } from 'src/components/ItemPicker/ItemPicker';
import { ISelectorOption } from 'src/components/Selector/Selector';
import styles from './TeamMembersList.css';
import { useIntl } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';
import cn from 'classnames';
import ScrollBar from 'src/components/ScrollBar';

interface ITeamMembersListComponentProps<T> {
  members: T[];
  addMember: (item: IPickerItem) => void;
  membersInfo: IRestCollectionInfo<T>;
  keyExtractor: (item: T) => string;
  nameExtractor: (item: T) => string;
  className?: string;
}

export interface ITeamMembersListProps<T> extends ITeamMembersListComponentProps<T> {
  onSearchChange: (value: string) => void;
  searchPlaceholder: string;
  selectedTeamOption?: ISelectorOption;
  teamMembers: T[];
}


interface IMembersSearchProps<T> {
  searchMemberValue: string;
  searchPlaceholder: string;
  onMemberSearchChange: (value: string) => void;
}

const TeamMembersListComponent = <T, >({
                                         members,
                                         addMember,
                                         membersInfo,
                                         keyExtractor,
                                         nameExtractor
                                       }: ITeamMembersListComponentProps<T>) => {
  const itemRenderer = (pickerProps: IItemPickerListItemProps) => (
    <div className={styles.ellipsis}>{nameExtractor(pickerProps.item.data)}</div>
  );

  const props = {
    ItemComponent: itemRenderer,
    className: styles.membersList,
    fetched: membersInfo.fetched,
    items: members.map(lrn => ({
      data: lrn,
      id: keyExtractor(lrn),
      name: nameExtractor(lrn),
      selected: false,
    })),
    loading: membersInfo.fetching,
    loadingError: membersInfo.fetchError,
    onClick: addMember,
  };

  return (<ItemPickerListComponent {...props} />);
};

const MembersSearch = <T, >({ searchMemberValue, onMemberSearchChange, searchPlaceholder }: IMembersSearchProps<T>) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    onMemberSearchChange(event.target.value);

  return (<div className={styles.searchContainer}>
    <input
      className={styles.search}
      placeholder={searchPlaceholder}
      type="search"
      value={searchMemberValue}
      onChange={onChange}
    />
  </div>);
};

const TeamMembersList = <T, >(
  {
    teamMembers,
    members,
    selectedTeamOption,
    addMember,
    membersInfo,
    onSearchChange,
    searchPlaceholder,
    keyExtractor,
    nameExtractor,
    className,
  }: ITeamMembersListProps<T>) => {
  const [searchMemberValue, setSearchMemberValue] = useState<string>('');

  useEffect(() => {
    setSearchMemberValue('');
  }, [selectedTeamOption]);

  useEffect(() => {
    onSearchChange(searchMemberValue);
  }, [searchMemberValue]);

  const searchProps: IMembersSearchProps<T> = {
    onMemberSearchChange: setSearchMemberValue,
    searchMemberValue,
    searchPlaceholder,
  };
  const listComponentProps: ITeamMembersListComponentProps<T> = {
    members,
    addMember,
    membersInfo,
    keyExtractor,
    nameExtractor,
    className,
  };
  const intl = useIntl();
  return (
    <FormGroup className={cn(styles.picker, className)}>
      <Form.Label>{TranslateMessage('MursionPortal.Label.SearchOrSelectLearners')}:</Form.Label>
      {
        teamMembers.length > 0
        && <MembersSearch {...searchProps} />
      }
      {
        members.length > 0
          ? <ScrollBar
              scrollClass={styles.ScrollBarWrap}
              contentClass={styles.scrollContent}
              horizontal={false}
            >
              <TeamMembersListComponent {...listComponentProps} />
            </ScrollBar>
          : (
            <span>{selectedTeamOption?.value ? intl.formatMessage({ id: 'MursionPortal.Message.NoMembersToSelect' }) : intl.formatMessage({ id: 'MursionPortal.Message.PleaseSelectATeamFirst' })}</span>
          )
      }
    </FormGroup>
  );
};

export default memo(TeamMembersList);
