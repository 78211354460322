import createListActions from 'src/app/redux/utils/createListActions';
import assetsRest from './rest';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';
import createItemActions from 'src/app/redux/utils/createItemActions';
import actionFromPromise from 'src/app/redux/utils/actionFromPromise';

export default {
  fetchAssetProject: actionFromPromise(assetsRest.fetchAssetProject),
  fetchAssetProjectList: createListActions('ASSETS_PROJECTS', assetsRest.fetchAssetProjectList),
  updateAssetProject: actionFromPromise(assetsRest.updateAssetProject, 'ASSETS_PROJECTS'),
  clearAssetProjectError: createClearEntityErrorAction('ASSETS_PROJECTS'),

  fetchAssetSettings: createItemActions('ASSETS_SETTINGS', assetsRest.fetchAssetSettings),
  updateAssetSettings: actionFromPromise(assetsRest.updateAssetSettings, 'ASSETS_SETTINGS'),
  fetchAssetSettingsByScenario: createListActions('ASSETS_SETTINGS_BY_SCENARIO', assetsRest.fetchAssetSettingsByScenario),
  fetchAssetSettingsByScenarioTemplate: createListActions('ASSETS_SETTINGS_BY_SCENARIO_TEMPLATE', assetsRest.fetchAssetSettingsByScenarioTemplate),
  clearAssetSettingsError: createClearEntityErrorAction('ASSETS_SETTINGS'),

  fetchAssetSettingsByScenarioWithOptions: actionFromPromise(assetsRest.fetchAssetSettingsByScenarioWithOptions),
  fetchAssetSettingsByScenarioTemplateWithOptions: actionFromPromise(assetsRest.fetchAssetSettingsByScenarioTemplateWithOptions),
  syncAssetProjects: actionFromPromise(assetsRest.syncAssetProjects, 'ASSET_SYNC', true),

  fetchAssetProjectListWithOptions: actionFromPromise(assetsRest.fetchAssetProjectListWithOptions),
  fetchAssetSettingsList: actionFromPromise(assetsRest.fetchAssetSettingsByScenario),
};