import { Theme } from 'react-select/dist/declarations/src/types';
import { INITIAL_STYLES, INITIAL_THEME, ISelectorTheme } from 'src/components/Selector/themes/SelectorThemes';

const blueTheme: ISelectorTheme = {

  customStyles: {
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      height: '2.45rem',
    }),
    menu: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menu,
      color: '#000',
      boxShadow: '1px 1px 6px 1px rgba(160, 160, 160, 0.3)',
      borderRadius: '9px',
      border: '1px solid #BECBD5',
      background : '#fff',
    }),
    menuPortal: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menuPortal,
    }),
    option: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.option,
      backgroundColor: state.isFocused ? '#D3E2FF' : '#fff',
      color: '#000',
      fontWeight: state.isSelected ? 500 : 'normal',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.singleValue,
      backgroundColor: state.isDisabled ? '#F6F9FC' : '#EAF1F7',
      color: state.isDisabled ? '#9BAAB6' : '#000',
      fontWeight: '400',
      fontSize: '16px'
    }),
    control: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.control,
      backgroundColor: state.isDisabled ? '#F6F9FC' : '#EAF1F7',
      borderRadius: '9px',
      fontWeight: 600,
      color: state.isDisabled ? 'rgb(90, 90, 90)' : '#555',
      border: 'none',
      padding: '0 0 0 5px',
      minHeight: '44px',
      boxShadow: 'none',

      '&:hover': {
        border: 'none'
      }
    }),
    indicatorSeparator: () => INITIAL_STYLES.indicatorSeparator,
    dropdownIndicator: (base) => ({
      ...base,
      fontSize: '0'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.placeholder,
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
      color: state.isDisabled ? '#9BAAB6' : '#000',
    }),
  },

  customTheme: (theme: Theme): Theme => ({
    ...theme,
    ...INITIAL_THEME,
    colors: {
      ...theme.colors,
      ...INITIAL_THEME.colors,
    },
  })
};

export default blueTheme;
