import * as React from 'react';
import { useEffect } from 'react';
import { Col, FormControl, FormControlProps, Row } from 'react-bootstrap';
import actions from 'src/app/redux/store/actions';
import styles from './AssetSettingField.css';
import cn from 'classnames';
import FieldGroup from 'src/components/FieldGroup';
import { IFieldGroupProps } from 'src/components/FieldGroup/FieldGroup';
import { IAssetSettings } from 'src/app/data/client/interfaces/IAsset';
import SelectList from 'src/components/SelectList/SelectList';
import SelectListItemPlain from 'src/components/SelectList/SelectListItemPlain';
import { useRawListDataFetchingWithOptions } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import Skeleton from 'react-loading-skeleton';
import LazyImage from 'src/components/LazyImage';
import { useIntl } from 'react-intl';

export interface IAssetSettingFieldProps {
  className?: string;
  scenarioId: string;
  aliasNames: {
    [assetId: string]: string,
  } | null;
  isScenarioTemplate?: boolean;
  selectedAssetSettingId: string;
  required?: boolean;
  viewMode?: boolean;
  label?: string;
  singleColumn?: boolean;
  onChange: (assetSettingsId: string, assetSettingName: string) => any;
  listView?: boolean;
}

const AssetSettingField = (props: IAssetSettingFieldProps) => {

  const {
    className,
    scenarioId,
    selectedAssetSettingId,
    required,
    viewMode,
    label,
    singleColumn,
    onChange,
    listView,
    isScenarioTemplate,
    aliasNames,
  } = props;
  const intl = useIntl();
  const fetcher = isScenarioTemplate
    ? actions.assets.fetchAssetSettingsByScenarioTemplateWithOptions
    : actions.assets.fetchAssetSettingsByScenarioWithOptions;
  const { items: assetSettingsByScenario, refreshing } = useRawListDataFetchingWithOptions(fetcher)({ scenarioId })();

  useEffect(() => {
    if (assetSettingsByScenario.length === 1 && !selectedAssetSettingId) {
      onSelect(assetSettingsByScenario[0].id)();
    } else {
      onSelect(selectedAssetSettingId);
    }
  }, [selectedAssetSettingId, assetSettingsByScenario, onChange]);

  const renderAssetSettingsImage = () => {

    if (!selectedAssetSettingId) {
      return null;
    }

    const imageUrl = getAssetImage();

    if (!imageUrl) {
      return <div>{intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.NoImage' })}</div>;
    }

    return <LazyImage src={imageUrl} alt={''}/>;
  };

  const onSelect = (assetSettingId: string) => () => {
    const selectedAssetSetting = assetSettingsByScenario && assetSettingsByScenario.find(setting => setting.id === assetSettingId);
    const aliasName = aliasNames && aliasNames[assetSettingId] ? aliasNames[assetSettingId] : '';
    const selectedAssetSettingName = aliasName || (!!selectedAssetSetting ? selectedAssetSetting.name : assetSettingId);

    onChange(assetSettingId, selectedAssetSettingName);
  };

  const getAssetName = () => {
    const emptyListMessage = intl.formatMessage({ id: 'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles' });

    if (!assetSettingsByScenario) {
      return emptyListMessage;
    }

    const currentAsset = assetSettingsByScenario.find((setting) => setting.id === selectedAssetSettingId);

    const aliasName = aliasNames && aliasNames[selectedAssetSettingId] ? aliasNames[selectedAssetSettingId] : '';

    return !!currentAsset && (aliasName || currentAsset.name) || emptyListMessage;
  };

  const getAssetImage = () => {
    if (!assetSettingsByScenario) {
      return null;
    }

    const currentAsset = assetSettingsByScenario.find((setting) => setting.id === selectedAssetSettingId);

    return !!currentAsset && currentAsset.picture;
  };

  const getAssetOptions = () => {
    const options: any[] = [
      <option key={'scenario-asset-settings'} value=""
              disabled={true}>{intl.formatMessage({ id: 'MursionPortal.SessionWizard.Option.SelectAnAsset' })}</option>,
    ];

    if (!assetSettingsByScenario) {
      return options;
    }

    return assetSettingsByScenario.reduce((opts, assetSetting) => {
      const { id, name } = assetSetting;

      return opts.concat(<option key={`scenario-asset-settings-${id}`} value={id}>{name}</option>);
    }, options);
  };

  const fieldGroupLabel = label || intl.formatMessage({ id: 'MursionPortal.Label.ArtBundle' });
  const newClassName = cn(className, !viewMode ? styles.propRowEdit : styles.propRow);
  const fieldGroupProps: IFieldGroupProps = {
    className: cn(newClassName, styles.container),
    as: 'select',
    id: 'scenario-asset-settings',
    onChange: (event: any) => onSelect(event.target.value)(),
    required,
    disabled: viewMode,
    value: selectedAssetSettingId,
  };

  if (refreshing){
    return <div style={{padding: 15}}><Skeleton count={3}/></div>;
  }

  if (singleColumn) {
    return (
      <>
        <FieldGroup label={fieldGroupLabel} {...fieldGroupProps}>
          {getAssetOptions()}
        </FieldGroup>
        {renderAssetSettingsImage()}
      </>
    );
  }

  if (listView) {
    return (
      <SelectList className={styles.listContainer}>
        {
          assetSettingsByScenario
            && assetSettingsByScenario.map((setting: IAssetSettings) =>
              <SelectListItemPlain
                onClick={onSelect(setting.id)}
                selected={setting.id === selectedAssetSettingId}
                key={`asset-setting-${setting.id}`}
                imageSrc={setting.picture}
                imageClassName={styles.assetImageContainer}
              >
                <div className={styles.item}>
                  {aliasNames && aliasNames[setting.id] ? aliasNames[setting.id] : setting.name}
                </div>
              </SelectListItemPlain>
          )
        }
      </SelectList>
    );
  }

  return (
    <Row className={className}>
      <Col xs={4}>
        {fieldGroupLabel}:
      </Col>
      <Col xs={8}>
        {
          viewMode
            ? (
              <>
                <span className={styles.propValue}>{getAssetName()}</span>
                {renderAssetSettingsImage()}
              </>
            )
            : (
              <FormControl {...fieldGroupProps as FormControlProps}>
                {getAssetOptions()}
              </FormControl>
            )
        }
      </Col>
    </Row>
  );
};

export default AssetSettingField;
