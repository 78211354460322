import { combineReducers } from 'redux';
import createItemReducer from 'src/app/redux/utils/createItemReducer';
import users, { ICompanyUsersState } from './users/reducer';
import IRestDataInfo from 'src/app/data/common/interfaces/IRestItemInfo';
import IProjectsCount from 'src/app/data/client/interfaces/IProjectsCount';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import {ICompany} from 'src/app/data/licensee/interfaces/ICompany';
import createListReducer from 'src/app/redux/utils/createListReducer';
import {IErrorState} from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';

export interface ICompanyState {
  list: IListDataState<ICompany>;
  projectsCount: IRestDataInfo<IProjectsCount>;
  users: ICompanyUsersState;
  error: IErrorState;
  data: IRestDataInfo<ICompany>;
}

export default combineReducers({
  error: createEntityErrorReducer('COMPANY'),
  list: createListReducer('COMPANY_LIST'),
  projectsCount: createItemReducer('COMPANY_PROJECTS_COUNT'),
  users,
  data: createItemReducer('COMPANY_NAME'),
});
