import errorsActions from 'src/app/redux/modules/errors/actions';
import RestError from 'src/app/services/rest/RestError';

export interface ICancellablePromise<U> {
  promise: Promise<U>;
  cancel: () => void;
}

export const makeCancellable = <U>(promise: Promise<U>): ICancellablePromise<U> => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val: U) => hasCanceled ? reject({ isCanceled: true }) : resolve(val),
      (error: any) => hasCanceled ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise as Promise<U>,
    cancel() {
      hasCanceled = true;
    },
  };
};

type ActionFromPromiseType = <T, U extends any[]>(
  request: (...args: U) => Promise<T>,
  entityName?: string,
  suppressAllErrors?: boolean,
  errorMessage?: string
) => ((...args: U) => Promise<T>);

const actionFromPromise: ActionFromPromiseType =
  (request, entityName, suppressAllErrors?, errorMessage?) => (
    (...args) => catchError(async () => await request(...args), entityName, suppressAllErrors, errorMessage) as unknown as Promise<any>
  );

const catchError = <T>(fn: (dispatch: any) => Promise<T>, entityName?: string, suppressAllErrors?: boolean, errorMessage?: string) =>
  (dispatch: any): Promise<T | null> =>
    fn(dispatch).then(
      null,
      (error: any) => {
        if (error && (error.code === 20 || error instanceof TypeError)) {
          // request is aborted
          return null;
        }
        if (entityName && (error.code === 'validation' || suppressAllErrors)) {
          dispatch(errorsActions.throwEntityError(entityName, error));
        } else {
          let customErrorMsg;
          if (errorMessage) {
            customErrorMsg = new RestError(errorMessage);
          }

          dispatch(errorsActions.throwError(customErrorMsg || error));
        }
        throw error;
      },
    );

export default actionFromPromise;
