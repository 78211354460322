exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".TierSelector__selectWrap___3Obtt{position:relative}.TierSelector__selectWrap___3Obtt span{position:absolute;background:transparent;width:30px;text-align:center;right:0;top:7px;pointer-events:none;height:22px}.TierSelector__selectWrap___3Obtt select::-ms-expand{display:none}.TierSelector__selectWrap___3Obtt select{-webkit-appearance:none;-moz-appearance:none;text-indent:1px;-o-text-overflow:\"\";text-overflow:\"\"}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/EntitySelectors/TierSelector.css"],"names":[],"mappings":"AAAA,kCACE,iBAAmB,CACpB,AACD,uCACE,kBAAmB,AACnB,uBAAwB,AACxB,WAAY,AACZ,kBAAmB,AACnB,QAAS,AACT,QAAS,AACT,oBAAqB,AACrB,WAAa,CACd,AAED,qDACI,YAAc,CACjB,AAED,yCACE,wBAAyB,AACzB,qBAAsB,AACtB,gBAAiB,AACjB,oBAAqB,AAClB,gBAAkB,CACtB","file":"TierSelector.css","sourcesContent":[".selectWrap{\n  position: relative;\n}\n.selectWrap span{\n  position: absolute;\n  background: transparent;\n  width: 30px;\n  text-align: center;\n  right: 0;\n  top: 7px;\n  pointer-events: none;\n  height: 22px;\n}\n\n.selectWrap select::-ms-expand {\n    display: none;\n}\n\n.selectWrap select{\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  text-indent: 1px;\n  -o-text-overflow: '';\n     text-overflow: '';\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"selectWrap": "TierSelector__selectWrap___3Obtt"
};