import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from 'src/app/redux/modules/session/rest';
import createListActions from 'src/app/redux/utils/createListActions';
import { AnyAction } from 'redux';
import * as types from 'src/app/redux/modules/session/types';
import { createClearEntityListAction } from 'src/app/redux/utils/createClearEntityListAction';

export default {
  refreshList: createListActions('SESSIONS', rest.fetchSessionListResponse),
  refreshTrainingsList: createListActions('SESSIONS', rest.fetchTrainingSessionListResponse),
  refreshListForClient: createListActions('SESSIONS_FOR_CLIENT', rest.fetchSessionListResponse),
  refreshListForLearner: createListActions('SESSIONS_FOR_LEARNER', rest.fetchLearnerReports),
  addCurrentUserToSession: actionFromPromise(rest.addCurrentUserToSession, 'SESSION', true),
  assignSSToSession: actionFromPromise(rest.assignSSToSession, 'SESSION', true),
  createSession: actionFromPromise(rest.createSession, 'SESSION', true),
  createEmergencySession: actionFromPromise(rest.createEmergencySession, 'SESSION', true),
  createTrainingSession: actionFromPromise(rest.createTrainingSession, 'SESSION', true),
  deleteSession: actionFromPromise(rest.deleteSession),
  deleteTrainingSession: actionFromPromise(rest.deleteTrainingSession),
  restoreSession: actionFromPromise(rest.restoreSession),
  fetchIcs: actionFromPromise(rest.fetchIcs),
  fetchTrainingIcs: actionFromPromise(rest.fetchTrainingIcs),
  fetchSession: actionFromPromise(rest.fetchSession),
  fetchTrainingSession: actionFromPromise(rest.fetchTrainingSession),
  fetchSessionListResponse: actionFromPromise(rest.fetchSessionListResponse),
  fetchTrainingSessionListResponse: actionFromPromise(rest.fetchTrainingSessionListResponse),
  fetchLearnerReports: actionFromPromise(rest.fetchLearnerReports),
  fetchLearnerReport: actionFromPromise(rest.fetchLearnerReport),
  confirmSelfReview: actionFromPromise(rest.confirmSelfReview),
  joinSession: actionFromPromise(rest.joinSession,'SESSION', true),
  leaveSession: actionFromPromise(rest.leaveSession, 'SESSION', true),
  requestSessionSwap: actionFromPromise(rest.requestSessionSwap, 'SESSION', true),
  reviewSession: actionFromPromise(rest.reviewSession, 'SESSION', true),
  startSession: actionFromPromise(rest.startSession),
  streamSession: actionFromPromise(rest.streamSession),
  updateSession: actionFromPromise(rest.updateSession, 'SESSION', true),
  updateTrainingSession: actionFromPromise(rest.updateTrainingSession, 'SESSION', true),
  watchSession: actionFromPromise(rest.watchSession),
  fetchSessionFlowEvents: actionFromPromise(rest.fetchSessionFlowEvents, 'SESSION_FLOW_EVENT', true),
  fetchSessionVideoURL: actionFromPromise(rest.fetchSessionVideoURL, 'SESSION_VIDEO', true),
  fetchTrainingSessionVideoURL: actionFromPromise(rest.fetchTrainingSessionVideoURL, 'SESSION_VIDEO', true),
  fetchSessionLearnerAnalytics: actionFromPromise(rest.fetchSessionLearnerAnalytics),
  shareSessionVideoLink: actionFromPromise(rest.shareSessionVideoLink),
  fetchSessionGoogleLink: actionFromPromise(rest.fetchSessionGoogleLink),
  fetchTrainingSessionGoogleLink: actionFromPromise(rest.fetchTrainingSessionGoogleLink),
  visitLearnerReport: actionFromPromise(rest.visitLearnerReport),
  fetchSessionSummary: actionFromPromise(rest.fetchSessionSummary),
  exportSessionsData: actionFromPromise(rest.exportSessionsData),
  exportTrainingSessionsData: actionFromPromise(rest.exportTrainingSessionsData),
  fetchSessionVideoDeleted: actionFromPromise(rest.fetchSessionVideoDeleted, 'SESSION', true),
  selectSessionTimezone: (timezoneId: string): AnyAction => ({
    payload: timezoneId,
    type: types.SELECT_TIMEZONE,
  }),
  startSessionAnalytics: actionFromPromise(rest.startSessionAnalytics, 'SESSION_ANALYTICS_START', true),
  exportSessionReport: actionFromPromise(rest.exportSessionReport),
  fetchUpcomingEventsCount: actionFromPromise(rest.fetchUpcomingEventsCount),
  fetchSessionLink: actionFromPromise(rest.fetchSessionLink),
  fetchTrainingLink: actionFromPromise(rest.fetchTrainingLink),
  fetchSessionRawAnalytics: actionFromPromise(rest.fetchSessionRawAnalytics),
  requestCertification: actionFromPromise(rest.requestCertification),
  refreshListCount: actionFromPromise(rest.fetchSessionListResponse),
  setAvailibility : actionFromPromise(rest.setAvailability),
  saveUserAttendanceAndConsent: actionFromPromise(rest.saveUserAttendanceAndConsent),
  updateSessionAttendance: actionFromPromise(rest.updateSessionAttendance),
  deleteRecording: actionFromPromise(rest.deleteRecording),
  triggerRescheduleEmail: actionFromPromise(rest.triggerRescheduleEmail),
  submitSessionSurveyResponse: actionFromPromise(rest.submitSessionSurveyResponse),
  fetchActiveScenarios: actionFromPromise(rest.fetchActiveScenarios),
  updateSessionSimPostSimulationSurvey: actionFromPromise(rest.updateSessionSimPostSimulationSurvey),
  getRestrictions: actionFromPromise(rest.getRestrictions),
  fetchRequestListSummaryDataPoints: actionFromPromise(rest.fetchRequestListSummaryDataPoints),
  fetchSimulationCounts: actionFromPromise(rest.fetchSimulationCounts),
  fetchSimulationCountsWithOptions: actionFromPromise(rest.fetchSimulationCountsWithOptions),
  logToSession: actionFromPromise(rest.logToSession),
  fetchSurveyAnalyticsResults: actionFromPromise(rest.fetchSurveyAnalyticsResults),
  fetchCanceledRescheduledListResponse: actionFromPromise(rest.fetchCanceledRescheduledListResponse),
  exportCanceledRescheduleData: actionFromPromise(rest.exportCanceledRescheduleData),
  declineSwapSession: actionFromPromise(rest.declineSwapSession, 'SESSION', true),
  getSessionStatus: actionFromPromise(rest.getSessionStatus),
  sessionListReset: createClearEntityListAction('SESSIONS'),
};
