import rest from './rest';
import createListActions from 'src/app/redux/utils/createListActions';
import actionFromPromise from 'src/app/redux/utils/actionFromPromise';

export default {
  fetchScenarioLearners: actionFromPromise(rest.fetchScenarioAllLearners),
  fetchScenarioAllLearnersOnly: actionFromPromise(rest.fetchScenarioAllLearnersOnly),
  fetchScenarioListWithLearners: actionFromPromise(rest.fetchScenarioListWithLearners),
  fetchScenarioLearnersForEditSession: createListActions('SESSION_SCENARIO_LEARNERS', rest.fetchScenarioLearners),
  refreshList: createListActions('SCENARIO_LEARNERS', rest.fetchScenarioLearners),
  fetchLearnerPersonalizedMessagesMetadata: actionFromPromise(rest.fetchLearnerPersonalizedMessagesMetadata),
  fetchPersonalizedMessage: actionFromPromise(rest.fetchPersonalizedMessage),
  addPersonalizedMessage: actionFromPromise(rest.addPersonalizedMessage),
  getPersonalizedScenarioList: actionFromPromise(rest.getPersonalizedScenarioList),
  deletePersonalizedMessage: actionFromPromise(rest.deletePersonalizedMessage),
  getPersonalizedPathwayList: actionFromPromise(rest.getPersonalizedPathwayList),
};
