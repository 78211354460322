import {
  ICanceledRescheduledList,
  IEmergencySessionCreate,
  ILearnerReport,
  IRequestData,
  ISelfReviewCreate,
  ISession,
  ISessionAttendanceUpdate,
  ISessionCreate,
  ISessionForList,
  ISessionReviewCreate,
  ISessionUpdate,
  ISessionWithHistoryInfo,
  ISimulationCountList,
  ITrainingSessionCreate,
  ITrainingSessionUpdate,
  IUpcomingEventsCounter,
  SessionStatusLabel,
  SessionUserStatus
} from 'src/app/data/session/interfaces/ISession';
import RestService from 'src/app/services/rest/RestService';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import { ISessionAnalyticsData, ISessionSummary } from 'src/app/data/session/interfaces/ISessionSummary';
import { ISessionFlowEventsLog, SessionFlowEventType } from 'src/app/data/session/interfaces/ISessionFlowEventsLog';
import SessionStatusType, { SessionStatusSubType } from 'src/app/data/session/interfaces/SessionStatusType';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import { intl } from 'src/i18n/createIntl';
import { googleFormId, submitSessionSurveyAPIToken } from "src/app/redux/modules/session/config";
import { IActiveScenarios } from 'src/app/data/projects/interfaces/IScenario';
import ITimeInterval from 'src/app/data/common/interfaces/ITimeInterval';
import { IRequestSummaryCounts } from 'src/layouts/common/Dashboard/components/DashboardSummary/RequestSummaryPointsContainer/RequestSummaryPointsContainer';
import { IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';

export enum SessionListQueryType {
  ALL = 'all',
  COMPLETED = 'completed',
  NON_COMPLETED = 'nonCompleted',
  IN_PROGRESS = 'inProgress',
  REPORT = 'report',
  CANCELLED = 'cancelled',
  RESCHEDULED = 'rescheduled',
}

export interface IRawSessionListQuery {
  client?: string;
  scenario?: string;
  team?: string;
  type?: SessionListQueryType;
  participatedFilter?: boolean;
  recRecipientFilter?: boolean;
  projectFilter?: string;
  scenarioFilter?: string;
  scenarioVersionFilter?: 'V2' | 'V3';
}

export interface ISessionListQuery {
  startDate?: number;
  endDate?: number;

  clientIds?: string[] | null;
  clientName?: string;

  projectIds?: string[] | null;
  projectName?: string;

  scenarioIds?: string[] | null;
  scenarioName?: string;

  teamIds?: string[] | null;

  learnerName?: string;
  participated?: boolean;
  recRecipient?: boolean;
  scenarioVersion?: 'V2' | 'V3';
  type?: SessionListQueryType | null;

  statuses?: SessionStatusType[] | null;
  participantIds?: string[] | null;
  billable?: boolean | null;
  subType?: SessionStatusSubType;
  certifiedScenarios?: boolean | null;
  requestStatusFilter? : string | null;
  scheduleTypeFilter ?: string | null;
  allCertificatesRequired?: boolean;
  videoStatusFilter?: string[] | null;
}
export interface IItemIds {
  value : string[] | null | undefined;
}

export interface ISummaryDataPointsQuery {
  startDate?: number;
  endDate?: number;

  clientIds?: IItemIds;
  projectIds?: IItemIds;
  scenarioIds?: IItemIds;
  sessionStatuses: SessionStatusType[] | null;
}

export interface ISessionSummaryReportQuery {
  interval: {
    startDate: number;
    endDate: number;
  };
  timezoneId: string;
  sessionStatus?: SessionStatusType[];
}

export interface ISessionLink {
  sessionId: string;
  link: {   // unity-app/zoom link
    web: string,
    desktop: string,
  };
  appInfo: {
    buildId: string;
    name: string;
    link: string; // app build download link
    version: string;
  } | null;
}

export interface IDashboardSessionLearner {
  clientName: string | null;
  deliveryMode: SessionType | null;
  learnerEmail: string | null;
  learnerId: string | null;
  learnerName: string | null;
  learnerStatus: SessionUserStatus | null;
  learnerTimeSpent: number | null;
  licenseeName: string | null;
  projectName: string | null;
  scenarioName: string | null;
  scenarioVersion: ScenarioVersion | null;
  sessionEndDate: number | null;
  sessionId: string | null;
  sessionStartDate: number | null;
  sessionStatus: SessionStatusType | null;
  sessionStatusLabel: SessionStatusLabel | null;
  simSpecialistEmail: string | null;
  simSpecialistId: string | null;
  simSpecialistName: string | null;
}

export interface ISetAvailability {
  SessionRequestStatus?: string;
  startDate?: number;
  endDate?: number;
  sessionId?: string;
}

export interface IUserAttendanceData {
  sessionId: string;
  userRecordingConsent: boolean;
  isRecordingLiesWithLearner?: boolean;
} 

const createSession = (sessionData: ISessionCreate): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session`, {
    body: JSON.stringify(sessionData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const shareSessionVideoLink = (sessionId: string, emails: string[]): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/video/send`, {
    body: JSON.stringify(emails),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const createEmergencySession = (sessionData: IEmergencySessionCreate): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/emergency`, {
    body: JSON.stringify(sessionData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const createTrainingSession = (sessionData: ITrainingSessionCreate): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/training`, {
    body: JSON.stringify(sessionData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchSession = (sessionId: string): Promise<ISessionWithHistoryInfo> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchTrainingSession = (sessionId: string): Promise<ISessionWithHistoryInfo> => {
  return RestService.fetch(`${RestService.REST_URL}/training/${sessionId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchSessionFlowEvents = (sessionId: string): Promise<ISessionFlowEventsLog> => {
  return RestService.fetch(`${RestService.BACKGROUND_REST_URL}/video/${sessionId}/events`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateSession = (sessionId: string, sessionData: ISessionUpdate): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}`, {
    body: JSON.stringify(sessionData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateTrainingSession = (sessionId: string, sessionData: ITrainingSessionUpdate): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/training/${sessionId}`, {
    body: JSON.stringify(sessionData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const deleteSession = (sessionId: string, reason: string, doNotSendCancellationEmail: boolean=false): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/?doNotSendCancellationEmail=${doNotSendCancellationEmail}`, {
    body: reason,
    headers: RestService.generateHeaders({
      'Content-Type': 'text/plain',
    }),
    method: 'DELETE',
  });
};

const deleteTrainingSession = (sessionId: string, reason: string): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/training/${sessionId}`, {
    body: reason,
    headers: RestService.generateHeaders({
      'Content-Type': 'text/plain',
    }),
    method: 'DELETE',
  });
};

const restoreSession = (sessionId: string): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/restore/${sessionId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const joinSession = (sessionId: string): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/join`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const startSession = (sessionId: string): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/start`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const requestSessionSwap = (sessionId: string): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/session/swap/request/${sessionId}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const addCurrentUserToSession = (sessionId: string): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/learner`, {
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const leaveSession = (sessionId: string, reason?: string): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/learner`, {
    body: reason,
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const streamSession = (sessionId: string): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/stream`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const watchSession = (sessionId: string): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/watch`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

/**
 * @param pageData
 * @param sessionQuery
 * @param signal
 */

const fetchSessionListResponse = (
  pageData: IListPageData,
  sessionQuery: ISessionListQuery | null = null, signal: AbortSignal | null = null): Promise<IListDataResponse<ISessionForList>> => {

  return RestService.fetch(`${RestService.REST_URL}/session/listNew`, {
    body: JSON.stringify({
      ...sessionQuery,
      ...pageData
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchUpcomingEventsCount = (): Promise<IUpcomingEventsCounter> => {
  return RestService.fetch(`${RestService.REST_URL}/event/counts`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchTrainingSessionListResponse = (
  pageData: IListPageData,
  sessionQuery: ISessionListQuery | null = null, signal: AbortSignal | null = null): Promise<IListDataResponse<ISessionForList>> => {

  return RestService.fetch(`${RestService.REST_URL}/training/listNew`, {
    body: JSON.stringify({
      ...sessionQuery,
      ...pageData
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportSessionsData = (pageData: IListPageData, sessionQuery: ISessionListQuery | null): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/session/list/export`, {
    body: JSON.stringify({
      ...sessionQuery,
      ...pageData,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const exportTrainingSessionsData = (pageData: IListPageData, sessionQuery: ISessionListQuery | null): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/training/list/export`, {
    body: JSON.stringify({
      ...sessionQuery,
      ...pageData,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const assignSSToSession = (sessionId: string, simspecialistId: string, reason: string, version: number, startDate?: number, endDate?: number): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/assignSS/${sessionId}`, {
    body: JSON.stringify({ simspecialistId, reason, version, startDate, endDate }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const declineSwapSession = (sessionId: string): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/session/decline/${sessionId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchIcs = (sessionId: string): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/calendar`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const  triggerRescheduleEmail = (sessionId: string): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/session/rescheduleReminder/${sessionId}`, {
    headers: RestService.generateHeaders(),
    method: 'PATCH',
  });
};

const fetchTrainingIcs = (sessionId: string): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/training/${sessionId}/calendar`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchSessionVideoURL = (sessionId: string, copyVideoLink: boolean = false): Promise<string | Error> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/video/?copyVideoLink=${copyVideoLink}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchSessionVideoDeleted = (sessionId: string): Promise<{sessionFlowEventType: SessionFlowEventType, supportCase: string | null}> => {
  return RestService.fetch(`${RestService.BACKGROUND_REST_URL}/video/${sessionId}/deleted`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchTrainingSessionVideoURL = (sessionId: string, copyVideoLink?: boolean): Promise<string | Error> => {
  return RestService.fetch(`${RestService.REST_URL}/training/${sessionId}/video/?copyVideoLink=${copyVideoLink}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchLearnerReports = (pageData: IListPageData): Promise<ILearnerReport[]> => {
  return RestService.fetch(`${RestService.REST_URL}/session/learnerReport${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const visitLearnerReport = (sessionId: string | undefined): Promise<ILearnerReport[]> => {
  if (!sessionId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.SessionIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/session/learnerReport/${sessionId}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchLearnerReport = (sessionId: string): Promise<ILearnerReport> => {
  if (!sessionId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.SessionIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/session/learnerReport/${sessionId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

/**
 * Request requires user to be logged in as a learner
 * @param sessionId
 */
const fetchSessionLearnerAnalytics = (sessionId: string): Promise<ISessionAnalyticsData[]> => {
  return RestService.fetch(`${RestService.BACKGROUND_REST_URL}/voice-analytics/${sessionId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

// Do not use Raw Data. Only for Export.
const fetchSessionRawAnalytics = (sessionId: string): Promise<any> => {
  return RestService.fetch(`${RestService.BACKGROUND_REST_URL}/voice-analytics/${sessionId}/raw/download`, {
    headers: RestService.generateHeaders(
      { 'Content-Type': 'application/zip' }
    ),
    method: 'GET',
  });
};

const fetchSessionGoogleLink = (sessionId: string): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/calendar/google`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchTrainingSessionGoogleLink = (sessionId: string): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/training/${sessionId}/calendar/google`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchSessionSummary = (): Promise<ISessionSummary> => {
  return RestService.fetch(`${RestService.REST_URL}/session/learnerReport/summary`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchSessionLink = (sessionId: string, platform: string, signal: AbortSignal | null = null): Promise<ISessionLink> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/app/${platform}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchTrainingLink = (sessionId: string, platform: string, signal: AbortSignal | null = null): Promise<ISessionLink> => {
  return RestService.fetch(`${RestService.REST_URL}/training/${sessionId}/app/${platform}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const reviewSession = (sessionId: string, reviewData: ISessionReviewCreate): Promise<ISession> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/review`, {
    body: JSON.stringify(reviewData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const confirmSelfReview = (sessionId: string, reviewData: ISelfReviewCreate): Promise<ISession> => {
  return RestService.fetch(`${RestService.BACKGROUND_REST_URL}/session/${sessionId}/review/self`, {
    body: JSON.stringify(reviewData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const startSessionAnalytics = (sessionId: string): Promise<any> => {
  return RestService.fetch(`${RestService.BACKGROUND_REST_URL}/voice-analytics/${sessionId}/start`, {
    body: '',
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const exportSessionReport = (params: ISessionSummaryReportQuery): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/report/sessions/export`, {
    body: JSON.stringify(params),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const requestCertification = (params: IRequestData): Promise<Response> => {
  const { scenario, simSpecialist, client, project } = params;
  const curentDateTime = new Date();
  const year = curentDateTime.getFullYear();
  const month = curentDateTime.getMonth() + 1;
  const day = curentDateTime.getDay();
  const hour = curentDateTime.getHours();
  const minutes = curentDateTime.getMinutes();
  const env = window.location.host;
  const url = `https://docs.google.com/forms/d/${googleFormId}/formResponse?entry.2107110270=${simSpecialist}&entry.276757934=${client}&entry.1986972622=${project}&entry.388225032=S${scenario}&entry.227085704_year=${year}&entry.227085704_month=${month}&entry.227085704_day=${day}&entry.1792644772_hour=${hour}&entry.1792644772_minute=${minutes}&entry.1296340993=${env}`;
  return fetch(url, {
    headers: RestService.generateHeaders({
      "Content-Type": "application/json",
    }),
    method: "GET",
    mode: "no-cors",
  });
};

const setAvailability = (params: ISetAvailability): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/session/request/status/${params.sessionId}`, {
    body: JSON.stringify({ ...params, sessionId: undefined }),
    headers: RestService.generateHeaders({
      "Content-Type": "application/json",
    }),
    method: "POST",
  });
};

const saveUserAttendanceAndConsent = (params: IUserAttendanceData): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/session/save/attendance`, {
    body: JSON.stringify({ ...params }),
    headers: RestService.generateHeaders({
      "Content-Type": "application/json",
    }),
    method: "POST",
  });
};

const updateSessionAttendance = (sessionAttendanceData: ISessionAttendanceUpdate, sessionId:string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/session/attendance/${sessionId}`, {
    headers: RestService.generateHeaders({
      "Content-Type": "application/json",
    }),
    method: 'PUT',
    body: JSON.stringify(sessionAttendanceData),
  });
};

const deleteRecording = (sessionId: string, supportCase: string): Promise<ISession> => {
  return RestService.fetch(`${RestService.BACKGROUND_REST_URL}/video/${sessionId}/deleteRecording`, {
    body: supportCase,
    headers: RestService.generateHeaders({
      'Content-Type': 'text/plain',
    }),
    method: 'DELETE',
  });
};

const submitSessionSurveyResponse = (surveyId: string, idempotencyKey: string, reqBody: any): Promise<ISession> => {
  return RestService.fetch("https://ca1.qualtrics.com/API/v3/surveys/" + surveyId + "/responses", {
    body: JSON.stringify(reqBody),
    headers: {
      "Content-Type": "application/json",
      "Idempotency-Key": idempotencyKey,
      "X-API-TOKEN": submitSessionSurveyAPIToken,
    },
    method: 'POST',
  });
};

const fetchActiveScenarios = (pageData: IListPageData, clientId: string, demandBased: boolean, isLearnerSequencingPrevention: boolean, joinNow: boolean): Promise<IActiveScenarios[]> => {
  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }
  return RestService.fetch(`${RestService.REST_URL}/scenario/list/active/client/${clientId}${getPageDataUrl(pageData)}&demandBased=${demandBased}&isLearnerSequencingPrevention=${isLearnerSequencingPrevention}&joinNow=${joinNow}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((listResponse) => {
    return listResponse.content || [];
  });
};

const  updateSessionSimPostSimulationSurvey = (sessionId: string, simPostSimulationSurveyCompleted: boolean): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/session/simPostSimulationSurvey/${sessionId}?simPostSimulationSurveyCompleted=${simPostSimulationSurveyCompleted}`, {
    headers: RestService.generateHeaders(),
    method: 'PATCH',
  });
};

const  getRestrictions = (providerId: string, startDate: number, endDate: number): Promise<ITimeInterval[]> => {
  return RestService.fetch(`${RestService.REST_URL}/restriction/list/${providerId}?startDate=${startDate}&endDate=${endDate}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

/**
 * fetch simulation data summary points for sim request list
 * @returns 
 */
 const fetchRequestListSummaryDataPoints = (params: IPageRequestParams & { query: ISummaryDataPointsQuery }): Promise<IRequestSummaryCounts> => {
  const { pageData, query } = params;
  return RestService.fetch(`${RestService.REST_URL}/dashboard/session/getSIMRosterCount`, {
      body: JSON.stringify({
      ...pageData,
      ...query,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchSimulationCounts = (pageData: IListPageData,
  sessionQuery: ISessionListQuery | null = null): Promise<ISimulationCountList> => {
 return fetchSessionCounts(pageData, sessionQuery);
};

const fetchSessionCounts = (pageData: IListPageData,
  sessionQuery: ISessionListQuery | null = null) => {
  return RestService.fetch(`${RestService.REST_URL}/session/count`, {
    body: JSON.stringify({
      ...sessionQuery,
      ...pageData
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchSimulationCountsWithOptions = (
  params: IPageRequestParams & { sessionQuery: ISessionListQuery | null }
): Promise<ISimulationCountList> => {
  const { pageData, sessionQuery } = params;
  return fetchSessionCounts(pageData, sessionQuery);
};

const logToSession = (userId: string, sessionId: string) => {
   return RestService.fetch(`${RestService.REST_URL}/session/logSession/${sessionId}`, {
    body: userId,
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const  fetchSurveyAnalyticsResults = (sessionId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/survey/analytics/results/${sessionId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchCanceledRescheduledListResponse = (
  pageData: IListPageData,
  sessionQuery: ISessionListQuery | null = null): Promise<IListDataResponse<ICanceledRescheduledList>> => {

  return RestService.fetch(`${RestService.REST_URL}/session/listCanceledRescheduled`, {
    body: JSON.stringify({
      ...sessionQuery,
      ...pageData
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const exportCanceledRescheduleData = (pageData: IListPageData, sessionQuery: ISessionListQuery | null): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/session/listCanceledRescheduled/export`, {
    body: JSON.stringify({
      ...sessionQuery,
      ...pageData,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const  getSessionStatus = (sessionId: string): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/session/${sessionId}/sessionStatus`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  addCurrentUserToSession,
  assignSSToSession,
  exportSessionsData,
  exportTrainingSessionsData,
  createSession,
  createEmergencySession,
  createTrainingSession,
  deleteSession,
  deleteTrainingSession,
  restoreSession,
  fetchIcs,
  fetchTrainingIcs,
  fetchSession,
  fetchTrainingSession,
  fetchSessionFlowEvents,
  fetchSessionListResponse,
  fetchTrainingSessionListResponse,
  fetchSessionVideoURL,
  fetchTrainingSessionVideoURL,
  fetchSessionLearnerAnalytics,
  fetchLearnerReports,
  fetchLearnerReport,
  confirmSelfReview,
  joinSession,
  leaveSession,
  requestSessionSwap,
  reviewSession,
  startSession,
  streamSession,
  updateSession,
  updateTrainingSession,
  visitLearnerReport,
  watchSession,
  shareSessionVideoLink,
  fetchSessionGoogleLink,
  fetchTrainingSessionGoogleLink,
  fetchSessionSummary,
  startSessionAnalytics,
  exportSessionReport,
  fetchUpcomingEventsCount,
  fetchSessionLink,
  fetchTrainingLink,
  fetchSessionRawAnalytics,
  requestCertification,
  setAvailability,
  saveUserAttendanceAndConsent,
  updateSessionAttendance,
  deleteRecording,
  triggerRescheduleEmail,
  submitSessionSurveyResponse,
  fetchActiveScenarios,
  fetchSessionVideoDeleted,
  updateSessionSimPostSimulationSurvey,
  getRestrictions,
  fetchRequestListSummaryDataPoints,
  fetchSimulationCounts,
  logToSession,
  fetchSurveyAnalyticsResults,
  fetchCanceledRescheduledListResponse,
  exportCanceledRescheduleData,
  fetchSimulationCountsWithOptions,
  declineSwapSession,
  getSessionStatus,
};
