import { IAppState } from 'src/app/redux/store/reducers';
import { ICreateEventInfo } from 'src/app/redux/modules/createEventDialog/reducer';

export const isCreateEventDialogShown = (state: IAppState): boolean => state.createEventDialog.showCreateEventDialog;
export const isScheduleSessionDialogShown = (state: IAppState): boolean => state.createEventDialog.showScheduleSessionDialog;
export const getCreateEventDialogInfo = (state: IAppState): ICreateEventInfo | null =>
  state.createEventDialog.createEventInfo ?
    ({
      ...state.createEventDialog.createEventInfo,
      date: state.createEventDialog.createEventInfo.date?.clone() || null,
    }) : null;
