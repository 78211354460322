import { combineReducers } from 'redux';
import IAvatar from 'src/app/data/client/interfaces/IAvatar';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import createListReducer from 'src/app/redux/utils/createListReducer';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';

export interface IAvatarsReduxState {
  list: IListDataState<IAvatar>;
  listByEnvironment: IListDataState<IAvatar>;
  error: IErrorState;
}

export default combineReducers({
  list: createListReducer('AVATARS'),
  listByEnvironment: createListReducer('AVATARS_BY_ENVIRONMENT'),
  error: createEntityErrorReducer('AVATAR'),
});
