import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';
import { createClearFieldValidationErrorAction } from 'src/app/redux/utils/createClearFieldValidationErrorAction';

export default {
  fetchSsoSchema: actionFromPromise(rest.fetchSsoSchema),
  fetchSsoSchemaWithOptions: actionFromPromise(rest.fetchSsoSchemaWithOptions),
  fetchSsoSchemasList: actionFromPromise(rest.fetchSsoSchemasList),
  createNewSsoSchema: actionFromPromise(rest.createNewSsoSchema, 'SSO', true),
  updateSsoSchema: actionFromPromise(rest.updateSsoSchema, 'SSO', true),
  clearError: createClearEntityErrorAction('SSO'),
  clearFieldValidationError: createClearFieldValidationErrorAction('SSO'),
  deleteSsoSchema: actionFromPromise(rest.deleteSsoSchema),
  restoreSsoSchema: actionFromPromise(rest.restoreSsoSchema),
  fetchSsoSchemasCountWithOptions: actionFromPromise(rest.fetchSsoSchemasCountWithOptions),
  fetchSsoMetadata: actionFromPromise(rest.fetchSsoMetadata),
};
