import React, { FunctionComponent, useMemo } from 'react';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import getScenarioVersionLabel from 'src/app/data/projects/utils/getScenarioVersionLabel';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import { EntitySelector, IEntitySelectorBaseProps, ValueToOptionMapper } from 'src/components/EntitySelectors';
import { getOptionValue } from 'src/app/data/common/utils';

export interface IUsedScenarioVersionSelectorProps extends IEntitySelectorBaseProps<ScenarioVersion> {
  onChange: (val: ScenarioVersion[] | null) => void;
  themeType?: SelectorTheme;
}

const scenarioVersionOptions = [
  ScenarioVersion.V2,
  ScenarioVersion.V3,
  ScenarioVersion.V3z,
  ScenarioVersion.V3meet,
  ScenarioVersion.V3MAGIC,
];

const UsedScenarioVersionSelector: FunctionComponent<IUsedScenarioVersionSelectorProps> = (props) => {

  const intl = useIntl();
  const clientConfig = useSelector(selectors.clientsConfig.getClientConfig);
  const companyScenarioConfig = useSelector(selectors.companyConfig.getScenarioConfig);

  const items = useMemo(() => {
    const availableScenarioVersions = clientConfig?.allowedScenarioVersions
      || companyScenarioConfig?.allowedScenarioVersions
      || null;

    if (availableScenarioVersions) {
      return scenarioVersionOptions.filter(
        opt => availableScenarioVersions.some(sv => sv === opt)
      );
    }

    return scenarioVersionOptions;
  }, [clientConfig, companyScenarioConfig]);


  const valueToOption: ValueToOptionMapper<ScenarioVersion, ScenarioVersion> = (value) => ({
    value,
    label: getScenarioVersionLabel(value),
  });

  return (
    <EntitySelector
      {...props}
      themeType={props.themeType || SelectorTheme.Filter}
      isMulti={true}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion' })}
      searchPlaceholder={intl.formatMessage({ id: 'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint' })}
      defaultValue={[]}
      items={items}
      valueToOption={valueToOption}
      optionToValue={getOptionValue}
    />
  );
};

export default React.memo(UsedScenarioVersionSelector);
