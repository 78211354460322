exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".FroalaEditor__floaraTextEditorWrap___28KtO .fr-popup .fr-checkbox-line label{display:inline-block;font-size:inherit;line-height:inherit}.FroalaEditor__floaraTextEditorWrap___28KtO .fr-second-toolbar:empty{display:none}.FroalaEditor__floaraTextEditorWrap___28KtO .fr-command.fr-submit{white-space:nowrap}.FroalaEditor__linkGreen___EAKtB{color:var(--color-success-green)}.FroalaEditor__linkStrong___3SkQN{font-weight:700}:is(ul,ol) li[style*=color] :is(a,h1,h2,h3,h4,h5,h6){color:inherit}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/FroalaEditor/FroalaEditor.css"],"names":[],"mappings":"AAAA,8EACI,qBAAsB,AACtB,kBAAmB,AACnB,mBAAqB,CACxB,AAED,qEACI,YAAc,CACjB,AAED,kEACI,kBAAoB,CACvB,AAED,iCACI,gCAAkC,CACrC,AAED,kCACI,eAAkB,CACrB,AAED,qDACI,aAAe,CAClB","file":"FroalaEditor.css","sourcesContent":[".floaraTextEditorWrap :global(.fr-popup .fr-checkbox-line label) {\n    display: inline-block;\n    font-size: inherit;\n    line-height: inherit;\n}\n\n.floaraTextEditorWrap :global(.fr-second-toolbar:empty) {\n    display: none;\n}\n\n.floaraTextEditorWrap :global(.fr-command.fr-submit) {\n    white-space: nowrap;\n}\n\n.linkGreen {\n    color: var(--color-success-green);\n}\n\n.linkStrong {\n    font-weight: bold;\n}\n\n:is(ul, ol) li[style*='color'] :is(a, h1, h2, h3, h4, h5, h6) {\n    color: inherit;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"floaraTextEditorWrap": "FroalaEditor__floaraTextEditorWrap___28KtO",
	"linkGreen": "FroalaEditor__linkGreen___EAKtB",
	"linkStrong": "FroalaEditor__linkStrong___3SkQN"
};