import * as React from "react";
import { FunctionComponent } from "react";
import styles from "./ScrollBar.css";
import cn from "classnames";
import ScrollArea from "react-scrollbar";

export interface IScrollBarProps {
  scrollClass: string;
  contentClass?: string;
  horizontal: boolean;
  scrollRef?: any;
}

const ScrollBar: FunctionComponent<IScrollBarProps> = (props) => {
  return (
    <div className={styles.divWrap}>
      <ScrollArea
        ref={props.scrollRef}
        speed={0.8}
        className={cn(styles.scrollStyle, props.scrollClass)}
        contentClassName={props.contentClass}
        horizontal={props.horizontal}
      >
        {props.children}
      </ScrollArea>
    </div>
  );
};

export default ScrollBar;
