import { AnyAction, combineReducers } from 'redux';
import IListDataState from "src/app/data/common/interfaces/IListDataState";
import IScenario, { IScenarioTemplate } from "src/app/data/projects/interfaces/IScenario";
import createListReducer from "src/app/redux/utils/createListReducer";
import { IScenarioLearnersState } from 'src/app/redux/modules/scenarios/learners/reducer';
import learners from './learners/reducer';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';
import createItemReducer from "src/app/redux/utils/createItemReducer";
import IItemDataState from 'src/app/data/common/interfaces/IItemDataState';
import * as types from './types';

export interface IScenariosReduxState {
  error: IErrorState;
  scenarioCurrent: IItemDataState<IScenario>;
  learners: IScenarioLearnersState;
  listByClient: IListDataState<IScenario>;
  listByProject: IListDataState<IScenario>;
  listByTeam: IListDataState<IScenario>;
  templatesList: IListDataState<IScenarioTemplate>;
  scenarioToUpdate: boolean;
}


const scenarioToUpdate = (state: boolean = false, action: AnyAction) => {
  switch (action.type) {
    case types.UPDATE_CURRENT_SCENARIO:
      return true;
    case types.CURRENT_SCENARIO_UPDATED:
      return false;
    default:
      return false;
  }
};

export default combineReducers({
  error: createEntityErrorReducer('SCENARIO'),
  learners,
  scenarioCurrent: createItemReducer('SCENARIO_CURRENT'),
  listByClient: createListReducer('SCENARIOS_BY_CLIENT'),
  listByProject: createListReducer('SCENARIOS_BY_PROJECT'),
  listByTeam: createListReducer('SCENARIOS_BY_TEAM'),
  templatesList: createListReducer('SCENARIOS_TEMPLATES'),
  scenarioToUpdate
});
