import React from 'react';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import styles from '../../../Selector.css';
import { components } from 'react-select';
import cn from 'classnames';
import { useIntl } from 'react-intl';

interface IMultiSelectorOptionProps {
  optionProps: OptionProps<any, any, any>;
}

const MultiSelectorOption = (props: IMultiSelectorOptionProps) => {
  const { optionProps } = props;
  const { data: { archived }, isSelected, label } = optionProps;

  const intl = useIntl();
  const ariaLabel = `${label} ${archived ? intl.formatMessage({ id: 'MursionPortal.Heading.Archived' }) : ''}`;

  return (
    <div className={styles.checkboxContainer} title={ariaLabel}>
      <components.Option {...optionProps}>
        <i className={styles.checkbox}>
          <input type={'checkbox'} checked={isSelected} readOnly={true} aria-label={ariaLabel} />
          <span />
        </i>
        <span className={cn(
          styles.optionLabel,
          archived && styles.archived,
          isSelected && styles.optionLabelChecked
        )}>{label}</span>
      </components.Option>
    </div>
  );
};

export default MultiSelectorOption;
