import authRest from 'src/app/redux/modules/auth/rest';
import RestService from './RestService';
import AuthService, { AuthTokenState } from 'src/app/services/auth/AuthService';
import RestError from 'src/app/services/rest/RestError';
import { intl } from 'src/i18n/createIntl';

class RestServiceWrapper {

  get REST_URL() {
    return RestService.REST_URL;
  }

  get GET_PUBLIC_URL() {
    return RestService.GET_PUBLIC_URL;
  }

  get DASHBOARD_REST_URL() {
    return RestService.DASHBOARD_REST_URL;
  }

  get BACKGROUND_REST_URL() {
    return RestService.BACKGROUND_REST_URL;
  }

  get SSO_REST_URL() {
    return RestService.SSO_REST_URL;
  }

  get GLOBAL_CONFIG_REST_URL() {
    return RestService.GLOBAL_CONFIG_REST_URL;
  }

  get INTERGRATION_SERVICE_URL(){
    return RestService.GET_INTEGRATION_REST_URL;
  }

  public generateHeaders(headers?: { [headerName: string]: string }) {
    return RestService.generateHeaders(headers);
  }

  public fetch(input: string, init?: RequestInit): Promise<any> {
    return RestService.fetch(input, init, this.onUnauthorized.bind(this, input, init));
  }

  private timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async onUnauthorized(input: string, init?: RequestInit) {
    const headers = init ? init.headers : {};
    const wasTokenRefreshing = AuthService.tokenState === AuthTokenState.REFRESHING;
    let retryCount = 0;
    const maxRetries = 5; // Set your desired maximum number of retries
  
    // for multiple simultaneously failed requests
    // wait while the token is refreshing, with a timeout
    while (AuthService.tokenState === AuthTokenState.REFRESHING && retryCount < maxRetries) {
      await this.timeout(500);
      retryCount++;
    }
    // Check if the maximum number of retries has been reached
    if ((retryCount === maxRetries) && (AuthService.tokenState === AuthTokenState.REFRESHING)) {
      throw new RestError('Maximum retries reached while waiting for token refreshing.', 401);
    }

    // if the token was already refreshing when the request failed no need to refresh the token again
    // just repeat the failed request with the new token
    if (wasTokenRefreshing) {
      const newToken = AuthService.getToken();

      return await RestService.fetch(input, {
        ...init,
        headers: {
          ...headers,
          ...RestService.getAuthHeader(newToken)
        },
      });
    }

    const token = AuthService.getRefreshToken();

    if (!token) {
      throw new RestError(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.RefreshTokenNotFound' }), 401);
    }

    let newAccessToken = '';
    try {
      AuthService.updateTokenState(AuthTokenState.REFRESHING);

      const result = await authRest.refreshToken(token);
      newAccessToken = result.access_token;

      AuthService.updateTokenState(AuthTokenState.VALID);
      RestService.emit(RestService.EVENTS.AUTH_TOKEN_UPDATED, result);
    } catch (e) {
      AuthService.updateTokenState(AuthTokenState.INVALID);
      throw new RestError(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.CannotRefreshToken' }), 401);
    }

    return await RestService.fetch(input, {
      ...init,
      headers: {
        ...headers,
        ...RestService.getAuthHeader(newAccessToken)
      },
    });
  }

}

export default new RestServiceWrapper();
