import auth from 'src/app/redux/modules/auth/actions';
import avatars from 'src/app/redux/modules/avatars/actions';
import calendar from 'src/app/redux/modules/calendar/actions';
import calendarEvents from 'src/app/redux/modules/calendarEvents/actions';
import createEventDialog from 'src/app/redux/modules/createEventDialog/actions';
import certificates from 'src/app/redux/modules/certificates/actions';
import clients from 'src/app/redux/modules/clients/actions';
import globalUsers from 'src/app/redux/modules/globalUsers/actions';
import clientsConfig from 'src/app/redux/modules/clientsConfig/actions';
import company from 'src/app/redux/modules/company/actions';
import companyConfig from 'src/app/redux/modules/companyConfig/actions';
import config from 'src/app/redux/modules/config/actions';
import downloads from 'src/app/redux/modules/downloads/actions';
import environments from 'src/app/redux/modules/environments/actions';
import entityHistory from 'src/app/redux/modules/entityHistory/actions';
import errors from 'src/app/redux/modules/errors/actions';
import feedback from 'src/app/redux/modules/feedback/actions';
import industries from 'src/app/redux/modules/industries/actions';
import layout from 'src/app/redux/modules/layout/actions';
import assets from 'src/app/redux/modules/assets/actions';
import messagePanel from 'src/app/redux/modules/messagePanel/actions';
import user from 'src/app/redux/modules/profile/actions';
import projects from 'src/app/redux/modules/projects/actions';
import scenarios from 'src/app/redux/modules/scenarios/actions';
import sessionWizard from 'src/app/redux/modules/sessionWizard/actions';
import session from 'src/app/redux/modules/session/actions';
import monitoring from 'src/app/redux/modules/monitoring/actions';
import sso from 'src/app/redux/modules/sso/actions';
import teams from 'src/app/redux/modules/teams/actions';
import ws from 'src/app/redux/modules/ws/actions';
import techRestrictions from 'src/app/redux/modules/techRestrictions/actions';
import demandBasedSession from 'src/app/redux/modules/demandBasedSession/actions';
import integrations from 'src/app/redux/modules/integrations/lti/actions';
import publicSession from 'src/app/redux/modules/publicSession/actions';
import supplyManagement from 'src/app/redux/modules/supplyManagement/actions';
import contract from 'src/app/redux/modules/contract/actions';
import learner from 'src/app/redux/modules/learner/actions';
import simulation from 'src/app/redux/modules/simulation/actions';
import dashboard from 'src/app/redux/modules/dashboard/actions';
import dashboardReport from "src/app/redux/modules/dashboardReport/actions";
import dashboardEmails from 'src/app/redux/modules/dashboardEmails/actions';
import scenarioBankEvent from 'src/app/redux/modules/scenarios/scenarioBankEvents/actions';
import nextGenScenario from 'src/app/redux/modules/nextGenScenario/actions';
import practiceSession from 'src/app/redux/modules/practiceSession/actions';

export default {
  auth,
  avatars,
  calendar,
  calendarEvents,
  createEventDialog,
  certificates,
  clients,
  globalUsers,
  clientsConfig,
  company,
  companyConfig,
  config,
  downloads,
  environments,
  entityHistory,
  errors,
  feedback,
  industries,
  layout,
  learner,
  assets,
  messagePanel,
  profile: user,
  projects,
  session,
  monitoring,
  sessionWizard,
  scenarios,
  sso,
  teams,
  user,
  ws,
  techRestrictions,
  demandBasedSession,
  integrations,
  publicSession,
  supplyManagement,
  contract,
  simulation,
  dashboard,
  dashboardReport,
  dashboardEmails,
  scenarioBankEvent,
  nextGenScenario,
  practiceSession,
};
