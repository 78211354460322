import { combineReducers } from 'redux';
import createListReducer from 'src/app/redux/utils/createListReducer';
import ICalendarEvent from 'src/app/data/calendar/interfaces/ICalendarEvent';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';


export interface ICalendarEventsReduxState {
  listForDashboard: IListDataState<ICalendarEvent>;
  listForEditSession: IListDataState<ICalendarEvent>;
  error: IErrorState;
}

export default combineReducers({
  listForDashboard: createListReducer('DASHBOARD_CALENDAR_EVENTS'),
  listForEditSession: createListReducer('SESSION_CALENDAR_EVENTS'),
  error: createEntityErrorReducer('EVENT'),
});
