import { combineReducers } from 'redux';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import createItemReducer from 'src/app/redux/utils/createItemReducer';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';
import IItemDataState from 'src/app/data/common/interfaces/IItemDataState';

export interface ICompanyConfigState {
  data: IItemDataState<ICompanyConfig>;
  error: IErrorState;
}

export default combineReducers({
  data: createItemReducer('COMPANY_CONFIG'),
  error: createEntityErrorReducer('COMPANY_CONFIG'),
});
