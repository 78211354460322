import RestService from "src/app/services/rest/RestService";
import {ICertificate} from "src/app/redux/modules/company/interfaces/ICertificate";
import { intl } from 'src/i18n/createIntl';
import getPageDataUrl from "src/app/data/common/utils/getPageDataUrl";
import { IPageRequestParams } from "src/app/data/common/interfaces/IRestRequest";
import IListDataResponse from "src/app/data/common/interfaces/IListDataResponse";
import { IEntityCertificationHistory } from 'src/app/data/common/interfaces/IEntityHistory';

const fetchCertificate = (certificateId: string, scenarioId: string): Promise<ICertificate> => {
  return RestService.fetch(`${RestService.REST_URL}/certificate/${certificateId}/scenario/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchClientCertificates = (clientId: string): Promise<ICertificate[]> => {
  if (!clientId){
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/certificate/client/${clientId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchLicenseeCertificates = (licenseeId: string): Promise<ICertificate[]> => {
  if (!licenseeId){
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/certificate/licensee/${licenseeId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const markSimInactiveOrDecertify  = (certificateId: string, action: string, scenarioId?: string ): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/user/licensee/certificate/${certificateId}/action/${action}`, {
    body: scenarioId,
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const fetchSimCertifications = (params: IPageRequestParams): Promise<IListDataResponse<ICertificate>> => {
  const { pageData } = params;
  return RestService.fetch(`${RestService.REST_URL}/dashboard/simSpecialist/summary/certificates${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const markAllSimCertificatesInactive = (userID: string): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/user/licensee/inactivate/sim/certificate/${userID}`, {
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const fetchCertificateHistory = (certificateId: string | undefined, page: number, size: number): Promise<IListDataResponse<IEntityCertificationHistory>> => {
  return RestService.fetch(`${RestService.REST_URL}/certificate/${certificateId}/auditList/${page}/${size}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  fetchCertificate,
  fetchClientCertificates,
  fetchLicenseeCertificates,
  markSimInactiveOrDecertify,
  fetchSimCertifications,
  markAllSimCertificatesInactive,
  fetchCertificateHistory,
};

