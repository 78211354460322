import cn from 'classnames';
import * as React from 'react';
import { IListViewProps } from './IListView';
import styles from './ListView.css';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';

class ListView extends React.Component<IListViewProps<object>> {

  private static renderElementOrComponent(Component?: React.ComponentClass<any> | React.ReactElement | (() => React.ReactElement) | null) {
    if (!Component) {
      return null;
    }

    if (React.isValidElement(Component)) {
      return Component;
    } else {
      // @ts-ignore
      return <Component/>;
    }
  }

  private keyWarnShown: boolean = false;

  public render() {
    const className = cn([
      styles.listView,
      this.props.className,
    ]);

    const isRefreshing = !!this.props.refreshing;

    return (
      <div className={className}>
        <LoadingOverlay active={isRefreshing} spinner={true}>
          <div className={styles.container}>
            <div className={cn(styles.headerWrapper, { [styles.refreshing]: isRefreshing })}>
              {ListView.renderElementOrComponent(this.props.ListHeaderComponent)}
            </div>
          </div>
          {this.renderItems()}
          {ListView.renderElementOrComponent(this.props.ListFooterComponent)}
        </LoadingOverlay>
      </div>
    );
  }

  private renderItems() {
    if (!this.props.data
      || !this.props.data.length) {
      return ListView.renderElementOrComponent(this.props.ListEmptyComponent);
    }

    return this.props.data.map((item: { key?: string | number }, index: number) => {
      let key = this.props.keyExtractor ? this.props.keyExtractor(item, index) : item.key;

      if (typeof key === 'undefined') {
        if (!this.keyWarnShown) {
          console.warn(this.constructor.name + ': missing keys for items, ' + // tslint:disable-line
            'make sure to specify a key property on each item or provide a custom keyExtractor.');
          this.keyWarnShown = true;
        }

        key = index;
      }

      return (
        <React.Fragment key={key}>
          {
            this.props.renderItem({ index, item })
          }
        </React.Fragment>
      );
    });
  }
}

export default ListView;
