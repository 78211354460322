
import { IDashboardReportIds, IReportScheduleAndSendData } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';
import RestService from 'src/app/services/rest/RestService';


const createDashboardScheduleReport = (reportData: IReportScheduleAndSendData): Promise<any> => {
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/schedule/report`, {
    body: JSON.stringify(reportData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const createDashboardSendReport = (reportData: IReportScheduleAndSendData): Promise<any> => {
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/schedule/report/export`, {
    body: JSON.stringify(reportData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updateDashboardScheduleReport = (reportData: IReportScheduleAndSendData): Promise<any> => {
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/schedule/report`, {
    body: JSON.stringify(reportData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const getDashboardScheduleReport = (reportType: string, clientId: string): Promise<any> => {
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/schedule/report?reportType=${reportType}&clientId=${clientId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  });
};

const deleteDashboardScheduleReport = (reportType: string, clientId: string): Promise<any> => {
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/schedule/report?reportType=${reportType}&clientId=${clientId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const scheduleReportIdConversion = (reportData: IDashboardReportIds): Promise<any> => {
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/schedule/report/id-conversion`, {
    body: JSON.stringify(reportData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

export default {
  createDashboardScheduleReport,
  createDashboardSendReport,
  updateDashboardScheduleReport,
  getDashboardScheduleReport,
  deleteDashboardScheduleReport,
  scheduleReportIdConversion
};
