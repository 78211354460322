import actionFromPromise from "src/app/redux/utils/actionFromPromise";
import createListActions from "src/app/redux/utils/createListActions";
import industriesRest from './rest';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';
import createItemActions from 'src/app/redux/utils/createItemActions';

export default {
  clearValidationErrors: createClearEntityErrorAction('INDUSTRY'),
  deprecated__fetchIndustriesList: actionFromPromise(industriesRest.fetchIndustriesList),
  fetchIndustriesList: createListActions('INDUSTRIES', industriesRest.fetchIndustriesList),
  fetchIndustry: createItemActions('INDUSTRY', industriesRest.fetchIndustry),
  createIndustry: actionFromPromise(industriesRest.createIndustry, 'INDUSTRY'),
  updateIndustry: actionFromPromise(industriesRest.updateIndustry, 'INDUSTRY'),
  deleteIndustry: actionFromPromise(industriesRest.deleteIndustry, 'INDUSTRY', true),
  restoreIndustry: actionFromPromise(industriesRest.restoreIndustry, 'INDUSTRY'),
  fetchIndustriesListWithOptions: actionFromPromise(industriesRest.fetchIndustriesListWithOptions),
};
