import { combineReducers } from 'redux';
import IListDataState from "src/app/data/common/interfaces/IListDataState";
import createListReducer from "src/app/redux/utils/createListReducer";
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';

export interface IScenarioLearnersState {
  list: IListDataState<ILearnerExtended>;
  listForEditSession: IListDataState<ILearnerExtended>;
}

export default combineReducers({
  list: createListReducer('SCENARIO_LEARNERS'),
  listForEditSession: createListReducer('SESSION_SCENARIO_LEARNERS'),
});
