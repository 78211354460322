import * as React from 'react';
import { DropdownButton, Dropdown, DropdownButtonProps} from 'react-bootstrap';
import styles from './DropdownButton.css';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';

interface IItemProps extends DropdownItemProps {
  labelText: string | number;
  value: any;
}

interface IProps extends DropdownButtonProps {
  value: any;
  onSelect?:(size:any)=>void;
  items: IItemProps[];
  id: string;
}

const MursionDropdownButton = ({ items, onSelect, value, ...props }: IProps) => {
  return   <DropdownButton
    {...props}
    variant='Primary'
    className={styles.dropdown}
    key={props.id}
    title={value}
    onSelect={onSelect}
  >
    {items.map(({ labelText, value: itemValue, ...item }, index) => (
      <Dropdown.Item
        {...item}
        className={styles.item}
        active={value === itemValue}
        eventKey={itemValue}
        key={`${props.id}-value-${index}`}
      >
        {labelText}
      </Dropdown.Item>
    ))}
  </DropdownButton>;
};

export default MursionDropdownButton;
