import { applyMiddleware, createStore, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import appMiddleware from './middleware';
import reducers from './reducers';
import restEnhancer from './restEnhancer';
import wsEnhancer from './wsEnhancer';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

function configureStore(): Store {
  const enhancer = composeWithDevTools(
    restEnhancer as any,
    wsEnhancer as any,
    applyMiddleware(
      thunkMiddleware,
      appMiddleware,
    ),
  );

  return createStore(reducers, enhancer);
}

export default configureStore;
