import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import nextGenScenarioRest from 'src/app/redux/modules/nextGenScenario/rest';

export default {
  createNextGenScenario: actionFromPromise(nextGenScenarioRest.createNextGenScenario),
  updateNextGenScenarioLibraryInfo: actionFromPromise(nextGenScenarioRest.updateNextGenScenarioLibraryInfo),
  updateNextGenScenarioContent: actionFromPromise(nextGenScenarioRest.updateNextGenScenarioContent),
  getAllActivePathwayList: actionFromPromise(nextGenScenarioRest.getAllActivePathwayList),
  fetchNextGenBankScenarioData: actionFromPromise(nextGenScenarioRest.fetchNextGenBankScenarioData),
  getAllActiveItemBankListByType: actionFromPromise(nextGenScenarioRest.getAllActiveItemBankListByType),
  updateNextGenScenarioAvatarPerspective: actionFromPromise(nextGenScenarioRest.updateNextGenScenarioAvatarPerspective),
  updateNextGenScenarioPrepGuide: actionFromPromise(nextGenScenarioRest.updateNextGenScenarioPrepGuide),
  updateNextGenScenarioReflectionGuide: actionFromPromise(nextGenScenarioRest.updateNextGenScenarioReflectionGuide),
  fetchNextGenScenarioAvatarGuide:  actionFromPromise(nextGenScenarioRest.fetchNextGenScenarioAvatarGuide),
  fetchScenarioPublishReadyDetails: actionFromPromise(nextGenScenarioRest.fetchScenarioPublishReadyDetails),
  publishNextGenScenario: actionFromPromise(nextGenScenarioRest.publishNextGenScenario),
  createNextGenBankScenarioTemplate:  actionFromPromise(nextGenScenarioRest.createNextGenBankScenarioTemplate),
  fetchNextGenBankScenarioTemplate:  actionFromPromise(nextGenScenarioRest.fetchNextGenBankScenarioTemplate),
  updateNextGenBankScenarioTemplateLibraryInfo:  actionFromPromise(nextGenScenarioRest.updateNextGenBankScenarioTemplateLibraryInfo),
  updateNextGenBankScenarioTemplateContent:  actionFromPromise(nextGenScenarioRest.updateNextGenBankScenarioTemplateContent),
  createAndUpdateDeliveryDetails: actionFromPromise(nextGenScenarioRest.createAndUpdateDeliveryDetails),
  updateNextGenScenarioPlanning: actionFromPromise(nextGenScenarioRest.updateNextGenScenarioPlanning),
  updateNextGenScenarioBankDeliveryDetails: actionFromPromise(nextGenScenarioRest.updateNextGenScenarioBankDeliveryDetails),
  fetchNextGenScenarioSideMenuItemsInfo: actionFromPromise(nextGenScenarioRest.fetchNextGenScenarioSideMenuItemsInfo),
  createNextGenScenarioFromTemplate: actionFromPromise(nextGenScenarioRest.createNextGenScenarioFromTemplate),
  getChildAndOrphanScenario: actionFromPromise(nextGenScenarioRest.getChildAndOrphanScenario),
  createDuplicateProjectScenario: actionFromPromise(nextGenScenarioRest.createDuplicateProjectScenario),
  createDuplicateBankScenario: actionFromPromise(nextGenScenarioRest.createDuplicateBankScenario),
  updateClientCustomizations: actionFromPromise(nextGenScenarioRest.updateClientCustomizations),
};
