import * as React from 'react';
import styles from './Token.css';
import getColorFromId from 'src/app/data/calendar/utils/getColorFromId';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import cn from 'classnames';

interface ITokenProps {
  color?: string;
  fill?: boolean;
  entry: INamedEntry;
}

const Token = (props: ITokenProps) => {
  const {entry, color, fill} = props;

  return (
    <div
      className={cn(styles.container, fill && styles.fill)}
      title={entry.name}
    >
      <span className={styles.colorStripe} style={{ backgroundColor: color || getColorFromId(entry.id)}} />
      <span className={styles.label}> {entry.name} </span>
    </div>
  );
};

export default Token;
