import { CompanyEventType, EventType } from 'src/app/data/calendar/interfaces/EventType';
import IAvailability from 'src/app/data/calendar/interfaces/IAvailability';
import ICalendarEvent from 'src/app/data/calendar/interfaces/ICalendarEvent';
import IEventRecurrence from 'src/app/data/calendar/interfaces/IEventRecurrence';
import { ILicenseeEvent, ILicenseeEventUpdate } from 'src/app/data/calendar/interfaces/ILicenseeEvent';
import RestService from 'src/app/services/rest/RestService';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import moment from 'moment-timezone';

export interface ICalendarViewEventBase {
  count: number;            // total number of events of a given type in a given day (after applying filters)
  startDate: number;        // start time of the first event in this day
  endDate: number;          // end time of the last event in this day
  durationSum: number;      // sum of time for events of a given type in a given day in ms
  firstEventId: string | null;     // event id to find a corresponding event in events array

  // session params
  canJoin?: boolean;        // if true - there is at least one session which can be joined by current user
  noLearnersCount?: number; // total number of sessions in this day without learners
  swapCount?: number;       // total number of sessions in this day in SWAP

  orphanCount?: number;     // total number of sessions in this day in ORPHAN
  needsReview?: boolean;
  orphanWithLearners?: boolean;
  orphanWithoutLearners?: boolean;
  reservedWithLearners?: boolean;
  reservedWithoutLearners?: boolean;
  waifWithLearners?: boolean;
  waifWithoutLearners?: boolean;

  // company event params
  trainingCount?: number;    // number of training events in a given day
  qaCount?: number;          // number of QA events in a given day

  // availability event params
  availableTime?: number;  // sum of time for the availability excluding restrictions

}

export interface ICalendarViewDaySummary {
  dayStart: number;                       // timestamp in UTC - to identify a day
  canSessionBeCreated: boolean;           // shows that current user can create a session in a given day
  sessions?: ICalendarViewEventBase;       // summaries for events with type = EventType.SESSION
  trainingSessions?: ICalendarViewEventBase; // summaries for events with type = EventType.TRAINING
  companyEvents?: ICalendarViewEventBase;  // summaries for events with type = EventType.COMPANY_EVENT
  availabilities?: ICalendarViewEventBase; // summaries for events with type = EventType.AVAILABILITY
}

export interface ICalendarViewEventsFilters {
  from: number;
  to: number;
  eventTypeFilter: EventType[] | null;
  clientIds?: string[] | null;
  meetingTypeFilter?: CompanyEventType[] | null;
  projectIds?: string[] | null;
  scenarioIds?: string[] | null;
  sessionStatusFilter?: SessionStatusType[] | null;
  simSpecialistIds?: string[] | null;
}

export interface ICalendarViewDataTotals {
  availabilities: boolean;
  availabilityTime: number;
  breakEvent: number;
  orphanSessions: number;
  orphanTrainings: number;
  prepEvent: number;
  qa: number;
  sessionTime: number;
  sessions: number;
  swapRequests: number;
  trainingTime: number;
  trainings: number;
}

export interface ICalendarViewData {
  days: ICalendarViewDaySummary[];
  events: ICalendarEvent[];                   // full event data for single events
  totals: ICalendarViewDataTotals | null;
}

export interface IResidualAvailability {
  busy: boolean;
  endDate: number;
  startDate: number;
  recurringId: string | null;
  recurringPeriodEndDate: number | null;
  id: string;
}


const fetchEventsWithFilters = (eventsFiltersData: ICalendarViewEventsFilters): Promise<ICalendarEvent[]> => {
  return RestService.fetch(`${RestService.REST_URL}/event/list/ext`, {
    body: JSON.stringify(eventsFiltersData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then((response: ICalendarEvent[]) => {
    return response.reduce((accum: ICalendarEvent[], e) => {
      if (e.type === EventType.AVAILABILITY) {

        // Cutting past availabilities
        const now = moment().valueOf();
        const roundedEnd = moment(eventsFiltersData.to).millisecond()
          ? moment(eventsFiltersData.to).add(1, 's').startOf('s').valueOf()
          : eventsFiltersData.to;

        if (e.endDate <= now) {
          return accum;
        } else if (e.startDate < now) {
          e.startDate = now;
        }

        const updatedStartDate = e.startDate < eventsFiltersData.from ? eventsFiltersData.from : e.startDate;
        const updatedEndDate = e.endDate > roundedEnd ? roundedEnd : e.endDate;

        if (updatedStartDate < updatedEndDate) {
          return accum.concat({
            ...e,
            startDate: updatedStartDate,
            endDate: updatedEndDate,
          });
        }

        return accum;
      }

      return accum.concat(e);
    }, []);
  });
};

const fetchEvents = (from: number, to: number, eventTypes?: EventType[]): Promise<ICalendarEvent[]> => {
  const params: ICalendarViewEventsFilters = {
    from,
    to,
    eventTypeFilter: eventTypes || []
  };

  return fetchEventsWithFilters(params);
};

const fetchResidualAvailabilities = (simspecialistId: string, startDate: number, endDate: number): Promise<IResidualAvailability[]> => {
  return RestService.fetch(`${RestService.REST_URL}/availability/${simspecialistId}/${startDate}/${endDate}/residual`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchAvailability = (availabilityId: string): Promise<IAvailability> => {
  return RestService.fetch(`${RestService.REST_URL}/availability/${availabilityId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchLicenseeEvent = (licenseeEventId: string): Promise<ILicenseeEvent> => {
  return RestService.fetch(`${RestService.REST_URL}/licenseeEvent/${licenseeEventId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const createAvailability = (availabilityData: IAvailability): Promise<IAvailability> => {
  return RestService.fetch(`${RestService.REST_URL}/availability`, {
    body: JSON.stringify(availabilityData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const createAvailabilityRecur = (availabilityData: IEventRecurrence): Promise<IAvailability> => {
  return RestService.fetch(`${RestService.REST_URL}/availability/recur`, {
    body: JSON.stringify(availabilityData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const createLicenseeEvent = (licenseeEvent: ILicenseeEventUpdate): Promise<ILicenseeEvent> => {
  return RestService.fetch(`${RestService.REST_URL}/licenseeEvent`, {
    body: JSON.stringify(licenseeEvent),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

// TODO: remove
const updateAvailability = (availabilityId: string, availabilityData: IAvailability): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/availability/${availabilityId}`, {
    body: JSON.stringify(availabilityData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const rewriteSimSpecAvailabilities = (simspecialistId: string, startDate: number, endDate: number, availabilities: Array<{ startDate: number, endDate: number }>): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/availability/${simspecialistId}/${startDate}/${endDate}`, {
    body: JSON.stringify(availabilities),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateLicenseeEvent = (licenseeEventId: string, licenseeEvent: ILicenseeEventUpdate): Promise<ILicenseeEvent> => {
  return RestService.fetch(`${RestService.REST_URL}/licenseeEvent/${licenseeEventId}`, {
    body: JSON.stringify(licenseeEvent),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

// TODO: remove
const deleteAvailabilityById = (availabilityId: string): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/availability/${availabilityId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const deleteAvailabilityByInterval = (startDate: number, endDate: number): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/availability`, {
    body: JSON.stringify({
      startDate, endDate
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const deleteLicenseeEvent = (licenseeEventId: string): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/licenseeEvent/${licenseeEventId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const restoreLicenseeEvent = (licenseeEventId: string): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/licenseeEvent/restore/${licenseeEventId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchCalendarViewData = (
  startTime: number,
  numberOfDays: number,
  clientIds: string[],
  projectIds: string[],
  scenarioIds: string[],
  simSpecialistIds: string[],
  timezoneId: string,
  eventTypeFilter: Array<'session' | 'training' | 'companyEvent' | 'availability'>,
  sessionStatusFilter: SessionStatusType[],
  meetingTypeFilter: CompanyEventType[],
  signal: AbortSignal | null = null,
): Promise<ICalendarViewData | null> => {

  return RestService.fetch(`${RestService.REST_URL}/event/summary`, {
    body: JSON.stringify({
      from: startTime,
      days: numberOfDays,
      clientIds,
      projectIds,
      scenarioIds,
      simSpecialistIds,
      timezoneId,
      eventTypeFilter,
      sessionStatusFilter,
      meetingTypeFilter,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal
  });
};

export default {
  createAvailability,
  createAvailabilityRecur,
  createLicenseeEvent,
  deleteAvailabilityById,
  deleteAvailabilityByInterval,
  deleteLicenseeEvent,
  restoreLicenseeEvent,
  fetchAvailability,
  fetchEvents,
  fetchEventsWithFilters,
  fetchLicenseeEvent,
  updateAvailability,
  updateLicenseeEvent,
  rewriteSimSpecAvailabilities,
  fetchCalendarViewData,
  fetchResidualAvailabilities
};
