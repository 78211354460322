import { AnyAction, Dispatch, Store } from 'redux';
import * as AuthActionTypes from '../modules/auth/types';
import createMiddleware from '../utils/createMiddleware';
import actions from './actions';
import moment from 'moment-timezone';

let tokenTimeout: any;
let refreshNotificationTokenTimeout: any;

const REFRESH_TOKEN_NOTIFICATION_TIME_MIN = moment.duration(10, 'second').asMilliseconds();
const REFRESH_TOKEN_NOTIFICATION_TIME_MAX = moment.duration(1, 'minute').asMilliseconds();

const handleTokenTimeout = (tokenExpirationDuration: number, callback: Dispatch) => {
  if (tokenTimeout) {
    clearTimeout(tokenTimeout);
  }
  if (refreshNotificationTokenTimeout) {
    clearTimeout(refreshNotificationTokenTimeout);
  }

  refreshNotificationTokenTimeout = setTimeout(() => {
      // @ts-ignore
      callback(actions.auth.notifyTokenTimeout(true));
      clearTimeout(refreshNotificationTokenTimeout);

    }, tokenExpirationDuration <= REFRESH_TOKEN_NOTIFICATION_TIME_MAX
    ? REFRESH_TOKEN_NOTIFICATION_TIME_MIN
    : tokenExpirationDuration - REFRESH_TOKEN_NOTIFICATION_TIME_MAX
  );

  tokenTimeout = setTimeout(() => {
    // @ts-ignore
    callback(actions.auth.logOut());
    clearTimeout(tokenTimeout);
  }, tokenExpirationDuration);
};

const middleware = {
  [AuthActionTypes.AUTHENTICATION_SUCCESSFUL](store: Store, next: Dispatch, action: AnyAction): any {
    // todo: get profile
    next(action);

    store.dispatch(actions.calendar.calendarReset());
    // @ts-ignore
    store.dispatch(actions.user.getUserProfile());
    // @ts-ignore
    store.dispatch(actions.ws.initWebSocketConnection());
  },
  [AuthActionTypes.AUTHENTICATION_TOKEN_UPDATE](store: Store, _: Dispatch, action: AnyAction): any {
    handleTokenTimeout(action.token.refresh_token_exp_duration, store.dispatch);
  },

  [AuthActionTypes.UPDATE_REFRESH_TIMEOUT_TOKEN](store: Store, _: Dispatch, action: AnyAction): any {
    handleTokenTimeout(action.refresh_token_exp_duration, store.dispatch);
  },

  [AuthActionTypes.NOT_AUTHENTICATED](store: Store, next: Dispatch, action: AnyAction): any {
    if (tokenTimeout) {
      clearTimeout(tokenTimeout);
    }
    if (refreshNotificationTokenTimeout) {
      clearTimeout(refreshNotificationTokenTimeout);
    }
    store.dispatch(actions.user.clearUserData());

    return next(action);
  },
};

export default createMiddleware(middleware);
