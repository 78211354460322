exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".IconButton__iconButton___3J4GE{background:transparent;border:none;font-weight:600;line-height:1.6;font-size:.8rem;display:-ms-inline-flexbox;display:inline-flex;-ms-flex-align:center;align-items:center}.IconButton__iconButton___3J4GE i{color:#636363}.IconButton__iconButton___3J4GE:not([disabled]):active{opacity:1}.IconButton__label___CM7vh{margin-left:.2rem;white-space:nowrap}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/IconButton/IconButton.css"],"names":[],"mappings":"AAAA,gCACE,uBAAwB,AACxB,YAAa,AACb,gBAAiB,AACjB,gBAAiB,AACjB,gBAAkB,AAClB,2BAA4B,AAC5B,oBAAqB,AACrB,sBAAuB,AACnB,kBAAoB,CACzB,AACD,kCACE,aAAc,CACf,AAED,uDACE,SAAW,CACZ,AAED,2BACE,kBAAmB,AACnB,kBAAoB,CACrB","file":"IconButton.css","sourcesContent":[".iconButton {\n  background: transparent;\n  border: none;\n  font-weight: 600;\n  line-height: 1.6;\n  font-size: 0.8rem;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -ms-flex-align: center;\n      align-items: center;\n}\n.iconButton i{\n  color:#636363;\n}\n\n.iconButton:not([disabled]):active {\n  opacity: 1;\n}\n\n.label {\n  margin-left: .2rem;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"iconButton": "IconButton__iconButton___3J4GE",
	"label": "IconButton__label___CM7vh"
};