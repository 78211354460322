import { combineReducers } from 'redux';
import ITeam from "src/app/data/client/interfaces/ITeam";
import ITeamClientView from "src/app/data/client/interfaces/ITeamClientView";
import IListDataState from "src/app/data/common/interfaces/IListDataState";
import createListReducer from "src/app/redux/utils/createListReducer";
import { ITeamLearnersState } from 'src/app/redux/modules/teams/learners/reducer';
import learners from './learners/reducer';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';

export interface ITeamsReduxState {
  learners: ITeamLearnersState;
  list: IListDataState<ITeam>;
  listForClientView: IListDataState<ITeamClientView>;
  error: IErrorState;
}

export default combineReducers({
  learners,
  list: createListReducer('TEAMS'),
  listForClientView: createListReducer('TEAMS_FOR_CLIENT_VIEW'),
  error: createEntityErrorReducer('TEAM'),
});
