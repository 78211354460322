import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers';
import { components } from 'react-select';
import styles from '../../../Selector.css';
import React, { useMemo } from 'react';
import SelectedLabel from 'src/components/Selector/components/common/SelectedLabel/SelectedLabel';

export interface ISelectorValueContainerProps {
  placeholder?: string;
  disabled?: boolean;
  valueContainerProps: ValueContainerProps<any, any, any>;
  ariaExpanded?: boolean;
  onToggleMenu: () => void;
  ariaDisabled?: boolean;
  showSelectedValues?: boolean;
}

const SelectorValueContainer = (props: ISelectorValueContainerProps) => {

  const { valueContainerProps, placeholder, disabled, ariaExpanded, onToggleMenu, ariaDisabled, showSelectedValues } = props;

  const selectPropsValue = valueContainerProps.selectProps.value;
  const options = valueContainerProps.selectProps.options;

  const label = showSelectedValues ?
    useMemo(() => (
      <SelectedLabel
        value={selectPropsValue}
        options={options}
        disabled={disabled}
        placeholder={placeholder || ''}
      />
    ), [selectPropsValue]) :
    placeholder;


  const onKeyDown = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyboardEvent.key === 'Enter') {
      keyboardEvent.preventDefault();
      (keyboardEvent.target as HTMLElement).click();
    }
  };

  const onLabelClick = () => onToggleMenu();
  return (
    <components.ValueContainer {...valueContainerProps}>
      <span
        className={styles.value}
        tabIndex={0}
        role={'button'}
        onClick={onLabelClick}
        onKeyDown={onKeyDown}
        aria-expanded={ariaExpanded}
        aria-disabled={ariaDisabled}
      >
        {label} {ariaDisabled && <span className="visibilityHidden">&nbsp; disabled</span>}
      </span>
    </components.ValueContainer>
  );
};

export default React.memo(SelectorValueContainer);
