import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from 'src/app/redux/modules/learner/rest';

export default {
  fetchLearnerActivity: actionFromPromise(rest.fetchLearnerActivity),
  fetchLearnerSimulations: actionFromPromise(rest.fetchLearnerSimulations),
  fetchLockedOutLearners: actionFromPromise(rest.fetchLockedOutLearners),
  releaseLockedLearners: actionFromPromise(rest.releaseLockedLearners),
  exportLockedOutLearners: actionFromPromise(rest.exportLockedOutLearners),
  fetchAnalyticsResponse: actionFromPromise(rest.fetchAnalyticsResponse),

  exportLearnerActivity: actionFromPromise(rest.exportLearnerActivity),
  exportLearnerSimulations: actionFromPromise(rest.exportLearnerSimulations),
  logLearnerStatusAsLate:actionFromPromise(rest.logLearnerStatusAsLate),
};
