import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import createListActions from "src/app/redux/utils/createListActions";
import environmentsRest from './rest';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';
import createItemActions from 'src/app/redux/utils/createItemActions';

export default {
  clearError: createClearEntityErrorAction('ENVIRONMENT'),
  createEnvironment: actionFromPromise(environmentsRest.createEnvironment, 'ENVIRONMENT'),
  deleteEnvironment: actionFromPromise(environmentsRest.deleteEnvironment, 'ENVIRONMENT', true),
  restoreEnvironment: actionFromPromise(environmentsRest.restoreEnvironment, 'ENVIRONMENT'),
  deprecated__fetchEnvironmentsList: actionFromPromise(environmentsRest.fetchEnvironmentsList),
  fetchEnvironmentById: createItemActions('ENVIRONMENT_CURRENT', environmentsRest.fetchEnvironmentById),
  deprecated__fetchEnvironmentById: actionFromPromise(environmentsRest.fetchEnvironmentById),
  fetchEnvironmentsList: createListActions('ENVIRONMENTS', environmentsRest.fetchEnvironmentsList),
  fetchEnvironmentsListByIndustry: createListActions('ENVIRONMENTS_BY_INDUSTRY', environmentsRest.fetchEnvironmentsListByIndustry),
  updateEnvironment: actionFromPromise(environmentsRest.updateEnvironment, 'ENVIRONMENT', true),
};
