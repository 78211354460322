/* tslint:disable */
import * as React from 'react';
import { FunctionComponent, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './Tooltip.css';
import cn from 'classnames';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';

export interface ITooltip {
  text: string;
}

export enum TooltipPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}

export enum TooltipTheme {
  DARK,
  LIGHT,
  COBALT,
  ORANGE
}

export interface ITooltipProps{
  placement?: TooltipPlacement;
  text: string;
  overlayChild?: JSX.Element;
  customStyles?: any;
  theme?: TooltipTheme;
  onFocusViewTooltip?: boolean;
  className?: string;
  onClickViewTooltip?: boolean;
  removeVisibilityEffect?: boolean;
  children: React.ReactElement;
}

const TooltipComponent: FunctionComponent<ITooltipProps> = (props) => {

  const {
    placement,
    text,
    customStyles,
    theme,
    children,
    onFocusViewTooltip,
    className,
    onClickViewTooltip,
    overlayChild,
    removeVisibilityEffect,
  } = props;

  const overlay = useMemo(() => {

    let themeStyles = '';

    switch (theme) {
      case TooltipTheme.LIGHT:
        themeStyles = styles.light;
        break;
      case TooltipTheme.COBALT:
        themeStyles = styles.cobalt;
        break;
        case TooltipTheme.ORANGE:
        themeStyles = styles.orange;
        break;
      case TooltipTheme.DARK:
      default:
        themeStyles = styles.dark;
    }
    const id = text.indexOf(' ') >= 0 ? text.replace(/\s/g, '') : text;
    return (
      <Tooltip
        id={id}
        className={cn(
          styles.tooltip,
          customStyles ? styles.statusLabel : themeStyles,
          className,
          !text && !overlayChild && styles.noText,
          removeVisibilityEffect ? styles.removeVisibilityEffect : null
        )}
        style={customStyles}
      >
       {overlayChild ? overlayChild: <div>{text}</div>} 
      </Tooltip>
    );
  }, [text, customStyles, theme,]);
  const handleEnterKeyPress = (e: any)=>{
    if(e.keyCode === 13){
      e.target.click();
    }
  };

  let trigger: OverlayTriggerType[];
  if (onClickViewTooltip) {
    trigger = ['click'];
  } else if (onFocusViewTooltip) {
    trigger = ['hover', 'click', 'focus'];
  } else {
    trigger = ['hover', 'click'];
  }

  return (
    <span onKeyDown={handleEnterKeyPress}>
    <OverlayTrigger
      placement={placement}
      overlay={overlay}
      trigger={trigger}
      rootClose
    >
      {children}
    </OverlayTrigger>
    </span>

  );
};

export default TooltipComponent;
