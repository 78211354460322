import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from 'src/app/redux/modules/practiceSession/rest';

export default {
    fetchPracticeSessionProject: actionFromPromise(rest.fetchPracticeSessionProject),
    fetchPracticeSessionScenario: actionFromPromise(rest.fetchPracticeSessionScenario),
    fetchPracticeScenarioAvailableTimeBlocks: actionFromPromise(rest.fetchPracticeScenarioAvailableTimeBlocks),
    fetchAssetSettingsForPracticeSession: actionFromPromise(rest.fetchAssetSettingsForPracticeSession),
    fetchPracticeSessionProjectsWithActiveScenarios: actionFromPromise(rest.fetchPracticeSessionProjectsWithActiveScenarios),
    fetchAllActivePracticeScenariosList: actionFromPromise(rest.fetchAllActivePracticeScenariosList),
    createPracticeSession: actionFromPromise(rest.createPracticeSession),
};