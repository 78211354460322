import IClientUser, {
  IClientUserExt,
  IClientUserParseResult,
  IClientUserTeamUpdate,
  IClientUserUpdate,
  INewClientUser,
  IUploadResults,
  IValidateClientUserEmails
} from 'src/app/data/client/interfaces/IClientUser';
import IUser, { IUserBase } from 'src/app/data/common/interfaces/IUser';
import RestService from 'src/app/services/rest/RestService';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { generateFormDataForParts } from 'src/app/data/common/utils/generateFormData';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import {
  IClientUserSchedulingDashboardItem
} from 'src/app/data/dashboard/IClientUserDashboard';
import { LearnerCompletionType, MissedLateActivityType, ScenarioStatus } from 'src/app/data/projects/interfaces/IScenario';
import * as queryString from 'querystring';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import { intl } from 'src/i18n/createIntl';

type EmptyType = null | undefined;

export interface IClientUsersQuery {
  clientIds?: string[] | EmptyType;
  projectIds?: string[] | EmptyType;
  scenarioIds?: string[] | EmptyType;
  teamIds?: string[] | EmptyType;
  roleIds?: string[] | EmptyType;
  accepted?: boolean | EmptyType;
  learnerCompletion?: LearnerCompletionType[] | null;
  missedLateActivity?: MissedLateActivityType[] | null;
}

export interface IExtendedStringFilter {
  value: string[] | null;
  exclude?: boolean;
}

export interface IDashboardClientUsersQuery {
  licenseeIds?: IExtendedStringFilter | null;
  clientIds?: IExtendedStringFilter | null;
  projectIds?: IExtendedStringFilter | null;
  scenarioIds?: IExtendedStringFilter | null;
  teamIds?: IExtendedStringFilter | null;
  industryIds?: IExtendedStringFilter | null;
  scenarioVersions?: ScenarioVersion[] | null;
  roleIds?: string[] | EmptyType;
  accepted?: boolean | EmptyType;
}

export interface IClientUserScenariosQuery {
  startDate?: number | EmptyType;
  endDate?: number | EmptyType;
  scenarioVersion?: string[] | EmptyType;
  status?: ScenarioStatus[] | EmptyType;
  projectIds?: string[] | EmptyType;
}

export interface IClientUserChartQuery {
  page: number;
  size: number;
  order?: string;
  asc?: boolean;
  archive?: string;
  clientIds?: IExtendedStringFilter | null;
  licenseeIds?: IExtendedStringFilter | null;
  projectIds?: IExtendedStringFilter | null;
  scenarioIds?: IExtendedStringFilter | null;
  teamIds?: IExtendedStringFilter | null;
  industryIds?: IExtendedStringFilter | null;
  scenarioVersion?: ScenarioVersion[] | null;
  roleIds?: string[] | EmptyType;
  accepted?: boolean | EmptyType;
  learnerCompletion?: LearnerCompletionType[] | null;
  missedLateActivity?: MissedLateActivityType[] | null;
}

export interface IDashboardClientUsersExportQuery {
  licenseeIds?: IExtendedStringFilter | null;
  clientIds?: IExtendedStringFilter | null;
  projectIds?: IExtendedStringFilter | null;
  scenarioIds?: IExtendedStringFilter | null;
  industryIds?: IExtendedStringFilter | null;
  scenarioVersions?: ScenarioVersion[] | null;
  accepted?: boolean | EmptyType;
  archiveFilter?: string | EmptyType;
  asc?: boolean | EmptyType;
  filter?: string | EmptyType;
  roleIds?: string[] | EmptyType;
  teamIds?: string[] | EmptyType;
  userActivityOrder?: string | EmptyType;
}

const createNewBuyer = (buyerData: IClientUserUpdate, clientId: string = ''): Promise<IUser> => {
  const body: any = clientId ? { ...buyerData, clientId } : buyerData;
  const updatedBuyerData = { ...body, timezoneId: body.timezoneId || null };

  return RestService.fetch(`${RestService.REST_URL}/user/client`, {
    body: JSON.stringify({
      ...updatedBuyerData,
      buyer: true,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const createNewClientUser = (userData: IClientUserUpdate, clientId: string = ''): Promise<IClientUser> => {
  const body: any = clientId ? { ...userData, clientId } : userData;
  const updatedData = { ...body, timezoneId: body.timezoneId || null };

  return RestService.fetch(`${RestService.REST_URL}/user/client`, {
    body: JSON.stringify(updatedData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const deleteClientUser = (userID: string): Promise<any> => {
  if (!userID) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/client/${userID}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const restoreClientUser = (userID: string): Promise<any> => {
  if (!userID) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/client/restore/${userID}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchClientUser = (clientUserID: string): Promise<IClientUser> => {
  if (!clientUserID) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/client/${clientUserID}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

/**
 * @deprecated
 * To be replaced by fetchClientUsersWithOptions
 */
const fetchClientUsers = (pageData: IListPageData, clientId: string, signal: AbortSignal | null = null): Promise<IListDataResponse<IClientUser>> => {
  return fetchClientUsersWithOptions({ pageData, clientId, signal });
};

const updateClientUser = (userId: string, userData: IClientUserUpdate, password?: string): Promise<IClientUser> => {
  if (!userId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  const user = { ...userData, timezoneId: userData.timezoneId || null };
  const passwordContainer = password ? { password } : null;

  return RestService.fetch(`${RestService.REST_URL}/user/client/${userId}`, {
    body: generateFormDataForParts({
      user,
      password: passwordContainer,
    }),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const fetchClientLearners = (pageData: IListPageData, clientId: string): Promise<ILearnerExtended[]> => {
  if (!clientId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/learner/listForClient/${clientId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((listResponse) => {
    return listResponse.content || [];
  });
};

const fetchClientUsersWithOptions = (params: IPageRequestParams & { clientId: string }): Promise<IListDataResponse<IClientUser>> => {
  const { pageData, clientId, signal } = params;

  if (!clientId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/client/${clientId}/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchClientUsersExt = (pageData: IListPageData, clientUsersQuery: IClientUsersQuery, signal: AbortSignal | null = null): Promise<IListDataResponse<IClientUserExt>> => {
  return fetchClientUsersExtWithOptions({ pageData, clientUsersQuery, signal });
};

const fetchClientUsersExtWithOptions = (params: IPageRequestParams & { clientUsersQuery: IClientUsersQuery }): Promise<IListDataResponse<IClientUserExt>> => {
  const { pageData, clientUsersQuery, signal } = params;

  const query = {
    ...pageData,
    ...clientUsersQuery
  };

  return RestService.fetch(`${RestService.REST_URL}/user/client/list/ext`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchScenarioLearnersWithOptions = (params: IPageRequestParams & { clientUsersQuery: IClientUsersQuery }): Promise<IListDataResponse<IUserBase>> => {
  const { pageData, clientUsersQuery } = params;

  const query = {
    ...pageData,
    ...clientUsersQuery
  };

  return RestService.fetch(`${RestService.REST_URL}/user/client/scenario/list`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      "Content-Type": "application/json",
    }),
    method: "POST",
  });
};

// dashboard users scheduling
const fetchDashboardClientUsersScheduling = (params: IPageRequestParams & { clientUsersQuery: IDashboardClientUsersQuery }): Promise<IListDataResponse<IClientUserSchedulingDashboardItem>> => {
  const { pageData, clientUsersQuery, signal } = params;

  const query = {
    ...pageData,
    ...clientUsersQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/user/client/scheduling`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

// dashboard users activity
const fetchDashboardClientUserScenariosList = (pageData: IListPageData, userId: string, clientUserScenariosQuery: IClientUserScenariosQuery | null = null): Promise<IListDataResponse<IClientUserSchedulingDashboardItem>> => {
  const query = {
    ...pageData,
    clientUserScenariosQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/user/client/learner/${userId}/scenarios`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const exportDashboardClientUsersScheduling = (params: IAbortableRequestParams & { clientUsersExportQuery: IDashboardClientUsersExportQuery }): Promise<any> => {
  const { clientUsersExportQuery, signal } = params;

  const query = {
    ...clientUsersExportQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/user/client/scheduling/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportClientUsersExtWithOptions = (params: IPageRequestParams & { clientUsersQuery: IClientUsersQuery }): Promise<any> => {
  const { pageData, clientUsersQuery, signal } = params;

  const query = {
    ...pageData,
    ...clientUsersQuery
  };

  return RestService.fetch(`${RestService.REST_URL}/user/client/list/ext/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportClientTeamExtWithOptions = (params: IPageRequestParams & { clientUsersQuery: IClientUsersQuery }): Promise<any> => {
  const { pageData, clientUsersQuery, signal } = params;

  const query = {
    ...pageData,
    ...clientUsersQuery
  };
  return RestService.fetch(`${RestService.REST_URL}/user/client/list/team/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchClientLearnersWithOptions = (params: IPageRequestParams & { clientId: string }): Promise<IListDataResponse<ILearnerExtended>> => {
  const { pageData, clientId, signal } = params;

  if (!clientId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ClientIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/learner/listForClient/${clientId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchTeamLearnersWithOptions = (params: IPageRequestParams & { teamId: string }): Promise<IListDataResponse<ILearnerExtended>> => {
  const { pageData, teamId, signal } = params;

  if (!teamId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.TeamIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/learner/listForTeam/${teamId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const updateClientUserTeams = (userId: string, data: IClientUserTeamUpdate): Promise<any> => {
  if (!userId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.UserIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/client/${userId}/teams`, {
    body: JSON.stringify(data),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const parseLearnersFromCsv = (clientId: string, csvFile: File): Promise<IClientUserParseResult[]> => {
  return RestService.fetch(`${RestService.REST_URL}/user/client/${clientId}/parse/learners`, {
    body: generateFormDataForParts(null, csvFile),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const parseExternalUsersFromCsv = (clientId: string, csvFile: File): Promise<IClientUserParseResult[]> => {
  return RestService.fetch(`${RestService.REST_URL}/user/client/${clientId}/parse/external`, {
    body: generateFormDataForParts(null, csvFile),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchImportTemplateUrl = (extension: string, externalUser: boolean): Promise<string> => {
  const params = _omitBy({
    extension,
    externalUser,
  }, _isUndefined);

  return RestService.fetch(`${RestService.REST_URL}/user/client/import/template?${queryString.stringify(params)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchClientImportProcessStatus = (clientId: string, importType: string): Promise<any> => {
  const params = _omitBy({
    processType: importType,
  }, _isUndefined);

  return RestService.fetch(`${RestService.REST_URL}/import-process/status/${clientId}?${queryString.stringify(params)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchImportProcessStatus = (clientId: string, processId: string, processType: string): Promise<any> => {
  const params = _omitBy({
    processType,
    processId,
  }, _isUndefined);

  return RestService.fetch(`${RestService.REST_URL}/import-process/status/${clientId}?${queryString.stringify(params)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const uploadInternalUser = (clientId: string, file: File): Promise<IUploadResults> => {
  return RestService.fetch(`${RestService.REST_URL}/user/client/${clientId}/import/non-sso-users`, {
    body: generateFormDataForParts(null, file),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const uploadExternalUser = (clientId: string, file: File): Promise<IUploadResults> => {
  return RestService.fetch(`${RestService.REST_URL}/user/client/${clientId}/import/sso-users`, {
    body: generateFormDataForParts(null, file),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const editTeamMembership = (clientId: string, file: File): Promise<IUploadResults> => {
  const params = _omitBy({
    clientId,
  }, _isUndefined);
  return RestService.fetch(`${RestService.REST_URL}/bulk/team/validateAndPersist?${queryString.stringify(params)}`, {
    body: generateFormDataForParts(null, file),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const validateEmails = (emails: string[]): Promise<IValidateClientUserEmails | null> => {
  return RestService.fetch(`${RestService.REST_URL}/user/client/validateEmails `, {
    body: JSON.stringify(emails),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const createClientUser = (userData: INewClientUser, clientId: string = '') : Promise<INewClientUser> => {
  const body: INewClientUser = clientId ? { ...userData, clientId } : userData;
  return RestService.fetch(`${RestService.REST_URL}/user/client/create`, {
    body: JSON.stringify(body),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchLearnersActivity = (pageData: IListPageData, clientUsersQuery: IClientUsersQuery, signal: AbortSignal | null = null): Promise<IListDataResponse<IClientUserExt>> => {
  return fetchLearnersActivityWithOptions({ pageData, clientUsersQuery, signal });
};

const fetchLearnersActivityWithOptions = (params: IPageRequestParams & { clientUsersQuery: IClientUsersQuery }): Promise<IListDataResponse<IClientUserExt>> => {
  const { pageData, clientUsersQuery, signal } = params;

  const query = {
    ...pageData,
    ...clientUsersQuery
  };

  return RestService.fetch(`${RestService.REST_URL}/user/client/learnersActivity`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

export default {
  createNewBuyer,
  createNewClientUser,
  deleteClientUser,
  restoreClientUser,
  fetchClientLearners,
  fetchClientUser,
  fetchClientUsers,
  updateClientUser,
  fetchClientUsersExt,
  exportClientUsersExtWithOptions,
  exportClientTeamExtWithOptions,
  exportDashboardClientUsersScheduling,
  fetchClientUsersExtWithOptions,
  fetchClientLearnersWithOptions,
  fetchTeamLearnersWithOptions,
  fetchClientUsersWithOptions,
  updateClientUserTeams,
  parseLearnersFromCsv,
  parseExternalUsersFromCsv,
  fetchImportTemplateUrl,
  fetchDashboardClientUsersScheduling,
  fetchDashboardClientUserScenariosList,
  fetchClientImportProcessStatus,
  fetchImportProcessStatus,
  uploadExternalUser,
  uploadInternalUser,
  editTeamMembership,
  validateEmails,
  createClientUser,
  fetchScenarioLearnersWithOptions,
  fetchLearnersActivityWithOptions,
  fetchLearnersActivity,
};
