import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';
import createListActions from 'src/app/redux/utils/createListActions';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';

export default {
  clearError: createClearEntityErrorAction('EVENT'),
  createAvailability: actionFromPromise(rest.createAvailability, 'EVENT', true),
  createAvailabilityRecur: actionFromPromise(rest.createAvailabilityRecur, 'EVENT', true),
  createLicenseeEvent: actionFromPromise(rest.createLicenseeEvent, 'EVENT', true),
  deleteAvailabilityById: actionFromPromise(rest.deleteAvailabilityById),
  deleteAvailabilityByInterval: actionFromPromise(rest.deleteAvailabilityByInterval),
  deleteLicenseeEvent: actionFromPromise(rest.deleteLicenseeEvent),
  restoreLicenseeEvent: actionFromPromise(rest.restoreLicenseeEvent),
  fetchAvailability: actionFromPromise(rest.fetchAvailability),
  fetchEvents: actionFromPromise(rest.fetchEvents),
  fetchResidualAvailabilities: actionFromPromise(rest.fetchResidualAvailabilities),
  fetchEventsWithFilters: actionFromPromise(rest.fetchEventsWithFilters),
  fetchDashboardEvents: createListActions('DASHBOARD_CALENDAR_EVENTS', rest.fetchEvents),
  fetchEventsForEditSession: createListActions('SESSION_CALENDAR_EVENTS', rest.fetchEvents),
  fetchLicenseeEvent: actionFromPromise(rest.fetchLicenseeEvent),
  updateAvailability: actionFromPromise(rest.updateAvailability, 'EVENT', true),
  updateLicenseeEvent: actionFromPromise(rest.updateLicenseeEvent, 'EVENT', true),
  rewriteSimSpecAvailabilities: actionFromPromise(rest.rewriteSimSpecAvailabilities),
  fetchCalendarViewData: actionFromPromise(rest.fetchCalendarViewData),
};
