import React, { FunctionComponent, useMemo } from 'react';
import actions from 'src/app/redux/store/actions';
import ITier from 'src/app/redux/modules/company/interfaces/ITier';
import { useRawListDataFetching } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { useIntl } from 'react-intl';
import styles from './TierSelector.css';
import getLabelForOption from 'src/app/data/common/utils/getLabelForOption';

export interface ITierSelectorProps {
  onChange: (val: ITier) => void;
  licenseeId: string;
  tierId: string | null;
}

const TierSelector: FunctionComponent<ITierSelectorProps> = (props) => {
  const { licenseeId, tierId } = props;

  const tiersInfo = useRawListDataFetching(actions.company.fetchLicenseeTiers)(licenseeId)();

  const onChange = (e:any) => {
    const val = items.find(x => x.value === e.target.value);
    props.onChange({ id: `${val?.value}`, name: `${val?.label}` });
  };

  const items = useMemo(() => {
    return tiersInfo.items.map(x => ({ value: x.id, label: x.name }));
  }, [tiersInfo.items]);

  const selectedValue = items.find(x => x.value === tierId);
  const intl = useIntl();
  return (
    <div className={styles.selectWrap}>
      <select onChange={onChange} aria-required={true}>
        <option disabled={true} selected={true}>{intl.formatMessage({ id: 'Users.CreateUser.PlaceHolder.SelectTier' })}</option>
        {items.map((item) => {
          const label = getLabelForOption(item.label);
          if(item.value !== selectedValue?.value){
            return <option title={item.label} value={item.value}> {label} </option>;
          }
          else{
            return <option title={item.label} value={item.value} selected={true}> {label} </option>;
          }
        })}
      </select>
      <span aria-hidden="true">
        <i className="fas fa-caret-down"/>
      </span>
    </div>
  );
};

export default React.memo(TierSelector);
