import RestService from 'src/app/services/rest/RestService';
import * as React from 'react';
import moment from 'moment-timezone';
import platform from 'platform';

const LOGGING_URL = window.location.protocol + '//' + window.location.host + '/log';


const getUserInfo = () => {

  return {
    detailedInfo: {

      timeOpened: new Date(),
      timezone: moment.tz.guess(),

      pageon: window.location.pathname,
      referrer: document.referrer,
      previousSites: history.length,

      browserName: navigator.appName,
      browserEngine: navigator.product,
      browserVersion1a: navigator.appVersion,
      browserVersion1b: navigator.userAgent,
      browserLanguage: navigator.language,
      browserOnline: navigator.onLine,
      browserPlatform: navigator.platform,
      javaEnabled: navigator.javaEnabled(),
      dataCookiesEnabled: navigator.cookieEnabled,
      dataCookies: document.cookie,

      sizeScreenW: screen.width,
      sizeScreenH: screen.height,
      sizeInW: innerWidth,
      sizeInH: innerHeight,
      sizeAvailW: screen.availWidth,
      sizeAvailH: screen.availHeight,
      scrColorDepth: screen.colorDepth,
      scrPixelDepth: screen.pixelDepth,
    }
  };
};

export default class LoggingService {


  public static async logRestError(restError: Error, response: Response): Promise<any> {
    
    const info = {
      body: await response.clone().json(),
      headers: response.headers,
      ok: response.ok,
      redirected: response.redirected,
      status: response.status,
      statusText: response.statusText,
       // trailer: response.trailer,
      type: response.type,
      url: response.url,
    };

    return LoggingService.logError(restError, info, 'rest');
  }

  public static logReactError(reactError: Error, info: React.ErrorInfo): Promise<any> {
    return LoggingService.logError(reactError, info, 'react');
  }

  public static getDeviceInformation(info: string){
    const { description, product, os} = platform;
    return {
      browserDescription: description,
      deviceType: product,
      osArchitecture: os?.architecture,
      osFamily: os?.family,
      osVersion: os?.version,
      info,
      ...getUserInfo()
    };    
  }

  public static async logInfo(info: string): Promise<void> {
    const operativeDeviceData: string = JSON.stringify(this.getDeviceInformation(info));
    try {
      await RestService.fetch(`${RestService.REST_URL}/log`, {
        body: operativeDeviceData,
        headers: RestService.generateHeaders({
          'Content-Type': 'text/plain',
        }),
        method: 'POST',
      });
    } catch (e) {
      return;
    }
  }

  private static async logError(
    error: Error | null,
    info: any,
    type: 'rest' | 'react' | null
  ): Promise<any> {
    const parsedError = error ?
      {
        name: error.name,
        message: error.message,
        stack: error.stack,
      }
      : null;

    try {
      await fetch(`${LOGGING_URL}`, {
        body: JSON.stringify({
          application: 'webapp',
          type,
          error: parsedError,
          info,
          ...getUserInfo()
        }),
        headers: RestService.generateHeaders({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      });
    } catch (e) {
      return;
    }
  }
}
