import { AnyAction } from 'redux';
import * as authActionTypes from 'src/app/redux/modules/auth/types';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import createReducer, { RESET_STORE } from 'src/app/redux/utils/createReducer';
import getListTypes from 'src/app/redux/utils/getListTypes';

const createListReducer = <T>(listName: string) => {

  const INITIAL_STATE: IListDataState<T> = {
    fetched: false,
    items: [],
    page: 0,
    sizePerPage: 0,
    refreshError: null,
    refreshing: false,
  };

  const types = getListTypes(listName);

  return createReducer(INITIAL_STATE, {
    [types.REFRESH_LIST_ERROR]: (state: IListDataState<T>, action: AnyAction): IListDataState<T> => ({
      ...state,
      fetched: true,
      items: [],
      refreshError: action.error,
      refreshing: false,
    }),

    [types.REFRESHING_LIST_TYPE]: (state: IListDataState<T>): IListDataState<T> => ({
      ...state,
      refreshing: true,
    }),

    [types.RECEIVED_LIST_TYPE]: (state: IListDataState<T>, action: AnyAction): IListDataState<T> => {
      // If the response has a common pageable list, it has a content property
      const isPageableList = !!action.response.content;

      if (isPageableList) {
        return {
          ...state,
          fetched: true,
          items: action.response.content,
          page: action.response.pageable.pageNumber,
          sizePerPage: action.response.size,
          totalSize: action.response.totalElements,
          last: action.response.last,
          refreshError: null,
          refreshing: false,
        };
      } else {
        // If the response is not a pageable list, then assume it as an array

        return {
          ...state,
          fetched: true,
          items: action.response,
          page: 0,
          sizePerPage: action.response.length || 0,
          totalSize: action.response.length || 0,
          refreshError: null,
          refreshing: false,
        };
      }

    },

    [types.RESET_LIST]: () => INITIAL_STATE,
    [authActionTypes.SWITCH_ROLE]: () => RESET_STORE,
    [authActionTypes.LOG_OUT]: () => RESET_STORE,
  });
};

export default createListReducer;
