exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".UnavailableSlotsError__noBookingTabMsg___1ZHJg{text-align:center;max-width:435px;margin-left:auto;padding-left:15px;padding-right:15px;display:block;margin-bottom:10px;font-size:15px;line-height:22px}.UnavailableSlotsError__sorryText___owTDW{font-weight:700}.UnavailableSlotsError__noBookingTabMsg___1ZHJg img{max-width:100%;display:block;margin-bottom:10px}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/UnavailableSlots/UnavailableSlotsError.css"],"names":[],"mappings":"AAAA,gDACI,kBAAmB,AACnB,gBAAiB,AACjB,iBAAkB,AAClB,kBAAmB,AACnB,mBAAoB,AACpB,cAAe,AACf,mBAAoB,AACpB,eAAgB,AAChB,gBAAkB,CACrB,AAED,0CACI,eAAiB,CACpB,AAED,oDACI,eAAgB,AAChB,cAAe,AACf,kBAAoB,CACvB","file":"UnavailableSlotsError.css","sourcesContent":[".noBookingTabMsg{\n    text-align: center;\n    max-width: 435px;\n    margin-left: auto;\n    padding-left: 15px;\n    padding-right: 15px;\n    display: block;\n    margin-bottom: 10px;\n    font-size: 15px;\n    line-height: 22px;\n}\n\n.sorryText{\n    font-weight: 700;\n}\n\n.noBookingTabMsg img {\n    max-width: 100%;\n    display: block;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"noBookingTabMsg": "UnavailableSlotsError__noBookingTabMsg___1ZHJg",
	"sorryText": "UnavailableSlotsError__sorryText___owTDW"
};