exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".LazyImage__imageWrapper___2iMMf{-o-object-fit:contain;object-fit:contain;height:100%;width:100%}.LazyImage__imageWrapper___2iMMf img{width:100%;height:100%}.LazyImage__imageWrapperDefault___1qw8v img{-o-object-fit:contain;object-fit:contain}.LazyImage__imageWrapperCircle___3wiTr img{-o-object-fit:cover;object-fit:cover}.LazyImage__imageAutoWidthWrapper___3hsQt{display:-ms-inline-flexbox;display:inline-flex;width:auto;height:auto;line-height:0}.LazyImage__imageAutoWidthWrapper___3hsQt .LazyImage__imageWrapper___2iMMf,.LazyImage__imageAutoWidthWrapper___3hsQt img{width:auto;height:auto;max-width:100%}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/LazyImage/LazyImage.css"],"names":[],"mappings":"AAAA,iCACE,sBAAuB,AACpB,mBAAoB,AACvB,YAAa,AACb,UAAY,CACb,AAED,qCACE,WAAY,AACZ,WAAa,CACd,AAED,4CACE,sBAAuB,AACpB,kBAAoB,CACxB,AAED,2CACE,oBAAqB,AAClB,gBAAkB,CACtB,AAED,0CACE,2BAA4B,AAC5B,oBAAqB,AACrB,WAAY,AACZ,YAAa,AACb,aAAe,CAChB,AAQD,yHALE,WAAY,AACZ,YAAa,AACb,cAAgB,CAOjB","file":"LazyImage.css","sourcesContent":[".imageWrapper {\n  -o-object-fit: contain;\n     object-fit: contain;\n  height: 100%;\n  width: 100%;\n}\n\n.imageWrapper img {\n  width: 100%;\n  height: 100%;\n}\n\n.imageWrapperDefault img {\n  -o-object-fit: contain;\n     object-fit: contain;\n}\n\n.imageWrapperCircle img {\n  -o-object-fit: cover;\n     object-fit: cover;\n}\n\n.imageAutoWidthWrapper{\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  width: auto;\n  height: auto;\n  line-height: 0;\n}\n\n.imageAutoWidthWrapper .imageWrapper{\n  width: auto;\n  height: auto;\n  max-width: 100%;\n}\n\n.imageAutoWidthWrapper img{\n  width: auto;\n  height: auto;\n  max-width: 100%;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"imageWrapper": "LazyImage__imageWrapper___2iMMf",
	"imageWrapperDefault": "LazyImage__imageWrapperDefault___1qw8v",
	"imageWrapperCircle": "LazyImage__imageWrapperCircle___3wiTr",
	"imageAutoWidthWrapper": "LazyImage__imageAutoWidthWrapper___3hsQt"
};