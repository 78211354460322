import { combineReducers } from 'redux';
import IListDataState from "src/app/data/common/interfaces/IListDataState";
import createListReducer from "src/app/redux/utils/createListReducer";
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';

export interface IProjectLearnersState {
  list: IListDataState<ILearnerExtended>;
}

export default combineReducers({
  list: createListReducer('PROJECT_LEARNERS'),
});
