import React, { MouseEventHandler, PropsWithChildren } from 'react';
import styles from './CtaButton.css';
import cn from 'classnames';

export type ICtaButtonTheme = 'primary' | 'secondary';

type ICtaButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & PropsWithChildren<{
  theme?: ICtaButtonTheme;
  disabled?: boolean;
  classNames?: string;
  onClick?: MouseEventHandler
}>;
const CtaButton = ({ theme = 'primary', classNames, onClick, children, ...props }: ICtaButtonProps) => {
  return (
    <button
      {...props}
      className={cn(styles[theme], classNames)}
      onClick={onClick}>
      {children}
    </button>
  );
};

export default CtaButton;
