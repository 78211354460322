import { Editor } from '@tinymce/tinymce-react';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ApiKey, fontSizeInputTinyMceDefaultUnit, listPlugins, portalFontFamilyFormats, tinyMceColorMap, tinyMceContentStyle, tinyMceFontSizeFormats, DefaultToolbarOptions } from 'src/components/TinyMceEditor/TimyMceEditor';
import { Editor as TinyMCEEditor } from 'tinymce';
import csvInjectionProtector from 'src/app/data/common/utils/csvInjectionProtector';
import stripHtml from 'src/app/data/common/utils/stripHtml';

const matchHtmlTags = '(<([^';
const matchCharMultiTimes = '>]+)>)';
const getAllHtmlTags = `${matchHtmlTags}${matchCharMultiTimes}`;
const regexForMatchHtml = new RegExp(getAllHtmlTags,"ig");
export interface ITinyMceEditor {
  width: number | string;
  height: number;
  menubar?: boolean;
  statusbar?: boolean;
  value?: string;
  onChange: (value: string, length?: number, isInputValid?: boolean) => void;
  toolbar?: string;
  csvProtected?: boolean;
  plugins?: string;
  placeholder: string;
  elementpath?: boolean;
  disabled?: boolean;
}

export const  getPlainText = (val:string, className?:string) => {
  return  <div className={className} dangerouslySetInnerHTML={{__html: val}}/>;
};

export const extractLengthFromHtmlData = (val: string) => {
  return stripHtml(val).trim().length;
};

export const  extractTextFromHtmlData = (val: string) => {
  return val.replace(regexForMatchHtml, "");
};

const TinyMceEditor: FunctionComponent<ITinyMceEditor> = (props) => {

  const { menubar, statusbar, value, width, height, onChange, csvProtected, placeholder, plugins, elementpath, disabled } = props;

  const [values, setValue] = useState<string>();

  const pluginsValue = plugins || listPlugins;

  useEffect(() => {
    if (value !== values) {
      setValue(value ?? '');
    }
  }, [value]);

  const onChangeEditor = (textValue: string, editor: TinyMCEEditor) => {
    const length = editor.getContent({ format: 'text' }).length;
    setValue(textValue);

    if (csvProtected) {
      textValue = csvInjectionProtector(textValue);
    }
     const isInputValid = editor.getContent({ format: 'text' }).trim().length === 0 ? false : true;
     onChange(textValue, length, isInputValid );
  };
  
  const EditorElement = useMemo(()=>{
      return <Editor
              apiKey={ApiKey}
              init={{
                width,
                height,
                menubar,
                statusbar,
                elementpath,
                plugins: pluginsValue,
                toolbar: DefaultToolbarOptions,
                branding: false, 
                placeholder,
                paste_data_images: false,
                paste_remove_styles_if_webkit: false,
                font_family_formats: portalFontFamilyFormats,
                font_size_formats: tinyMceFontSizeFormats,
                font_size_input_default_unit: fontSizeInputTinyMceDefaultUnit,
                content_style: tinyMceContentStyle,
                color_map: tinyMceColorMap,
                relative_urls: false,
                remove_script_host:false,
              }}
              disabled={disabled}
              value={values}
              onEditorChange={onChangeEditor}
            />;
  }, [props, values]);

  return (
    <>
      {EditorElement}
    </>
  );
};

export default TinyMceEditor;
