import * as React from 'react';
import BootstrapTable, {
  BootstrapTableProps,
  ColumnDescription,
  RowEventHandlerProps,
  SortOrder
} from 'react-bootstrap-table-next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IRestCollectionInfo from 'src/app/data/common/interfaces/IRestCollectionInfo';
import styles from './EntityTable.css';
import queryString from 'query-string';
import { PARAM_SORTBY, PARAM_SORTBY_ORDER } from 'src/components/SortBy';
import TranslateMessage from 'src/i18n/TranslateMessage';
import Skeleton from 'react-loading-skeleton';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Image } from 'react-bootstrap';
import cn from 'classnames';
import { getFontSizeClass, getPageDataPrefix, getTableTheme } from 'src/app/data/common/utils/entityTableUtils';

const MAX_LENGTH_CHARS = 60;

const ACTION_VIEW = 'viewAction';

export interface IEntity { [key: string]: any; }

interface IEntityAction {
  enabledChecker?: (entity: IEntity) => boolean;
  title?: string;
  validate?: (entity: IEntity) => void;
}

export enum TableTheme {
  Default,
  Cobalt,
  Custom,
}

export interface IViewEntityAction extends IEntityAction {
  urlPrefix?: string;
  callback?: (id: string, entity: any, element?: HTMLElement) => void;
}

export type TitleFormatter = (
  cell: any,
  row: any,
  rowIndex: number,
  formatExtraData: any,
) => string;

export type HeaderTextFormatter<T extends object = any> = (
  column: IEntityColumn<T>,
  colIndex: number,
) => JSX.Element | string | number | React.ReactText;

export interface IEntityColumn<Row extends object = any, FormatExtraData = any>
  extends ColumnDescription<Row, FormatExtraData> {
  titleFormatter?: TitleFormatter;
  headerTextFormatter?: HeaderTextFormatter;
}

export type IEntityIdExtractor<T extends IEntity = IEntity> = (entity: T, index: number) => string | number;

interface IEntityTableProps extends WrappedComponentProps, Omit<BootstrapTableProps, 'keyField' | 'data'>  {
  inline?: boolean;
  columns: IEntityColumn[];
  entityViewAction?: IViewEntityAction | null;
  entityIdExtractor: IEntityIdExtractor;
  emptyMessage?: string | JSX.Element;
  entitiesInfo: IRestCollectionInfo<IEntity>;
  entityToDataMapper?: (entity: IEntity, index: number) => any;
  localSort?: boolean;
  pageDataPrefix?: string;
  stickyColumns?: number[];
  themeType?: TableTheme;
  header?: string | null;
  hoverText?: string;
  query?: any;
  className?: string;
  fontSizeClass?: string;
  hasTablePlaceHolder?: boolean;
}

interface IDeleteEntityInfo {
  confirmMessage: string;
  error: string | null;
  onDeleteConfirm: () => Promise<void>;
}

interface IEntityTableState {
  deleteEntityInfo: IDeleteEntityInfo | null;
  activeRow: number | null;
  stickyColumnStyles: any[];
  scrollMessageWidth: number;
  sort: { dataField?: any, order: SortOrder } | undefined;
}

export const longValueFormatter = (maxLength = MAX_LENGTH_CHARS) => {
  return (value: string) => {
    if (!value) {
      return <span>{TranslateMessage('MursionPortal.NotAvailable')}</span>;
    }

    let props = {};

    let viewValue = value;
    if (value.length > maxLength) {
      viewValue = `${value.substr(0, maxLength)}...`;
      props = {
        title: value,
      };
    }

    return (
      <span {...props}>{viewValue}</span>
    );
  };
};

export const dateValueFormatter = () => {
  return (data: any) => {
    if (!data) {
      return <span>{TranslateMessage('MursionPortal.NotAvailable')}</span>;
    }

    return (
      <span className={styles.dateCell}>
        <span>{data.date}</span>
        <span>{data.time}</span>
      </span>
    );
  };
};


export const getLongWordValueFormatter = (maxWordLength: number, maxWords?: number, separator?: string) => {
  const stringifyResult = (resultArr: string[]) => {
    return resultArr.join(separator || ' ');
  };

  return (value: any) => {
    if (!value) {
      return '';
    }

    const valueParts = value.toString().split(new RegExp(separator || ' '));
    const resultValueParts = [];

    for (const currentValue of valueParts) {
      let currentPart = currentValue;

      if (currentPart.length > maxWordLength) {
        currentPart = `${currentPart.substr(0, maxWordLength)}...`;

        if (!maxWords) { // crop the text once the long word found
          return <span title={value}>{`${stringifyResult(resultValueParts)} ${currentPart}`}</span>;
        }
      }

      resultValueParts.push(currentPart);
    }

    return <span title={value}>{stringifyResult(resultValueParts)}</span>;
  };
};

export const getListValueFormatter = (maxListLength: number, maxItemLength?: number) => {
  return (list: string[] | null) => {

    if (!list || !list.length) {
      return TranslateMessage('MursionPortal.NotAvailable');
    }

    return (
      <ul className={styles.list}>
        {
          list.filter((item, i) => i < maxListLength).map((item, i) =>
            <li key={`item-${i}`} title={item}>
              <span>
                {
                  maxItemLength && item.length > maxItemLength ? `${item.substr(0, maxItemLength)}...` : item
                }
              </span>
            </li>
          )
        }
        {
          list.length > maxListLength && (
            <li title={list.join(', ')}>...</li>
          )
        }
      </ul>
    );
  };
};

class EntityTable extends React.Component<IEntityTableProps & RouteComponentProps<any>, IEntityTableState> {

  public static defaultProps = {
    themeType: TableTheme.Default,
  };

  public state: IEntityTableState = {
    deleteEntityInfo: null,
    activeRow: null,
    stickyColumnStyles: [],
    scrollMessageWidth: 800,
    sort: { order: 'asc' },
  };
  public tableRef: React.RefObject<HTMLDivElement> | null = React.createRef();

  public componentDidMount() {
    this.updateSizes();
    window.addEventListener('resize', this.updateSizes.bind(this));
  }

  /*The a11y enhancement required for the table was to announce complete word 'ascending' or 'descending after the column name instead of short 'asc' or 'desc',
  so these query selectors are used to set the attributes like this because this third party UI library for table is not allowing us with the flexibility to set the aria label attribute as per our requirement.*/
  public componentDidUpdate() {
    const tableHeaders = this.tableRef?.current?.getElementsByTagName('th');
    const tableRows = this.tableRef?.current?.getElementsByTagName('tr');
    const tableColumn = this.tableRef?.current?.getElementsByTagName('td');
    const { intl } = this.props;

    if (tableHeaders && tableHeaders.length) {
      const colHeaders = Array.from(tableHeaders);

      colHeaders.forEach((head) => {
        const ariaLabel = head.getAttribute('aria-label');
        head.setAttribute('scope', 'col');
        if (ariaLabel) {
          const sort = ariaLabel.substr(ariaLabel.length - 3);
          if (sort === 'asc' || sort === 'ble') {
            // Current aria-label for table headers is like `${headerName} sort asc` and now the table header name is `${headerName} sort by ascending/descending.`
            head.setAttribute('aria-label', ariaLabel.substring(0, ariaLabel.length - 8) + intl.formatMessage({ id: 'MursionPortal.AriaLabel.SortByAscendingOrderButton' }));
          } else if (sort === 'esc') {
            head.setAttribute('aria-label', ariaLabel.substring(0, ariaLabel.length - 9) + intl.formatMessage({ id: 'MursionPortal.AriaLabel.SortByDescendingOrderButton' }));
          }
        }
        });

      if (colHeaders[colHeaders.length - 1].innerHTML === '') {
        colHeaders[colHeaders.length - 1].setAttribute('tabIndex', '-1');
      }
    }

    if (tableColumn && tableColumn.length) {
      Array.from(tableColumn).forEach((column) => {
        column.setAttribute('tabIndex', '0');
      });
    }
    if (tableRows && tableRows.length) {
      Array.from(tableRows).forEach((row) => {
        row.addEventListener('keydown', this.triggerClickOnEnter(row));
      });
    }
  }

  public componentWillUnmount() {
    const tableRows = this.tableRef?.current?.getElementsByTagName('tr');
    if (tableRows && tableRows.length) {
      Array.from(tableRows).forEach((row) => {
        row.removeEventListener('keydown', this.triggerClickOnEnter(row));
      });
    }

    window.removeEventListener('resize', this.updateSizes.bind(this));
  }

  public render() {
    const { entitiesInfo, entityIdExtractor, fontSizeClass } = this.props;
    const columns = this.calculateColumns();
    if(!entitiesInfo){
      return [];
    }
    const data = this.entitiesInfoItemMapped();

    const rowEvents: RowEventHandlerProps = {
      onClick: (e, row, rowIndex) => {
        const element = e.target as HTMLElement;
        const viewActionWrapper = row[ACTION_VIEW];
        if (!(viewActionWrapper && viewActionWrapper.action)) {
          return;
        }

        if (viewActionWrapper.action.urlPrefix) {
          const link = `${viewActionWrapper.action.urlPrefix}${entityIdExtractor(viewActionWrapper.entity, rowIndex)}`;
          this.props.history.push(link);
        }

        if (viewActionWrapper.action.callback) {
          viewActionWrapper.action.callback(entityIdExtractor(viewActionWrapper.entity, rowIndex), viewActionWrapper.entity, element);
        }
      },
      onMouseEnter: (_, row) => this.setState({ activeRow: data.findIndex(r => r === row) }),
      onMouseLeave: () => this.setState({ activeRow: null })
    };

    const onTableChange = (type: string, { sortField, sortOrder }: any) => {
      const { history, location, pageDataPrefix } = this.props;
      const params = queryString.parse(location.search);
      if (type === 'sort') {
        location.search = queryString.stringify({
          ...params,
          [getPageDataPrefix(pageDataPrefix) + PARAM_SORTBY]: sortField,
          [getPageDataPrefix(pageDataPrefix) + PARAM_SORTBY_ORDER]: sortOrder
        });
        this.setState({ sort: { dataField: sortField, order: sortOrder } });
        history.push(location);
      }
    };

    const rowClasses = (row: any, index: number) => cn(
      row.archived && styles.rowArchived,
      this.state.activeRow === index && styles.hovered,
    );

    const remote = this.remoteSort();
    const caption = this.captionElement();

    const stickyColumns = this.stickyColumnsHelpers();
    const tableColumns = this.tableColumnsHelpers(columns);

    return (
      <div className={cn(styles.wrapper, getTableTheme(this.props.themeType), getFontSizeClass(fontSizeClass))}>
        <div className={cn(
          styles.container,
          this.state.stickyColumnStyles.length && styles.containerWithSticky, this.props.className
        )} ref={this.tableRef}>
          {columns.length ? (
            <BootstrapTable
              {...this.props}
              caption={caption}
              columns={tableColumns}
              condensed={true}
              data={data}
              hover={true}
              keyField="fieldId"
              noDataIndication={this.renderNoData}
              onTableChange={onTableChange}
              remote={remote}
              rowEvents={rowEvents}
              bordered={false}
              rowClasses={rowClasses}
              wrapperClasses={cn(styles.table, stickyColumns && styles.tableScroll)}
            />
          ) : (
            <Skeleton count={5} />
          )}
        </div>
      </div>
    );
  }

  private stickyColumnsHelpers(){
    return this.props.themeType === TableTheme.Cobalt && this.props.stickyColumns;
  }

  private captionElement(){
    return this.props.caption || <></>;
  }

  private remoteSort(){
    const { localSort } = this.props;
    return !localSort ? { sort: true } : undefined;
  }

  private tableColumnsHelpers(columns: ColumnDescription[]){
    return columns.map((column: ColumnDescription, index: number) => {
      const columnStyle = this.state.stickyColumnStyles[index];
      if (!columnStyle) {
        return column;
      }

      return ({
        ...column,
        style: {
          ...column.style,
          ...columnStyle,
        },
        headerStyle: {
          ...column.headerStyle,
          ...columnStyle,
        },
        classes: cn(column.classes, columnStyle && styles.stickyCell),
        headerClasses: cn(column.headerClasses, columnStyle && styles.stickyCell),
      });
    });
  }

  private entitiesInfoItemMapped(){
    const { entityViewAction, entitiesInfo } = this.props;
    if(!Array.isArray(entitiesInfo.items)){
      return [];
    }
    return entitiesInfo.items.map((entity: IEntity, index: number) => {
      const entityData = this.props.entityToDataMapper
        ? this.props.entityToDataMapper(entity, index) : entity;
      return {
        ...entityData,
        fieldId: 'entity-' + index,
        [ACTION_VIEW]: {
          action: entityViewAction,
          entity,
        },
      };
    });
  }

  private getLearnerTimeSpentInMinutes(column:any, args:any){
    if(column.dataField === 'learnerTimeSpent'){
      return Math.round(args[0]/60000);
    }else{
      return args[0];
    }
  }

  private getValueTitle(value: any, hoverText: string | undefined) {
    const titleValue = typeof value !== 'object' ? value : hoverText;
    return titleValue === false ? 0 : titleValue;
  }

  private getCellTitle(column: IEntityColumn, title: string | undefined) {
    return column.dataField === 'status' ? '' : title;
  }

  private calculateColumns() {
    const entityViewAction = this.props.entityViewAction;
    let columns = this.props.columns;
    const hoverText = this.props.hoverText;

    if (entityViewAction) {
      columns = this.props.columns.map(column => ({
        ...column,
        formatter: (...args) => { // wrap the table cell into a span with an entity view action title
          const value = column.formatter ? 
                        column.formatter(...args) :
                        this.getLearnerTimeSpentInMinutes(column, args);
          const title = hoverText || (column.titleFormatter ? column.titleFormatter(...args) : entityViewAction.title);
          const valueTitle = this.getValueTitle(value, hoverText);
          const cellTitle =  this.getCellTitle(column, title?.toString());

          return this.props.themeType === TableTheme.Cobalt ? (
            <span className={styles.cellTitleHolder} title={cellTitle}>
              <span><span title={valueTitle}>{value}</span></span>
            </span>
          ) : (
            <span title={cellTitle}>
              {value}
            </span>
          );
        },
        headerFormatter: (col, colIndex, components) => {
          const headerValue = column.headerTextFormatter ? column.headerTextFormatter(col, colIndex) : column.text;
          return <>
            {headerValue}
            {components.filterElement}
            {components.sortElement}
          </>;
        },
        sortCaret: this.sortCaret.bind(this),
      }));
    }

    return columns;
  }

  private updateSizes() {
    const stickyColumns = this.props.stickyColumns || [];
    if (window.innerWidth < 700 || !stickyColumns.length || this.props.themeType !== TableTheme.Cobalt) {
      this.setState({ stickyColumnStyles: [] });
    } else {
      const tableWidth = this.tableRef?.current?.offsetWidth;
      if (!tableWidth) {
        return;
      }

      const sumPercent = stickyColumns.reduce((s, v) => s + v, 0);
      const fixedRowsWidth = tableWidth * (sumPercent / 100);
      let marginLeft = 0;

      const stickyColumnStyles = stickyColumns.map((percent) => {
        const width = fixedRowsWidth / sumPercent * percent;
        const style = ({
          left: marginLeft,
          minWidth: width,
          maxWidth: width,
        });
        marginLeft += width;
        return style;
      });

      this.setState({
        stickyColumnStyles,
        scrollMessageWidth: fixedRowsWidth,
      });
    }
  }

  private sortCaret(order?: 'asc' | 'desc') {
    const { intl } = this.props;

    if (this.props.inline) {
      return;
    }

    if (this.props.themeType === TableTheme.Cobalt || this.props.themeType === TableTheme.Custom) {
      if (!order) {
        return <Image className={styles.caret} src={require(`src/images/sort-none.svg`)} alt={intl.formatMessage({ id: 'MursionPortal.AriaLabel.SortByAscending' })}/>;
      }
      return (order === 'desc') ? (
        <Image className={styles.caret} src={require(`src/images/sort-desc.svg`)} alt={intl.formatMessage({ id: 'MursionPortal.AriaLabel.SortByDescending' })}/>
      ) : (
          <Image className={styles.caret} src={require(`src/images/sort-asc.svg`)} alt={intl.formatMessage({ id: 'MursionPortal.AriaLabel.SortByAscending' })}/>
      );
    }

    const icon = (order === 'desc') ? 'chevron-up' : 'chevron-down';
    const iconStyle = (order === undefined) ? styles.caretUndef : styles.caretDef;

    return <i className={cn('fas', `fa-${icon}`, iconStyle)} />;
  }

  private getTablePlaceholder = () => {
    const { fetchError } = this.props.entitiesInfo;
    const { header = null, query, hasTablePlaceHolder = false } = this.props;
    if(hasTablePlaceHolder) {
      return header;
    }
    if(!query) {
      return header || TranslateMessage('MursionPortal.Table.NoRecordsMessage');
    }
    if(fetchError) {
      return fetchError.message;
    }
    return this.props.emptyMessage || TranslateMessage('MursionPortal.Table.NoRecordsMessage');
  }

  private renderNoData = () => {
    return (
      <div className={styles.emptyContainer} role="presentation">
        {this.getTablePlaceholder()}
      </div>
    );
  };

  private triggerClickOnEnter = (row: HTMLTableRowElement) => (e: any) => {
    if (e.keyCode === 13) {
      row.click();
    }
  };
}

export default injectIntl(withRouter(EntityTable));
