import _get from 'lodash/get';
import { AnyAction } from 'redux';
import * as logOutTypes from 'src/app/redux/modules/auth/types';
import createReducer from 'src/app/redux/utils/createReducer';
import { IErrorState, IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import { getEntityErrorType } from 'src/app/redux/utils/getEntityErrorType';
import { ERROR_CODE_UNARCHIVED_DESCENDANT, ERROR_CODE_VALIDATION } from 'src/app/data/common/utils/errorUtils';

const createEntityErrorReducer = (entityName: string) => {

  const INITIAL_STATE: IErrorState = {
    code: '',
    message: '',
    validations: [],
    properties: null,
  };

  const types = getEntityErrorType(entityName);

  return createReducer(INITIAL_STATE, {
    [logOutTypes.LOG_OUT]: () => INITIAL_STATE,
    [types.CLEAR_ERROR]: () => INITIAL_STATE,
    [types.CLEAR_VALIDATION]: (state: IErrorState, action: AnyAction) => {
      return {
        ...state,
        validations: state.validations.reduce((accum, val) => {
          if (val.field === action.fieldName) {
            return accum;
          }

          return accum.concat(val);
        }, [] as IErrorValidation[])
      };
    },
    [types.THROW_ERROR]: (state: IErrorState, action: AnyAction): IErrorState => {
      const error = action.payload;
      const message = error.code === ERROR_CODE_VALIDATION
      || error.code === ERROR_CODE_UNARCHIVED_DESCENDANT && !!_get(error.properties, 'entity')
        ? '' : error.message; // do not show validation and archive general error message

      return {
        ...action.payload,
        message,
        validations: action.payload.validations || []
      };
    },
  });
};

export default createEntityErrorReducer;
