export const googleFormId = '1N8sMZ3kDBCSAerD0IvTcpVAUehsScoOQL75GDEtcmeI';
export const submitSessionSurveyAPIToken = "dIeYbCxSXgr50hpdcdWqEhiIU37DcaooyfKQsvnZ";
const ldClientIdDev = "627a740c55909914cfbffa42";
const ldClientIdStage = "629fbfc6d91f9a155438b949";
const ldClientIdProd= "627a740c55909914cfbffa43";

// ldClientIdProd for Prod, ldClientIdStage for stage and ldClientIdDev for all other environments
export const launchDarklyClientId = () => {
    const prodHostName = "portal.mursion.com";
    const stageHostName = "staging.portal.mursion.com";
    const testingHostName = "testing-portal.mursion.com";
    const integrationTestingHostName = "integration-testing-portal.mursion.com";

    if (prodHostName === window.location.hostname) {
        return ldClientIdProd;
    }
    if (stageHostName === window.location.hostname || testingHostName === window.location.hostname || integrationTestingHostName === window.location.hostname) {
        return ldClientIdStage;
    }
    return ldClientIdDev;
};
