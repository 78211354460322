import React, { ErrorInfo } from 'react';
import LoggingService from 'src/app/services/logging/LoggingService';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';

class ErrorBoundary extends React.Component {

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  public state = {
    hasError: false,
  };

  public async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    await LoggingService.logReactError(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage/>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;