exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".TeamMembersList__search___3rT5_{margin-bottom:10px}.TeamMembersList__ScrollBarWrap___sqZvR{max-height:150px}.TeamMembersList__ScrollBarWrap___sqZvR .scrollbar-container.vertical{right:-3px!important;width:15px!important}.TeamMembersList__ScrollBarWrap___sqZvR.scrollarea .scrollbar-container.vertical .scrollbar{width:5px!important}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/TeamMembersList/TeamMembersList.css"],"names":[],"mappings":"AAAA,iCACE,kBAAoB,CACrB,AAED,wCACE,gBAAkB,CACnB,AAED,sEACE,qBAAsB,AACtB,oBAAsB,CACvB,AAED,4FACE,mBAAqB,CACtB","file":"TeamMembersList.css","sourcesContent":[".search{\n  margin-bottom: 10px;\n}\n\n.ScrollBarWrap{\n  max-height: 150px;\n}\n\n.ScrollBarWrap :global(.scrollbar-container.vertical){\n  right: -3px!important;\n  width: 15px!important;\n}\n\n.ScrollBarWrap:global(.scrollarea .scrollbar-container.vertical .scrollbar){\n  width: 5px!important;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"search": "TeamMembersList__search___3rT5_",
	"ScrollBarWrap": "TeamMembersList__ScrollBarWrap___sqZvR"
};