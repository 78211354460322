import RestService from 'src/app/services/rest/RestService';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { ISessionWizardTinyScenario } from 'src/app/redux/modules/sessionWizard/rest';
import IProject from 'src/app/data/projects/interfaces/IProject';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
export interface ISessionWizardTinyItem {
  id: number | string;
  name: string;
}

const fetchDemandBasedProjects = (pageData: IListPageData | null, clientId: number | string, joinNow: boolean = true): Promise<IListDataResponse<IProject>> => {
  const queryParamValues = pageData ? getPageDataUrl(pageData) : `/0/9999?demandBased=true&joinNow=${joinNow}`;
  return RestService.fetch(`${RestService.REST_URL}/project/list/${clientId}/withActiveScenario${queryParamValues}`, {
    headers: RestService.generateHeaders(),
    method: "GET",
    signal: null,
  });
};

const fetchLearnerAvailability = (from: number, to:number): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/restriction/learner?from=${from}&to=${to}`, {
    headers: RestService.generateHeaders(),
    method: "GET",
    signal: null,
  });
};

const fetchDemandBasedActiveScenarios = (projectId: number | string, nonAutoSimScenarios: boolean = false): Promise<IListDataResponse<ISessionWizardTinyScenario>> => {

  return RestService.fetch(`${RestService.REST_URL}/scenario/list/active/project/${projectId}/0/9999?&nameOnly=true&order=name&includeDraft=false&demandBased=true&nonAutoSimScenarios=${nonAutoSimScenarios}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal: null
  });
};

  export default {
  fetchDemandBasedProjects,
  fetchLearnerAvailability,
  fetchDemandBasedActiveScenarios
  };
  