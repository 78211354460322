import IRestError from "src/app/data/common/interfaces/IRestError";
import { IAppState } from 'src/app/redux/store/reducers';
import { parseErrorMessage } from 'src/app/data/common/utils/errorUtils';

export const getList = (state: IAppState) => state.entityHistory.list.items;
export const getListPage = (state: IAppState): number => state.entityHistory.list.page || 0;
export const getListSizePerPage = (state: IAppState): number => state.entityHistory.list.sizePerPage || 0;
export const getListTotalSize = (state: IAppState): number => state.entityHistory.list.totalSize || 0;
export const isListFetched = (state: IAppState): boolean => state.entityHistory.list.fetched;
export const getListRefreshError = (state: IAppState): IRestError | null => state.entityHistory.list.refreshError;
export const isListRefreshing = (state: IAppState): boolean => state.entityHistory.list.refreshing;

export const getListBySession = (state: IAppState) => state.entityHistory.listBySession.items;
export const getListBySessionPage = (state: IAppState): number => state.entityHistory.listBySession.page || 0;
export const getListBySessionSizePerPage = (state: IAppState): number => state.entityHistory.listBySession.sizePerPage || 0;
export const getListBySessionTotalSize = (state: IAppState): number => state.entityHistory.listBySession.totalSize || 0;
export const isListBySessionFetched = (state: IAppState): boolean => state.entityHistory.listBySession.fetched;
export const getListBySessionRefreshError = (state: IAppState): IRestError | null => state.entityHistory.listBySession.refreshError;
export const isListBySessionRefreshing = (state: IAppState): boolean => state.entityHistory.listBySession.refreshing;

export const getErrorMessage = (state: IAppState): string => parseErrorMessage(state.entityHistory.error);
