import EventEmitter from 'events';
import AuthService from 'src/app/services/auth/AuthService';
import RestError from 'src/app/services/rest/RestError';
import LoggingService from 'src/app/services/logging/LoggingService';
import { intl } from 'src/i18n/createIntl';
import { ERROR_CODE_FORBIDDEN, getForbiddenError } from 'src/app/data/common/utils/errorUtils';

export const PUBLIC_URL = window.location.protocol + '//' + window.location.host;
const REST_URL = PUBLIC_URL + '/rest';
const DASHBOARD_REST_URL = REST_URL + '/dashboard';
const BACKGROUND_REST_URL = REST_URL + '/background';
const SSO_AUTH_URL = PUBLIC_URL + '/sso/auth';
const SSO_LINK_URL = PUBLIC_URL + '/sso/link';
const SSO_REST_URL = PUBLIC_URL + '/sso/rest';
const SSO_LOGIN_URL = SSO_AUTH_URL + '/login';
const SSO_LOGOUT_URL = SSO_AUTH_URL + '/logout';
const GLOBAL_CONFIG_REST_URL = PUBLIC_URL + '/global-config/rest';
const INTEGRATION_SERVICE_REST_URL= PUBLIC_URL + '/rest/v1/integration';

function isForbiddenError(error: any) {
  return error && error.code === ERROR_CODE_FORBIDDEN;
}
class RestService extends EventEmitter {

  private static instance: RestService;
  public EVENTS = {
    AUTH_ERROR: 'AUTH_ERROR',
    AUTH_TOKEN_UPDATED: 'AUTH_TOKEN_UPDATED',
  };

  private constructor() {
    super();
  }

  get REST_URL() {
    return REST_URL;
  }

  get GLOBAL_CONFIG_REST_URL() {
    return GLOBAL_CONFIG_REST_URL;
  }

  get DASHBOARD_REST_URL() {
    return DASHBOARD_REST_URL;
  }

  get BACKGROUND_REST_URL() {
    return BACKGROUND_REST_URL;
  }

  get SSO_LOGIN_URL() {
    return SSO_LOGIN_URL;
  }

  get SSO_LOGOUT_URL() {
    return SSO_LOGOUT_URL;
  }

  get SSO_LINK_URL() {
    return SSO_LINK_URL;
  }

  get SSO_REST_URL() {
    return SSO_REST_URL;
  }

  get GET_PUBLIC_URL() {
    return PUBLIC_URL;
  }

  get GET_INTEGRATION_REST_URL(){
    return INTEGRATION_SERVICE_REST_URL;
  }

  public static getInstance() {
    if (!RestService.instance) {
      RestService.instance = new RestService();
    }
    return RestService.instance;
  }

  public generateHeaders(headers?: { [headerName: string]: string }) {
    const token = AuthService.getToken();

    return {
      ...this.getAuthHeader(token),
      ...headers,
    };
  }

  public getAuthHeader(token?: string) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  public fetch(input: Request | string, init?: RequestInit, onUnauthorized?: () => Promise<any>): Promise<any> {
    return fetch(input, init)
      .then(async (response) => {
        let error: any = null;
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          const contentDisposition = response.headers.get('content-disposition');
          if (!!contentDisposition && !contentType) {
            return response.arrayBuffer();
          }

          if (contentType?.includes('application/json')) {
            return response.json();
          }

          return response.text();
        }

        switch (response.status) {
          case (400): {
            error = await response.clone().json();
            if (isForbiddenError(error)) {
              error = new RestError(error.message, response.status);
            }
            await LoggingService.logRestError(error, response);
            throw error;
          }
          case (401): {
            if (onUnauthorized) {
              try {
                return await onUnauthorized();
              } catch (e) {
                if (e.code === 401) {
                  this.throwAuthError();
                } else {
                  throw e;
                }
              }
            } else {
              this.throwAuthError();
            }
            break;
          }
          case(403): {
            if (response.url.includes('/global-config/')) {
              return; 
            }

            const errorMessage = await response.clone().json();
            await LoggingService.logRestError(errorMessage, response);
            error = getForbiddenError(errorMessage, response.status);
            throw error;
          }
          default: {
            console.error(response); // tslint:disable-line

            error = new RestError(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.TechnicalIssueMsg' }),response.status);
            await LoggingService.logRestError(error, response);

            throw error;
          }
        }
      });
  }

  private throwAuthError() {
    this.emit(this.EVENTS.AUTH_ERROR);

    throw new RestError(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.Unauthorized' }), 401);
  }
}

export default RestService.getInstance();
