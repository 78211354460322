import { AnyAction } from 'redux';
import createReducer from 'src/app/redux/utils/createReducer';
import * as types from './types';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';

const INITIAL_STATE: IErrorState = {
  code: '',
  message: '',
  validations: [],
  properties: null,
};

export default createReducer(INITIAL_STATE, {

  [types.CLEAR_ERROR]: () => INITIAL_STATE,

  [types.THROW_ERROR]: (state: IErrorState, action: AnyAction): IErrorState => {

    if (action.payload.code === 401) {
      return state;
    }

    return {
      ...state,
      code: action.payload.code,
      message: action.payload.message,
      validations: action.payload.validations || [],
      properties: action.payload.properties,
    };
  },

});
