import { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { components } from 'react-select';
import styles from '../../../Selector.css';
import cn from 'classnames';
import React, { ChangeEventHandler, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { useIntl } from 'react-intl';
import { Pills } from 'src/components/pills';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import Checkbox from 'src/components/Checkbox/Checkbox';

export interface ISelectorMenuPortal {
  menuProps: MenuPortalProps<any, any, any>;
  isMulti: boolean | undefined;
  isCheckAll?: boolean;
  searchPlaceholder?: string;
  total?: number;
  inputAriaLabel?: string;
  search: string;
  onSearchChange: (val: string) => void;
  onMenuClose: () => void;
  onClearSelectedItems: () => void;
  onSelectAllItems: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showSelected?: boolean;
  themeType: SelectorTheme;
  isSelectAll: boolean;
  isSelectAllOptions: boolean;
}

const SelectorMenuPortal = (props: ISelectorMenuPortal) => {

  const {
    menuProps,
    onMenuClose,
    searchPlaceholder,
    search,
    onSearchChange,
    total,
    showSelected,
    isMulti,
    isSelectAll,
    isSelectAllOptions,
  } = props;

  const [inputValue, setInputValue] = useState(search || '');
  const [ checked, setChecked ] = useState<boolean>(false);
  const menuContainerRef = useRef(null);

  useOutsideClick(menuContainerRef, onMenuClose);

  useEffect(() => {
    onSearchChange(inputValue);
  }, [inputValue]);

  useEffect(() => {
    setChecked(isSelectAllOptions);
  }, [ isSelectAllOptions ]);

  const handleSearchChange: ChangeEventHandler<HTMLInputElement>
    = (e) => setInputValue(e.target.value);

  const onClear = () => setInputValue('');

  const onSelectAllItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(check => !check);
    props.onSelectAllItems(e);
  };

  const onClearSelectedItems: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setChecked(false);
    props.onClearSelectedItems();
  };

  const onSearchKeyDown = (e: any) => {
    e.stopPropagation();
  };

  const selectedCount = total ? menuProps.selectProps.value?.length || 0 : 0;
  const intl = useIntl();
  const formattedSelectedCount = intl.formatNumber(selectedCount);
  const formattedTotal = intl.formatNumber(total || 0);

  const selected = useMemo(() => {
    if (!showSelected) { return undefined; }
    const values =  menuProps.selectProps.value || [];
    const selectedValues: INamedEntry[] = values
      .map(({ label, value }: any) => ({ id: value, name: label }));

    if(selectedValues.length === 0) {
      return undefined;
    }



    const handleRemoveValue = (...rest: [INamedEntry, number]) => {
      const [, index] = rest;
      const filtered = values.filter((...childRest: [any, number]) => {
        const [, id] = childRest;
        return id !== index;
      });
      menuProps.setValue(filtered, 'select-option');
    };

    return(
      <div className={styles.selectedContainer}>
        <Pills
          selectedAll={false}
          values={selectedValues}
          morePlaceholder={'Filters.More'}
          withButton={true}
          onButtonClick={handleRemoveValue}
          maxPills={10}
        />
      </div>
    );
  }, [showSelected, menuProps.selectProps.value]);

  return (
    <components.MenuPortal {...menuProps} >
      <div ref={menuContainerRef} className={cn(props.themeType === SelectorTheme.Cobalt && styles.cobalt, props.themeType === SelectorTheme.Blue && styles.blueTheme,"searchWrap")}>
        <div className={styles.searchWrapper}>
          <input
            aria-label={props.inputAriaLabel + ' ' + 'expanded'}
            className={styles.search}
            type={'search'}
            value={inputValue}
            autoFocus={true}
            placeholder={searchPlaceholder || intl.formatMessage({ id: 'Dashboard.UsersActivity.Filter.Roles.Placeholder' })}
            onChange={handleSearchChange}
            onKeyDown={onSearchKeyDown}
          />
          {
            inputValue
              ? (
                <span
                  className={cn(styles.searchIcon, styles.activeIcon)}
                  onClick={onClear}
                >
                  <i className="fas fa-times-circle" />
                </span>
              ) : <span className={styles.searchIcon}><i className="fas fa-search" /></span>

          }
        </div>
        {!!total && (
          <>
            <div className={styles.clearWrapper}>
              {TranslateMessage('MursionPortal.SelectorMenu.SelectedCountOfTotal', {
                formattedSelectedCount,
                formattedTotal
              })}
              {
                isSelectAll && (
                  <a
                    className={styles.clear}
                    href="#"
                    onClick={onClearSelectedItems}
                  >
                    {TranslateMessage('MursionPortal.Button.ClearAll')}
                  </a>
                )
              }
            </div>

            { selected }

          </>
        )}
        {isMulti && isSelectAll && (
          <div className={cn(styles.clearWrapper, styles.multiSelectAll)}>
            <Checkbox
                className={cn(styles.checkbox,  'focusBg')}
                onChange={onSelectAllItems}
                id={`multi-select-all`}
                checked={checked}
              >
                {TranslateMessage('MursionPortal.Label.SelectAll')}
            </Checkbox>
          </div>
        )}

        { props.themeType === SelectorTheme.Cobalt && <hr className={styles.hr} />}

        {menuProps.children}
      </div>
    </components.MenuPortal>
  );
};

export default React.memo(SelectorMenuPortal);
