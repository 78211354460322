import { combineReducers } from 'redux';
import IEnvironment from "src/app/data/client/interfaces/IEnvironment";
import IListDataState from "src/app/data/common/interfaces/IListDataState";
import createListReducer from "src/app/redux/utils/createListReducer";
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import IRestDataInfo from 'src/app/data/common/interfaces/IRestItemInfo';
import createItemReducer from 'src/app/redux/utils/createItemReducer';

export interface IEnvironmentReduxState {
  currentEnvironment: IRestDataInfo<IEnvironment>;
  list: IListDataState<IEnvironment>;
  listByIndustry: IListDataState<IEnvironment>;
  error: IErrorState;
}

export default combineReducers({
  currentEnvironment: createItemReducer('ENVIRONMENT_CURRENT'),
  list: createListReducer('ENVIRONMENTS'),
  listByIndustry: createListReducer('ENVIRONMENTS_BY_INDUSTRY'),
  error: createEntityErrorReducer('ENVIRONMENT'),
});
