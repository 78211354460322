import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import actions from 'src/app/redux/store/actions';
import { usePagedListFetchingWithOptions } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';
import { ProjectStatus } from 'src/app/data/projects/interfaces/IProject';
import { EntitySelector, IEntitySelectorBaseProps } from 'src/components/EntitySelectors';
import { ISelectorOption } from 'src/components/Selector/Selector';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';

export interface IProjectSelectorProps extends IEntitySelectorBaseProps {
  clientIds: string[] | null;
  teamIds?:  string[] | null;
  startDate?: number;
  endDate?: number;
  active: boolean;
  statuses?: ProjectStatus[];
  archive: TListPageArchiveOption;
  ml3WebOnly?: boolean;
}

interface IProjectNamedEntry extends INamedEntry{
  timezoneId?: string | null;
  demandBasedScheduling?: boolean;
}

interface IProjectSelectorOption extends ISelectorOption{
  timezoneId?: string | null;
  demandBasedScheduling?: boolean;
}

const ProjectSelector: FunctionComponent<IProjectSelectorProps> = (props) => {

  const {
    clientIds,
    startDate, endDate,
    active, archive, statuses,
    onChange,
    disabledTitle,
    teamIds,
    ml3WebOnly=false,
    placeholder
  } = props;

  const intl = useIntl();
  const [input, setInput] = useState('');
 
  const itemsInfo = usePagedListFetchingWithOptions(actions.projects.fetchProjectNamesListWithOptions)(
    { clientIds, teamIds, projectName: input, startDate, endDate, ml3WebOnly }
  )({
    page: 0,
    size: 9999,
    projectStatus: statuses,
    archive
  }, 300, active);

  const valueToOption = (item: IProjectNamedEntry) => {
    return {
        value: item.id,
        label: item.name,
        timezoneId: item.timezoneId,
        demandBasedScheduling: item.demandBasedScheduling,
    };
};

const optionToValue = (option: IProjectSelectorOption) => {
    return {
        id: option.value,
        name: option.label,
        timezoneId: option.timezoneId,
        demandBasedScheduling: option.demandBasedScheduling,
    };
};

  useEffect(() => {
    if (!itemsInfo.items.length && !itemsInfo.refreshing) {
      onChange(null);
    }
  }, [itemsInfo.items.length]);

  return (
    <EntitySelector
      {...props}
      valueToOption={valueToOption}
      optionToValue={optionToValue}
      themeType={props.themeType || SelectorTheme.Filter}
      isMulti={props.isMulti !== false}
      isSearchable={props.isSearchable !== false}
      placeholder={placeholder ?? intl.formatMessage({ id: 'Filters.Project' })}
      searchPlaceholder={intl.formatMessage({ id: 'Filters.ProjectPlaceHolderHint' })}
      disabledTitle={disabledTitle || intl.formatMessage({ id: 'Filters.ProjectDisabledTitle' })}
      onInputChange={setInput}
      disabled={!active}
      itemsInfo={itemsInfo}
    />
  );
};

export default React.memo(ProjectSelector);
