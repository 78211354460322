import { ILearnerActivity, ILearnerLockedOut, ILearnerSimulation } from 'src/app/data/client/interfaces/learner';
import RestService from 'src/app/services/rest/RestService/RestService';
import { IListDataExport, IListDataFetch } from 'src/app/data/common/interfaces/fetch';
import { ReleaseLevelValue } from 'src/components/EntitySelectors/ReleaseLevelSelector';

interface IDateRange {
  timeZone?: string;
  fromDate?: number;
  toDate?: number;
}
export interface ILearnerActivityQuery {
  client?: string;
  projectIds?: string[];
  dateRange?: IDateRange;
  provider?: string;
  registrationStatus?: boolean;
  scenariosIds?: string[];
  searchQuery?: string;
  teamIds?: string[];
  isDateRangeEnabled: boolean;
}

export interface ILearnerSimulationQuery {
  billable?: boolean;
  client?: string;
  projectIds?: string[];
  scenarioIds?: string[];
  dateRange?: IDateRange;
  deliveryMode?: string;
  learnerStatus?: string;
  project?: string[];
  provider?: string;
  scenario?: string;
  searchQuery?: string;
  simulationRecorded?: boolean;
  simulationStatus?: string[];
  softwareVersion?: string;
  standardized?: boolean;
  teamIds?: string[];
  simSpecialistStatuses?: string;
}

export interface ILearnerLockedOutQuery {
  clientIds?: string[];
  projectIds?: string[];
  scenarioIds?: string[];
  searchQuery?: string;
  teamIds?: string[];
  startDate?: number;
  endDate?: number;
  lockoutLevel?: ReleaseLevelValue;
}

export interface ICompetencies {
  domain: string;
  skill: string;
}

export interface IStrengthsAndOppotunities{
  type: string;
  avatars: IEventAvatarResponseQuote[];
  impact:string;
  observation:string;
}

export interface IEventAvatarResponseQuote{
  avatarId : string;
  avatarName :string;
  avatarQuote : string;
}

export interface ISurveyMissionResult {
  scenarioOutcome: string;
  result: string;
}
export interface IAnalyticsResults {
  scenarioId: string;
  scenarioTitle: string;
  synopsis: string;
  competencies: ICompetencies[];
  recommendations: string;
  strengthsAndOpportunities: IStrengthsAndOppotunities[];
  theMission: ISurveyMissionResult;
  nameCustomized?: string;
}


/* region learner activity */
const fetchLearnerActivity: IListDataFetch<ILearnerActivityQuery, ILearnerActivity> = (params) => {
  const { pageData, query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/learner/activity`, {
    body: JSON.stringify({ ...pageData, ...query }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};

export interface ILearnerActivityExportQuery  extends ILearnerActivityQuery {
  order: string;
  asc: boolean;
}
const exportLearnerActivity: IListDataExport<ILearnerActivityExportQuery> = (params) => {
  const { query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/learner/activity/export`, {
    body: JSON.stringify({
      ...query,
      page: 0,
      size: 10,
    }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};
/* endregion learner activity */

/* region learner simulations */
type MapLearnerSimulationsSorting = {
  [key in keyof ILearnerSimulation]?: string;
};

export const mapLearnerSimulationsSorting: MapLearnerSimulationsSorting = {
  learnerName: 'LEARNER_NAME',
  provider :'PROVIDER_NAME',
  clientName :'CLIENT_NAME',
  projectName :'PROJECT_NAME',
  scenarioName :'SCENARIO_NAME',
  // learnerEmail :'LEARNER_EMAIL',
  simSpecialistName :'SIM_SPECIALIST_NAME',
  simSpecialistEmail :'SIM_SPECIALIST_EMAIL',
  simulationStartDate :'SIMULATION_STARTDATE',
};

const fetchLearnerSimulations: IListDataFetch<ILearnerSimulationQuery, ILearnerSimulation> = (params) => {
  const { pageData, query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/learner/simulations`, {
    body: JSON.stringify({
      ...pageData,
      ...query,
      order: pageData.order && mapLearnerSimulationsSorting[pageData.order] || pageData.order
    }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};


export interface ILearnerSimulationExportQuery  extends ILearnerSimulationQuery {
  order: string;
  asc: boolean;
}
const exportLearnerSimulations: IListDataExport<ILearnerSimulationExportQuery> = (params) => {
  const { query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/learner/simulations/export`, {
    body: JSON.stringify({
      ...query,
      page: 0,
      size: 10,
    }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};
/* endregion learner simulations */

/* region learner locked out */
export interface ILearnerAndScenarioId {
  learnerId: string;
  scenarioId: string;
}

export interface IReleaseLearnerQuery extends Omit<ILearnerLockedOutQuery, 'lockoutLevel'>   {
  excludeList?: ILearnerAndScenarioId[];
  lockedLearners?: ILearnerAndScenarioId[];
  releaseLevel: ReleaseLevelValue;
}

const fetchLockedOutLearners: IListDataFetch<ILearnerLockedOutQuery, ILearnerLockedOut> = (params) => {
  const { pageData, query, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/session/lockedLearners`, {
    body: JSON.stringify({ ...pageData, ...query }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};

const releaseLockedLearners = (params: IReleaseLearnerQuery): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/unlockLearners`, {
    body: JSON.stringify(params),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'PATCH',
  });
};

export interface ILearnerLockedOutExportQuery  extends ILearnerSimulationQuery {
  order: string;
  asc: boolean;
}
const exportLockedOutLearners: IListDataExport<ILearnerLockedOutExportQuery> = (params) => {
  const { query, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/session/lockedLearners/export`, {
    body: JSON.stringify({
      ...query,
      page: 0,
      size: 9999
    }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};

const fetchAnalyticsResponse = (sessionId: string): Promise<IAnalyticsResults> => {
  return RestService.fetch(`${RestService.REST_URL}/survey/nextGen/sessionReports/results/${sessionId}`, {
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'GET',
  });
};

const logLearnerStatusAsLate = (sessionId: string) => {
  return RestService.fetch(`${RestService.REST_URL}/session/logLearnerStatusAsLate/${sessionId}`, {
    headers: RestService.generateHeaders({
      "Content-Type": "application/json",
    }),
    method: 'POST',
  });
};

/* endregion learner locked out */

export default {
  fetchLearnerActivity,
  fetchLearnerSimulations,
  fetchLockedOutLearners,
  releaseLockedLearners,
  exportLearnerActivity,
  exportLearnerSimulations,
  exportLockedOutLearners,
  fetchAnalyticsResponse,
  logLearnerStatusAsLate,
};
