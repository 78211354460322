import { ILearnerSimulation } from 'src/app/data/client/interfaces/learner';
import RestService from 'src/app/services/rest/RestService';
import { IListDataExport, IListDataFetch } from 'src/app/data/common/interfaces/fetch';
import { ISimulationSummaries } from 'src/app/data/client/interfaces/simulation';
import { stringifyQuery } from 'src/app/data/common/utils/stringifyEntityQuery';

export interface ISimulationSummariesQuery {
  billable?: boolean;
  clientIds?: { value: string[] };
  deliveryModes?: string[];
  endDate?: number;
  filter?: string;
  learnerSessionStatuses?: string[];
  learnerStatuses?: string[];
  licenseeIds?: { value: string[] };
  projectIds?: { value: string[] };
  teamIds?: string[];
  recorded?: boolean;
  scenarioIds?: { value: string[] };
  scenarioVersion?: string[];
  sessionStatuses?: string[];
  simulationStatuses?: string[];
  ssIds?: { value: string[] };
  standardized?: boolean;
  lateReschedule?: boolean;
  startDate?: number;
  tierNames?: { value: string[] };
  timeZone?: string;
  order?: string;
  simulationIds?: { value: string[] };
  provider?: string;
  simSpecialistStatuses?: string;
  onSimulationStatusesChange?: () => void;
}

export interface ISimulationDetailQuery {
  simulationIds?: { value: string[] };
}

const fetchSimulationSummaries: IListDataFetch<ISimulationSummariesQuery, ISimulationSummaries> = (params) => {
  const { pageData, query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simulation/summaries`, {
    body: JSON.stringify({ ...pageData, ...query }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};


const exportSimulationSummaries: IListDataExport<ISimulationSummariesQuery> = (params) => {
  const { query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simulation/summaries/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};


const fetchSimulationDetails: IListDataFetch<ISimulationDetailQuery, ILearnerSimulation> = (params) => {
  const { pageData, query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simulation/details`, {
    body: JSON.stringify({ ...pageData, ...query }),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};

const exportSimulationDetails: IListDataExport<ISimulationDetailQuery> = (params) => {
  const { query, signal } = params;

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simulation/details/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
    signal,
  });
};

const fetchSimulationIds: IListDataFetch<{}, string> = (params) => {
  const { pageData, signal } = params;
  const { page, size, filter: search, ...pageDataExtra } = pageData || {};

  return RestService.fetch(
    `${RestService.DASHBOARD_REST_URL}/simulation/simulationids/${page}/${size}${
      stringifyQuery({ search, ...pageDataExtra })
    }`,
    {
      headers: RestService.generateHeaders(),
      method: 'GET',
      signal,
    }).then((response) => (response ? {
      ...response,
      content: response.content && response?.content[0]?.simulationIds
    } : response)
  );
};


export default {
  fetchSimulationSummaries,
  fetchSimulationDetails,
  fetchSimulationIds,
  exportSimulationSummaries,
  exportSimulationDetails,
};
