import * as React from 'react';
import { FunctionComponent } from 'react';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import { IListRenderItemInfo } from 'src/components/ListView/IListView';
import ListView from 'src/components/ListView/ListView';
import SelectList from '../../../SelectList';
import { IItemPickerListItemProps, IPickerItem } from '../../ItemPicker';

export interface IItemPickerListComponentProps {
  className?: string;
  ItemComponent: React.ComponentClass<IItemPickerListItemProps> | FunctionComponent<IItemPickerListItemProps>;
  items: IPickerItem[];
  fetched: boolean;
  loadingError: IRestError | null;
  loading: boolean;
  onClick: (item: IPickerItem) => void;
}

class ItemPickerListComponent extends React.Component<IItemPickerListComponentProps> {

  private static keyExtractor(item: any, index: number) {
    return `item-picker-item-${index}`;
  }

  public render() {
    return (
      <ListView
        className={this.props.className}
        ListEmptyComponent={this.getEmptyComponent()}
        keyExtractor={ItemPickerListComponent.keyExtractor}
        data={this.props.items}
        refreshing={this.props.loading}
        renderItem={this.renderClientUserItem}
      />
    );
  }

  private renderClientUserItem = ({ item, index }: IListRenderItemInfo<IPickerItem>) => {
    const { ItemComponent } = this.props;
    const onClick = () => {
      this.onClick(item);
    };

    return (
      <SelectList.ListItem
        onClick={onClick}
        disabled={item.disabled}
      >
        <ItemComponent item={item}/>
      </SelectList.ListItem>
    );
  };

  private onClick = (item: IPickerItem) => {
    this.props.onClick(item);
  };

  private getEmptyComponent = () => {
    if (!this.props.fetched) {
      return null;
    }

    if (this.props.loadingError) {
      // todo: render Error
      return (
        <div>{this.props.loadingError.message}</div>
      );
    } else if (!this.props.loading) {
      // Empty list
      return <span/>;
    }

    return null;
  };
}

export default ItemPickerListComponent;
