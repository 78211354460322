import React, {FunctionComponent} from 'react';
import { Modal } from 'react-bootstrap';
import A11yModal from '../A11yModal';
import CtaButton from '../CtaButton/CtaButton';
import styles from './Dialog.css';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import cn from 'classnames';

export interface IDialogProps {
    title: string | React.ReactNode;
    label: string;
    onClose: () => void;
    modalBody: string;
    show: boolean;
    onHide: (event:KeyboardEvent | undefined) => void;
    modalBodyHeading: string;
    isLoading?: boolean;
    className?: string;
    shouldCloseOnOverlayClick?:boolean;
  }

const Dialog: FunctionComponent<IDialogProps> = (props) => {
    const { 
        title, 
        label, 
        onClose, 
        modalBody, 
        show, 
        onHide, 
        modalBodyHeading,
        isLoading = false,
        className,
        shouldCloseOnOverlayClick,
    } = props;

    return (
        <A11yModal 
            size={'sm'} 
            show={show} 
            onHide={onHide} 
            className={cn(styles.dialogModal, className)}
            bodyOpenClassName={styles.confirmationModalContainer}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            <Modal.Header className={styles.modalHeader}>
                <Modal.Title as={'h4'}>{title}</Modal.Title>
            </Modal.Header>
            <LoadingOverlay active={isLoading} className={styles.loading} spinner={isLoading}>
                <Modal.Body className={styles.modalBodyHeading}>
                    <h4>{modalBodyHeading}</h4>
                </Modal.Body>
                <Modal.Body className={styles.modalBody}>
                    <p>{modalBody}</p>
                </Modal.Body>
            </LoadingOverlay>
            <Modal.Footer className={styles.modalFooter}>
                <div>
                    <CtaButton onClick={onClose}>
                        {label}
                    </CtaButton>
                </div>
            </Modal.Footer>
        </A11yModal>
        
    );
};

export default Dialog;