import { DeepPartial, Reducer, StoreCreator } from 'redux';
import RestService from 'src/app/services/rest/RestService/RestService';
import actions from './actions';
import IAuthToken from 'src/app/data/auth/IAuthToken';

const restEnhancer = (next: StoreCreator) => (reducer: Reducer, preloadedState: DeepPartial<any>) => {
  const store = next(reducer, preloadedState);

  // Logout if an Auth Error occurred.
  RestService.on(RestService.EVENTS.AUTH_ERROR, () => {
    // @ts-ignore
    store.dispatch(actions.auth.logOut());
  });

  RestService.on(RestService.EVENTS.AUTH_TOKEN_UPDATED, (token: IAuthToken) => {
    // @ts-ignore
    store.dispatch(actions.auth.saveToken(token, true));
    // @ts-ignore
    store.dispatch(actions.ws.initWebSocketConnection());
  });

  return store;
};

export default restEnhancer;
