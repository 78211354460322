import { AnyAction } from 'redux';
import * as types from './types';
import { ICreateEventInfo } from "src/app/redux/modules/createEventDialog/reducer";

const openCreateEventDialog = (createDialogInfo: ICreateEventInfo): AnyAction => ({
  payload: createDialogInfo,
  type: types.OPEN_CREATE_EVENT_DIALOG,
});

const closeCreateEventDialog = (keepEventInfo?: boolean): AnyAction => ({
  payload: keepEventInfo,
  type: types.CLOSE_CREATE_EVENT_DIALOG,
});

const openScheduleSessionDialog = (createDialogInfo: ICreateEventInfo | null): AnyAction => ({
  payload: createDialogInfo,
  type: types.OPEN_SCHEDULE_SESSION_DIALOG,
});

const closeScheduleSessionDialog = (): AnyAction => ({
  type: types.CLOSE_SCHEDULE_SESSION_DIALOG,
});

export default {
  openCreateEventDialog,
  openScheduleSessionDialog,
  closeCreateEventDialog,
  closeScheduleSessionDialog,
};
