import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import SessionStatusType, { getSessionStatusTypeI18nId } from 'src/app/data/session/interfaces/SessionStatusType';
import { EntitySelector, IEntitySelectorBaseProps, ValueToOptionMapper } from 'src/components/EntitySelectors';
import { getOptionValue } from 'src/app/data/common/utils';


const sessionStatusTypes: SessionStatusType[] = [
  SessionStatusType.BOOKED,
  SessionStatusType.CANCELLED,
  SessionStatusType.LICENSEE_CANCELLED,
  SessionStatusType.EARLY_CANCELLED,
  SessionStatusType.LATE_CANCELLED,
  SessionStatusType.COMPLETED,
  SessionStatusType.ERROR,
  SessionStatusType.MISSED,
  SessionStatusType.NEEDS_REVIEW,
  SessionStatusType.ORPHAN,
  SessionStatusType.PENDING,
  SessionStatusType.RESERVED,
  SessionStatusType.REVIEWED,
  SessionStatusType.RUNNING,
  SessionStatusType.SWAP,
  SessionStatusType.UNDEFINED,
  SessionStatusType.UPCOMING,
  SessionStatusType.WAIF,
];


interface ISessionStatusSelectorProps extends IEntitySelectorBaseProps<SessionStatusType> {
  active?: boolean;
}

const SessionStatusSelector: FunctionComponent<ISessionStatusSelectorProps> = ({ active, ...props }) => {

  const intl = useIntl();

  const valueToOption: ValueToOptionMapper<SessionStatusType, SessionStatusType> = (value) => ({
    value,
    label: intl.formatMessage({ id: getSessionStatusTypeI18nId(value, false) })
  });


  return (
    <EntitySelector
      {...props}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'Filters.SimulationStatus' })}
      searchPlaceholder={intl.formatMessage({ id: 'Filters.SimulationStatusPlaceholder' })}
      disabledTitle={''}
      items={sessionStatusTypes}
      disabled={!active}
      valueToOption={valueToOption}
      optionToValue={getOptionValue}
    />
  );
};

export default React.memo(SessionStatusSelector);
