
import RestService from 'src/app/services/rest/RestService';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { ISupplyManagementQuery, ISupplyManagementSimRosterDashboardItem, ISupplyManagementClientRosterDashboardItem, ISupplyManagementSchedulingDashboardItem, ISupplyManagementSimViewItem, ISupplyManagementSchedulingViewItem } from 'src/app/data/dashboard/ISupplyManagementDashboard';

const fetchDashboardsSupplyManagementSimRoster = (params: IPageRequestParams & { supplyManagementsQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementSimRosterDashboardItem>> => {
  const { pageData, supplyManagementsQuery, signal } = params;

  const query = {
    ...pageData,
    ...supplyManagementsQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/session/getSIMRosterViewReportData`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportDashboardSupplyManagementSimRoster = (params: IAbortableRequestParams & { supplyManagementExportQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementSimRosterDashboardItem>> => {
  const { supplyManagementExportQuery, signal } = params;

  const query = {
    ...supplyManagementExportQuery
  };
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/session/exportSIMRosterViewReportData`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchDashboardsSupplyManagementClientRoster = (params: IPageRequestParams & { supplyManagementsQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementClientRosterDashboardItem>> => {
  const { pageData, supplyManagementsQuery, signal } = params;

  const query = {
    ...pageData,
    ...supplyManagementsQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/session/getClientRosterViewReportData`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportDashboardSupplyManagementClientRoster = (params: IAbortableRequestParams & { supplyManagementExportQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementClientRosterDashboardItem>> => {
  const { supplyManagementExportQuery, signal } = params;

  const query = {
    ...supplyManagementExportQuery
  };
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/session/exportClientRosterViewReportData`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};


// This will return limited group by records. Need to send page:0 and size:100 with every call to not include pagination.
const fetchDashboardsSupplyManagementScheduling = (params: IPageRequestParams & { supplyManagementsQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementSchedulingDashboardItem>> => {
    const { pageData, supplyManagementsQuery, signal } = params;
    const query = {
      ...pageData,
      ...supplyManagementsQuery
    };
  
    return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/session/getSchedulingViewReportData`, {
      body: JSON.stringify(query),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal,
    });
};

const exportDashboardSupplyManagementScheduling = (params: IAbortableRequestParams & { supplyManagementExportQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementSchedulingDashboardItem>> => {
    const { supplyManagementExportQuery, signal } = params;
  
    const query = {
      ...supplyManagementExportQuery
    };
  
    return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/session/exportSchedulingViewReportData`, {
      body: JSON.stringify(query),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal,
    });
};

const fetchDashboardsSupplyManagementSimView = (params: IPageRequestParams & { supplyManagementsQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementSimViewItem>> => {
  const { pageData, supplyManagementsQuery, signal } = params;

  const query = {
    ...pageData,
    ...supplyManagementsQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simworkforce/getSIMView`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportDashboardSupplyManagementSimViewData  = (params: IAbortableRequestParams & { supplyManagementExportQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementSimViewItem>> => {
  const { supplyManagementExportQuery, signal } = params;

  const query = {
    ...supplyManagementExportQuery
  };
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simworkforce/exportSIMView`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchDashboardsSupplyManagementSchedulingView = (params: IPageRequestParams & { supplyManagementsQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementSchedulingViewItem>> => {
  const { pageData, supplyManagementsQuery, signal } = params;

  const query = {
    ...pageData,
    ...supplyManagementsQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simworkforce/getNewSchedulingViewReportData`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportDashboardSupplyManagementSchedulingViewData  = (params: IAbortableRequestParams & { supplyManagementExportQuery: ISupplyManagementQuery }): Promise<IListDataResponse<ISupplyManagementSchedulingViewItem>> => {
  const { supplyManagementExportQuery, signal } = params;

  const query = {
    ...supplyManagementExportQuery
  };
  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/simworkforce/exportNewSchedulingViewReportData`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

export default {
  fetchDashboardsSupplyManagementSimRoster,
  exportDashboardSupplyManagementSimRoster,
  fetchDashboardsSupplyManagementClientRoster,
  exportDashboardSupplyManagementClientRoster,
  fetchDashboardsSupplyManagementScheduling,
  exportDashboardSupplyManagementScheduling,
  fetchDashboardsSupplyManagementSimView,
  exportDashboardSupplyManagementSimViewData,
  fetchDashboardsSupplyManagementSchedulingView,
  exportDashboardSupplyManagementSchedulingViewData
};
