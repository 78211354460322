import * as React from 'react';
import styles from './Tokienizer.css';
import cn from 'classnames';
import Token from 'src/components/Tokenizer/Token';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';

interface ITokenizerProps {
  label?: string;
  labelClassName?: string;
  items: INamedEntry[];
  color?: string;
  className?: string;
}

const Tokenizer = (props: ITokenizerProps) => {
  const {
    label, labelClassName, items, color, className
  } = props;


  return (
    <div className={cn(styles.container, className)}>
      {label &&
        <div className={cn(styles.label, labelClassName)}>
          {label}
        </div>
      }
      <div className={styles.itemsContainer}>
      {
        items.map(item => (
          <Token
            key={`token-${item.id}`}
            entry={item}
            color={color}
          />
        ))
      }
      </div>
    </div>
  );
};

export default Tokenizer;

