import { IAppState } from 'src/app/redux/store/reducers';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';

export const getList = (state: IAppState): ILearnerExtended[] => state.scenarios.learners.list.items;
export const getListPage = (state: IAppState): number => state.scenarios.learners.list.page || 0;
export const getListSizePerPage = (state: IAppState): number => state.scenarios.learners.list.sizePerPage || 0;
export const getListTotalSize = (state: IAppState): number => state.scenarios.learners.list.totalSize || 0;
export const isListFetched = (state: IAppState): boolean => state.scenarios.learners.list.fetched;
export const getListRefreshError = (state: IAppState): IRestError | null => state.scenarios.learners.list.refreshError;
export const isListRefreshing = (state: IAppState): boolean => state.scenarios.learners.list.refreshing;

export const getForSessionList = (state: IAppState): ILearnerExtended[] => state.scenarios.learners.listForEditSession.items;
export const getForSessionListPage = (state: IAppState): number => state.scenarios.learners.listForEditSession.page || 0;
export const getForSessionListSizePerPage = (state: IAppState): number => state.scenarios.learners.listForEditSession.sizePerPage || 0;
export const getForSessionListTotalSize = (state: IAppState): number => state.scenarios.learners.listForEditSession.totalSize || 0;
export const isForSessionListFetched = (state: IAppState): boolean => state.scenarios.learners.listForEditSession.fetched;
export const geForSessiontListRefreshError = (state: IAppState): IRestError | null => state.scenarios.learners.listForEditSession.refreshError;
export const isForSessionListRefreshing = (state: IAppState): boolean => state.scenarios.learners.listForEditSession.refreshing;
