import { combineReducers } from 'redux';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';

export interface ILtiState {
  error: IErrorState;
}


export default combineReducers({
  error: createEntityErrorReducer('LTI'),
});
