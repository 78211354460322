import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { DELIVERY_MODE } from 'src/app/data/common/DeliveryMode';
import { EntitySelector, IEntitySelectorBaseProps, ValueToOptionMapper } from 'src/components/EntitySelectors';
import { getOptionValue } from 'src/app/data/common/utils';

const items: SessionType[] = [
  SessionType.ONE_TO_ONE,
  SessionType.ONE_TO_MANY_REMOTE,
  SessionType.ONE_TO_MANY_LOCAL,
  SessionType.GROUP,
];

export interface IDeliveryModeSelectorProps extends IEntitySelectorBaseProps<SessionType> {
  active?: boolean;
}

const DeliveryModeSelector: FunctionComponent<IDeliveryModeSelectorProps> = (props) => {

  const intl = useIntl();

  const valueToOption: ValueToOptionMapper<SessionType, SessionType> = (value) => ({
    value,
    label: intl.formatMessage({ id: DELIVERY_MODE[value] })
  });

  return (
    <EntitySelector
      {...props}
      isMulti={props.isMulti !== false}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'MursionPortal.Filters.DeliveryMode' })}
      searchPlaceholder={intl.formatMessage({ id: 'MursionPortal.Filters.PlaceHolder.DeliveryMode' })}
      disabledTitle={''}
      defaultValue={[]}
      items={items}
      disabled={!props?.active || false}
      valueToOption={valueToOption}
      optionToValue={getOptionValue}
    />
  );
};

export default React.memo(DeliveryModeSelector);
