import { AnyAction } from 'redux';
import createReducer from 'src/app/redux/utils/createReducer';
import * as types from './types';

export interface IAllocatedTimeBlock {
  startDate: number;
  endDate: number;
  expired: boolean;
}

export interface ISessionWizardState {
  allocatedTimeBlock: IAllocatedTimeBlock | null;
}

const INITIAL_STATE: ISessionWizardState = {
  allocatedTimeBlock: null,
};

export default createReducer(INITIAL_STATE, {
  [types.ALLOCATE_TIMEBLOCK]: (state: ISessionWizardState, action: AnyAction) => ({
    ...state,
    allocatedTimeBlock: action.payload
  }),
  [types.RELEASE_TIMEBLOCK]: (state: ISessionWizardState, action: AnyAction) => ({
    ...state,
    allocatedTimeBlock: state.allocatedTimeBlock ? {
      ...state.allocatedTimeBlock,
      expired:
        state?.allocatedTimeBlock?.startDate === action.payload.startDate
        && state?.allocatedTimeBlock?.endDate === action.payload.endDate
    } : null,
  }),
});