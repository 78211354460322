import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import RestService from 'src/app/services/rest/RestService';
import { IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import IGlobalUser, { IGlobalUserUpdate } from 'src/app/data/global/IGlobalUser';
import { generateFormDataForParts } from 'src/app/data/common/utils/generateFormData';
import { intl } from 'src/i18n/createIntl';


export interface IGlobalUsersQuery {
  roleIds?: string[] | null;
  accepted?: true | false | null;
}

const createNewGlobalUser = (userData: IGlobalUserUpdate): Promise<IGlobalUser> => {
  const body: any = userData;
  const updatedData = { ...body, timezoneId: body.timezoneId || null };

  return RestService.fetch(`${RestService.REST_URL}/user/global`, {
    body: JSON.stringify(updatedData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updateGlobalUser = (userData: IGlobalUserUpdate, password?: string): Promise<IGlobalUser> => {
  const user = { ...userData, timezoneId: userData.timezoneId || null };
  const passwordContainer = password ? { password } : null;

  return RestService.fetch(`${RestService.REST_URL}/user/global/${user.id}`, {
    body: generateFormDataForParts({
      user,
      userId: user.id,
      password: passwordContainer,
    }),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const deleteGlobalUser = (userID: string): Promise<any> => {
  if (!userID) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/global/${userID}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const restoreGlobalUser = (userID: string): Promise<any> => {
  if (!userID) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/global/restore/${userID}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchGlobalUser = (globalUserID: string): Promise<IGlobalUser> => {
  if (!globalUserID) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/global/${globalUserID}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchGlobalUsersWithOptions = (params: IPageRequestParams): Promise<IListDataResponse<IGlobalUser>> => {
  const { pageData, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/user/global/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchGlobalUsers = (pageData: IListPageData, signal: AbortSignal | null = null): Promise<IListDataResponse<IGlobalUser>> => {
  return fetchGlobalUsersWithOptions({ pageData, signal });
};


const fetchGlobalUsersExtWithOptions = (params: IPageRequestParams & { globalUsersQuery: IGlobalUsersQuery }): Promise<IListDataResponse<IGlobalUser>> => {
  const { pageData, globalUsersQuery, signal } = params;

  const query = {
    ...pageData,
    ...globalUsersQuery
  };

  return RestService.fetch(`${RestService.REST_URL}/user/global/list/ext`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchGlobalUsersExt = (pageData: IListPageData, globalUsersQuery: IGlobalUsersQuery, signal: AbortSignal | null = null): Promise<IListDataResponse<IGlobalUser>> => {
  return fetchGlobalUsersExtWithOptions({ pageData, globalUsersQuery, signal });
};

export default {
  createNewGlobalUser,
  deleteGlobalUser,
  restoreGlobalUser,
  fetchGlobalUser,
  fetchGlobalUsers,
  fetchGlobalUsersExt,
  updateGlobalUser,
};