import IClientConfig, { IClientConfigUpdate, IClientVideoRetentionConfig } from 'src/app/data/clientConfig/interfaces/IClientConfig';
import RestService from 'src/app/services/rest/RestService';
import { IAbortableRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import { intl } from 'src/i18n/createIntl';

/**
 * @deprecated
 * To be replaced by fetchClientConfigWithOptions
 */
const fetchClientConfig = (clientId: string, signal: AbortSignal | null = null): Promise<IClientConfig> => {
  return fetchClientConfigWithOptions({ clientId, signal });
};

const updateClientConfig = (configData: IClientConfigUpdate, clientId: string): Promise<IClientConfig> => {
  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/client/${clientId}/config`, {
    body: JSON.stringify(configData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const fetchClientConfigWithOptions = (params: IAbortableRequestParams & { clientId: string }): Promise<IClientConfig> => {
  const { clientId, signal } = params;

  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/client/${clientId}/config`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const updateVideoRetentionFields = (configData: IClientVideoRetentionConfig, clientId: string): Promise<IClientConfig> => {
  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/client/${clientId}/videoRecordingRetentionConfig`, {
    body: JSON.stringify(configData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

export default {
  fetchClientConfig,
  updateClientConfig,
  fetchClientConfigWithOptions,
  updateVideoRetentionFields,
};
