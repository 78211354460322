import { AnyAction, combineReducers } from 'redux';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import createListReducer from 'src/app/redux/utils/createListReducer';
import { ISession } from 'src/app/data/session/interfaces/ISession';
import { getNearestAvailableTimezone } from 'src/app/data/common/utils/calendarUtils';
import moment from 'moment-timezone';
import createReducer from 'src/app/redux/utils/createReducer';
import * as types from './types';

export interface ISessionState {
  list: IListDataState<ISession>;
  listForClient: IListDataState<ISession>;
  listForLearner: IListDataState<ISession>;
  timezone: ITimezoneState;
}

interface ITimezoneState {
  id: string;
}

const INITIAL_TIMEZONE: ITimezoneState = {
  id: getNearestAvailableTimezone(moment.tz.guess()).id,
};

export default combineReducers({
  list: createListReducer('SESSIONS'),
  listForClient: createListReducer('SESSIONS_FOR_CLIENT'),
  listForLearner: createListReducer('SESSIONS_FOR_LEARNER'),
  timezone: createReducer(INITIAL_TIMEZONE, {
    [types.SELECT_TIMEZONE]: (state: ITimezoneState, action: AnyAction) => ({
      ...state,
      id: action.payload || INITIAL_TIMEZONE.id,
    })
  })
});
