import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import ErrorMessage from 'src/components/ErrorMessage/ErrorMessage';
import { TLocaleId } from 'src/i18n';

interface IErrorMsgContainer {
    showErrorMsg: boolean;
    errorMsg: TLocaleId;
    isInputValid: boolean;
}

const ErrorMsgContainer: FunctionComponent<IErrorMsgContainer> = (props) => {
    const { showErrorMsg, errorMsg, isInputValid } = props;
    const intl = useIntl();

    const errorMsgText = isInputValid ? '' : intl.formatMessage({ id: errorMsg });

    return showErrorMsg ? <ErrorMessage message={errorMsgText} /> : null;
};

export default ErrorMsgContainer;
