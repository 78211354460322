import classNames from 'classnames';
import * as React from 'react';
import { ListGroup } from 'react-bootstrap';
import SelectListItem from './SelectListItem';

export interface ISelectListProps {
  className?: string;
}

class SelectList extends React.Component<ISelectListProps> {

  public static ListItem = SelectListItem;

  public render() {
    const containerClassName = classNames([
      this.props.className,
    ]);

    return (
      <ListGroup className={containerClassName}>
        {this.props.children}
      </ListGroup>
    );
  }
}

export default SelectList;
