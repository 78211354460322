import _isArray from 'lodash/isArray';
import { DeepPartial, Reducer, StoreCreator } from 'redux';
import { WSConnectionStatus } from 'src/app/data/common/interfaces/WSConnectionStatus';
import WebSocketService from 'src/app/services/ws/WebSocketService';
import actions from './actions';

const wsEnhancer = (next: StoreCreator) => (reducer: Reducer, preloadedState: DeepPartial<any>) => {
  const store = next(reducer, preloadedState);

  // Logout if an Auth Error occurred.
  WebSocketService.on(WebSocketService.EVENTS.AUTH_ERROR, () => {
    // @ts-ignore
    store.dispatch(actions.auth.logOut());
  });

  WebSocketService.on(WebSocketService.EVENTS.WS_CONNECTED, () => {
    // @ts-ignore
    store.dispatch(actions.ws.updateConnectionStatus(WSConnectionStatus.WS_CONNECTED));
  });

  WebSocketService.on(WebSocketService.EVENTS.WS_DISCONNECTED, () => {
    // @ts-ignore
    store.dispatch(actions.ws.updateConnectionStatus(WSConnectionStatus.WS_DISCONNECTED));
  });

  WebSocketService.on(WebSocketService.EVENTS.WS_NEW_MESSAGE, (data: string) => {
    const dataObj = JSON.parse(data);

    switch (dataObj.action) {
      case 'timeBlockReleased':
        // @ts-ignore
        return store.dispatch(actions.sessionWizard.releaseTimeBlock(dataObj.interval));
      case 'changedObjects':
        // @ts-ignore
        return store.dispatch(actions.ws.updateEntities(!_isArray(dataObj) ? [dataObj] : dataObj));
      case 'logout':
        // @ts-ignore
        return store.dispatch(actions.auth.wsLogOut());
      case 'action':
      default:
        // @ts-ignore
        return store.dispatch(actions.ws.appendNotificationsList(!_isArray(dataObj) ? [dataObj] : dataObj));
    }

  });

  return store;
};

export default wsEnhancer;
