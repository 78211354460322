import { Theme } from 'react-select/dist/declarations/src/types';
import { INITIAL_STYLES, INITIAL_THEME, ISelectorTheme } from 'src/components/Selector/themes/SelectorThemes';

const filterTheme: ISelectorTheme = {

  customStyles: {
    menu: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menu,
      color: '#000',
      backgroundColor: '#fff',
      boxShadow: 'none',
      position: 'relative',
      border: 0,
      padding: 0,
      margin: 0,
    }),
    menuPortal: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menuPortal,
      width: 250,
      backgroundColor: '#fff',
      borderRadius: '3px',
      boxShadow: '0 4px 8px -2px rgba(9,30,66,0.25), 0 0 1px rgba(9,30,66,0.31);'
    }),
    option: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.option,
      backgroundColor: state.isFocused ? '#ccc' : '#fff',
      color: state.isSelected ? '#ff615c' : '#000',
      fontWeight: state.isSelected ? 500 : 'normal',
      width: '100%',
      display: 'flex',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.singleValue,
      backgroundColor: '#fff',
      color: 'rgb(85, 85, 85)',
      transform: 'none',
      display: 'inline-block',
      fontWeight: 600,
      position: 'relative',
      maxWidth: 300,
    }),
    placeholder: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.placeholder,
      color: '#555',
      position: 'relative',
      transform: 'none',
    }),
    input: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.input,
      maxWidth: 300,
      position: 'fixed'
    }),
    container: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.container,
      border: 0,
      display: 'block',
    }),
    control: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.control,
      backgroundColor: 'white',
      fontWeight: 600,
      color: '#555',
      boxShadow: 'none',
      padding: '0',
      cursor: 'pointer',
      border: '1px solid hsla(0, 0%, 56%, 0.68)',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderWidth: '1px',
      minHeight: '2rem',
      marginRight: '0',
    }),
    indicatorSeparator: () => INITIAL_STYLES.indicatorSeparator,
  },

  customTheme: (theme: Theme): Theme => ({
    ...theme,
    ...INITIAL_THEME,
    colors: {
      ...theme.colors,
      ...INITIAL_THEME.colors,
    },
  })
};

export default filterTheme;
