import RestService from 'src/app/services/rest/RestService';
import IProjectsCount from 'src/app/data/client/interfaces/IProjectsCount';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import { ICompany, ICompanyUpdate } from 'src/app/data/licensee/interfaces/ICompany';
import IEntityQuery from 'src/app/data/common/interfaces/IEntityQuery';
import stringifyEntityQuery from 'src/app/data/common/utils/stringifyEntityQuery';
import { IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';

const createNewCompany = (companyData: ICompanyUpdate): Promise<ICompany> => {
  return RestService.fetch(`${RestService.REST_URL}/licensee`, {
    body: JSON.stringify(companyData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updateCompany = (companyId: string, companyData: ICompanyUpdate): Promise<ICompany> => {
  return RestService.fetch(`${RestService.REST_URL}/licensee/${companyId}`, {
    body: JSON.stringify(companyData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const deleteCompany = (companyId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/licensee/${companyId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const fetchCompanyList = (pageData: IListPageData): Promise<IListDataResponse<ICompany>> => {
  return RestService.fetch(`${RestService.REST_URL}/licensee/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchCompany = (companyId: string, query?: IEntityQuery): Promise<ICompany> => {
  return RestService.fetch(`${RestService.REST_URL}/licensee/${companyId}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchProjectsCount = (): Promise<IProjectsCount> => {
  return RestService.fetch(`${RestService.REST_URL}/licensee/projectCount`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchCompanyListWithOptions = (params: IPageRequestParams): Promise<IListDataResponse<ICompany>> => {
  const { pageData, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/licensee/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};


export default {
  createNewCompany,
  deleteCompany,
  fetchCompany,
  fetchCompanyList,
  fetchCompanyListWithOptions,
  fetchProjectsCount,
  updateCompany,
};

