import * as React from "react";
import { FunctionComponent, useRef } from "react";
import styles from "./ToggleButton.css";
import cn from "classnames";
import { SelectorTheme } from "../Selector/themes";

export interface IToggleButtonProps {
  options: Array<{ label: string; value: number | string; dataToggle?: string; role?: string; disabled?: boolean; sup?: any; className?: string, icon?: string }>;
  onChange: (step: any) => void;
  value: number | string;
  ariaLabel?: string;
  role?: string;
  disabled?: boolean;
  className?: string;
  themeType?: SelectorTheme;
}

const ToggleButtonComponent: FunctionComponent<IToggleButtonProps> = (props) => {
  const selectedOption = useRef<HTMLButtonElement>(null);
  const handleOnChange = (value:number | string)=>() => {
    props.onChange(value);
  };

  const handleArrowKeys = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    const selectedItem = props.options.find((o) => o.value === props.value);
    const index = selectedItem ? props.options.indexOf(selectedItem) : -1;
    let count: number | null = null;
    if (index > -1) {
      if (keyboardEvent.key === 'ArrowRight') {
        keyboardEvent.preventDefault();
        if (index < props.options.length - 1) {
          for (let step =index+1; step < props.options.length ; step++) {
            if(!props.options[step].disabled ){
              count = step;
              break;
            }
          }
          if(count !== null){
            props.onChange(props.options[count].value);
            setTimeout(() => {
              if (selectedOption.current) {
                selectedOption.current.focus();
              }
            });
          }
        }
      } else if (keyboardEvent.key === 'ArrowLeft') {
        keyboardEvent.preventDefault();
        if (index > 0) {
          for (let step =index-1; step >= 0 ; step--) {
            if(!props.options[step].disabled){
              count = step;
              break;
            }
          }
          if(count !== null){
            props.onChange(props.options[count].value);
            setTimeout(() => {
              if (selectedOption.current) {
                selectedOption.current.focus();
              }
            });
          }
        }
      }
    }
  };
  
  return (
    <div 
      className={
        cn(styles.toggleButtonWrapper,
        props.className, 
        (props.themeType === SelectorTheme.Cobalt) && styles.cobalt, 
        (props.themeType === SelectorTheme.Blue) && styles.blue,
        (props.themeType === SelectorTheme.Filter) && styles.filter, 
        (props.themeType === SelectorTheme.Dark) && styles.dark)} 
        role="tablist" 
        aria-label={props.ariaLabel
      }>
      {props.options.map((option, index) => {
        return <button
            tabIndex={(props.value === option.value)? 0 : -1}
            className={cn(styles.btn, (props.value === option.value) ? styles.active :  option.disabled && styles.disabled, option.className)}
            data-toggle={option.dataToggle}
            role={option.role}
            aria-current={(props.value === option.value) ? 'true' : 'false'}
            onKeyDown={handleArrowKeys}
            ref={(props.value === option.value) ? selectedOption : null}
            key={`toggle-button-option-${index}`}
            onClick={handleOnChange(option.value)}
            disabled={option.disabled}
          >
            {
              option.icon &&
              <i className={cn('fas', option.icon, styles.toggleButtonIcon)} />
            } 
              {option.label} 
            {
              (option.sup >= 0) &&
              <sup>{option.sup}</sup>
            }
          </button>;
      })}
    </div>
  );
};

export default ToggleButtonComponent;
