import cn from 'classnames';
import * as React from 'react';
import styles from './Separator.css';
import { FunctionComponent } from 'react';

export interface ISeparatorProps {
  color?: string;
  className?: string;
}

const Separator: FunctionComponent<ISeparatorProps> = (props) => {
    return (
      <div
        className={cn(styles.separator, props.className)}
        style={{borderTopColor: props.color || ''}}
      />
    );
};

export default Separator;
