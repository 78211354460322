import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import createListActions from "src/app/redux/utils/createListActions";
import rest from './rest';
import learners from './learners/actions';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';

export default {
  learners,
  fetchClientTeams: createListActions('TEAMS', rest.fetchClientTeamsResponse),
  fetchClientTeamsResponse: actionFromPromise(rest.fetchClientTeamsResponse),
  fetchTeamsListForClientView: createListActions('TEAMS_FOR_CLIENT_VIEW', rest.fetchTeamsListForClientView),
  clearValidationErrors: createClearEntityErrorAction('TEAM'),
  createNewTeam: actionFromPromise(rest.createNewTeam, 'TEAM'),
  deleteTeam: actionFromPromise(rest.deleteTeam),
  restoreTeam: actionFromPromise(rest.restoreTeam),
  fetchTeam: actionFromPromise(rest.fetchTeam),
  fetchTeamName: actionFromPromise(rest.fetchTeamName),
  updateTeam: actionFromPromise(rest.updateTeam, 'TEAM', true),
  updateTeamFacilitators: actionFromPromise(rest.updateTeamFacilitators, 'TEAM'),
  updateTeamLearners: actionFromPromise(rest.updateTeamLearners, 'TEAM'),
  updateTeamTags: actionFromPromise(rest.updateTeamTags, 'TEAM'),

  fetchTeamsForClientWithOptions: actionFromPromise(rest.fetchTeamsForClientWithOptions),
  fetchTeamNamesListWithOptions: actionFromPromise(rest.fetchTeamNamesListWithOptions),
  fetchTeamsForLearner: actionFromPromise(rest.fetchTeamsForLearner, 'TEAMS_FOR_LEARNER', true),

  deprecated__fetchClientTeams: actionFromPromise(rest.fetchClientTeams),
  deprecated__fetchClientViewTeams: actionFromPromise(rest.deprecatedFetchClientViewTeams),
  teamInviteLink: actionFromPromise(rest.teamInviteLink),
  fetchFullTeamsListForClientView: actionFromPromise(rest.fetchTeamsListForClientView),
  updateScenarioTeam: actionFromPromise(rest.updateScenarioTeam),
};
