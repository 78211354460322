import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { SelectorTheme } from 'src/components/Selector';
import { getLearnerStatusLabelTypeI18nId, LearnerStatusType } from 'src/app/data/session/interfaces/SessionStatusType';
import { IEntitySelectorBaseProps } from 'src/components/EntitySelectors/EntitySelectorBase/EntitySelectorBaseProps';
import { EntitySelector, ValueToOptionMapper } from 'src/components/EntitySelectors';
import { getOptionValue } from 'src/app/data/common/utils';


const learnerStatusTypes: LearnerStatusType[] = [
  LearnerStatusType.COMPLETED,
  LearnerStatusType.CONNECTED,
  LearnerStatusType.DECLINED,
  LearnerStatusType.ERROR,
  LearnerStatusType.IMMERSING,
  LearnerStatusType.LATE,
  LearnerStatusType.LEFT,
  LearnerStatusType.MISSED,
  LearnerStatusType.UNABLE_TO_COMPLETE
];


interface ILearnerStatusSelectorProps extends IEntitySelectorBaseProps<LearnerStatusType> {
  active?: boolean;
}

const LearnerStatusSelector: FunctionComponent<ILearnerStatusSelectorProps> = (props) => {

  const intl = useIntl();

  const valueToOption: ValueToOptionMapper<LearnerStatusType, LearnerStatusType> = (value) => ({
    value,
    label: intl.formatMessage({ id: getLearnerStatusLabelTypeI18nId(value) })
  });

  return (
    <EntitySelector
      {...props}
      isMulti={true}
      themeType={props.themeType || SelectorTheme.Cobalt}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'Filters.LearnerStatus' })}
      searchPlaceholder={intl.formatMessage({ id: 'Filters.LearnerStatusPlaceHolderHint' })}
      items={learnerStatusTypes}
      disabled={!props?.active}
      valueToOption={valueToOption}
      optionToValue={getOptionValue}
    />
  );
};

export default React.memo(LearnerStatusSelector);
