import RestService from 'src/app/services/rest/RestService';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import { ILtiConfigListItem, ILtiSchema, ILtiSchemaBase, ILtiSchemaQuery } from 'src/app/data/integrations/lti/ILtiSchema';
import { intl } from 'src/i18n/createIntl';

export interface IInstructorMapping {
  projectId?: string;
  scenarioId: string;
  teamId: string;
  contextId?: string;
  userId?: string;
}

const fetchLTISchemasList = (params: IPageRequestParams & ILtiSchemaQuery): Promise<IListDataResponse<ILtiConfigListItem>> => {
  const { pageData,
    clientIds,
    signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/lti/list`, {
    body: JSON.stringify({
      clientIds: { value: clientIds },
      ...pageData,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json ',
    }),
    method: 'POST',
    signal,
  });
};
const createNewLtiSchema = (ltiSchemaData: ILtiSchemaBase): Promise<ILtiSchemaBase> => {
  const updatedClientData = { ...ltiSchemaData };
  
  return RestService.fetch(`${RestService.REST_URL}/lti`, {
    body: JSON.stringify(updatedClientData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json ',
    }),
    method: 'POST',
  });
};

const updateLtiSchema = (ltiId: string, ltiSchemaData: any,): Promise<ILtiSchemaBase> => {
  const updatedData = { ...ltiSchemaData };

  return RestService.fetch(`${RestService.REST_URL}/lti/${ltiId}`, {
    body: JSON.stringify(updatedData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const deleteLtiRole = (ltiRoleId: number): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/lti/role/${ltiRoleId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const deleteLtiSchema = (ltiId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/lti/${ltiId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const fetchLtiSchemaWithOptions = (params: IAbortableRequestParams & { ltiId: string }): Promise<ILtiSchema> => {
  const { ltiId, signal } = params;

  if (!ltiId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.LtiIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/lti/${ltiId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json ',
    }),
    method: 'GET',
    signal,
  });
};

const createInstructorMapping = ({ contextId, scenarioId, teamId }:IInstructorMapping): Promise<void> => {
  return RestService.fetch(`${RestService.GET_PUBLIC_URL}/lti11/${contextId}/${scenarioId}/${teamId}`, {
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
  });
};

const fetchInstructorMapping = (contextId: string): Promise<IInstructorMapping> => {
  return RestService.fetch(`${RestService.GET_PUBLIC_URL}/lti11/getInstitutionalMapping?contextId=${contextId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const checkInstructorMapping = ({ scenarioId, teamId }: IInstructorMapping): Promise<IInstructorMapping[]> => {
  return RestService.fetch(`${RestService.GET_PUBLIC_URL}/lti11/checkInstitutionalMappingForUser?scenarioId=${scenarioId}&teamId=${teamId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  fetchLTISchemasList,
  createNewLtiSchema,
  updateLtiSchema,
  deleteLtiSchema,
  fetchLtiSchemaWithOptions,
  deleteLtiRole,
  createInstructorMapping,
  fetchInstructorMapping,
  checkInstructorMapping,
};