import RestService from "src/app/services/rest/RestService";
import * as queryString from "querystring";
import _omitBy from "lodash/omitBy";
import _isUndefined from "lodash/isUndefined";
import IEntityHistory, { ISessionHistory } from "src/app/data/common/interfaces/IEntityHistory";

// TODO: add new entities after [BE] implementation
export enum EntityType {
  SESSION = 'Session',
}

export interface IEntityHistoryQuery {
  entityId?: string;
  entityType?: EntityType;
  from?: number;
  to?: number;
  page: number;
  size: number;
}

const fetchEntityHistoryList = (entityHistoryQuery: IEntityHistoryQuery): Promise<Array<IEntityHistory<any>>> => {
  const {
    page,
    size,
    ...rest
  } = entityHistoryQuery;

  const query = queryString.stringify(_omitBy(rest, _isUndefined));

  return RestService.fetch(`${RestService.REST_URL}/history/list/${page}/${size}?${query}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchEntityHistoryBySessionList = (sessionId: string, page: number, size: number): Promise<Array<IEntityHistory<ISessionHistory>>> => {

  return RestService.fetch(`${RestService.REST_URL}/history/session/${sessionId}/${page}/${size}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  fetchEntityHistoryList,
  fetchEntityHistoryBySessionList,
};
