import { AnyAction } from 'redux';
import * as types from './ui/types';
import rest from './rest';
import actionFromPromise from 'src/app/redux/utils/actionFromPromise';

const toggleMessagePanel = (): AnyAction => ({
  type: types.TOGGLE_MESSAGE_PANEL,
});
const showMessagePanel = (): AnyAction => ({
  type: types.SHOW_MESSAGE_PANEL,
});
const hideMessagePanel = (): AnyAction => ({
  type: types.HIDE_MESSAGE_PANEL,
});

const setNewMessageCount = (count: number): AnyAction => ({
  type: types.SET_NEW_MESSAGE_COUNT,
  newMessageCount: count,
});

export default {
  markMessagesAsRead: actionFromPromise(rest.markMessagesAsRead, 'NOTIFICATION_MESSAGES'),
  archiveAllMessages: actionFromPromise(rest.archiveAllMessages, 'NOTIFICATION_MESSAGES'),
  fetchMessagesWithOptions: actionFromPromise(rest.fetchMessagesWithOptions),
  toggleMessagePanel,
  showMessagePanel,
  hideMessagePanel,
  setNewMessageCount,
  fetchAllLearnerInvitations: actionFromPromise(rest.fetchAllLearnerInvitations),
  cancelScheduledInvitation: actionFromPromise(rest.cancelScheduledInvitation),
  viewInvitationsDetailsById: actionFromPromise(rest.viewInvitationsDetailsById),
  getExcludedLearnersInfo: actionFromPromise(rest.getExcludedLearnersInfo),
};
