import * as React from 'react';
import { FunctionComponent } from 'react';
import Modal from 'react-modal';
import styles from './A11yModal.css';
import cn from 'classnames';

export interface IA11yModalProps {
  show: boolean;
  onHide: any;
  size?: 'lg' | 'sm' | 'xs';
  className?: string;
  bodyOpenClassName?: string;
  shouldCloseOnOverlayClick?: boolean;
  a11yModalTheme?: string;
  centerModal?: boolean;
}

const A11yModal: FunctionComponent<IA11yModalProps> = (props) => {
  const customStyles = {
    overlay:{
      backgroundColor: 'rgba(0 0 0 0)',
      zIndex: 1050,
    },
    content : {
      padding  : '0',
      margin: '15px auto',
      height: 'max-content',
      inset: '0',
      top:'0',
      left:'0',
      right: '0',
    },
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.show}
      style={customStyles}
      onRequestClose={props.onHide}
      portalClassName={cn(props.size, styles.a11yModalWrap, props.className, props.a11yModalTheme, props.centerModal && styles.center)}
      bodyOpenClassName={props.bodyOpenClassName}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
    >
      {props.children}
    </Modal>
  );
};

export default A11yModal;
