import IContractsCount from 'src/app/data/client/interfaces/IContractsCount';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import { IAbortableRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import {
  IContractRequest,
  IContractResponse,
  IContractShort,
  IContractShortListRequest,
  IListContractResponse,
  ISSingleContract,
} from 'src/app/data/contract/IContract';
import RestService from 'src/app/services/rest/RestService';

const createNewContract = (contractData: IContractRequest): Promise<IContractResponse> => {

  return RestService.fetch(`${RestService.REST_URL}/contract`, {
    body: JSON.stringify(contractData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const editContract = (contractId: string, contractData: IContractRequest): Promise<IContractResponse> => {

  return RestService.fetch(`${RestService.REST_URL}/contract/${contractId}`, {
    body: JSON.stringify(contractData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const getContractsShortList = (contractShortList: IContractShortListRequest): Promise<IContractShort[]> => {
  return RestService.fetch(`${RestService.REST_URL}/contract/list/short`, {
    body: JSON.stringify(contractShortList),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).catch(console.error);
};

const getContract = (contractId: string): Promise<ISSingleContract> => {
  return RestService.fetch(`${RestService.REST_URL}/contract/${contractId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const archiveContract = (CONTRACT_ID: string) => {
  return RestService.fetch(`${RestService.REST_URL}/contract/${CONTRACT_ID}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const fetchContractsList = (
  pageData: IListPageData,
  clientId: string
): Promise<IListDataResponse<IListContractResponse>> => {
  return RestService.fetch(
    `${RestService.REST_URL}/contract/list${clientId ? '/' + clientId : ''}${getPageDataUrl(pageData)}`,
    {
      headers: RestService.generateHeaders(),
      method: 'GET',
    }
  );
};

const addContractToProject = (CONTRACT_ID: string, projectId: string): Promise<string> => {
  return RestService.fetch(`${RestService.REST_URL}/contract/addTo${projectId ? '/' + projectId : ''}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
    body: CONTRACT_ID,
  });
};

const fetchContractsForProject = (projectId: string): Promise<IContractShort[]> => {
  return RestService.fetch(`${RestService.REST_URL}/contract/getList${projectId ? '/' + projectId : ''}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchContractCountWithOptions = (
  params: IAbortableRequestParams & { clientId: string }
): Promise<IContractsCount> => {
  const { clientId, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/contract/${clientId}/contractCount`, {
    headers: RestService.generateHeaders(),
    method: "GET",
    signal,
  });
};

const fetchContractsCount = (clientID: string): Promise<IContractsCount> => {
  return RestService.fetch(`${RestService.REST_URL}/contract/${clientID}/contractCount`, {
    headers: RestService.generateHeaders(),
    method: "GET",
  });
};

const deleteProjectContract = (projectId: string, contractID: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/contract/${projectId}/remove/${contractID}`, {
    headers: RestService.generateHeaders(),
    method: "DELETE",
  });
};

const restoreContract = (contractId: string): Promise<IListContractResponse> => {
  return RestService.fetch(`${RestService.REST_URL}/contract/restore/${contractId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

export default {
  createNewContract,
  getContractsShortList,
  getContract,
  archiveContract,
  fetchContractsList,
  editContract,
  addContractToProject,
  fetchContractsForProject,
  fetchContractCountWithOptions,
  fetchContractsCount,
  deleteProjectContract,
  restoreContract,
};
