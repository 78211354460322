import React, { FunctionComponent } from 'react';
import actions from 'src/app/redux/store/actions';
import { usePagedListFetchingWithOptions } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { useIntl } from 'react-intl';
import { EntitySelector, IEntitySelectorBaseProps } from 'src/components/EntitySelectors';

export interface IProviderSelectorProps extends IEntitySelectorBaseProps {
  onChange: (val: INamedEntry[] | INamedEntry | null) => void;
  archive: TListPageArchiveOption;
}

const ProviderSelector: FunctionComponent<IProviderSelectorProps> = (props) => {

  const intl = useIntl();

  const itemsInfo = usePagedListFetchingWithOptions(actions.company.fetchCompanyListWithOptions)()({
    page: 0,
    size: 9999,
    archive: props.archive,
  });

  return (
    <EntitySelector
      {...props}
      themeType={props.themeType || SelectorTheme.Filter}
      isMulti={props.isMulti !== false}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'MursionPortal.Filter.ProviderAll' })}
      searchPlaceholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.SearchProviders' })}
      valuePlaceholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.ProvidersValue' })}
      itemsInfo={itemsInfo}
    />
  );
};

export default React.memo(ProviderSelector);
