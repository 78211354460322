import * as queryString from "querystring";
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import ICompanyUser, { ICompanyUserUpdate } from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import RestService from 'src/app/services/rest/RestService';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import { generateFormDataForParts } from 'src/app/data/common/utils/generateFormData';
import ITimeInterval from 'src/app/data/common/interfaces/ITimeInterval';
import { intl } from 'src/i18n/createIntl';

const createNewUser = (userData: ICompanyUserUpdate): Promise<any> => {
  const updatedUserData = {...userData, timezoneId: userData.timezoneId || null};

  return RestService.fetch(`${RestService.REST_URL}/user/licensee`, {
    body: JSON.stringify(updatedUserData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const deleteUser = (userID: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/user/licensee/${userID}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
};

const restoreUser = (userID: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/user/licensee/restore/${userID}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updateUser = (userData: ICompanyUserUpdate, password?: string, scenarioId?: string): Promise<any> => {
  const user = {...userData, timezoneId: userData.timezoneId || null};
  const passwordContainer = password ? { password } : null;

  return RestService.fetch(`${RestService.REST_URL}/user/licensee/${user.id}`, {
    body: generateFormDataForParts({
      user,
      password: passwordContainer,
      scenarioId
    }),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const fetchUser = async (userID: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/user/licensee/${userID}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  });
};

const fetchUsersList = (pageData: IListPageData, licenseeId: string, filterParams?: any): Promise<IListDataResponse<ICompanyUser>> => {
  if (!licenseeId){
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/user/licensee/${licenseeId}/list${getPageDataUrl(pageData, filterParams)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchUserSummary = (): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/user/licensee/summaryRoles`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  })
    .then((userCounts) => {
      return userCounts || {};
    });
};

const fetchFullClientProjectsList = (clientID: string): Promise<INamedEntry[]> => {
  // todo: change entry point after backend implementation
  return RestService.fetch(`${RestService.REST_URL}/project/list/${clientID ? clientID + '/' : ''}0/1000`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  })
    .then((listResponse) => {
      return listResponse.content || [];
    });
};

const fetchClientList = (pageData: IListPageData): Promise<IListDataResponse<INamedEntry>> => {
  return RestService.fetch(`${RestService.REST_URL}/client/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  })
    .then((listResponse) => {
      return listResponse || [];
    });
};

const fetchUserAvailableTime = (from: number, to: number, excludeEventId?: string): Promise<ITimeInterval[]> => {
  const params: any = {
    from,
    to,
  };

  if (excludeEventId) {
    params.excludeEvents = [excludeEventId];
  }

  return RestService.fetch(`${RestService.REST_URL}/user/licensee/nonBusyTime?${queryString.stringify(params)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updatePracticeSims = (payload: {simsToAdd: string[], simsToRemove: string[]}, certificateId: string | undefined): Promise<number> => {
  return RestService.fetch(`${RestService.REST_URL}/user/licensee/practice-certificate/${certificateId}/scenarioId`, {
    body: JSON.stringify(payload),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

export default {
  createNewUser,
  deleteUser,
  restoreUser,
  fetchFullClientProjectsList,
  fetchClientList,
  fetchUser,
  fetchUsersList,
  updateUser,
  fetchUserSummary,
  fetchUserAvailableTime,
  updatePracticeSims,
};
