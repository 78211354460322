import { combineReducers } from 'redux';
import createItemReducer from 'src/app/redux/utils/createItemReducer';
import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import IRestDataInfo from 'src/app/data/common/interfaces/IRestItemInfo';

export interface IClientsConfigState {
  data: IRestDataInfo<IClientConfig>;
  dataForDashboard: IRestDataInfo<IClientConfig>;
  dataForEditSession: IRestDataInfo<IClientConfig>;
}

export default combineReducers({
  data: createItemReducer('CLIENT_CONFIG_FOR_CLIENTS'),
  dataForDashboard: createItemReducer('DASHBOARD_CLIENT_CONFIG'),
  dataForEditSession: createItemReducer('SESSION_CLIENT_CONFIG'),
});
