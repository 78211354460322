import { combineReducers } from 'redux';
import IContractsCount from 'src/app/data/client/interfaces/IContractsCount';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import IRestDataInfo from 'src/app/data/common/interfaces/IRestItemInfo';
import { IListContractResponse } from 'src/app/data/contract/IContract';
import createEntityErrorReducer from '../../utils/createEntityErrorReducer';
import createItemReducer from '../../utils/createItemReducer';
import createListReducer from '../../utils/createListReducer';

export interface IContractsReduxState {
    list: IListDataState<IListContractResponse>;
    error: IErrorState;
    projectsCount:IRestDataInfo<IContractsCount>;
  }

export default combineReducers({
    list: createListReducer('CONTRACTS'),
    error: createEntityErrorReducer('CONTRACTS'),
    projectsCount: createItemReducer('CLIENT_CONTRACTS_COUNT'),
  });