import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import RestService from 'src/app/services/rest/RestService';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import { ISessionForLearnerList } from 'src/app/data/session/interfaces/ISession';
import { ILearnerPersonalizationMessage, PersonalizationMessageType, IPersonalizationMsgCreate, IExtendedPersonalizationMsgUpdate } from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/PersonalizationCommunicationContainer';
import { IPersonalizationMessagesMetadata } from 'src/layouts/mursion/ProviderSettings/components/PersonalizationCommunication/components/PersonalizationMessagesMetadata/PersonalizationMessagesMetadata';
import { IScenarioShort } from 'src/app/data/projects/interfaces/IScenario';
import { IProjectShort } from 'src/app/data/projects/interfaces/IProject';

const fetchScenarioAllLearners = (scenarioID: string): Promise<ILearnerExtended[]> => {
  return RestService.fetch(`${RestService.REST_URL}/user/learner/listForScenario/${scenarioID}/0/9999`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((response) => response.content);
};

const fetchScenarioLearners = (pageData: IListPageData, scenarioID: string): Promise<IListDataResponse<ILearnerExtended>> => {
  return RestService.fetch(`${RestService.REST_URL}/user/learner/listForScenario/${scenarioID}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchScenarioListWithLearners = (scenarioID: string): Promise<ISessionForLearnerList[]> => {
  return RestService.fetch(`${RestService.REST_URL}/session/scenario/${scenarioID}/learners`, {
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
  });
};

const fetchScenarioAllLearnersOnly = (scenarioID: string): Promise<ILearnerExtended[]> => {
  return RestService.fetch(`${RestService.REST_URL}/user/learnerOnly/listForScenario/${scenarioID}/0/9999`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((response) => response.content);
};

const fetchLearnerPersonalizedMessagesMetadata = (
  pageData: IListPageData,
  id: number
): Promise<IListDataResponse<IPersonalizationMessagesMetadata>> => {
  const query = {
    ...pageData,
    id
  };

  return RestService.fetch(`${RestService.REST_URL}/learnerPersonalization/auditList`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
  });
};

const fetchPersonalizedMessage = (clientID: string, messageType: PersonalizationMessageType | string = '', scenarioID: string = ''): Promise<ILearnerPersonalizationMessage> => {
  return RestService.fetch(`${RestService.REST_URL}/learnerPersonalization/getMessages/${clientID}/?messageType=${messageType}&scenarioId=${scenarioID}`, {
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'GET',
  });
};

const addPersonalizedMessage = (PersonalizationMsgData: IPersonalizationMsgCreate | IExtendedPersonalizationMsgUpdate, methodType: string) => {
  return RestService.fetch(`${RestService.REST_URL}/learnerPersonalization`, {
    body: JSON.stringify(PersonalizationMsgData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: methodType,
  });
};

const getPersonalizedScenarioList = (clientId: string, projectId: string | number): Promise<{scenarios: IScenarioShort[]}> => {
  return RestService.fetch(`${RestService.REST_URL}/learnerPersonalization/getScenarios/client/${clientId}/project/${projectId}`, {
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'GET',
  });
};

const deletePersonalizedMessage = (messageId: number) => {
  return RestService.fetch(`${RestService.REST_URL}/learnerPersonalization/delete/${messageId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const getPersonalizedPathwayList = (clientId: string): Promise<{projects: IProjectShort[]}> => {
  return RestService.fetch(`${RestService.REST_URL}/learnerPersonalization/getProjects/client/${clientId}`, {
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'GET',
  });
};

export default {
  fetchScenarioAllLearners,
  fetchScenarioLearners,
  fetchScenarioListWithLearners,
  fetchScenarioAllLearnersOnly,
  fetchLearnerPersonalizedMessagesMetadata,
  fetchPersonalizedMessage,
  addPersonalizedMessage,
  getPersonalizedScenarioList,
  deletePersonalizedMessage,
  getPersonalizedPathwayList,
};
