import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import createListActions from 'src/app/redux/utils/createListActions';
import scenariosRest from './rest';
import learners from './learners/actions';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';
import createItemActions from 'src/app/redux/utils/createItemActions';
import { AnyAction } from 'redux';
import * as types from './types';

const resetCurrentScenario = (): AnyAction => ({
  type: 'SCENARIO_CURRENT:RESET_ITEM'
});

export default {
  learners,
  clearValidationErrors: createClearEntityErrorAction('SCENARIO'),
  createScenario: actionFromPromise(scenariosRest.createScenario, 'SCENARIO'),
  deleteScenario: actionFromPromise(scenariosRest.deleteScenario),
  restoreScenario: actionFromPromise(scenariosRest.restoreScenario),
  updateScenarioTemplate: actionFromPromise(scenariosRest.updateScenarioTemplate),
  updateScenarioTemplateAttachments: actionFromPromise(scenariosRest.updateScenarioTemplateAttachments),
  updateScenarioDraft: actionFromPromise(scenariosRest.updateScenarioDraft, 'SCENARIO', true),
  updateScenarioAttachments: actionFromPromise(scenariosRest.updateScenarioAttachments, 'SCENARIO', true),
  updateScenarioPlanning: actionFromPromise(scenariosRest.updateScenarioPlanning, 'SCENARIO', true),
  updateScenarioPlanningTeams: actionFromPromise(scenariosRest.updateScenarioPlanningTeams, 'SCENARIO', true),
  fetchClientScenariosList: createListActions('SCENARIOS_BY_CLIENT', scenariosRest.fetchClientScenariosList),
  fetchProjectScenariosList: createListActions('SCENARIOS_BY_PROJECT', scenariosRest.fetchProjectScenariosList),
  fetchClientScenarios: actionFromPromise(scenariosRest.fetchClientScenarios),
  fetchProjectScenarios: actionFromPromise(scenariosRest.fetchProjectScenarios),
  fetchTeamScenariosList: createListActions('SCENARIOS_BY_TEAM', scenariosRest.fetchTeamScenariosList),
  fetchScenario: createItemActions('SCENARIO_CURRENT', scenariosRest.fetchScenario),
  fetchScenarioById: actionFromPromise(scenariosRest.fetchScenario),
  fetchScenarioName: actionFromPromise(scenariosRest.fetchScenarioName),
  repeatScenario: actionFromPromise(scenariosRest.repeatScenario, 'SCENARIO', true),
  deprecated__fetchScenario: actionFromPromise(scenariosRest.fetchScenario),
  createScenarioFromTemplate: actionFromPromise(scenariosRest.createScenarioFromTemplate, 'SCENARIO', true),
  createScenarioTemplate: actionFromPromise(scenariosRest.createScenarioTemplate, 'SCENARIO'),
  fetchScenarioTemplate: actionFromPromise(scenariosRest.fetchScenarioTemplate),
  deleteScenarioTemplate: actionFromPromise(scenariosRest.deleteScenarioTemplate),
  restoreScenarioTemplate: actionFromPromise(scenariosRest.restoreScenarioTemplate),
  fetchScenarioTemplatesList: actionFromPromise(scenariosRest.fetchScenarioTemplatesList),
  fetchScenarioTemplatesListToStore: createListActions('SCENARIOS_TEMPLATES', scenariosRest.fetchScenarioTemplatesList),
  notifyScenarioUpdate: (): AnyAction => ({
    type: types.UPDATE_CURRENT_SCENARIO,
  }),
  notifyScenarioIsUpdated: (): AnyAction => ({
    type: types.CURRENT_SCENARIO_UPDATED,
  }),
  fetchTemplateScenarioList: actionFromPromise(scenariosRest.fetchTemplateScenarioList),
  fetchScenarioAttachmentLink: actionFromPromise(scenariosRest.fetchScenarioAttachmentLink),
  fetchScenarioTemplateAttachmentLink: actionFromPromise(scenariosRest.fetchScenarioTemplateAttachmentLink),
  fetchActiveScenarios: actionFromPromise(scenariosRest.fetchActiveScenarios),
  resetCurrentScenario,
  fetchScenariosShortList: actionFromPromise(scenariosRest.fetchScenariosShortList),

  fetchScenarioWithOptions: actionFromPromise(scenariosRest.fetchScenarioWithOptions),
  fetchScenarioNamesListWithOptions: actionFromPromise(scenariosRest.fetchScenarioNamesListWithOptions),
  fetchProjectScenariosWithOptions: actionFromPromise(scenariosRest.fetchProjectScenariosWithOptions),
  fetchProjectScenarioCountWithOptions: actionFromPromise(scenariosRest.fetchProjectScenarioCountWithOptions),
  fetchScenariosWithSequence: actionFromPromise(scenariosRest.fetchScenariosWithSequence),
  updateScenarioSequence: actionFromPromise(scenariosRest.updateScenarioSequence),
  fetchItemBankList: actionFromPromise(scenariosRest.fetchItemBankList),
  saveItemBankData: actionFromPromise(scenariosRest.saveItemBankData),
  fetchItemBankDetails: actionFromPromise(scenariosRest.fetchItemBankDetails),
  updateItemBankData: actionFromPromise(scenariosRest.updateItemBankData),
  fetchPublisherListWithOptions: actionFromPromise(scenariosRest.fetchPublisherListWithOptions),
  saveResourceBuildingBlockData: actionFromPromise(scenariosRest.saveResourceBuildingBlockData),
  updateResourceBuildingBlockData: actionFromPromise(scenariosRest.updateResourceBuildingBlockData),
  fetchResourceBuildingBlockData: actionFromPromise(scenariosRest.fetchResourceBuildingBlockData),
  fetchItemBankListForBasicBlock: actionFromPromise(scenariosRest.fetchItemBankListForBasicBlock),
  deleteArchiveItemBankData: actionFromPromise(scenariosRest.deleteArchiveItemBankData),
  patchUnarchiveItemBankData: actionFromPromise(scenariosRest.patchUnarchiveItemBankData),
  fetchPathwayList: actionFromPromise(scenariosRest.fetchPathwayList),
  savePathwayData: actionFromPromise(scenariosRest.savePathwayData),
  updatePathwayData: actionFromPromise(scenariosRest.updatePathwayData),
  fetchPathwayDetails: actionFromPromise(scenariosRest.fetchPathwayDetails),
};
