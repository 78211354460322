import { AnyAction } from 'redux';
import createReducer from 'src/app/redux/utils/createReducer';
import * as types from './types';

export interface ILayoutReduxState {
  clientHeader: string;
  companyHeader: string;
  projectHeader: string;
  teamHeader: string;
  ssoSchemaHeader: string;
}

const INITIAL_STATE: ILayoutReduxState = {
  clientHeader: '',
  companyHeader: '',
  projectHeader: '',
  teamHeader: '',
  ssoSchemaHeader: '',
};

export default createReducer(INITIAL_STATE, {
  [types.CHANGE_CLIENT_HEADER]: (state: ILayoutReduxState, action: AnyAction) => ({
    ...state,
    clientHeader: action.payload,
  }),
  [types.CHANGE_COMPANY_HEADER]: (state: ILayoutReduxState, action: AnyAction) => ({
    ...state,
    companyHeader: action.payload,
  }),
  [types.CHANGE_PROJECT_HEADER]: (state: ILayoutReduxState, action: AnyAction) => ({
    ...state,
    projectHeader: action.payload,
  }),
  [types.CHANGE_TEAM_HEADER]: (state: ILayoutReduxState, action: AnyAction) => ({
    ...state,
    teamHeader: action.payload,
  }),
  [types.CHANGE_SSO_SCHEMA_HEADER]: (state: ILayoutReduxState, action: AnyAction) => ({
    ...state,
    ssoSchemaHeader: action.payload,
  }),
  [types.RESET_ALL_HEADERS]: (state: ILayoutReduxState) => ({
    ...state, ...INITIAL_STATE
  }),
});
