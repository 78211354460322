import { IAppState } from 'src/app/redux/store/reducers';
import IRestError, { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import { IListContractResponse } from 'src/app/data/contract/IContract';
import IContractsCount from 'src/app/data/client/interfaces/IContractsCount';

export const getContractsList = (state: IAppState): IListContractResponse[] => state.contracts.list.items;
export const getContractsListPage = (state: IAppState): number => state.contracts.list.page || 0;
export const getContractsListSizePerPage = (state: IAppState): number => state.contracts.list.sizePerPage || 0;
export const getContractsListTotalSize = (state: IAppState): number => state.contracts.list.totalSize || 0;
export const getContractsListRefreshError = (state: IAppState): IRestError | null => state.projects.list.refreshError;
export const isContractsListFetched = (state: IAppState): boolean => state.contracts.list.fetched;
export const isContractsListRefreshing = (state: IAppState): boolean => state.contracts.list.refreshing;

export const getContractValidationErrors = (state: IAppState): IErrorValidation[] => state.contracts.error.validations;
export const getErrorMessage = (state: IAppState): string => state.contracts.error.message;
export const getContractsCount = (state: IAppState): IContractsCount | null => state.contracts.projectsCount.item;