import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useLaunchDarkly } from 'src/hooks/useLaunchDarkly';
import { LaunchDarklyType } from 'src/layouts/common/LaunchDarkly/LaunchDarklyType';
import { getS3BucketURL } from 'src/s3bucketurls/S3BucketUrlUtils';

import styles from './IVSBrowserWarning.css';

export function useUnsupportedBrowser() {
  const [unsupported, setUnsupported] = useState(false);
 
  const ivsEnabled = useLaunchDarkly(LaunchDarklyType.MUR_70_MAGIC_IVS);
  
  useEffect(() => {
    if (ivsEnabled) {
      checkBrowser();
    }
  }, [ivsEnabled]);

  function checkBrowser() {
    // we're only looking for computers, not ipads or iphones
    if ('ontouchstart' in window) { return; } 
    
    const userAgent = window.navigator.userAgent;
    const macRegex = new RegExp("mac os", "i");
    const isMacOS = macRegex.test(userAgent);
    if (!isMacOS) { return; } 

    const safariRegex = /17.\d*.\d* safari/i;
    const isSafari17 = safariRegex.test(userAgent);
    if (isSafari17) { setUnsupported(true); }
  }

  return unsupported;
}

const IVSBrowserWarning = () => {
  const intl = useIntl();
  const isUnsupported = useUnsupportedBrowser();

  if (isUnsupported) {
    return (
      <div className={styles.container}>
        <div className={styles.message}>
          <img src={getS3BucketURL('images', 'app/InfoError.svg')} className={styles.warningIcon} />
          <div>
            <h4>
              {intl.formatMessage({ id: 'MursionPortal.IVSBrowserWarning.Safari17.Message1' })}
            </h4>
            <p>
              {intl.formatMessage({ id: 'MursionPortal.IVSBrowserWarning.Safari17.Message2' })}
            </p>
          </div>
        </div>

        <div className={styles.linkContainer}>
          <a href="https://www.mozilla.org/en-US/firefox/" target="_blank">
            <img src={getS3BucketURL('images','app/firefoxIcon.svg')} />
            {intl.formatMessage({ id: 'MursionPortal.IVSBrowserWarning.Safari17.Firefox' })}
          </a>
          <a href="https://www.google.com/chrome/" target="_blank">
          <img src={getS3BucketURL('images','app/chromeIcon.svg')} />
          <span className={styles.chrome}>
              {intl.formatMessage({ id: 'MursionPortal.IVSBrowserWarning.Safari17.Chrome' })}
            </span>
            <span className={styles.recommended}>
              {intl.formatMessage({ id: 'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle' })}
            </span>
          </a>
          <a href="https://www.microsoft.com/en-us/edge" target="_blank">
          <img src={getS3BucketURL('images','app/edgeIcon.svg')} />
            {intl.formatMessage({ id: 'MursionPortal.IVSBrowserWarning.Safari17.Edge' })}
          </a>
        </div>
      </div>
    );
  }

  return null;
};

export default IVSBrowserWarning;
