import _find from 'lodash/find';
import { IAppState } from 'src/app/redux/store/reducers';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import moment from 'moment-timezone';
import { getNearestAvailableTimezone } from 'src/app/data/common/utils/calendarUtils';
import { LOCALES } from 'src/i18n';

const getClientId = (state: IAppState): string => {
  return state.profile ? (state.profile.clientId || '') : '';
};

const getCurrentUserRole = (state: IAppState): IUserRoleExtended | null => {
  const userProfile = getUserProfile(state);

  if (!userProfile) {
    return null;
  }

  return _find(userProfile.roles, (role: IUserRoleExtended) => role.id === userProfile.currentRoleId) || null;
};

const getLicenseeId = (state: IAppState): string => {
  return state.profile ? state.profile.licenseeId || '' : '';
};

const getUserProfile = (state: IAppState): IUserProfile | null => {
  return state.profile;
};

const getUserTimeZoneId = (state: IAppState): string =>{
  return (state.profile && state.profile.timezoneId)
    ? state.profile.timezoneId
    : getNearestAvailableTimezone(moment.tz.guess()).id;
};

const getUserLanguage = (state: IAppState): string =>{
  let locale = (state.profile?.lang) ? state.profile.lang : navigator.language;
  const queryValue = window.location.search;
  const urlParams = new URLSearchParams(queryValue);
  const userLanguage = urlParams.get('lang');
  if (userLanguage) {
    locale = userLanguage;
  }
  const supportedLanguages = Object.values(LOCALES);
  const index = supportedLanguages.indexOf(locale);
  if (index > -1) {
    return supportedLanguages[index];
  } else {
    return supportedLanguages[0];
  }
};

const getUserId = (state: IAppState): string => state?.profile?.id || '';

const isUserProfileLoaded = (state: IAppState): boolean => !!(getUserProfile(state));

const getUserName = (state: IAppState): string => {
  return state.profile ? `${state.profile.firstName} ${state.profile.lastName}` : '';
};

const getUserEmail = (state: IAppState): string => {
  return state.profile ? state.profile.email || "" : "";
};

const getClientName = (state: IAppState): string => {
  return state.profile ? (state.profile.clientName || '') : '';
};

const getAllRolesOfUser = (state: IAppState): IUserRoleExtended[] => {
  return state.profile?.roles ? state.profile?.roles : [];
};

const isUserOnboarded = (state: IAppState) => {
  return state.profile?.onboarded;
};

export {
  getUserId,
  getClientId,
  getCurrentUserRole,
  getLicenseeId,
  getUserProfile,
  isUserProfileLoaded,
  getUserTimeZoneId,
  getUserLanguage,
  getUserName,
  getUserEmail,
  getClientName,
  getAllRolesOfUser,
  isUserOnboarded,
};
