import { AnyAction } from 'redux';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import * as types from './types';
import ITimeline from 'src/app/data/common/interfaces/ITimeline';
import IUser from 'src/app/data/common/interfaces/IUser';
import { EntityFilter } from 'src/app/data/calendar/interfaces/EntityFilter';
import { CalendarEventFilter } from 'src/layouts/common/Calendar/components/CalendarEventsFilter';
import ICalendarRange from 'src/layouts/common/Calendar/components/common/ICalendarRange';

const applyClients = (clientIds: string[]): AnyAction => ({
  payload: clientIds,
  type: types.APPLY_CLIENTS,
});

const applyProjects = (projectIds: string[]): AnyAction => ({
  payload: projectIds,
  type: types.APPLY_PROJECTS,
});

const applyFilters = (filters: CalendarEventFilter[]): AnyAction => ({
  payload: filters,
  type: types.APPLY_FILTERS,
});

const applySimspecialists = (simspecialistsIds: string[]): AnyAction => ({
  payload: simspecialistsIds,
  type: types.APPLY_SIMSPECIALIST
});

const selectClient = (clientId: string): AnyAction => ({
  payload: clientId,
  type: types.SELECT_CLIENT,
});

const selectProject = (projectId: string): AnyAction => ({
  payload: projectId,
  type: types.SELECT_PROJECT,
});

const selectScenario = (scenarioId: string): AnyAction => ({
  payload: scenarioId,
  type: types.SELECT_SCENARIO,
});

const selectTimezone = (timezoneId: string): AnyAction => ({
  payload: timezoneId,
  type: types.SELECT_TIMEZONE,
});

const selectSimSpecialist = (simspecialist: string): AnyAction => ({
  payload: simspecialist,
  type: types.SELECT_SIMSPECIALIST,
});

const calendarReset = (): AnyAction => ({
  type: types.RESET,
});

const setAvailableLearnersCount = (learnersCount: number): AnyAction => ({
  payload: learnersCount,
  type: types.SET_AVAILABLE_LEARNERS_COUNT,
});

const setCertificateId = (certificateId: string): AnyAction => ({
  payload: certificateId,
  type: types.SET_CERTIFICATE_ID,
});

const setDeliveryMode = (deliveryMode: SessionType | ''): AnyAction => ({
  payload: deliveryMode,
  type: types.SET_DELIVERY_MODE,
});

const setScenarioTimeline = (timeline: ITimeline): AnyAction => ({
  payload: timeline,
  type: types.SET_SCENARIO_TIMELINE,
});

const setSelectedLearners = (selectedLearners: IUser[]): AnyAction => ({
  payload: selectedLearners,
  type: types.SET_SELECTED_LEARNERS,
});

const setSessionLength = (sessionLength: number): AnyAction => ({
  payload: sessionLength,
  type: types.SET_SESSION_LENGTH,
});

const closeOverviewPanel = (): AnyAction => ({
  type: types.CLOSE_OVERVIEW_PANEL,
});

const setSchedulingBlocked = (blocked: boolean): AnyAction => ({
  payload: blocked,
  type: types.SET_SCHEDULING_BLOCKED,
});

const setEntityFilter = (filter: EntityFilter | null): AnyAction => ({
  payload: filter,
  type: types.CHANGE_ENTITY_FILTER,
});

const toggleEventViewDialog = (isOpen: boolean): AnyAction =>({
  payload: isOpen,
  type: types.TOGGLE_EVENT_VIEW_DIALOG,
});

const setRange = (range: ICalendarRange): AnyAction =>({
  payload: range,
  type: types.SET_CURRENT_RANGE,
});

const setDate = (date: number | null): AnyAction =>({
  payload: date,
  type: types.SET_CURRENT_DATE,
});

const changeEntitySearchFilter = (search: string): AnyAction =>({
  search,
  type: types.CHANGE_ENTITY_SEARCH_FILTER,
});

const clearEntitySearchFilters = (): AnyAction =>({
  type: types.CLEAR_ENTITY_SEARCH_FILTERS,
});

const changeEntityIndustriesFilters = (industriesIds: string[] | null): AnyAction =>({
  industriesIds,
  type: types.CHANGE_ENTITY_INDUSTRIES_FILTER,
});

export default {
  applyClients,
  applyFilters,
  applyProjects,
  applySimspecialists,
  calendarReset,
  closeOverviewPanel,
  selectClient,
  selectProject,
  selectScenario,
  selectSimSpecialist,
  selectTimezone,
  setAvailableLearnersCount,
  setEntityFilter,
  setCertificateId,
  setDeliveryMode,
  setScenarioTimeline,
  setSelectedLearners,
  setSessionLength,
  setSchedulingBlocked,
  toggleEventViewDialog,
  setRange,
  setDate,
  changeEntitySearchFilter,
  clearEntitySearchFilters,
  changeEntityIndustriesFilters
};
