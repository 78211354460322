import React from 'react';
import styles from 'src/components/UnavailableSlots/UnavailableSlotsError.css';
import { useIntl } from 'react-intl';
import { getS3BucketURL } from 'src/s3bucketurls/S3BucketUrlUtils';

const UnavailableSlotsError = () => {
  const intl = useIntl();

  return (
    <div className={styles.noBookingTabMsg}>
      <img src={getS3BucketURL('images','app/learner.gif')} alt={intl.formatMessage({ id: 'MursionPortal.UnavailableSlotsError.SorryText' })} />
      <div className={styles.sorryText}>{intl.formatMessage({ id: 'MursionPortal.UnavailableSlotsError.SorryText' })}</div>
      <div>{intl.formatMessage({ id: 'MursionPortal.UnavailableSlotsError.BusyText' })}</div>
      <div>{intl.formatMessage({ id: 'MursionPortal.UnavailableSlotsError.TryAgainText' })}</div>
    </div>
  );
};

export default UnavailableSlotsError;