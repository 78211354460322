import RestService from 'src/app/services/rest/RestService';

export interface IDashboardEmailsQuery {
    clientIds?: string[] | [];
    startDate: number | null;
    endDate: number | null;
    emailCategories?: string[] | [];
    emailTypes?: string[] | [];
    tz: string;
}

export interface IDashboardEmailsData {
    emailData: {
        opened: number;
        actedOn: number;
        unread: number;
    };
    barGraphData: {
        sentEmailList: number[],
        actedOnEmailList: number[],
        requestDate: number[]
    };
}

export interface IEmailsData {
    opened: number;
    actedOn: number;
    unread: number;
}

export interface IDashboardEmailCategoriesSummary {
    registrationEmails: IEmailsData;
    callToActionEmails: IEmailsData;
    schedulingEmails: IEmailsData;
    reschedulingEmails: IEmailsData;
    videoEmails: IEmailsData;
}

const fetchDashboardEmailsData = (query: IDashboardEmailsQuery, signal: AbortSignal): Promise<IDashboardEmailsData> => {
    return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/emailAnalytics/emailData`, {
        body: JSON.stringify(query),
        headers: RestService.generateHeaders({
            'Content-Type': 'application/json',
        }),
        method: 'POST',
        signal,
    });
};

const fetchDashboardEmailCategoriesSummary = (query: IDashboardEmailsQuery, signal: AbortSignal): Promise<IDashboardEmailCategoriesSummary> => {
    return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/emailAnalytics/categorizedEmailData`, {
        body: JSON.stringify(query),
        headers: RestService.generateHeaders({
            'Content-Type': 'application/json',
        }),
        method: 'POST',
        signal,
    });
};

const exportDashboardEmailCategoriesSummary = (query: IDashboardEmailsQuery): Promise<void> => {
    return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/emailAnalytics/summaries/export`, {
        body: JSON.stringify(query),
        headers: RestService.generateHeaders({
            'Content-Type': 'application/json',
        }),
        method: 'POST',
    });
};

export default {
    fetchDashboardEmailsData,
    fetchDashboardEmailCategoriesSummary,
    exportDashboardEmailCategoriesSummary,
};
