import { combineReducers } from 'redux';
import INotificationMessage from 'src/app/data/common/interfaces/INotificationMessage';
import { IMessagePanelUIState } from 'src/app/redux/modules/messagePanel/ui/reducer';
import ui from './ui/reducer';
import createListReducer from 'src/app/redux/utils/createListReducer';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';

export interface IMessagePanelReduxState {
  ui: IMessagePanelUIState;
  list: IListDataState<INotificationMessage>;
  newList: IListDataState<INotificationMessage>;
}

export default combineReducers({
  ui,
  list: createListReducer('NOTIFICATION_MESSAGES'),
  newList: createListReducer('NEW_NOTIFICATION_MESSAGES'),
});
