exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".OnlyBillable__onBillableContainer___RGPeg{float:right}.OnlyBillable__onBillableContainer___RGPeg label div{font-weight:400;color:var(--color-cobalt);font-size:14px;line-height:1.3;padding-left:23px}.OnlyBillable__onBillableContainer___RGPeg label div span{background:var(--color-cobalt);border-color:var(--color-cobalt)}.OnlyBillable__onBillableContainer___RGPeg label div .checkBgOuterBorder{border:1px solid var(--color-cobalt);-webkit-box-shadow:none;box-shadow:none;background:var(--color-white)}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/Checkbox/OnlyBillable/OnlyBillable.css"],"names":[],"mappings":"AACA,2CACE,WAAa,CACd,AAED,qDACE,gBAAiB,AACjB,0BAA2B,AAC3B,eAAgB,AAChB,gBAAiB,AACjB,iBAAmB,CACpB,AAED,0DACE,+BAAgC,AAChC,gCAAkC,CACnC,AAED,yEACE,qCAAsC,AACtC,wBAAyB,AACjB,gBAAiB,AACzB,6BAA+B,CAChC","file":"OnlyBillable.css","sourcesContent":["\n.onBillableContainer {\n  float: right;\n}\n\n.onBillableContainer label div {\n  font-weight: 400;\n  color: var(--color-cobalt);\n  font-size: 14px;\n  line-height: 1.3;\n  padding-left: 23px;\n}\n\n.onBillableContainer label div span {\n  background: var(--color-cobalt);\n  border-color: var(--color-cobalt);\n}\n\n.onBillableContainer label div :global(.checkBgOuterBorder) {\n  border: 1px solid var(--color-cobalt);\n  -webkit-box-shadow: none;\n          box-shadow: none;\n  background: var(--color-white);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"onBillableContainer": "OnlyBillable__onBillableContainer___RGPeg"
};