import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import actions from 'src/app/redux/store/actions';
import { usePagedListFetchingWithOptions } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';
import { EntitySelector, IEntitySelectorBaseProps } from 'src/components/EntitySelectors';

export interface IScenarioSelectorProps extends IEntitySelectorBaseProps {
  clientIds: string[] | null;
  projectIds: string[] | null;
  scenarioIds: string[] | null;
  active: boolean;
  archive: TListPageArchiveOption;
  hasScenarioChanged?: boolean;
  setHasScenarioChanged?: (value: boolean) => void;
  ignoreCachedValue?: boolean;
}

const TeamSelector: FunctionComponent<IScenarioSelectorProps> = (props) => {

  const { clientIds, projectIds, scenarioIds, active, archive, hasScenarioChanged, setHasScenarioChanged, ignoreCachedValue } = props;

  const intl = useIntl();
  const [input, setInput] = useState('');

  const itemsInfo = usePagedListFetchingWithOptions(actions.teams.fetchTeamNamesListWithOptions)(
    { clientIds, projectIds, scenarioIds, teamName: input }
  )({
    page: 0,
    size: 1000,
    archive
  }, 300, active);

  useEffect(() => {
    if (!itemsInfo.items.length && !itemsInfo.refreshing) {
      props.onChange(null);
    }
  }, [itemsInfo.items.length]);


  return (
    <EntitySelector
      {...props}
      themeType={props.themeType || SelectorTheme.Filter}
      isMulti={props.isMulti !== false}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'Filters.Team' })}
      searchPlaceholder={intl.formatMessage({ id: 'Filters.TeamPlaceHolderHint' })}
      disabledTitle={intl.formatMessage({ id: 'Filters.TeamDisabledTitle' })}
      itemsInfo={itemsInfo}
      onInputChange={setInput}
      disabled={!active}
      hasScenarioChanged={hasScenarioChanged}
      setHasScenarioChanged={setHasScenarioChanged}
      ignoreCachedValue={ignoreCachedValue}
    />
  );
};

export default React.memo(TeamSelector);
