import IAvatar from 'src/app/data/client/interfaces/IAvatar';
import ITeamClientView from 'src/app/data/client/interfaces/ITeamClientView';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { ISOWReportData } from 'src/app/data/client/interfaces/IClient';
import IProject, {
  IClientProjectList,
  IDuplicateTargetScenarioType,
  IProjectBase,
  IProjectListByContractPayload,
  IProjectUpdate,
  IProjectUtilization,
  ProjectStatus,
  ScenarioVersion
} from 'src/app/data/projects/interfaces/IProject';
import RestService from 'src/app/services/rest/RestService';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import IEntityQuery from 'src/app/data/common/interfaces/IEntityQuery';
import stringifyEntityQuery from 'src/app/data/common/utils/stringifyEntityQuery';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import {
  IProjectActivityDashboardItem,
  IProjectDashboardChartData,
  IProjectsQuery,
  IProjectUtilizationDashboardItem
} from 'src/app/data/dashboard/IProjectDashboard';
import { ScenarioStatus } from 'src/app/data/projects/interfaces/IScenario';
import ITeamProjectView from 'src/app/data/client/interfaces/ITeamProjectView';
import { intl } from 'src/i18n/createIntl';
import { IExtendedStringFilter } from 'src/app/redux/modules/clients/users/rest';
import { ITeams } from 'src/layouts/common/InviteToSchedulePage/InviteToSchedulePage';


export interface IProjectDashboardChartQuery {
  page: number;
  size: number;
  filter?: string;
  order?: string;
  asc?: boolean;
  archive?: string;
  licenseeIds?: IExtendedStringFilter | null;
  clientIds?: IExtendedStringFilter | null;
  scenarioVersion?: ScenarioVersion[] | null;
  statuses?: ProjectStatus[] | null;
  startDate?: number | null;
  endDate?: number | null;
  billable?: boolean | null;
}

export interface IDashboardProjectsExportQuery extends Partial<IAbortableRequestParams> {
  licenseeIds?: { value: string[] | null, exclude?: boolean } | null;
  clientIds?: { value: string[] | null, exclude?: boolean } | null;
  archiveFilter?: string | null;
  asc?: boolean | null;
  billable?: boolean | null;
  endDate?: number | null;
  filter?: string | null;
  scenarioVersion?: ScenarioVersion[] | null;
  startDate?: number | null;
  statuses?: ScenarioStatus[] | null;
  userActivityOrder?: string | null;
}

const createProject = (projectData: IProjectUpdate): Promise<IProjectBase> => {
  return RestService.fetch(`${RestService.REST_URL}/project`, {
    body: JSON.stringify(projectData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const deleteProject = (projectId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/project/${projectId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const restoreProject = (projectId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/project/restore/${projectId}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchFullClientProjectsList = (pageData: IListPageData, clientId: string): Promise<IListDataResponse<IProject>> => {
  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/project/list${clientId ? '/' + clientId : ''}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchFullProjectsList = (pageData: IListPageData): Promise<IListDataResponse<IProject>> => {
  return RestService.fetch(`${RestService.REST_URL}/project/viewList${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchProject = (projectID: string, query?: IEntityQuery): Promise<IProject> => {
  return RestService.fetch(`${RestService.REST_URL}/project/${projectID}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchProjectName = (projectID: string): Promise<string> => {
  return fetchProject(projectID, { nameOnly: true }).then(project => project.name);
};

const fetchAvatarsList = (): Promise<IAvatar[]> => {
  return RestService.fetch(`${RestService.REST_URL}/avatar/list/0/1000`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchProjectTeams = (projectID: string, fetchScenarioInfo: boolean = false): Promise<ITeamClientView[] | ITeams[]> => {
  if (!projectID) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified'}));
  }

  // todo: change entry point after backend implementation
  return RestService.fetch(`${RestService.REST_URL}/team/listForProject/${projectID}/0/1000?fetchScenarioInfo=${fetchScenarioInfo}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((listResponse) => {
    return listResponse.content || [];
  });
};

const updateProject = (projectId: string, projectData: IProjectUpdate, reason?: string): Promise<IProjectBase> => {
  return RestService.fetch(`${RestService.REST_URL}/project/${projectId}`, {
    body: JSON.stringify({ ...projectData, reason }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateProjectAM = (projectId: string, ids: string[]): Promise<IProject> => {
  return RestService.fetch(`${RestService.REST_URL}/project/${projectId}/am`, {
    body: JSON.stringify(ids),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateProjectTags = (projectId: string, tags: string[]): Promise<IProject> => {
  return RestService.fetch(`${RestService.REST_URL}/project/${projectId}/tag`, {
    body: JSON.stringify(tags),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const fetchClientProjectsListWithOptions = (params: IPageRequestParams & { clientId: string }): Promise<IListDataResponse<IProject>> => {
  const { pageData, clientId, signal } = params;

  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/project/list/${clientId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchProjectTeamsWithOptions = (params: IPageRequestParams & { projectId: string }): Promise<IListDataResponse<ITeamProjectView>> => {

  const { pageData, projectId, signal } = params;

  if (!projectId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/team/listForProject/${projectId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchProjectTeamsList = (pageData: IListPageData, projectId: string): Promise<IListDataResponse<ITeamProjectView>> => {
  return fetchProjectTeamsWithOptions({ signal: null, pageData, projectId });
};

const fetchProjectWithOptions = (params: IAbortableRequestParams & { projectID: string, query?: IEntityQuery }): Promise<IProject> => {
  const { projectID, query, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/project/${projectID}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchProjectUtilizationWithOptions = (params: IAbortableRequestParams & { projectID: string }): Promise<IProjectUtilization> => {
  const { projectID, signal } = params;


  return RestService.fetch(`${RestService.REST_URL}/project/${projectID}/utilization`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchProjectNamesListWithOptions = (params: IPageRequestParams & {
  clientIds?: string[] | null,
  teamIds?: string[] | null,
  projectName?: string,
  startDate?: number,
  endDate?: number,
  ml3WebOnly?: boolean,
}): Promise<IListDataResponse<IProject>> => {
  const { pageData, clientIds, projectName, startDate, endDate, signal, teamIds, ml3WebOnly } = params;

  return RestService.fetch(`${RestService.REST_URL}/project/list/short`, {
    body: JSON.stringify({
      clientIds: { value: clientIds },
      teamIds: { value: teamIds },
      ml3WebOnly,
      startDate,
      endDate,
      projectName,
      ...pageData
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal
  });
};

// dashboard project activity
const fetchDashboardProjectsActivity = (params: IPageRequestParams & { projectsQuery: IProjectsQuery }): Promise<IListDataResponse<IProjectActivityDashboardItem>> => {
  const { pageData, projectsQuery, signal } = params;

  const query = {
    ...pageData,
    ...projectsQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/project/activity`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

export type IFetchDashboardProjectActivityChartData = (params: IAbortableRequestParams & { clientUsersQuery: IProjectDashboardChartQuery }) => Promise<IProjectDashboardChartData>;
const fetchDashboardProjectActivityChartData: IFetchDashboardProjectActivityChartData = (params) => {
  const { clientUsersQuery, signal } = params;

  const query = {
    ...clientUsersQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/project/activity/statistics`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportDashboardProjectsActivity = (params: IAbortableRequestParams & { projectsExportQuery: IDashboardProjectsExportQuery }): Promise<any> => {
  const { projectsExportQuery, signal } = params;

  const query = {
    ...projectsExportQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/project/activity/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchDashboardProjectsUtilization = (params: IPageRequestParams & { projectsQuery: IProjectsQuery }): Promise<IListDataResponse<IProjectUtilizationDashboardItem>> => {
  const { pageData, projectsQuery, signal } = params;

  const query = {
    ...pageData,
    ...projectsQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/project/utilization`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const exportDashboardProjectsUtilization = (params: IAbortableRequestParams & { projectsExportQuery: IDashboardProjectsExportQuery }): Promise<any> => {
  const { projectsExportQuery, signal } = params;

  const query = {
    ...projectsExportQuery
  };

  return RestService.fetch(`${RestService.DASHBOARD_REST_URL}/project/utilization/export`, {
    body: JSON.stringify(query),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchClientGlobalConfig = (projectId: string, clientId: string, licenseeId: string, configType: string): Promise<any> => {
  return RestService.fetch(`${RestService.GLOBAL_CONFIG_REST_URL}/getConfig?projectId=${projectId}&clientId=${clientId}&licenseeId=${licenseeId}&configType=${configType}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateGlobalConfig = (sowData: ISOWReportData): Promise<any> => {
  return RestService.fetch(`${RestService.GLOBAL_CONFIG_REST_URL}/update`, {
    body: JSON.stringify(sowData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const createGlobalConfig = (sowData: ISOWReportData): Promise<any> => {
  return RestService.fetch(`${RestService.GLOBAL_CONFIG_REST_URL}/save`, {
    body: JSON.stringify(sowData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchClientProjectsScenariosListWithOptions = (clientId: string, signal: AbortSignal | null = null): Promise<IListDataResponse<IProject>> => {
  return RestService.fetch(`${RestService.REST_URL}/project/list/${clientId}/0/999?asc=true&order=name&archive=active&status=active&`, {
      headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const duplicateSocialtoMagic = (projectId: string, targetScenarioType: IDuplicateTargetScenarioType): Promise<IProject> => {
  return RestService.fetch(`${RestService.REST_URL}/project/duplicate/${projectId}`, {
    body: JSON.stringify(targetScenarioType),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchProjectListByContract = (
  projectListPayload: IProjectListByContractPayload
): Promise<IClientProjectList[]> => {

  const { clientId, ...restPayload } = projectListPayload;
  return RestService.fetch(`${RestService.REST_URL}/project/listByContract`, {
    body: JSON.stringify({
      clientIds: clientId,
      ...restPayload,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

export default {
  createProject,
  deleteProject,
  restoreProject,
  fetchAvatarsList,
  fetchFullClientProjectsList,
  fetchFullProjectsList,
  fetchProject,
  fetchProjectName,
  fetchProjectTeams,
  updateProject,
  updateProjectAM,
  updateProjectTags,
  duplicateSocialtoMagic,
  fetchClientProjectsListWithOptions,
  fetchProjectWithOptions,
  fetchProjectNamesListWithOptions,
  fetchProjectUtilizationWithOptions,
  fetchDashboardProjectsActivity,
  fetchDashboardProjectsUtilization,
  fetchProjectTeamsWithOptions,
  fetchProjectTeamsList,
  exportDashboardProjectsActivity,
  exportDashboardProjectsUtilization,
  fetchDashboardProjectActivityChartData,
  fetchClientGlobalConfig,
  createGlobalConfig,
  updateGlobalConfig,
  fetchClientProjectsScenariosListWithOptions,
  fetchProjectListByContract,
};
