import { Dispatch } from 'redux';
import IWebSocketNotification from 'src/app/data/common/interfaces/IWebSocketNotification';
import { WSConnectionStatus } from 'src/app/data/common/interfaces/WSConnectionStatus';
import WebSocketService from 'src/app/services/ws/WebSocketService';
import { CLEAR_NOTIFICATIONS, UPDATE_CONNECTION_STATUS, UPDATE_ENTITIES, UPDATE_NOTIFICATIONS, APPEND_NOTIFICATIONS } from 'src/app/redux/modules/ws/types';
import IWebSocketEntity from 'src/app/data/common/interfaces/IWebSocketEntity';
import { WsAction } from 'src/app/data/common/interfaces/WsAction';

const initWebSocketConnection = () => (dispatch: Dispatch) => {
  WebSocketService.connect();

  dispatch({
    connectionStatus: WSConnectionStatus.WS_CONNECTING,
    type: UPDATE_CONNECTION_STATUS
  });
};

const closeWebSocketConnection = () => (dispatch: Dispatch) => {
  WebSocketService.disconnect();

  dispatch({
    connectionStatus: WSConnectionStatus.WS_DISCONNECTING,
    type: UPDATE_CONNECTION_STATUS
  });
};


const clearNotificationsList = (wsAction?: WsAction) => (dispatch: Dispatch) => {
  dispatch({
    wsAction,
    type: CLEAR_NOTIFICATIONS
  });
};

const clearEntitiesList = () => (dispatch: Dispatch) => {
  dispatch({
    entities: [],
    type: UPDATE_ENTITIES
  });
};

const updateConnectionStatus = (connectionStatus: WSConnectionStatus) => (dispatch: Dispatch) => {
  dispatch({
    connectionStatus,
    type: UPDATE_CONNECTION_STATUS
  });
};

const updateNotificationsList = (notifications: IWebSocketNotification[]) => (dispatch: Dispatch) => {
  dispatch({
    notifications,
    type: UPDATE_NOTIFICATIONS
  });
};

const appendNotificationsList = (notifications: IWebSocketNotification[]) => (dispatch: Dispatch) => {
  dispatch({
    notifications,
    type: APPEND_NOTIFICATIONS
  });
};

const updateEntities = (entities: IWebSocketEntity[]) => (dispatch: Dispatch) => {
  dispatch({
    entities,
    type: UPDATE_ENTITIES
  });
};

export default {
  clearNotificationsList,
  clearEntitiesList,
  closeWebSocketConnection,
  initWebSocketConnection,
  updateConnectionStatus,
  updateNotificationsList,
  appendNotificationsList,
  updateEntities,
};
