import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from 'src/layouts/common/Static/StaticPages.css';
import cn from 'classnames';
import mursionIcon from 'src/layouts/unauthorized/Login/images/mursion-mark-1024.png';
import TranslateMessage from 'src/i18n/TranslateMessage';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import actions from 'src/app/redux/store/actions';

export interface IEmailVerificationPage {
  match: any;
  unRegisteredUser: (sessionId: string) => Promise<boolean>;
}

export interface IEmailVerificationState {
  fetchingEmailVerification: boolean;
  fetchedEmailVerification: boolean;
  isEmailVerified: boolean;
}

const EmailVerificationPage: FunctionComponent<any> = (props: IEmailVerificationPage) => {
  const {token} = props.match.params;
  const {unRegisteredUser} = props;
  const [initialState, setInitialState] = useState<IEmailVerificationState>({
    fetchingEmailVerification: false,
    fetchedEmailVerification: false,
    isEmailVerified: true
  });
  const {fetchingEmailVerification, fetchedEmailVerification, isEmailVerified} = initialState;

  useEffect(() => {
    setInitialState({ 
        ...initialState, 
        fetchingEmailVerification: true
    });
    unRegisteredUser(token).then(resp => {
      resp ? emailVerificationSuccess() : emailVerificationError();
    })
    .catch(() => {
      emailVerificationError();
    });
  }, []);

  const emailVerificationSuccess = () => {
    setInitialState({ 
      ...initialState, 
      fetchingEmailVerification: false,
      fetchedEmailVerification: true,
      isEmailVerified: true
    });
  };

  const emailVerificationError = () => {
    setInitialState({ 
      ...initialState, 
      fetchingEmailVerification: false,
      fetchedEmailVerification: true,
      isEmailVerified: false
    });
  };

  const showLoader = () => <LoadingOverlay active={true} spinner={true}/>;

  const displayData = () => {
    return (
      <div className={cn(styles.container, styles.successContainer)}>
        <div className={cn(styles.message, styles.messageSuccess)}>
          <div className={styles.messageBody}>
            <div className={styles.logoWrapper}>
              <div className={cn(styles.logoItem, styles.mursionIcon)}>
                <img src={mursionIcon} style={{ width: '50%', height: 'auto' }} alt={''}/>
              </div>
            </div>

            <div className={styles.text}>
              <h3 className={styles.successTitle}> {isEmailVerified ? TranslateMessage('MursionPortal.EmailVerificationPage.Success') : TranslateMessage('MursionPortal.EmailVerificationPage.Error')} </h3>
              <h4 className={styles.successTitle}> {isEmailVerified ? TranslateMessage('MursionPortal.EmailVerificationPage.SuccessDesc') : TranslateMessage('MursionPortal.EmailVerificationPage.ErrorDesc')} </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    !fetchingEmailVerification && fetchedEmailVerification ?
      displayData()
    : showLoader()
  );
};

export default withRouter(connect(
  () => ({
  }),{
    unRegisteredUser: actions.auth.unRegisteredUser
  }
)(EmailVerificationPage));