import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';

export default {
  fetchOverviewSimBreakdown: actionFromPromise(rest.fetchOverviewSimBreakdown),
  
  fetchClientContractSummaries: actionFromPromise(rest.fetchClientContractSummaries),
  fetchClientContractItems: actionFromPromise(rest.fetchClientContractItems),
  fetchClientContractProjects: actionFromPromise(rest.fetchClientContractProjects),
  fetchClientContractScenarios: actionFromPromise(rest.fetchClientContractScenarios),
  fetchClientContractSelfService: actionFromPromise(rest.fetchClientContractSelfService),

  exportClientContractSummaries: actionFromPromise(rest.exportClientContractSummaries),
  exportClientContractItems: actionFromPromise(rest.exportClientContractItems),
  exportClientContractProjects: actionFromPromise(rest.exportClientContractProjects),
  exportClientContractScenarios: actionFromPromise(rest.exportClientContractScenarios),
  exportClientContractSelfService: actionFromPromise(rest.exportClientContractSelfService),

  fetchClientContractsWithOptions: actionFromPromise(rest.fetchClientContractsWithOptions),

  fetchClientScenariosContract: actionFromPromise(rest.fetchClientScenariosContract),
  fetchContractLearnerBreakdown: actionFromPromise(rest.fetchContractLearnerBreakdown),
  fetchContractOverviewBreakdown: actionFromPromise(rest.fetchContractOverviewBreakdown, 'CONTRACT', true),
};