import React, { useMemo } from 'react';
import { components } from 'react-select';
import styles from 'src/components/Selector/Selector.css';
import SelectedLabel from 'src/components/Selector/components/common/SelectedLabel/SelectedLabel';
import { ContainerProps } from 'react-select/dist/declarations/src/components/containers';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectors } from 'src/app/redux';
import RoleID from 'src/app/data/common/interfaces/RoleID';

interface IValueLineContainer {
  containerProps: ContainerProps<any, any, any>;
  disabled?: boolean;
  valuePlaceholder?: string;
  isValueLineContainer?: boolean;
  clearSelectedItems?: () => void;
}

const ValueLineContainer = (props: IValueLineContainer) => {
  const intl = useIntl();
  const userRole = useSelector(selectors.profile.getCurrentUserRole);
  
  const {
    containerProps: { children, ...containerProps },
    disabled,
    clearSelectedItems,
    isValueLineContainer = false,
    valuePlaceholder = intl.formatMessage({ id: 'Filters.All' }),
  } = props;
  const { options, value } = containerProps.selectProps;

  const isAoUser = useMemo(() => {
    if(userRole && userRole.id === RoleID.BUYER){
      return false;
    }
    return isValueLineContainer;
  }, [userRole]);

  const disableClear = useMemo(() => Array.isArray(value) ? value.length === 0 : !value?.value, [containerProps.selectProps]);

  const selected = useMemo(() => (
    <SelectedLabel
      value={value}
      options={options}
      disabled={disabled}
      placeholder={valuePlaceholder}
    />
  ), [value, options, disabled, valuePlaceholder]);

  return (
    <components.SelectContainer {...containerProps}>
      <div className={styles.withValueLine}>
        <div>{children}</div>

        <div className={styles.valueLine} tabIndex={0}>
          <span className={styles.value}>{selected}</span>
          {clearSelectedItems ? (
            <button
              onClick={clearSelectedItems}
              disabled={disabled || disableClear || isAoUser}
              className={styles.clearValue}
              tabIndex={0} role="button"
              aria-label={intl.formatMessage({ id: 'MursionPortal.AriaLabel.Close' })}
            />
          ) : null}
        </div>
      </div>
    </components.SelectContainer>
  );
};

export default ValueLineContainer;
