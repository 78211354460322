import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  IPagedDataHookResult,
  usePagedListFetchingWithOptions
} from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import actions from 'src/app/redux/store/actions';
import Selector, { ISelectorOption } from 'src/components/Selector/Selector';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';


export interface ITeamSelectorSinglePropsBase {
  onChange: (val: INamedEntry) => void;
  active: boolean;
  clientId: string; // client id
  value: string; // selected team id
}

interface ITeamSelectorSingleProps extends ITeamSelectorSinglePropsBase {
  itemsInfo: IPagedDataHookResult<INamedEntry>;
  initialOption: ISelectorOption<string> | null;
  onInputChange: (filter: string) => void;
}

interface ITeamSelectorSingleContainerProps extends ITeamSelectorSinglePropsBase {
  archive: TListPageArchiveOption;
}

const TeamSelectorSingle: FunctionComponent<ITeamSelectorSingleProps> = (props) => {

  const { active, itemsInfo, initialOption, value, onInputChange } = props;

  const { items, total, refreshing } = itemsInfo;

  const [hasChanges, setHasChanges] = useState(false);

  const onChange = useCallback((val: ISelectorOption) => {
    if (val.value === initialOption?.value) {
      return;
    }

    setHasChanges(true);

    props.onChange({
      id: `${val.value}`,
      name: `${val.label}`,
    });
  }, [props.onChange, items, initialOption?.value]);

  const options: ISelectorOption[] = useMemo(() => {
    const opts = items.map(x => ({ value: x.id, label: x.name }));

    if (initialOption && !opts.some(opt => opt.value === initialOption?.value)) {
      opts.push(initialOption);
    }

    return opts;
  }, [items, initialOption, hasChanges]);

  const selectedOption = options.find(opt => opt.value === (hasChanges ? value : initialOption?.value));
  const intl = useIntl();
  return (
    <Selector
      isMulti={false}
      isSearchable={true}
      placeholder={intl.formatMessage({ id: 'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam' })}
      searchPlaceholder={intl.formatMessage({id:'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam'})}
      value={selectedOption}
      options={options}
      onChange={onChange}
      onInputChange={onInputChange}
      total={total}
      disabled={!active}
      loading={active && refreshing}
      clearOnOptionsChange={true}
    />
  );
};


export const TeamSelectorSingleContainer: FunctionComponent<ITeamSelectorSingleContainerProps> = (props) => {

  const [filter, setFilter] = useState('');
  const [initialOption, setInitialOption] = useState<ISelectorOption<string> | null>(null);

  const teamsInfo = usePagedListFetchingWithOptions(actions.teams.fetchTeamNamesListWithOptions)({
    clientIds: [props.clientId],
    teamName: filter,
  })({
    page: 0,
    size: 20,
    archive: props.archive,
  }, 300, props.active);

  const dispatch = useDispatch();

  useEffect(() => {

    if (!props.value) {
      return;
    }

    dispatch(actions.teams.fetchTeamName(props.value))
      .then((selectedTeamName) => {
        setInitialOption({
          value: props.value,
          label: selectedTeamName
        });
      });

  }, [props.value]);

  return (
    <TeamSelectorSingle
      initialOption={initialOption}
      itemsInfo={teamsInfo}
      onInputChange={setFilter}
      {...props}
    />
  );
};
