import IEnvironment, { IEnvironmentUpdate } from "src/app/data/client/interfaces/IEnvironment";
import RestService from "src/app/services/rest/RestService";
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import generateFormData from 'src/app/data/common/utils/generateFormData';


const createEnvironment = (environmentData: IEnvironmentUpdate, picture: File | null): Promise<IEnvironment> => {
  return RestService.fetch(`${RestService.REST_URL}/environment/`, {
    body: generateFormData(environmentData, 'environment', picture),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const updateEnvironment = (environmentId: string, environmentData: IEnvironmentUpdate, picture: File | null): Promise<IEnvironment> => {
  return RestService.fetch(`${RestService.REST_URL}/environment/${environmentId}`, {
    body: generateFormData(environmentData, 'environment', picture),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const deleteEnvironment = (environmentId: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/environment/${environmentId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const restoreEnvironment = (environmentId: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/environment/restore/${environmentId}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchEnvironmentById = (environmentId: string): Promise<IEnvironment> => {
  return RestService.fetch(`${RestService.REST_URL}/environment/${environmentId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchEnvironmentsList = (pageData: IListPageData): Promise<IEnvironment[]> => {
  return RestService.fetch(`${RestService.REST_URL}/environment/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchEnvironmentsListByIndustry = (industryId: string): Promise<IEnvironment[]> => {
  return RestService.fetch(`${RestService.REST_URL}/environment/byIndustry/${industryId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  createEnvironment,
  deleteEnvironment,
  restoreEnvironment,
  fetchEnvironmentById,
  fetchEnvironmentsList,
  fetchEnvironmentsListByIndustry,
  updateEnvironment,
};
