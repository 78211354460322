import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import actions from 'src/app/redux/store/actions';
import { usePagedListFetchingWithOptions } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';
import { ScenarioStatus } from 'src/app/data/projects/interfaces/IScenario';
import { EntitySelector, IEntitySelectorBaseProps } from 'src/components/EntitySelectors';
import { ISelectorOption } from 'src/components/Selector/Selector';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { getScenarioNameForClientUsers } from 'src/app/data/common/utils/scenarioTemplateUtils';
import { useSelector } from 'react-redux';
import { selectors } from 'src/app/redux';

export interface IScenarioSelectorProps extends IEntitySelectorBaseProps {
  clientIds: string[] | null;
  projectIds: string[] | null;
  startDate?: number;
  endDate?: number;
  status?: ScenarioStatus[];
  active: boolean;
  archive: TListPageArchiveOption;
}

interface IScenarioNamedEntry extends INamedEntry{
  generationType?: number;
  nameCustomized?: string;
}

interface IScenarioSelectorOption extends ISelectorOption{
  generationType?: number;
}

const ScenarioSelector: FunctionComponent<IScenarioSelectorProps> = (props) => {
  const userRole = useSelector(selectors.profile.getCurrentUserRole);

  const { clientIds, projectIds, startDate, endDate, active, archive, status, onChange } = props;

  const intl = useIntl();
  const [input, setInput] = useState('');

  const itemsInfo = usePagedListFetchingWithOptions(actions.scenarios.fetchScenarioNamesListWithOptions)({
    clientIds, projectIds,
    scenarioName: input,
    startDate, endDate, status
  })({
    page: 0, size: 9999, archive,
  }, 300, active);

  const valueToOption = (item: IScenarioNamedEntry) => {
    const scenarioTitle = getScenarioNameForClientUsers(userRole, item?.nameCustomized, item.name);
    return {
        value: item.id,
        label: scenarioTitle ?? '',
        generationType: item.generationType,
    };
};

const optionToValue = (option: IScenarioSelectorOption) => {
    return {
        id: option.value,
        name: option.label,
        generationType: option.generationType,
    };
};

  useEffect(() => {
    if (!itemsInfo.items.length && !itemsInfo.refreshing) {
      onChange(null);
    }
  }, [itemsInfo.items.length]);

  return (
    <EntitySelector
      {...props}
      valueToOption={valueToOption}
      optionToValue={optionToValue}
      themeType={props.themeType || SelectorTheme.Filter}
      isMulti={props.isMulti !== false}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'Filters.Scenario' })}
      searchPlaceholder={intl.formatMessage({ id: 'Filters.ScenarioPlaceHolderHint' })}
      disabledTitle={intl.formatMessage({ id: 'Filters.ScenarioDisabledTitle' })}
      itemsInfo={itemsInfo}
      onInputChange={setInput}
      disabled={!active}
    />
  );
};

export default React.memo(ScenarioSelector);
