import { combineReducers } from 'redux';
import IListDataState from "src/app/data/common/interfaces/IListDataState";
import createListReducer from "src/app/redux/utils/createListReducer";
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';
import IEntityHistory, { ISessionHistory } from "src/app/data/common/interfaces/IEntityHistory";

export interface IEntityHistoryReduxState {
  list: IListDataState<IEntityHistory<any>>;
  listBySession: IListDataState<IEntityHistory<ISessionHistory>>;
  error: IErrorState;
}

export default combineReducers({
  list: createListReducer('ENTITY_HISTORY_LIST'),
  listBySession: createListReducer('ENTITY_HISTORY_LIST_BY_SESSION'),
  error: createEntityErrorReducer('ENTITY_HISTORY'),
});
