import _noop from 'lodash/noop';
import * as React from 'react';
import { Col, Pagination as ReactPagination, DropdownButton, Row, Container } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from 'src/components/Pagination/Pagination.css';
import queryString from 'query-string';
import cn from 'classnames';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import MursionDropdownButton from 'src/components/DropdownButton/MursionDropdownButton';
import { TableTheme } from 'src/components/EntityTable/EntityTable';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';

export interface IPaginationProps extends WrappedComponentProps{
  refreshing?: boolean;
  disabled?: boolean;
  dropUp?: DropDirection;
  page: number;
  paginationSize?: number;
  sizePerPage: number;
  sizePerPageList?: number[];
  totalSize: number;
  compactView?: boolean;
  onChange?: (page: number, size: number) => void;
  themeType?: TableTheme;
}

export const PARAM_PAGE = 'page';
export const PARAM_SIZE = 'size';
export const PARAM_PROJECT_STATUS = 'project_status';
export const DEFAULT_SIZE_PER_PAGE_LIST = [10, 25, 50, 100];
export const DEFAULT_SIZE_PER_PAGE = DEFAULT_SIZE_PER_PAGE_LIST[0];
export const DEFAULT_SIZE_PER_PAGE_FOR_SIM_DASHBOARD = DEFAULT_SIZE_PER_PAGE_LIST[1];
export const DEFAULT_PAGINATION_SIZE = 4;
export const PARAM_CONTRACT_STATUS = 'contract_status';

class Pagination extends React.Component<IPaginationProps & RouteComponentProps<any>> {

  public static defaultProps = {
    themeType: TableTheme.Default,
    sizePerPageList: DEFAULT_SIZE_PER_PAGE_LIST,
  };

  public state = {
    initialized: false
  };

  public componentDidMount(): void {
    this.setState({ initialized: false });
  }

  public componentDidUpdate(prevProps: Readonly<IPaginationProps & RouteComponentProps<any>>, prevState: Readonly<{}>, snapshot?: any): void {
    if (!this.state.initialized && !this.props.disabled) {
      this.setState({ initialized: true });
    }
  }

  public render() {
    if (!this.state.initialized) {
      return null;
    }

    const totalPages = this.getTotalPagesCount();
    const hide = this.props.sizePerPage === this.sizePerPageList[0] && totalPages < 2 || !this.props.totalSize;

    if (hide) {
      return null;
    }

    if (this.props.compactView) {
      return (
        <div className={cn(
          styles.container,
          styles.compact,
          this.props.themeType === TableTheme.Cobalt && styles.cobalt
        )}>
          <div className={styles.sizePerPage}>
            {this.renderSizePerPageDropdown()}
          </div>
          <Col className={styles.paginationItems}>
            {this.renderPageItems()}
          </Col>
        </div>
      );
    }

    return (
      <Container className={cn(styles.container, styles.paginationContainer, this.props.themeType === TableTheme.Cobalt && styles.cobalt)}>
        <Row>
          <Col className={cn(styles.sizePerPage, styles.sizePerPageitem)} xs={12} sm={12} md={2}>
            {this.renderSizePerPageDropdown()}
          </Col>
          <Col className={cn(styles.paginationItems, styles.sizePerPageitem)} xs={12} sm={12} md={10}>
            {this.renderPageItems()}
          </Col>
        </Row>
      </Container>
    );
  }

  private renderPageItems() {
    const { disabled, page, sizePerPage, intl } = this.props;
    const totalPages = this.getTotalPagesCount();
    const paginationSize = this.props.paginationSize || DEFAULT_PAGINATION_SIZE;
    const showPageFrom = Math.max(0, page - 2);
    const showPageTo = Math.min(totalPages, showPageFrom + paginationSize);

    const items = [];

    for (let i = showPageFrom; i < showPageTo; i++) {
      const onPageClicked = () => {
        this.updateLocation(i, sizePerPage);
      };
      const active = i === page;
      
      items.push(
        <ReactPagination.Item
          activeLabel='' 
          active={active}
          disabled={disabled}
          key={`page-item-${i}`}
          onClick={disabled || active ? _noop : onPageClicked}
        >
          {intl.formatNumber(i + 1)}
        </ReactPagination.Item>
      );
    }

    const next = () => {
      this.updateLocation(page + 1, sizePerPage);
    };
    const last = () => {
      this.updateLocation(totalPages - 1, sizePerPage);
    };
    const prev = () => {
      this.updateLocation(page - 1, sizePerPage);
    };
    const first = () => {
      this.updateLocation(0, sizePerPage);
    };
    const paginationItem = (title: string, onClick?: () => void, itemDisabled?: boolean) => {

      return (
        <ReactPagination.Item disabled={disabled || itemDisabled} onClick={onClick}>{title}</ReactPagination.Item>
      );
    };

    return (
      <ReactPagination className={'float-right'}>
        {showPageFrom > 0 && page > 0 && paginationItem(intl.formatMessage({ id: 'Pagination.Button.First' }), first)}
        {page > 0 && paginationItem(intl.formatMessage({ id: 'Pagination.Button.Back' }), prev)}
        {showPageFrom > 0 && paginationItem('...', _noop, true)}
        {items}
        {showPageTo < totalPages && paginationItem('...', _noop, true)}
        {page < totalPages - 1 && paginationItem(intl.formatMessage({ id: 'Dashboard.Next' }), next)}
        {showPageTo < totalPages && page < totalPages - 1 && paginationItem(intl.formatMessage({ id: 'Dashboard.Last' }), last)}
      </ReactPagination>
    );
  }

  private renderSizePerPageDropdown() {
    const { disabled, dropUp, sizePerPage } = this.props;
    const onSelect = (size: any) => this.updateLocation(0, size);
    const direction = dropUp ? 'up' : 'down';

    if (this.props.themeType === TableTheme.Cobalt) {
      return (
        <MursionDropdownButton
          disabled={disabled}
          drop={direction}
          value={sizePerPage}
          onSelect={onSelect}
          id='table-size-select-button'
          items={this.sizePerPageList.map((size) => ({ labelText: size, value: size }))}
          title={''}
          children={''}
        />
      );
    }

    return (
      <DropdownButton
        variant='Primary'
        disabled={disabled}
        drop={direction}
        title={sizePerPage}
        className={styles.mursionDropdown}
        key='table-size-select-button'
        id='table-size-select-button'
        onSelect={onSelect}>
        {
          this.sizePerPageList.map((size, i) => {
            const props = {
              className: sizePerPage === size ? styles.selectedSizeItem : '',
              eventKey: size
            };

            return (<Dropdown.Item key={`size-per-page-item-${i}`} {...props}>{size}</Dropdown.Item>);
          })
        }
      </DropdownButton>
    );
  }

  private getTotalPagesCount(newSizePerPage?: number) {
    const { sizePerPage, totalSize } = this.props;

    return Math.ceil(totalSize / (newSizePerPage || sizePerPage));
  }

  private get sizePerPageList() {
    return this.props.sizePerPageList || DEFAULT_SIZE_PER_PAGE_LIST;
  }

  private updateLocation(page: number, size: number) {
    
    if (this.props.onChange) {
      return this.props.onChange(page, size);
    }

    const { history, location } = this.props;
    const params = queryString.parse(location.search);

    location.search = queryString.stringify({
      ...params,
      [PARAM_PAGE]: page,
      [PARAM_SIZE]: size,
    });

    history.push(location);
  }
}

export default injectIntl(withRouter(Pagination));
