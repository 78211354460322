import * as React from 'react';
import Button, { ButtonFont, ButtonSize, ButtonType } from 'src/components/Button';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import IconButton from 'src/components/IconButton';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { TLocaleId } from 'src/i18n';
import { DialogThemeType } from 'src/components/ConfirmationDialog/ConfirmationDialog';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';

export enum ConfirmButtonType {
  DELETE = 'delete',
  DELETE_SSO = 'deleteSso',
  RESTORE = 'restore',
  UPDATE = 'update',
  SWAP = 'swap',
  CANCEL_SESSION = 'cancelSession',
  CANCEL_TRAINING = 'cancelTraining',
  CANCEL_EVENT = 'cancelEvent',
  PROJECT_CHANGE = 'projectChange',
  CREATE_EMERGENCY_SESSION = 'createEmergencySession',
  ACCEPT_REQUEST = 'acceptSessionRequest',
  ASSIGN_SS = 'assignSimSpecialist',
  CHANGE_SS = 'changeSimSpecialist',
  DEFAULT_TIMEZONE = 'defaultTimezone',
  DELETE_LTI_ROLE = 'deleteLtiRole',
}

export interface IConfirmButtonProps extends WrappedComponentProps {
  type: ConfirmButtonType;
  buttonTitle: string;
  confirmationText: string;
  buttonStyle?: boolean;
  disabled?: boolean;
  error?: string;
  addReason?: boolean;
  onConfirm: (reason?: string) => any;
  onConfirmShow?: () => void;
  onDialogHidden?: any;
  className?: string;
  btnTypeAttribute?: "reset" | "submit" | "button";
  confirmationDialogThemeType?: DialogThemeType;
  title?: string;
  showConfirmationModal?: boolean;
}

interface IConfirmButtonState {
  showConfirmDialog: boolean;
}

interface IConfirmButtonConfig {
  iconName?: string;
  iconLabel?: TLocaleId;
  submitBtnTitle?: TLocaleId;
  cancelBtnTitle?: TLocaleId;
  headerTitle?: TLocaleId;
  reasonTextLabel?: TLocaleId;
  reasonTextPlaceholder?: TLocaleId;
  primary?: boolean;
  btnType?: ButtonType;
  btnSize?: ButtonSize;
  btnFont?: ButtonFont;
}

  const TYPES_CONFIG: { [key in ConfirmButtonType]: IConfirmButtonConfig } = {
    [ConfirmButtonType.DELETE]: {
      iconName: 'trash',
      iconLabel:'MursionPortal.IconButton.Archive',
      submitBtnTitle: 'MursionPortal.Button.Archive',
      cancelBtnTitle:'MursionPortal.Button.Cancel',
      headerTitle:'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive',
    },
    [ConfirmButtonType.DELETE_SSO]: {
      iconName: 'trash',
      iconLabel:'MursionPortal.Label.Delete',
      submitBtnTitle:'MursionPortal.Button.Delete',
      cancelBtnTitle:'MursionPortal.Button.Cancel',
      headerTitle:'Settings.SSO.Modal.ConfirmDelete',
    },
    [ConfirmButtonType.RESTORE]: {
      iconName: 'trash-restore',
      iconLabel: 'MursionPortal.Label.Restore',
      submitBtnTitle: 'MursionPortal.Button.Restore',
      cancelBtnTitle: 'MursionPortal.Button.Cancel',
      headerTitle: 'MursionPortal.HeaderTitle.ConfirmRestore',
    },
    [ConfirmButtonType.CANCEL_SESSION]: {
      iconName: 'trash',
      iconLabel: 'MursionPortal.Label.Archive',
      headerTitle: 'ConfirmButton.CancelSession.ConfirmCancellation',
      submitBtnTitle: 'ConfirmButton.CancelSession',
      cancelBtnTitle: 'ConfirmButton.CancelSession.No',
    },
    [ConfirmButtonType.CANCEL_TRAINING]: {
      iconName: 'trash',
      iconLabel: 'MursionPortal.Label.Archive',
      headerTitle: 'MursionPortal.HeaderTitle.ConfirmCancellation',
      submitBtnTitle: 'MursionPortal.Button.CancelTraining',
      cancelBtnTitle: 'MursionPortal.Button.No',
    },
    [ConfirmButtonType.CANCEL_EVENT]: {
      iconName: 'trash',
      iconLabel: 'MursionPortal.Label.Archive',
      headerTitle: 'MursionPortal.HeaderTitle.ConfirmCancellation',
      submitBtnTitle: 'MursionPortal.Button.CancelEvent',
      cancelBtnTitle: 'MursionPortal.Button.No',
    },
    [ConfirmButtonType.UPDATE]: {
      iconName: 'edit',
      iconLabel: 'MursionPortal.Label.Update',
      headerTitle: 'MursionPortal.HeaderTitle.ConfirmUpdate',
      submitBtnTitle: 'MursionPortal.Button.UpdateSession',
      cancelBtnTitle: 'MursionPortal.Button.No',
      primary: true,
    },
    [ConfirmButtonType.SWAP]: {
      iconName: 'edit',
      iconLabel: 'MursionPortal.Label.Swap',
      headerTitle: 'MursionPortal.HeaderTitle.ConfirmAcceptedSwap',
      submitBtnTitle: 'MursionPortal.Button.AcceptSwap',
      cancelBtnTitle: 'MursionPortal.Button.No',
      reasonTextLabel: 'MursionPortal.Label.Reason',
      reasonTextPlaceholder: 'MursionPortal.Placeholder.ReasonForSwapping',
    },
    [ConfirmButtonType.ACCEPT_REQUEST]: {
      iconName: 'edit',
      iconLabel: 'MursionPortal.Label.Request',
      headerTitle: 'MursionPortal.HeaderTitle.ConfirmAcceptedRequest',
      submitBtnTitle: 'MursionPortal.Button.AcceptRequest',
      cancelBtnTitle: 'MursionPortal.Button.No',
      reasonTextLabel: 'MursionPortal.Label.Reason',
      reasonTextPlaceholder: 'MursionPortal.Placeholder.ReasonForAccepting',
    },
    [ConfirmButtonType.PROJECT_CHANGE]: {
      iconName: 'edit',
      iconLabel: 'MursionPortal.Label.Update',
      submitBtnTitle: 'MursionPortal.Button.ConfirmProjectChange',
      cancelBtnTitle: 'MursionPortal.Button.Cancel',
      headerTitle: 'MursionPortal.HeaderTitle.Confirm',
    },
    [ConfirmButtonType.CREATE_EMERGENCY_SESSION]: {
      submitBtnTitle: 'MursionPortal.Button.ConfirmCreateSession',
      cancelBtnTitle: 'MursionPortal.Button.Cancel',
      headerTitle: 'MursionPortal.HeaderTitle.Confirm',
      reasonTextLabel: 'MursionPortal.Label.Reason',
      reasonTextPlaceholder: 'MursionPortal.Placeholder.ReasonForCreation',
      primary: true,
    },
    [ConfirmButtonType.ASSIGN_SS]: {
      submitBtnTitle: 'MursionPortal.Button.ConfirmAssigningSimSpecialist',
      cancelBtnTitle: 'MursionPortal.Button.Cancel',
      headerTitle: 'MursionPortal.HeaderTitle.Confirm',
      reasonTextLabel: 'MursionPortal.Label.Reason',
      reasonTextPlaceholder: 'MursionPortal.Placeholder.ReasonForAssigning',
      primary: true,
    },
    [ConfirmButtonType.CHANGE_SS]: {
      submitBtnTitle: 'MursionPortal.Button.ConfirmChangingSimSpecialist',
      cancelBtnTitle: 'MursionPortal.Button.Cancel',
      headerTitle: 'MursionPortal.HeaderTitle.Confirm',
      reasonTextLabel: 'MursionPortal.Label.Reason',
      reasonTextPlaceholder: 'MursionPortal.Placeholder.ReasonForChanging',
      primary: true,
    },
    [ConfirmButtonType.DEFAULT_TIMEZONE]: {
      submitBtnTitle: 'MursionPortal.HeaderTitle.Confirm',
      cancelBtnTitle: 'MursionPortal.Button.Cancel',
      headerTitle: 'MursionPortal.HeaderTitle.ConfirmTimeZone',
      btnSize: ButtonSize.NORMAL,
      btnType: ButtonType.SPECIAL_RED,
      btnFont: ButtonFont.LIGHT,
    },
    [ConfirmButtonType.DELETE_LTI_ROLE]: {
      iconName: 'times',
      iconLabel: 'MursionPortal.Status.Empty',
      submitBtnTitle:'MursionPortal.Button.Delete',
      cancelBtnTitle:'MursionPortal.Button.Cancel',
      headerTitle:'Settings.SSO.Modal.ConfirmDelete',
    },
  };


class ConfirmButton extends React.Component<IConfirmButtonProps, IConfirmButtonState> {

  public state: IConfirmButtonState = {
    showConfirmDialog: false
  };

  public render() {
    const {
      type,
      buttonStyle,
      buttonTitle,
      disabled,
      intl,
      className,
      btnTypeAttribute,
      title,
    } = this.props;
    const { showConfirmDialog } = this.state;
    const typeConfig = TYPES_CONFIG[type];
    return (
      <>
        {
          buttonStyle
            ? (
              <Button
                btnSize={typeConfig.btnSize || ButtonSize.MEDIUM}
                btnFont={typeConfig.btnFont || ButtonFont.LIGHT}
                btnType={typeConfig.btnType || (typeConfig.primary ? ButtonType.SPECIAL_PURPLE : ButtonType.PURPLE)}
                disabled={disabled}
                onClick={this.onBtnClicked}
                onKeyDown={this.handleStepOnSubmitButton}
                aria-label={buttonTitle}
                title={title}
                className={className}
              >
                {buttonTitle}
              </Button>
            )
            : (
              <IconButton
                iconName={typeConfig.iconName || 'edit'}
                label={typeConfig.iconLabel ? intl.formatMessage({ id: typeConfig.iconLabel }) : intl.formatMessage({ id: 'MursionPortal.Label.Edit' })}
                aria-label={buttonTitle}
                title={buttonTitle}
                disabled={disabled}
                onClick={this.onBtnClicked}
                onKeyDown={this.handleStepOnSubmitButton}
                className={className}
                type={btnTypeAttribute}
              />
            )
        }
        {showConfirmDialog &&
          this.renderConfirmationPopup()
        }
      </>
    );
  }

  private renderConfirmationPopup = () => {
    const { type, intl, error, confirmationText, addReason, confirmationDialogThemeType, showConfirmationModal } = this.props;
    const { showConfirmDialog } = this.state;
    const typeConfig = TYPES_CONFIG[type];

    if (showConfirmationModal) {
      return <ConfirmationModal
        show={showConfirmDialog}
        headerTitle={typeConfig.headerTitle && intl.formatMessage({ id: typeConfig.headerTitle })}
        bodyText={confirmationText}
        onCancelModal={this.hideDialog}
        submitBtnTitle={typeConfig.submitBtnTitle && intl.formatMessage({ id: typeConfig.submitBtnTitle })}
        onConfirm={this.onConfirm}
        onOtherAction={this.hideDialog}
      />;
    }

    return <ConfirmationDialog
      error={error || ''}
      text={confirmationText}
      onConfirm={this.onConfirm}
      onHide={this.hideDialog}
      headerTitle={typeConfig.headerTitle && intl.formatMessage({ id: typeConfig.headerTitle })}
      submitBtnTitle={typeConfig.submitBtnTitle && intl.formatMessage({ id: typeConfig.submitBtnTitle })}
      cancelBtnTitle={typeConfig.cancelBtnTitle && intl.formatMessage({ id: typeConfig.cancelBtnTitle })}
      reasonTextLabel={typeConfig.reasonTextLabel && intl.formatMessage({ id: typeConfig.reasonTextLabel })}
      reasonTextPlaceholder={typeConfig.reasonTextPlaceholder && intl.formatMessage({ id: typeConfig.reasonTextPlaceholder })}
      addReason={addReason}
      show={showConfirmDialog}
      themeType={confirmationDialogThemeType}
    />;
  }

  private onConfirm = async (reason?: string) => {
    this.hideDialog();

    await this.props.onConfirm(reason);
  };

  private onBtnClicked = () => {
    this.setState({
      showConfirmDialog: true,
    });

    const { onConfirmShow } = this.props;

    if (onConfirmShow) {
      onConfirmShow();
    }
  };

  private handleStepOnSubmitButton = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyboardEvent.key === 'Enter') {
      this.onBtnClicked();
    }
  };

  private hideDialog = () => {
    this.setState({showConfirmDialog: false}, () =>{
      if(this.props.onDialogHidden){
        this.props.onDialogHidden();
      }
    });
  };
}

export default injectIntl(ConfirmButton);
