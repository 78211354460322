import RestService from "src/app/services/rest/RestService";

const fetchConfig = (): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/config/webapp`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  fetchConfig,
};
