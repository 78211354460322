import React, { FunctionComponent } from 'react';
import styles from "src/components/A11ySelector/A11ySelector.css";
import cn from "classnames";
import { ISelectorOption } from "src/components/Selector/Selector";
import { Listbox, ListboxOption } from "@reach/listbox";

export interface IA11ySelectorProps {
  options: Array<ISelectorOption<any | null>>;
  defaultValue?: string; // for uncontrolled Listbox
  onChange: any;
  disabled?: boolean;
  className?: string;
  value?: string; // for controlled Listbox
}

const A11ySelector: FunctionComponent<IA11ySelectorProps> = (props) => {
  const { options, defaultValue, onChange, disabled, className, value } = props;

  return (
    <div className={cn(styles.a11ySelector, disabled && styles.disabled)}>
      <Listbox className={className} onChange={onChange} defaultValue={defaultValue} value={value}>
        {options.map((option,i) => (
          <ListboxOption value={option.value} key={i}>{option.label}</ListboxOption>
        ))}
      </Listbox>
    </div>
  );
};

export default A11ySelector;
