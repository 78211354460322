import Cookies from 'js-cookie';
import IAuthToken from 'src/app/data/auth/IAuthToken';

const TOKEN_KEY = 'mursion-scheduler-portal-token';
const REFRESH_TOKEN_KEY = 'mursion-scheduler-refresh-token';
const TOKEN_EXPIRATION_DURATION_KEY = 'mursion-scheduler-token-expiration-duration';
const SURVEY_COMPLETED = 'surveyCompleted';
const SAME_SITE_OPTION = { SameSite: 'Lax' };
const SECURE_OPTION = (window.location.protocol === 'https:' ? { secure: true } : {});
const OPTIONS = {
  ...SAME_SITE_OPTION,
  ...SECURE_OPTION,
};

export enum AuthTokenState {
  REFRESHING = 'REFRESHING',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export default class AuthService {

  public static tokenState: AuthTokenState = AuthTokenState.INVALID;

  public static updateTokenState(tokenState: AuthTokenState) {
    AuthService.tokenState = tokenState;
  }

  /**
   * Removes previously saved JWT token from cookies
   */
  public static clearToken(): void {
    Cookies.remove(TOKEN_KEY);
    Cookies.remove(REFRESH_TOKEN_KEY);
    Cookies.remove(TOKEN_EXPIRATION_DURATION_KEY);
    Cookies.remove(SURVEY_COMPLETED);
    AuthService.updateTokenState(AuthTokenState.INVALID);
  }

  /**
   * Returns JWT token containing both access and refresh token
   */
  public static getTokenContainer(): IAuthToken | null {
    const access_token = this.getToken(); // tslint:disable-line
    const refresh_token = this.getRefreshToken(); // tslint:disable-line
    const refresh_token_exp_duration = this.getTokenExpirationDate(); // tslint:disable-line

    return access_token && refresh_token && refresh_token_exp_duration
      ? {
        access_token,
        refresh_token,
        refresh_token_exp_duration,
      } : null;
  }

  /**
   * Returns JWT token if it is
   */
  public static getToken(): string | undefined {
    return Cookies.get(TOKEN_KEY);
  }

  public static getTokenExpirationDuration(): string | undefined {
    return Cookies.get(TOKEN_EXPIRATION_DURATION_KEY);
  }

  /**
   * Returns JWT refresh token if it is
   */
  public static getRefreshToken(): string | undefined {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  /**
   * Returns JWT token expiration date
   */
  public static getTokenExpirationDate(): number | undefined {
    const expirationDuration = Cookies.get(TOKEN_EXPIRATION_DURATION_KEY);

    return expirationDuration ? +expirationDuration : undefined;
  }

  /**
   * Saves JWT token in cookies
   */
  public static saveToken(tokenContainer: IAuthToken): void {
    if (!tokenContainer || !tokenContainer.access_token || !tokenContainer.refresh_token || !tokenContainer.refresh_token_exp_duration) {
      throw new Error('Token can\'t be empty');
    }

    Cookies.set(TOKEN_KEY, tokenContainer.access_token, OPTIONS);
    Cookies.set(REFRESH_TOKEN_KEY, tokenContainer.refresh_token, OPTIONS);
    Cookies.set(TOKEN_EXPIRATION_DURATION_KEY, tokenContainer.refresh_token_exp_duration.toString(), OPTIONS);
    AuthService.updateTokenState(AuthTokenState.VALID);
  }

}
