import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { ProjectStatus } from 'src/app/data/projects/interfaces/IProject';
import { EntitySelector, IEntitySelectorBaseProps, ValueToOptionMapper } from 'src/components/EntitySelectors';
import { getOptionValue } from 'src/app/data/common/utils';
import { TLocaleId } from 'src/i18n';

export interface IProjectStatusSelectorProps extends IEntitySelectorBaseProps<ProjectStatus> {
  onChange: (val: ProjectStatus[] | null) => void;
}

const items = [
  ProjectStatus.ACTIVE,
  ProjectStatus.COMPLETED,
  ProjectStatus.ARCHIVED,
];

const itemLabels: { [key in ProjectStatus]: TLocaleId } = {
  [ProjectStatus.ACTIVE]: 'MursionPortal.ProjectStatus.Active',
  [ProjectStatus.COMPLETED]: 'MursionPortal.ProjectStatus.Completed',
  [ProjectStatus.ARCHIVED]: 'MursionPortal.ProjectStatus.Archived',
};

const ProjectStatusSelector: FunctionComponent<IProjectStatusSelectorProps> = (props) => {
  const intl = useIntl();

  const valueToOption: ValueToOptionMapper<ProjectStatus, ProjectStatus> = (value) => ({
    value,
    label: intl.formatMessage({ id: itemLabels[value] })
  });

  return (
    <EntitySelector
      {...props}
      themeType={SelectorTheme.Cobalt}
      isMulti={true}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'MursionPortal.Filters.Status' })}
      searchPlaceholder={intl.formatMessage({ id: 'MursionPortal.Filters.PlaceHolder.SearchStatus' })}
      items={items}
      disabled={false}
      valueToOption={valueToOption}
      optionToValue={getOptionValue}
    />
  );
};

export default React.memo(ProjectStatusSelector);
