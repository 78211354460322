import React, { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import actions from 'src/app/redux/store/actions';
import {
  usePagedListFetchingWithOptions
} from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import EntitySelector from './EntitySelectorBase/EntitySelector';
import { IEntitySelectorBaseProps } from 'src/components/EntitySelectors';


const SimulationSelector: FunctionComponent<IEntitySelectorBaseProps> = (props) => {
  const intl = useIntl();
  const [search, setSearch] = useState('');

  const itemsInfo = usePagedListFetchingWithOptions(actions.simulation.fetchSimulationIds)()({
    page: 0,
    size: 100,
    archive: 'active',
    filter: search,
  });

  const items = itemsInfo?.items.map(v => ({id: v, name: v})) || [];

  const itemsInformation = {
    ...itemsInfo,
    items,
  };

  return (
    <EntitySelector
      {...props}
      isSearchable={true}
      placeholder={intl.formatMessage({ id: 'Dashboard.Filters.SimulationID' })}
      itemsInfo={itemsInformation}
      onInputChange={setSearch}
    />
  );
};

export default SimulationSelector;
