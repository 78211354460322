import RestService from 'src/app/services/rest/RestService';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import IAssetProject, {
  IAssetProjectUpdate,
  IAssetSettings,
  IAssetSettingsUpdate
} from 'src/app/data/client/interfaces/IAsset';
import IEntityQuery from 'src/app/data/common/interfaces/IEntityQuery';
import stringifyEntityQuery from 'src/app/data/common/utils/stringifyEntityQuery';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import { intl } from 'src/i18n/createIntl';

const fetchAssetProject = (assetProjectId: string): Promise<IAssetProject> => {
  if (!assetProjectId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/asset/project/${assetProjectId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchAssetProjectList = (pageData: IListPageData): Promise<IAssetProject[]> => {
  return RestService.fetch(`${RestService.REST_URL}/asset/project/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchAssetProjectListWithOptions = (params: IPageRequestParams): Promise<IListDataResponse<IAssetProject>> => {
  const { pageData, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/asset/project/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const updateAssetProject = (assetProjectId: string, assetProject: IAssetProjectUpdate): Promise<IAssetProject> => {
  if (!assetProjectId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/asset/project/${assetProjectId}`, {
    body: JSON.stringify(assetProject),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

// Asset settings -----------------

const fetchAssetSettings = (assetSettingsId: string, query?: IEntityQuery): Promise<IAssetSettings[]> => {
  if (!assetSettingsId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/asset/settings/${assetSettingsId}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateAssetSettings = (assetSettingsId: string, assetSettings: IAssetSettingsUpdate): Promise<IAssetSettings> => {
  if (!assetSettingsId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/asset/settings/${assetSettingsId}`, {
    body: JSON.stringify(assetSettings),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const fetchAssetSettingsByScenario = (scenarioId: string): Promise<IAssetSettings[]> => {
  if (!scenarioId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/asset/settings/scenario/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};
const fetchAssetSettingsByScenarioTemplate = (scenarioTemplateId: string): Promise<IAssetSettings[]> => {
  if (!scenarioTemplateId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/asset/settings/scenarioTemplate/${scenarioTemplateId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchAssetSettingsByScenarioWithOptions = (params: IAbortableRequestParams & { scenarioId: string }): Promise<IAssetSettings[]> => {
  const { scenarioId, signal } = params;

  if (!scenarioId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/asset/settings/scenario/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};
const fetchAssetSettingsByScenarioTemplateWithOptions = (params: IAbortableRequestParams & { scenarioId: string }): Promise<IAssetSettings[]> => {
  const { scenarioId, signal } = params;

  if (!scenarioId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/asset/settings/scenarioTemplate/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const syncAssetProjects = (): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/asset/project/sync`, {
    body: '',
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

export default {
  fetchAssetProject,
  fetchAssetProjectList,
  updateAssetProject,
  fetchAssetSettings,
  updateAssetSettings,
  fetchAssetSettingsByScenario,
  fetchAssetSettingsByScenarioTemplate,

  fetchAssetSettingsByScenarioWithOptions,
  fetchAssetSettingsByScenarioTemplateWithOptions,
  fetchAssetProjectListWithOptions,

  syncAssetProjects,
};
