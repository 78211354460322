export const APPLY_CLIENTS = 'CALENDAR:APPLY_CLIENTS';
export const APPLY_PROJECTS = 'CALENDAR:APPLY_PROJECTS';
export const APPLY_SIMSPECIALIST = 'CALENDAR:APPLY_SIMSPECIALIST';
export const SELECT_CLIENT = 'CALENDAR:SELECT_CLIENT';
export const SELECT_SIMSPECIALIST = 'CALENDAR:SELECT_SIMSPECIALIST';
export const SELECT_PROJECT = 'CALENDAR:SELECT_PROJECT';
export const SELECT_SCENARIO = 'CALENDAR:SELECT_SCENARIO';
export const SET_AVAILABLE_LEARNERS_COUNT = 'CALENDAR:SET_AVAILABLE_LEARNERS_COUNT';
export const SET_CERTIFICATE_ID = 'CALENDAR:SET_CERTIFICATE_ID';
export const SET_DELIVERY_MODE = 'CALENDAR:SET_DELIVERY_MODE';
export const SET_SCENARIO_TIMELINE = 'CALENDAR:SET_SCENARIO_TIMELINE';
export const SET_SCHEDULING_BLOCKED = 'CALENDAR:BLOCK_SCHEDULING';
export const SET_SELECTED_LEARNERS = 'CALENDAR:SET_SELECTED_LEARNERS';
export const SET_SESSION_LENGTH = 'CALENDAR:SET_SESSION_LENGTH';
export const APPLY_FILTERS = 'CALENDAR:APPLY_FILTERS';
export const SELECT_TIMEZONE = 'CALENDAR:SELECT_TIMEZONE';
export const CLOSE_OVERVIEW_PANEL = 'CALENDAR:CLOSE_OVERVIEW_PANEL';
export const CHANGE_ENTITY_FILTER = 'CALENDAR:CHANGE_ENTITY_FILTER';
export const CHANGE_ENTITY_SEARCH_FILTER = 'CALENDAR:CHANGE_ENTITY_SEARCH_FILTER';
export const CLEAR_ENTITY_SEARCH_FILTERS = 'CALENDAR:CLEAR_ENTITY_SEARCH_FILTERS';
export const CHANGE_ENTITY_INDUSTRIES_FILTER = 'CALENDAR:CHANGE_ENTITY_INDUSTRIES_FILTER';
export const TOGGLE_EVENT_VIEW_DIALOG = 'CALENDAR:TOGGLE_EVENT_VIEW_DIALOG';
export const SET_CURRENT_RANGE = 'CALENDAR:SET_CURRENT_RANGE';
export const SET_CURRENT_DATE = 'CALENDAR:SET_CURRENT_DATE';
export const RESET = 'CALENDAR:RESET';
