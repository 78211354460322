import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { DateRangePicker, DateRangePickerShape } from 'react-dates';
import { useIntl } from 'react-intl';
import moment from 'moment';
import useWindowSize from 'src/hooks/useWindowSize';
import TranslateMessage from 'src/i18n/TranslateMessage';
import styles from './A11yDateRangeSelector.css';
import cn from 'classnames';
import { DateRangeSelectorTheme } from 'src/components/EntitySelectors/DateRangeSelector';

export type IA11yDateRangeSelector = DateRangePickerShape & { themeType?: DateRangeSelectorTheme };

const A11yDateRangeSelector: FunctionComponent<IA11yDateRangeSelector> = (props) => {
  const { startDate, endDate, focusedInput } = props;
  const intl = useIntl();
  const dateRangeRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  
  const [key, setKey] = useState<number>(Date.now());

  const initialVisibleMonth = () => {
    return focusedInput === 'endDate' && endDate ? endDate : startDate || moment();
  };

  const getVisibleMonth = () => {
    return startDate && endDate
      ? initialVisibleMonth
      : undefined;
  };

  useEffect(() => {
    const isDateChanged = startDate && endDate && !(startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year'));
    if (isDateChanged) {
      setKey(Date.now());
    }
  }, [focusedInput]);

  useEffect(() => {
    const inputFields = dateRangeRef?.current?.getElementsByTagName('input');

    if (props.focusedInput === null) {

      const buttons = dateRangeRef?.current?.getElementsByTagName('button');
      if (inputFields) {
        inputFields[0].setAttribute('tabindex', '-1');
        inputFields[1].setAttribute('tabindex', '-1');
        if (!props.withPortal) {
          inputFields[1].addEventListener('keydown', handleKeyPressOnDateRangeSelector);
        }
      }
      if (buttons) {
        buttons[0].setAttribute('aria-label', intl.formatMessage({ id: 'MursionPortal.AriaLabel.DateRangeCalendarButton' }));
      }
    } else {
      if (inputFields) {
        inputFields[0].removeAttribute('tabindex');
        inputFields[1].removeAttribute('tabindex');
      }
    }

    return () => {
      if (!props.withPortal) {
        const input = dateRangeRef?.current?.getElementsByTagName('input');
        if (input) {
          input[1].removeEventListener('keydown', handleKeyPressOnDateRangeSelector);
        }
      }
    };


  }, [dateRangeRef.current, props.focusedInput]);


  const handleKeyPressOnDateRangeSelector = (e: any) => {
    if (e.keyCode === 9) {
      props.onFocusChange(null);
    }
  };

  const renderRevisedDateRange = () => {
    const fromLabel = intl.formatMessage({ id: "MursionPortal.AriaLabel.From" });
    const toLabel = intl.formatMessage({ id: "MursionPortal.AriaLabel.To" });
    const inline = true;
    const { withPortal, withFullScreenPortal, themeType, ...restProps } = props;

    return (
      <div
        className={cn(
          styles.timeRange,
          inline && styles.inline,
          themeType === DateRangeSelectorTheme.Cobalt && "DateRangePickerCobalt"
        )}
        ref={dateRangeRef}
      >
        <span tabIndex={0} aria-label={fromLabel}>
          {TranslateMessage("Filters.Date.From")} &nbsp;
        </span>
        <DateRangePicker
          {...restProps}
          key={key}
          initialVisibleMonth={getVisibleMonth()}
          withPortal={false}
          withFullScreenPortal={false}
          customInputIcon={<i className={"fa fa-calendar-alt"} />}
          orientation={orientation}
          customArrowIcon={
            <span aria-label={`${fromLabel} - ${toLabel}`} className={styles.labelPadding}>
              {TranslateMessage("Filters.Date.To")}
            </span>
          }
        />
      </div>
    );
  };

  const mobileDevice = windowSize.width <= 680;
  const tabDevice = windowSize.width <= 925;
  const orientation = mobileDevice ? 'vertical' : 'horizontal';
  const numberOfMonths = tabDevice ? 2 : 3;

  function getDateRangePicker(){
    if(props.withPortal){
      return (
        <DateRangePicker
            {...props}
            numberOfMonths={numberOfMonths}
            customInputIcon={<i className={'fa fa-calendar-alt'}/>}
            orientation = {orientation}
            key={key}
            initialVisibleMonth={getVisibleMonth()}
            customCloseIcon={
              <svg className={styles.closeButton} viewBox="0 0 12 12">
                <path fill-rule="evenodd" d="M11.53.47a.75.75 0 0 0-1.061 0l-4.47 4.47L1.529.47A.75.75 0 1 0 .468 1.531l4.47 4.47-4.47 4.47a.75.75 0 1 0 1.061 1.061l4.47-4.47 4.47 4.47a.75.75 0 1 0 1.061-1.061l-4.47-4.47 4.47-4.47a.75.75 0 0 0 0-1.061z"/>
              </svg>
            }
        />
      );
    }
    return (
      <DateRangePicker
        {...props}
        numberOfMonths={numberOfMonths}
        orientation={orientation}
        customInputIcon={<i className={'fa fa-calendar-alt'}/>}
        key={key}
        initialVisibleMonth={getVisibleMonth()}
      />
    );
  }

  function dateRangeSelectorHandler(){
    if(props.themeType === DateRangeSelectorTheme.Cobalt){
      return renderRevisedDateRange();
    }
    return (
      <div
        className={styles.DateRangePicker}
        ref={dateRangeRef}>
        {getDateRangePicker()}
      </div>
    );
  }

  return (
    <>
      {dateRangeSelectorHandler()}
    </>
  );
};

export default A11yDateRangeSelector;