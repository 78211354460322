exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".LocaleDropdown__localeDropdownContainer___17e4e{width:100%;-ms-flex:0 1 120px;flex:0 1 120px;letter-spacing:-1px;text-align:center}.LocaleDropdown__localeDropdownControl___2ZF8J{-webkit-box-shadow:inset 0 2px 5px rgba(0,0,0,.1);box-shadow:inset 0 2px 5px rgba(0,0,0,.1);border-radius:25px}.LocaleDropdown__localeDropdownContainer___17e4e input{border:0;-webkit-box-shadow:none;box-shadow:none;background:transparent;height:1.6rem!important}.LocaleDropdown__inline___1n8GI{margin:0;display:inline-block}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/LocaleDropdown/LocaleDropdown.css"],"names":[],"mappings":"AAAA,iDACE,WAAY,AACZ,mBAAoB,AAChB,eAAgB,AACpB,oBAAqB,AACrB,iBAAmB,CACpB,AAED,+CACE,kDAAuD,AAC/C,0CAA+C,AACvD,kBAAoB,CACrB,AAED,uDACE,SAAU,AACV,wBAAyB,AACjB,gBAAiB,AACzB,uBAAwB,AACxB,uBAA0B,CAC3B,AAED,gCACE,SAAU,AACV,oBAAsB,CACvB","file":"LocaleDropdown.css","sourcesContent":[".localeDropdownContainer {\n  width: 100%;\n  -ms-flex: 0 1 120px;\n      flex: 0 1 120px;\n  letter-spacing: -1px;\n  text-align: center;\n}\n\n.localeDropdownControl {\n  -webkit-box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);\n          box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);\n  border-radius: 25px;\n}\n\n.localeDropdownContainer input {\n  border: 0;\n  -webkit-box-shadow: none;\n          box-shadow: none;\n  background: transparent;\n  height: 1.6rem !important;\n}\n\n.inline {\n  margin: 0;\n  display: inline-block;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"localeDropdownContainer": "LocaleDropdown__localeDropdownContainer___17e4e",
	"localeDropdownControl": "LocaleDropdown__localeDropdownControl___2ZF8J",
	"inline": "LocaleDropdown__inline___1n8GI"
};