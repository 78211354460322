import * as queryString from 'querystring';
import RestService from 'src/app/services/rest/RestService';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import { IScenarioDraft, IScenarioTemplate } from 'src/app/data/projects/interfaces/IScenario';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import IUser from 'src/app/data/common/interfaces/IUser';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import ITimeblocks from 'src/app/data/common/interfaces/ITimeblocks';

export enum SessionListQueryType {
  ALL = 'all',
  COMPLETED = 'completed',
  NON_COMPLETED = 'nonCompleted',
  IN_PROGRESS = 'inProgress',
}

export interface ISessionListQuery {
  client?: string;
  scenario?: string;
  team?: string;
  type?: SessionListQueryType;
  participatedFilter?: boolean;
}

export interface ISessionWizardTinyItem {
  id: number | string;
  name: string;
}

export interface ISessionWizardTinyScenario extends ISessionWizardTinyItem {
  draft?: IScenarioDraft;
  deliveryMode: SessionType;
  sessionLength: number;
  nameCustomized?: string;
}

export interface ITimeAllocationResponse {
  success: boolean;
  allocationPeriod: number;
}

export interface IAllocateTimeRequest {
  scenarioId: string;
  interval: {
    startDate: number;
    endDate: number;
  };
}

export interface ITimeBlockOptions {
  scenarioId: number | string;
  isTraining: boolean;
  startTime: number;
  endTime: number;
  timezone: string;
  signal?: AbortSignal | null;
  simAvailabilityOnly?: boolean;
}
// For PS AM SS roles
const fetchClientsWithActiveScenarios = (pageData: IListPageData, filterParams: any, signal: AbortSignal | null = null): Promise<IListDataResponse<ISessionWizardTinyItem>> => {
  const params = queryString.stringify({
    ...filterParams,
    nameOnly: true,
    order: 'name',
  });

  return RestService.fetch(`${RestService.REST_URL}/client/list/withActiveScenario${getPageDataUrl(pageData)}&${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

// TODO: nameOnly =  true?
const fetchProjectsWithActiveScenarios = (pageData: IListPageData, clientId: number | string, filterParams: any, signal: AbortSignal | null = null): Promise<IListDataResponse<ISessionWizardTinyItem>> => {
  const params = queryString.stringify({
    ...filterParams,
    nameOnly: true,
    order: 'name',
  });

  return RestService.fetch(`${RestService.REST_URL}/project/list/${clientId}/withActiveScenario${getPageDataUrl(pageData)}&${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchSessionWizardActiveScenarios = (pageData: IListPageData, projectId: number | string, training?: boolean, includeDraft: boolean = false, signal: AbortSignal | null = null, nonAutoSimScenarios: boolean = false): Promise<IListDataResponse<ISessionWizardTinyScenario>> => {
  const params = queryString.stringify({
    nameOnly: true,
    order: 'name',
    includeDraft,
    training,
    nonAutoSimScenarios,
  });

  return RestService.fetch(`${RestService.REST_URL}/scenario/list/active/project/${projectId}${getPageDataUrl(pageData)}&${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchSessionWizardActiveScenarioTemplates = (pageData: IListPageData, licenseeId: string, scenarioVersion: ScenarioVersion, signal: AbortSignal | null = null): Promise<IListDataResponse<IScenarioTemplate>> => {
  const params = queryString.stringify({
    order: 'name',
    scenarioVersion,
  });

  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/list/${licenseeId}/active${getPageDataUrl(pageData)}&${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchSimSpecsByScenarioId = (pageData: IListPageData, scenarioId: number | string, startTime: number, endTime: number, training?: boolean, signal: AbortSignal | null = null, simAvailability?:boolean): Promise<IListDataResponse<ICompanyUser>> => {
  const params = queryString.stringify({
    from: startTime,
    to: endTime,
    order: 'firstName',
    training,
    simAvailability,
  });

  return RestService.fetch(`${RestService.REST_URL}/user/licensee/ssWithTime/scenario/${scenarioId}${getPageDataUrl(pageData)}&${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchSimSpecsByScenarioTemplateId = (pageData: IListPageData, scenarioTemplateId: number | string, startTime: number, endTime: number, signal: AbortSignal | null = null): Promise<IListDataResponse<ICompanyUser>> => {
  const params = queryString.stringify({
    from: startTime,
    to: endTime,
    order: 'firstName',
  });

  return RestService.fetch(`${RestService.REST_URL}/user/licensee/ssWithTime/scenarioTemplate/${scenarioTemplateId}${getPageDataUrl(pageData)}&${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchAvailableLearners = (pageData: IListPageData, scenarioId: number | string, startTime: number, endTime: number, signal: AbortSignal | null = null): Promise<IListDataResponse<ILearnerExtended>> => {
  const params = queryString.stringify({
    from: startTime,
    to: endTime,
    order: 'firstName',
  });

  return RestService.fetch(`${RestService.REST_URL}/user/learner/freeForScenario/${scenarioId}${getPageDataUrl(pageData)}&${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchAvailableSsLearners = (pageData: IListPageData, licenseeId: string, startTime: number, endTime: number, signal: AbortSignal | null = null): Promise<IListDataResponse<IUser>> => {
  const params = queryString.stringify({
    availableFrom: startTime,
    availableTo: endTime,
    order: 'firstName',
    roleId: 'simspecialist',
  });

  return RestService.fetch(`${RestService.REST_URL}/user/licensee/${licenseeId}/list${getPageDataUrl(pageData)}&${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchScenarioAvailableTimeBlocks = (timeBlockOptions: ITimeBlockOptions): Promise<ITimeblocks> => {
  const { scenarioId, isTraining, startTime, endTime, timezone, simAvailabilityOnly, signal = null } = timeBlockOptions;
  const params = queryString.stringify({
    training: isTraining || false,
    from: startTime,
    to: endTime,
    timezone,
    simAvailabilityOnly,
  });

  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}/timeblocks/ext?${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchScenarioTemplateAvailableTimeBlocks = (
  scenarioTemplateId: number | string,
  startTime: number,
  endTime: number,
  timezone: string,
  signal: AbortSignal | null = null
): Promise<ITimeblocks> => {
  const params = queryString.stringify({
    from: startTime,
    to: endTime,
    timezone
  });

  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/${scenarioTemplateId}/timeblocks/ext?${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const allocateAvailability = (
  allocateTimeRequest: IAllocateTimeRequest,
  signal: AbortSignal | null = null
): Promise<ITimeAllocationResponse> => {

  return RestService.fetch(`${RestService.REST_URL}/availability/allocation/allocate`, {
    body: JSON.stringify(allocateTimeRequest),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal
  });
};

const reAllocateAvailability = (
  sessionId: string,
  timeInterval: {
    startDate: number,
    endDate: number,
  },
  signal: AbortSignal | null = null
): Promise<ITimeAllocationResponse> => {

  return RestService.fetch(`${RestService.REST_URL}/availability/allocation/allocate/${sessionId}`, {
    body: JSON.stringify(timeInterval),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    signal
  });
};

const releaseAvailability = (signal: AbortSignal | null = null): Promise<void> => {

  return RestService.fetch(`${RestService.REST_URL}/availability/allocation/release`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
    signal
  });
};

export default {
  fetchClientsWithActiveScenarios,
  fetchProjectsWithActiveScenarios,
  fetchSessionWizardActiveScenarios,
  fetchSessionWizardActiveScenarioTemplates,
  fetchSimSpecsByScenarioId,
  fetchSimSpecsByScenarioTemplateId,
  fetchAvailableLearners,
  fetchAvailableSsLearners,
  fetchScenarioAvailableTimeBlocks,
  fetchScenarioTemplateAvailableTimeBlocks,
  allocateAvailability,
  reAllocateAvailability,
  releaseAvailability,
};
