import IRestError, { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import { IAppState } from 'src/app/redux/store/reducers';
import { parseErrorMessage } from 'src/app/data/common/utils/errorUtils';

export const getList = (state: IAppState) => state.environments.list.items;
export const getListPage = (state: IAppState): number => state.environments.list.page || 0;
export const getListSizePerPage = (state: IAppState): number => state.environments.list.sizePerPage || 0;
export const getListTotalSize = (state: IAppState): number => state.environments.list.totalSize || 0;
export const isListFetched = (state: IAppState): boolean => state.environments.list.fetched;
export const getListRefreshError = (state: IAppState): IRestError | null => state.environments.list.refreshError;
export const isListRefreshing = (state: IAppState): boolean => state.environments.list.refreshing;

export const getListByIndustry = (state: IAppState) => state.environments.listByIndustry.items;
export const getListByIndustryPage = (state: IAppState): number => state.environments.listByIndustry.page || 0;
export const getListByIndustrySizePerPage = (state: IAppState): number => state.environments.listByIndustry.sizePerPage || 0;
export const getListByIndustryTotalSize = (state: IAppState): number => state.environments.listByIndustry.totalSize || 0;
export const isListByIndustryFetched = (state: IAppState): boolean => state.environments.listByIndustry.fetched;
export const getListByIndustryRefreshError = (state: IAppState): IRestError | null => state.environments.listByIndustry.refreshError;
export const isListByIndustryRefreshing = (state: IAppState): boolean => state.environments.listByIndustry.refreshing;

export const getErrorMessage = (state: IAppState): string => parseErrorMessage(state.environments.error);
export const getValidationErrors = (state: IAppState): IErrorValidation[] => state.environments.error.validations;

export const getCurrentEnvironent = (state: IAppState) => state.environments.currentEnvironment.item;
export const isCurrentEnvironentFetched = (state: IAppState): boolean => state.environments.currentEnvironment.fetched;
export const getCurrentEnvironentRefreshError = (state: IAppState): IRestError | null => state.environments.currentEnvironment.fetchError;
export const isCurrentEnvironentRefreshing = (state: IAppState): boolean => state.environments.currentEnvironment.fetching;

export const isRefreshing = (state: IAppState) =>
  isListRefreshing(state)
  || isListByIndustryRefreshing(state)
  || isListByIndustryRefreshing(state);
