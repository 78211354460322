import { AnyAction, Dispatch } from 'redux';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';
import * as types from './types';

/**
 * Clears all user data (used on logout)
 */
const clearUserData = (): AnyAction => ({
  type: types.CLEAR_USER_DATA,
});

const getUserProfile = () => async (dispatch: Dispatch) => {
  try {
    const profile = await rest.fetchUserProfile();

    dispatch(receivedUserProfile(profile));
  } catch (e) {
    // todo: throw error if it is not Auth Error
  }
};

/**
 * User Profile has been received.
 * @param profile
 */
const receivedUserProfile = (profile: IUserProfile): AnyAction => ({
  profile,
  type: types.RECEIVED_USER_PROFILE,
});

const acceptEULA = () => async (dispatch: Dispatch) => {
  try {
    const profile = await rest.accentEULA();

    dispatch(receivedUserProfile(profile));
  } catch (e) {
    // todo: throw error if it is not Auth Error
  }
};

export default {
  clearUserData,
  getUserProfile,
  receivedUserProfile,
  // tslint:disable-next-line:object-literal-sort-keys
  fetchUserProfile: actionFromPromise(rest.fetchUserProfile),
  fetchUserProfileLinks: actionFromPromise(rest.fetchUserProfileLinks),
  passTutorial: actionFromPromise(rest.passTutorial, 'USER_PROFILE', true),
  passAppInstallation: actionFromPromise(rest.passAppInstallation, 'USER_PROFILE', true),
  saveUserProfile: actionFromPromise(rest.saveUserProfile, 'USER_PROFILE', true),
  updateProfileTimezone: actionFromPromise(rest.updateProfileTimezone, 'USER_PROFILE', true),
  updateProfileLanguage: actionFromPromise(rest.updateProfileLanguage, 'USER_PROFILE', true),
  unlinkSsoProfile: actionFromPromise(rest.unlinkSsoProfile),
  fetchMappedSSOClaimNames: actionFromPromise(rest.fetchMappedSSOClaimNames),
  acceptEULA,
  userOnboard: actionFromPromise(rest.userOnboard),
  validateTOTP: actionFromPromise(rest.validateTOTP),
  resendTOTP: actionFromPromise(rest.resendTOTP),
};
