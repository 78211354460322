import React, { FunctionComponent } from 'react';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { useIntl } from 'react-intl';
import { EntitySelector } from 'src/components/EntitySelectors';
import { getOptionValue } from 'src/app/data/common/utils';
import { TLocaleId } from 'src/i18n';

export interface IStatusSelectorProps {
  onChange: (val: string | number) => void;
  isSimSpecialist?: boolean;
  theme?: SelectorTheme;
  value: string;
}

export enum RequestStatusType {
  ALL = 'ALL',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

const RequestStatusTypeLabel: { [key in RequestStatusType]: TLocaleId } = {
  [RequestStatusType.ALL]: 'MursionPortal.RequestStatus.All',
  [RequestStatusType.ACCEPTED]: 'MursionPortal.RequestStatus.Accepted',
  [RequestStatusType.DECLINED]: 'MursionPortal.RequestStatus.Declined',
  [RequestStatusType.PENDING]: 'MursionPortal.RequestStatus.Pending',
};

const StatusSelector: FunctionComponent<IStatusSelectorProps> = (props) => {
  const intl = useIntl();

  const items: RequestStatusType[] = [
    RequestStatusType.ALL,
    RequestStatusType.ACCEPTED,
    RequestStatusType.DECLINED,
    RequestStatusType.PENDING,
  ];

  const valueToOption = (value: string) => ({
    value,
    label: intl.formatMessage({ id: RequestStatusTypeLabel[value] }),
  });

  return (
  <EntitySelector
    {...props}
    clearOnOptionsChange={true}
    themeType={props.theme || SelectorTheme.Filter}
    isMulti={false}
    isSearchable={false}
    placeholder={props.isSimSpecialist ? intl.formatMessage({ id: 'MursionPortal.Selector.RequestStatus' }) : intl.formatMessage({ id: 'MursionPortal.RequestStatus.All' })}
    items={items}
    loading={false}
    disabledTitle={''}
    showSelectedValue={true}
    valueToOption={valueToOption}
    optionToValue={getOptionValue}
    searchPlaceholder={intl.formatMessage({ id: 'Dashboard.UsersActivity.Filter.Roles.Placeholder' })}
    ariaLabel={intl.formatMessage({ id: 'MursionPortal.Selector.RequestStatus' })}
  />
  );
};

export default React.memo(StatusSelector);
