import { getEntityErrorType } from 'src/app/redux/utils/getEntityErrorType';

export const createClearFieldValidationErrorAction = (entityName: string) => {
  const types = getEntityErrorType(entityName);

  return (fieldName: string) => ({
    type: types.CLEAR_VALIDATION,
    fieldName
  });
};
