import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import createItemActions from 'src/app/redux/utils/createItemActions';
import rest from './rest';

export default {
  fetchClientConfig: actionFromPromise(rest.fetchClientConfig),
  fetchClientConfigWithOptions: actionFromPromise(rest.fetchClientConfigWithOptions),
  fetchClientConfigForClientUsers: createItemActions('CLIENT_CONFIG_FOR_CLIENTS', rest.fetchClientConfig),
  fetchClientConfigForEditSession: createItemActions('SESSION_CLIENT_CONFIG', rest.fetchClientConfig),
  fetchDashboardClientConfig: createItemActions('DASHBOARD_CLIENT_CONFIG', rest.fetchClientConfig),
  updateClientConfig: actionFromPromise(rest.updateClientConfig),
  updateVideoRetentionFields: actionFromPromise(rest.updateVideoRetentionFields),
};
