import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import RestService from 'src/app/services/rest/RestService';
import { ISessionMonitoringUser, ISessionUser } from 'src/layouts/analytics/SessionMonitoringDashboard/interfaces/ISessionMonitoringUser';
import { IWebSocketP2pNotification } from 'src/layouts/analytics/SessionMonitoringDashboard/IWebSocketMessages';

export interface ISessionMonitoring {
  id: string;
  startDate: number;
  endDate: number;
  status: SessionStatusType;
  scenarioName: string;
  scenarioVersion: ScenarioVersion;
  simspecialist: ISessionMonitoringUser;
  learners: ISessionMonitoringUser[];
  listKey: string;
}

export interface ISessionMonitoringDetailInformation {
  id: string;
  startDate: number;
  endDate: number;
  status: SessionStatusType;
  scenarioName: string;
  simspecialist: ISessionUser;
  deliveryMode: SessionType;
  learners: ISessionUser[];
}

export interface ISessionMonitoringSummary {
  normalUsers: number;
  issueUsers: number;
  notLoggedUsers: number;
  errorUsers: number;
  preparationUsers: number;
  totalUsers: number;
}

const SESSION_MONITORING_REST_URL = window.location.protocol + '//' + window.location.host + '/session-monitor/rest';

const fetchSessions = (providers: string[], clientIds: string[], startDate: number, endDate: number, size: number, offset: number): Promise<ISessionMonitoring[]> => {
  return RestService.fetch(`${SESSION_MONITORING_REST_URL}/session/list/${offset}/${size}`, {
    body: JSON.stringify(
      {
        licenseeIds: providers,
        clientIds,
        from: startDate,
        to: endDate
      }
    ),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
  });
};

const fetchSummaryStatusesValues = (providers: string[], clientIds: string[], startDate: number, endDate: number): Promise<ISessionMonitoringSummary> => {
  return RestService.fetch(`${SESSION_MONITORING_REST_URL}/session/user/status/summary`, {
    body: JSON.stringify(
      {
        licenseeIds: providers,
        clientIds,
        from: startDate,
        to: endDate
      }
    ),
    headers: RestService.generateHeaders({ 'Content-Type': 'application/json' }),
    method: 'POST',
  });
};

const fetchSession = (sessionId: string): Promise<ISessionMonitoringDetailInformation> => {
  return RestService.fetch(`${SESSION_MONITORING_REST_URL}/session/${sessionId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchUsersDetailedInformationByGroupId = (sessionId: string, user1Id: string, user2Id: string, groupIds: string[]): Promise<IWebSocketP2pNotification[]> => {
  return RestService.fetch(`${SESSION_MONITORING_REST_URL}/session/${sessionId}/user/${user1Id}/${user2Id}/attribute?groupIds=${groupIds}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  fetchSessions,
  fetchSession,
  fetchSummaryStatusesValues,
  fetchUsersDetailedInformationByGroupId
};
