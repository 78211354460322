import React, { FunctionComponent, useCallback } from 'react';
import styles from './TimeRangeSlider.css';
import moment, { Moment } from 'moment-timezone';
import { Range } from 'rc-slider';
import { getTimeFormat } from 'src/app/data/common/utils/dateUtil';
import TimeRangeSliderHandle
  from 'src/components/TimeRangeSlider/TimeRangeSliderHandle';

export interface ITimeRangeSliderProps {
  min: Moment;
  max: Moment;
  defaultStart: Moment;
  defaultEnd: Moment;
  tzId: string;
  onChange: (start: Moment, end: Moment) => void;
}

const TimeRangeSlider: FunctionComponent<ITimeRangeSliderProps> = (props) => {

  const { min, max, tzId, defaultStart, defaultEnd, onChange } = props;

  const step = moment.duration(30, 'm').asMilliseconds();

  const onChangeRange = (values: number[]) => {
    onChange(moment.tz(values[0], tzId), moment.tz(values[1], tzId));
  };

  const handle = useCallback((p: any) =>
      <TimeRangeSliderHandle key={p.index} tzId={tzId} {...p}/>
    , [tzId]
  );

  return (
    <div className={styles.container}>
      <Range
        min={min.valueOf()}
        max={max.valueOf()}
        defaultValue={[defaultStart.valueOf(), defaultEnd.valueOf()]}
        marks={{
          [min.valueOf()]: {
            label: min.format(getTimeFormat()),
            style: { width: 70 }
          },
          [max.valueOf()]: {
            label: max.format(getTimeFormat()),
            style: { width: 70 }
          },
        }}
        step={step}
        allowCross={false}
        pushable={step}
        onChange={onChangeRange}
        handle={handle}
      />
    </div>
  );
};

export default TimeRangeSlider;