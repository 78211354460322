import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import cn from 'classnames';
import styles from 'src/components/LocaleDropdown/LocaleDropdown.css';
import { LOCALES } from 'src/i18n';
import { useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import Selector from 'src/components/Selector/Selector';

export interface ILocaleDropdownProps {
  onChange: (language: string) => void;
  ariaLabel?: string;
  required?: boolean;
  defaultValue? : string | null;
  removeMenuPortalTarget?: boolean;
}

interface IOptionType {
  label: string;
  value: string;
}

const LocaleDropdown: FunctionComponent<ILocaleDropdownProps> = (props) => {

  const {removeMenuPortalTarget,
  } = props;

  const [selectedOption, setSelectedOption] = useState<IOptionType>();
  const locale = useSelector(selectors.profile.getUserLanguage);

  useEffect(() => {
    setInitialValue();
  }, [props?.defaultValue]);

  const getOptions = useMemo(() => {
    const supportedLanguages = Object.entries(LOCALES);
    const options = [];
    for (const [label, value] of supportedLanguages) {
      options.push({ value, label });
    }
    return options;
  },[]);

  const handleChange = (selectedLanguage: IOptionType) => {
    setSelectedOption(selectedLanguage);
    props.onChange(selectedLanguage.value);
  };

  const setInitialValue = () => {
    const options = getOptions;
    const value = props.defaultValue || locale;
    const initialValue = options.find(e => e.value === value) || options[0];
    handleChange(initialValue);
  };

  return (
    <div className={cn(styles.localeDropdownContainer, styles.inline, 'innerInputFocusNone')}>
      <Selector
         isMulti={false}
         value={selectedOption}
         onChange={handleChange}
         options={getOptions}
         required={props.required ? 'required' : ''}
         ariaLabel={props.ariaLabel + ' ' + selectedOption?.label}
         classNamePrefix={'language'}
         removeMenuPortalTarget={removeMenuPortalTarget}
      />
    </div>
  );
};

export default LocaleDropdown;