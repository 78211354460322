import React, { FunctionComponent } from 'react';
import actions from 'src/app/redux/store/actions';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { useRawListDataFetchingWithOptions } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { useIntl } from 'react-intl';
import { EntitySelector, IEntitySelectorBaseProps } from '.';

export interface IIndustrySelectorProps extends IEntitySelectorBaseProps {
  onChange: (val: INamedEntry[] | null | undefined) => void;
  themeType?: SelectorTheme;
}

const IndustrySelector: FunctionComponent<IIndustrySelectorProps> = (props) => {

  const intl = useIntl();
  const itemsInfo = useRawListDataFetchingWithOptions(actions.industries.fetchIndustriesListWithOptions)()();

  return (
    <EntitySelector
      {...props}
      themeType={props.themeType || SelectorTheme.Filter}
      isMulti={true}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'Dashboard.SchedulingInfo.Filter.IndustriesAll' })}
      searchPlaceholder={intl.formatMessage({ id: 'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint' })}
      disabledTitle={intl.formatMessage({ id: 'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle' })}
      itemsInfo={itemsInfo}
    />
  );
};

export default React.memo(IndustrySelector);
