import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MatomoProvider, createInstance, useMatomo } from '@jonkoops/matomo-tracker-react';

import { PROD_HOST_NAME } from 'src/app/data/common/constants';
import selectors from 'src/app/redux/selectors';

interface IMatomoProps extends RouteComponentProps {
  children: React.ReactNode;
}
interface IMatomoWrapperProps {
  children: React.ReactNode;
}

const MATOMO_CONFIGS: Record<string, { urlBase: string, siteId: number, email: number, clientId: number }> = {
  production: {
    urlBase: "https://matomo.mursion.com",
    siteId: 2,
    email: 2,
    clientId: 1
  },
  default: { 
    urlBase: "https://matomo-alpha.mursion.com",
    siteId: 2,
    email: 2,
    clientId: 1
  }
};

const host = window.location.hostname;
const config = host === PROD_HOST_NAME ? MATOMO_CONFIGS.production : MATOMO_CONFIGS.default;
const instance = createInstance({ urlBase: config.urlBase, siteId: config.siteId });

const Matomo = ({ children, location }: IMatomoProps) => {
    const userProfile = useSelector(selectors.profile.getUserProfile);
    const pathname = location.pathname;
    const { pushInstruction } = useMatomo();

    useEffect(() => {
      if (userProfile) {
        pushInstruction('setUserId', userProfile.id);
        pushInstruction('setCustomDimension', config.email, userProfile.email);
        pushInstruction('setCustomDimension', config.clientId, userProfile.clientId);
        pushInstruction('trackPageView');
      }
    }, [userProfile]);

    useEffect(() => {
      pushInstruction('setCustomUrl', pathname);
      pushInstruction('trackPageView');
    }, [pathname]);

    return <>{children}</>;
};

const MatomoWithRouter = withRouter(Matomo);

const MatomoWrapper = ({ children }: IMatomoWrapperProps) => {
  return(
    <MatomoProvider value={instance}>
      <MatomoWithRouter>
        {children}
      </MatomoWithRouter>
    </MatomoProvider>
  );
};

export default MatomoWrapper;
