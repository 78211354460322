import React, { Component } from 'react';
import styles from 'src/components/ScenarioInfo/AssetViewV2/AssetViewV2.css';
import { IScenarioDraftUpdate } from 'src/app/data/projects/interfaces/IScenario';
import cn from 'classnames';
import EnvironmentField
  from 'src/layouts/common/Projects/components/ProjectCard/components/common/ScenarioEditForm/EnvironmentField/EnvironmentField';
import AvatarsField
  from 'src/layouts/common/Projects/components/ProjectCard/components/common/ScenarioEditForm/AvatarsField/AvatarsField';
import IndustryField
  from 'src/layouts/common/Projects/components/ProjectCard/components/common/ScenarioEditForm/IndustryField/IndustryField';
import TranslateMessage from 'src/i18n/TranslateMessage';

interface IAssetViewV3Props {
  industryId: string | null;
  scenarioDraft: IScenarioDraftUpdate | null;
  viewMode?: boolean;
  labelClassName?: string;
  onChange?: (scenarioDraft: IScenarioDraftUpdate) => void;
  isScenarioTemplate?: boolean;
  className?: string;
}

interface IAssetViewV3State {
  industryId: string;
}

class AssetViewV2 extends Component<IAssetViewV3Props, IAssetViewV3State> {

  public state: IAssetViewV3State = {
    industryId: '',
  };

  public componentDidMount(): void {
    this.setState({
      industryId: this.props.industryId || '',
    });
  }

  public componentDidUpdate(prevProps: Readonly<IAssetViewV3Props>, prevState: Readonly<IAssetViewV3State>, snapshot?: any): void {
    if (this.props.industryId && this.props.industryId !== prevProps.industryId) {
      this.setState({
        industryId: this.props.industryId || '',
      });
    }
  }

  public render() {

    const { viewMode, scenarioDraft, labelClassName, isScenarioTemplate, className } = this.props;
    const industryId = this.state.industryId;
    if (!scenarioDraft) {
      return null;
    }

    const { environmentId, avatarIds } = scenarioDraft;

    return (
      <div className={cn(styles.assetViewWrap, className, viewMode && styles.viewMode)}>
        {
          (!viewMode || isScenarioTemplate) &&
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <div className={cn(styles.label, labelClassName)}>
                {TranslateMessage('Session.Edit.Modal.AssetIndustry')}{!viewMode ? '*' : ''}
              </div>
              <IndustryField
                industryId={industryId}
                viewMode={viewMode}
                onChange={this.onChangeIndustry}
              />
            </div>
          </div>
        }
        <div className={styles.formRow}>
          <div className={styles.formCol}>
            <div className={cn(styles.label, labelClassName)}>
              {TranslateMessage('Session.Edit.Modal.SimulationEnvironment')}{!viewMode ? '*' : ''}
            </div>
            <EnvironmentField
              industryId={industryId}
              environmentId={environmentId || ''}
              viewMode={viewMode}
              onChange={this.onChangeEnvironment}
            />
          </div>
        </div>
        <div className={cn(styles.formRow)}>
          <div className={styles.formCol}>
            <div className={cn(styles.label, labelClassName)}>
              {TranslateMessage('Session.Edit.Modal.AvatarInSimulation')}{!viewMode ? '*' : ''}
            </div>
            <AvatarsField
              environmentId={environmentId || ''}
              avatarIds={avatarIds || []}
              viewMode={viewMode}
              onChange={this.onChangeAvatars}
            />
          </div>
        </div>
      </div>
    );
  }


  private onChangeIndustry = (industryId: string) => {
    const { scenarioDraft } = this.props;
    if (!scenarioDraft) {
      return;
    }

    this.setState({
      industryId,
    });


    if (this.props.onChange) {
      this.props.onChange({
        ...scenarioDraft,
        environmentId: '',
        avatarIds: [],
      });
    }
  }


  private onChangeEnvironment = (environmentId: string) => {
    const { scenarioDraft } = this.props;
    if (!scenarioDraft) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange({
        ...scenarioDraft,
        environmentId,
        avatarIds: [],
      },);
    }
  }

  private onChangeAvatars = (avatarIds: string[]) => {
    const { scenarioDraft } = this.props;
    if (!scenarioDraft) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange({
        ...scenarioDraft,
        avatarIds,
      },);
    }
  }
}

export default AssetViewV2;
