import RestService from 'src/app/services/rest/RestService';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import generateFormData from 'src/app/data/common/utils/generateFormData';
import IEntityQuery from 'src/app/data/common/interfaces/IEntityQuery';
import stringifyEntityQuery from 'src/app/data/common/utils/stringifyEntityQuery';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import {
  ISsoConfigListItem,
  ISsoSchema,
  ISsoSchemaQuery,
  ISsoSchemaUpdate
} from 'src/app/data/ssoSchema/interfaces/ISsoSchema';
import ISsoSchemasCount from 'src/app/data/ssoSchema/interfaces/ISsoSchemasCount';
import { intl } from 'src/i18n/createIntl';

const createNewSsoSchema = (ssoSchemaData: ISsoSchemaUpdate, logo: File | null): Promise<ISsoSchema> => {
  const updatedClientData = { ...ssoSchemaData };

  return RestService.fetch(`${RestService.REST_URL}/sso`, {
    body: generateFormData(updatedClientData, 'data', logo),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchSsoSchemasList = (params: IPageRequestParams & ISsoSchemaQuery): Promise<IListDataResponse<ISsoConfigListItem>> => {
  const { pageData, clientIds, schemas, shared, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/sso/list`, {
    body: JSON.stringify({
      clientIds: { value: clientIds },
      schemas,
      shared,
      ...pageData,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json ',
    }),
    method: 'POST',
    signal,
  });
};


const fetchSsoMetadata = (ssoId: string, signal: AbortSignal | null = null): Promise<any> => {

  return RestService.fetch(`${RestService.REST_URL}/sso/${ssoId}/saml-metadata`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/xml ',
    }),
    method: 'GET',
    signal,
  });
};

const updateSsoSchema = (ssoId: string, ssoSchemaData: any, logo: File | null): Promise<ISsoSchema> => {
  const updatedData = { ...ssoSchemaData, timezoneId: ssoSchemaData.timezoneId || null };

  return RestService.fetch(`${RestService.REST_URL}/sso/${ssoId}`, {
    body: generateFormData(updatedData, 'data', logo),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const fetchSsoSchemaWithOptions = (params: IAbortableRequestParams & { ssoId: string, query?: IEntityQuery | null }): Promise<ISsoSchema> => {
  const { ssoId, query, signal } = params;

  if (!ssoId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.SSOIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/sso/${ssoId}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchSsoSchema = (ssoId: string, query?: IEntityQuery | null, signal: AbortSignal | null = null): Promise<ISsoSchema> => {
  return fetchSsoSchemaWithOptions({ signal, ssoId, query });
};

const deleteSsoSchema = (ssoId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/sso/${ssoId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const restoreSsoSchema = (ssoId: string): Promise<ISsoSchema> => {
  return RestService.fetch(`${RestService.REST_URL}/sso/${ssoId}/restore/`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchSsoSchemasCountWithOptions = (params: IAbortableRequestParams): Promise<ISsoSchemasCount> => {
  const { signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/sso/count`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

export default {
  fetchSsoSchemasList,
  fetchSsoSchema,
  fetchSsoSchemaWithOptions,
  createNewSsoSchema,
  updateSsoSchema,
  restoreSsoSchema,
  deleteSsoSchema,
  fetchSsoSchemasCountWithOptions,
  fetchSsoMetadata,
};
