import cn from 'classnames';
import { filter as _filter, map as _map, } from 'lodash';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';
import ErrorMessage from 'src/components/ErrorMessage';
import SearchField from 'src/components/SearchField';
import IRestError from '../../app/data/common/interfaces/IRestError';
import IconButton from '../IconButton/IconButton';
import RefreshIndicator from '../RefreshIndicator/RefreshIndicator';
import RemovableLabel from '../RemovableLabel';
import Separator from '../Separator/Separator';
import ItemPickerListComponent from './components/ItemPickerListComponent/ItemPickerListComponent';
import styles from './ItemPicker.css';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { IRemovableLabelProps } from 'src/components/RemovableLabel/RemovableLabel';
import ITeam from 'src/app/data/client/interfaces/ITeam';

export interface IPickerItem<T = any> {
  data: T;
  disabled?: boolean;
  id: string;
  invalid?: boolean;
  selected: boolean;
  hidden?: boolean;
  assigned?: boolean;
}

export interface IItemPickerListProps {
  className?: string;
  items: IPickerItem[];
}

export interface IItemPickerListItemProps {
  item: IPickerItem;
}

export interface IItemPickerBaseProps extends WrappedComponentProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  listClassName?: string;
  ItemListComponent?: React.ComponentClass<IItemPickerListProps>;
  ItemComponent: React.ComponentClass<IItemPickerListItemProps> | FunctionComponent<IItemPickerListItemProps>;
  SelectedItemComponent?: React.ComponentClass<IItemPickerListItemProps> | FunctionComponent<IItemPickerListItemProps>;
  items: IPickerItem[];
  itemsFetchError: IRestError | null;
  itemsFetched: boolean;
  itemsFetching: boolean;
  emptyListMessage: string;
  max?: number;
  keyExtractor: (item: IPickerItem, index: number) => string;
  searchFilter?: (text: string, item: IPickerItem) => boolean;
  selectedItemTitleExtractor: (item: IPickerItem) => string;
  searchFieldAriaLabel?: string;
  onSearch?: (search: string) => void;
  mappedTeam?: ITeam[];
}

export interface IItemPickerProps extends IItemPickerBaseProps {
  showExpanded?: boolean;
  onChange: (items: IPickerItem) => void;
  onItemListExpand?: () => void;
  autoAssignChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IItemPickerState {
  itemsListCollapsed: boolean;
  searchText: string;
  errorMessage: string;
  ariaLiveMsg: string;
}

class ItemPicker extends React.Component<IItemPickerProps, IItemPickerState> {

  public state: IItemPickerState = {
    itemsListCollapsed: true,
    searchText: '',
    errorMessage: '',
    ariaLiveMsg: '',
  };
  public selectedItemsRef: React.RefObject<HTMLDivElement> | null = React.createRef();

  public componentDidUpdate(prevProps: any, prevState: IItemPickerState) {
    if (prevState.itemsListCollapsed && !this.state.itemsListCollapsed
      && this.props.onItemListExpand
    ) {
      this.props.onItemListExpand();
    }
  }

  public render() {
    const { className, disabled, showExpanded } = this.props;
    const cls = cn([
      className,
      styles.pickerContainer,
    ]);
    const { itemsListCollapsed } = this.state;

    return (
      <div className={cls}>
        <div className={styles.selectedItemsRow}>
          {!disabled && !showExpanded && itemsListCollapsed && this.renderEditButton()}
          {this.renderSelectedItems()}
          <div ref={this.selectedItemsRef} className={'visibilityHidden'} aria-live="polite"
               role="status">{this.state.ariaLiveMsg}</div>
        </div>
        {
          !disabled && (showExpanded || !itemsListCollapsed) && <React.Fragment>
            <Separator/>
            {this.renderItemsList()}
            {!showExpanded && this.renderAcceptButton()}
          </React.Fragment>
        }
      </div>
    );
  }

  private renderSearch() {
    const { searchFieldAriaLabel } = this.props;
    return (
      <SearchField
        className={styles.searchField}
        id={`${this.props.id || 'items'}-search`}
        onChange={this.onSearch}
        value={this.state.searchText}
        ariaLabel={searchFieldAriaLabel}
      />
    );
  }

  private onSearch = (searchText: string) => {
    this.setState({
      searchText,
    });

    if (this.props.onSearch) {
      this.props.onSearch(searchText);
    }
  };

  private renderAcceptButton() {
    return (
      <div className={cn(styles.buttonRow, 'buttonWrap')}>
        <Button
          onClick={this.toggleView}
        >
          {TranslateMessage('Settings.Avatars.Modal.EditButton.Accept')}
        </Button>
      </div>
    );
  }

  private renderEditButton() {
    const { intl } = this.props;
    return (
      <div className={cn(styles.edit,'editBtnWrap')}>
        <IconButton
          label={intl.formatMessage({ id: 'Settings.Avatars.Modal.Button.Edit' })}
          iconName="pencil-alt"
          onClick={this.toggleView}
        />
      </div>
    );
  }

  private renderItemsList() {
    const { listClassName, ItemListComponent, searchFilter } = this.props;
    const { errorMessage } = this.state;
    const restItems = this.getRestItems();

    return (
      <div className={cn(styles.listContainer, 'listContainer')}>
        {
          errorMessage &&
          <>
            <ErrorMessage>
              {errorMessage}
            </ErrorMessage>
            <Separator/>
          </>
        }
        {
          !!searchFilter && (restItems.length > 0 || !!this.state.searchText) && this.renderSearch()
        }
        {
          ItemListComponent
            ? <ItemListComponent className={listClassName} items={restItems}/>
            : this.renderDefaultListComponent(restItems)
        }
      </div>
    );
  }

  private toggleView = () => {
    const { itemsListCollapsed, searchText } = this.state;

    this.setState({
      itemsListCollapsed: !itemsListCollapsed,
      searchText: !itemsListCollapsed ? '' : searchText, // clear the search field on picker collapse
    });
  };

  private getRestItems() {
    return this.props.items.filter(item =>
      !item.selected && (!this.props.searchFilter || this.props.searchFilter(this.state.searchText, item)));
  }

  private renderDefaultListComponent(items: IPickerItem[]) {
    const { itemsFetched, itemsFetchError, itemsFetching, ItemComponent, listClassName } = this.props;

    const props = {
      ItemComponent,
      className: listClassName,
      fetched: itemsFetched,
      items,
      loading: itemsFetching,
      loadingError: itemsFetchError,
      onClick: this.addItem,
    };

    return (<ItemPickerListComponent {...props}/>);
  }

  private renderSelectedItems() {
    const { SelectedItemComponent } = this.props;
    const extractKey = (item: IPickerItem, index: number) => {
      return this.props.keyExtractor(item, index);
    };
    const selectedItems = _filter(this.props.items, { selected: true });
    const selectedItemsElement = _map(selectedItems, (item: IPickerItem, index: number) => {
      if (item.hidden) {
        return null;
      }

      const key = extractKey(item, index);
      const props: IRemovableLabelProps = {
        className: cn(
          styles.selectedItem,
          { [styles.invalidItem]: item.invalid },
          { [styles.customItem]: !!SelectedItemComponent },
        ),
        iconClassName: styles.iconRemove,
        onRemove: this.removeItem,
        removeArgument: item,
        showRemoveButton: !this.props.disabled && !item.disabled,
      };

      return (
        <RemovableLabel key={key + index} {...props} as={"span"}>
          {SelectedItemComponent ? <SelectedItemComponent item={item}/> : this.props.selectedItemTitleExtractor(item)}
        </RemovableLabel>
      );
    });

    return (
      <div className={styles.selectedItemsContainer}>
        <RefreshIndicator show={this.props.itemsFetching}/>

        {
          selectedItemsElement.length
            ? selectedItemsElement
            : this.props.itemsFetched
              ? <span>{this.props.emptyListMessage}</span>
              : ''
        }
      </div>
    );
  }

  private addItem = (item: IPickerItem) => {
    const { items, max, intl } = this.props;

    if (max) {
      const selectedItems = _filter(items, { selected: true });

      if (selectedItems.length >= max) {
        this.setState({
          errorMessage: max > 1
            ? intl.formatMessage({ id: 'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable' }, { max })
            : intl.formatMessage({ id: 'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable' }, { max })
        });

        return;
      }
    }
    this.setState({
      ariaLiveMsg: this.props.selectedItemTitleExtractor(item) + ' ' + intl.formatMessage({ id: 'MursionPortal.VisibilityHidden.EntityAdded' }),
    });

    this.props.onChange({
      ...item,
      selected: true,
    });
  };

  private removeItem = (item: IPickerItem) => {
    this.setState({
      errorMessage: '',
      ariaLiveMsg: this.props.selectedItemTitleExtractor(item) + ' ' + this.props.intl.formatMessage({ id: 'MursionPortal.VisibilityHidden.EntityRemoved' }),
    });

    this.props.onChange({
      ...item,
      selected: false,
    });
  };
}

export default injectIntl(ItemPicker);
