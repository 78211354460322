import React, { ComponentType } from 'react';

function retry(fn: () => Promise<{ default: any }>, retriesLeft = 10, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // maximum retries exceeded;
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  }) as Promise<{ default: ComponentType<any> }>;
}

export default (r: () => Promise<{ default: any }>, retryCount: number = 5, interval: number = 1000) =>
  React.lazy(() => retry(r, retryCount, interval));
