import styles from 'src/layouts/common/Calendar/components/ProjectOverview/ProjectOverview.css';
import React, { Component } from 'react';
import cn from 'classnames';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { getPlainText } from 'src/components/TinyMceEditor/TinyMceEditor';

export interface IDescriptionFieldProps extends WrappedComponentProps {
  text: string | JSX.Element[] | JSX.Element;
  maxLength?: number;
  className?: string;
  expandText?: string;
}

interface IDescriptionFieldState {
  showMore: boolean;
}

export const getDescriptionText = (vignette: string | null | undefined) => {
  if (vignette) {
    return getPlainText(vignette);
  }
  return '';
};

class DescriptionField extends Component<IDescriptionFieldProps, IDescriptionFieldState> {


  public render() {

    const { text, className, intl } = this.props;

    const content = text ? text : intl.formatMessage({ id: 'Settings.ScenarioBank.Modal.NoDescriptionSpecified' });
       
    return (
      <p className={cn(styles.vignetteField, className)}>{content}</p>
    );
  }
}


export default injectIntl(DescriptionField);
