import RestService from 'src/app/services/rest/RestService';

const reInviteUser = (userId: string) => {
  return RestService.fetch(`${RestService.REST_URL}/user/${userId}/reinvite`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

export default {
  reInviteUser,
};
