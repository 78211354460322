export default interface IAuthData {
  email: string;
  currentSchema: IAuthSchema | null;
  password?: string;
  redirectUrl?: string;
}

export interface IAuthSchema {
  id?: string;
  schema: AuthType;
  shared?: boolean;
  name?: string;
  icon?: string;
  unlinkAllowed?: boolean;
  singleLogoutEnabled?: boolean;
}

export interface IExternalLoginData {
  id: string;
  email: string;
  type: AuthType;
  error: boolean;
}

export enum AuthType {
  PASSWORD = 'PASSWORD',
  LTI = 'LTI',
  OAUTH2 = 'OAUTH2',
}

export const PARAM_LOGIN_EXTERNAL = 'mrsn-login-external';
export const PARAM_LINK_EXTERNAL = 'mrsn-link-external';
export const PARAM_LOGIN_FAILED = 'auth_failure';

export const LOGOUT_TIMEOUT = 2000;
export const UPLOAD_BACKGROUND_PROCESS = 'mrsn-upload-background-process';