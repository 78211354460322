import cn from 'classnames';
import * as React from 'react';
import { ReactElement } from 'react';
import styles from './SelectListItemPlain.css';
import LazyImage from 'src/components/LazyImage';
import Skeleton from 'react-loading-skeleton';

export interface ISelectListItemPlainProps extends React.HTMLProps<HTMLElement> {
  className?: string;
  imageClassName?: string;
  selected?: boolean;
  imageSrc?: string;
  onClick?: () => void;
  fallback?: string;
  circle?: boolean;
  refreshing?: boolean;
  itemRenderer?: (props: any) => ReactElement;
}

class SelectListItemPlain extends React.Component<ISelectListItemPlainProps> {
  public render() {
    const {
      refreshing,
      imageSrc,
      imageClassName,
      fallback,
      circle,
      onClick,
      selected,
      title,
      children,
      className,
    } = this.props;

    const handleStepOnKeyPress = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
      if (keyboardEvent.key === 'Enter') {
        if (onClick) {
          onClick();
        }
      }
    };

    return (
      <div
        className={cn(
          styles.itemContainer,
          selected && styles.selected,
          refreshing && styles.disabled,
          className
        )}
        onClick={onClick}
        tabIndex={0}
        onKeyDown={handleStepOnKeyPress}
      >
        {
          refreshing
            ? (
              <>
                <Skeleton circle={true} width={40} height={40}/>
                <div style={{ width: '100%' }}><Skeleton count={2}/></div>
              </>
            )
            : (
              <>
                {
                  imageSrc !== undefined &&
                  <div className={imageClassName}>
                    <LazyImage
                      src={imageSrc}
                      className={styles.image}
                      fallback={fallback}
                      circle={circle}
                    />
                  </div>
                }
                <div className={styles.text} title={title}>
                  {children}
                </div>
              </>
            )
        }
      </div>
    );
  }
}

export default SelectListItemPlain;
