import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import { createClearEntityErrorAction } from '../../utils/createClearEntityErrorAction';
import createItemActions from '../../utils/createItemActions';
import createListActions from '../../utils/createListActions';
import rest from './rest';

export default {
  createNewContract: actionFromPromise(rest.createNewContract),
  getContract: actionFromPromise(rest.getContract),
  getContractsShortList:actionFromPromise(rest.getContractsShortList),
  archiveContract:actionFromPromise(rest.archiveContract),
  refreshContractsList: createListActions('CONTRACTS', rest.fetchContractsList),
  editContract: actionFromPromise(rest.editContract),
  addContractToProject:actionFromPromise(rest.addContractToProject,'CONTRACTS',true),
  fetchContractsForProject:actionFromPromise(rest.fetchContractsForProject),
  clearError: createClearEntityErrorAction('CONTRACTS'),
  fetchContractCountWithOptions:actionFromPromise(rest.fetchContractCountWithOptions),
  fetchContractCount:createItemActions('CLIENT_CONTRACTS_COUNT',rest.fetchContractsCount),
  deleteProjectContract: actionFromPromise(rest.deleteProjectContract),
  restoreContract: actionFromPromise(rest.restoreContract),
};
