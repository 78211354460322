import { toast } from 'react-toastify';
import styles from 'src/hooks/useCopyToClipboard/useCopyToClipboard.css';
import * as React from 'react';
import TranslateMessage from 'src/i18n/TranslateMessage';
import copy from 'copy-to-clipboard';
import Button, { ButtonSize, ButtonType } from 'src/components/Button';
import { useIntl } from 'react-intl';

export interface IUseCopyToClipboardChildParams {
  onClickToCopy: (e: any) => void;
}

export interface IUseCopyToClipboardProps {
  value: string | number;
  messageTitle: string;
  autoClose?: number | false | undefined;
  children: (childParams: IUseCopyToClipboardChildParams) => any;
  cobaltToast?: boolean;
}

const COPIED_TEXT_MAX_LENGTH = 250; // Its string length

const UseCopyToClipboard = (props: IUseCopyToClipboardProps) => {
  const { value, messageTitle, autoClose, cobaltToast } = props;

  const copiedTextWord = value.toString();
  const formattedTextWord = copiedTextWord.length > COPIED_TEXT_MAX_LENGTH ? `${copiedTextWord.substring(0, COPIED_TEXT_MAX_LENGTH)}...` : copiedTextWord;

  const copyToClipboard = (str: string) => {
    copy(str);
  };

  const intl = useIntl();

  const dismissToast = () => {
    toast.dismiss();
  };

  const onClickToCopy = (e: any) => {
    e.stopPropagation();
    copyToClipboard(value.toString());

    showToast();
  };

  const showToast = () => {
    let toastContent = (
      <p>
        <i className={'far fa-copy'}/> {messageTitle}
        <br/>
        {TranslateMessage('MursionPortal.CopyText.CopiedToClipboard',
        {
          code: () => <strong> {formattedTextWord} </strong> ,
          lineBreak: <br/>,
          value
        })
        }
      </p>
    );

    const getToastTheme = () => {
      return cobaltToast ? styles.cobaltToast : styles.defaultToast;
    };

    const toastOptions = {
      autoClose,
      className: getToastTheme(),
      toastClassName: getToastTheme(),
      bodyClassName: styles.notification,
      progressClassName: styles.notificationProgressbar,
    };

    if (cobaltToast) {
      toastContent = (
        <>
          <div className={styles.cobaltToastMessage}>
            {messageTitle}
          </div>
          <div className={styles.cobaltToastfooter}>
            <Button 
              btnType={ButtonType.BLUE} 
              btnSize={ButtonSize.SMALL} 
              aria-label={intl.formatMessage({ id: "ConfirmationDialogue.Button.Ok" })}
              onClick={dismissToast}>
              {intl.formatMessage({ id: "ConfirmationDialogue.Button.Ok" })}
            </Button>
          </div>
        </>
      );
      toastOptions.autoClose = false;
    }

    toast(toastContent, toastOptions);
  };

  return props.children({
    onClickToCopy
  });
};

export default UseCopyToClipboard;
