import { combineReducers } from 'redux';
import createListReducer from 'src/app/redux/utils/createListReducer';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';

export interface ICompanyUsersState {
  list: IListDataState<ICompanyUser>;
  listForDashboard: IListDataState<ICompanyUser>;
  listForEditSession: IListDataState<ICompanyUser>;
  error: IErrorState;
}


export default combineReducers({
  list: createListReducer('COMPANY_USERS'),
  listForDashboard: createListReducer('DASHBOARD_USERS'),
  listForEditSession: createListReducer('SESSION_USERS'),
  error: createEntityErrorReducer('COMPANY_USER'),
});
