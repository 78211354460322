exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ErrorMessage__error___HKyqH{color:red;font-style:italic;white-space:pre-wrap}.ErrorMessage__errorDark___3glTJ{color:#000;font-style:normal;white-space:pre-wrap}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/ErrorMessage/ErrorMessage.css"],"names":[],"mappings":"AAAA,6BACE,UAAW,AACX,kBAAmB,AACnB,oBAAsB,CACvB,AAED,iCACE,WAAY,AACZ,kBAAmB,AACnB,oBAAsB,CACvB","file":"ErrorMessage.css","sourcesContent":[".error {\n  color: red;\n  font-style: italic;\n  white-space: pre-wrap;\n}\n\n.errorDark {\n  color: #000;\n  font-style: normal;\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"error": "ErrorMessage__error___HKyqH",
	"errorDark": "ErrorMessage__errorDark___3glTJ"
};