import cn from 'classnames';
import * as React from 'react';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import ITeam from 'src/app/data/client/interfaces/ITeam';
import IRestCollectionInfo from 'src/app/data/common/interfaces/IRestCollectionInfo';
import IUser from 'src/app/data/common/interfaces/IUser';
import getUserName from 'src/app/data/common/utils/getUserName';
import Button, { ButtonSize } from 'src/components/Button';
import ErrorMessage from 'src/components/ErrorMessage';
import IconButton from 'src/components/IconButton/IconButton';
import { IItemPickerListItemProps, IPickerItem } from 'src/components/ItemPicker/ItemPicker';
import Separator from 'src/components/Separator';
import styles from './LearnersPicker.css';
import ItemPicker from 'src/components/ItemPicker';
import Selector, { ISelectorOption } from '../Selector/Selector';
import TeamMembersList from 'src/components/TeamMembersList/TeamMembersList';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import deleteIcon from './images/delete.png';
import { ISession } from 'src/app/data/session/interfaces/ISession';
import { getLearnerStatus, getSessionAttendanceDurationForLearnerStatus, getSessionUserAttendanceLearnerStatus } from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/utils';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';

export interface ILearnersPickerProps {
  disabled?: boolean;
  ignoreTeams?: boolean;
  learnersInfo: IRestCollectionInfo<ILearnerExtended>;
  maxLearners?: number;
  teams: ITeam[];
  selectedLearners: IUser[];
  onChange: (learners: IUser[]) => void;
  onExpand?: () => void;
  session: ISession | null;
  displayLearnerStatus?: boolean;
}

interface ILearnersPickerState {
  selectedTeamOption: ISelectorOption | undefined;
  viewMode: boolean;
  errorMessage: string;
  searchLearnerValue: string;
}

class LearnersPicker extends React.PureComponent<ILearnersPickerProps & WrappedComponentProps, ILearnersPickerState> {

  public state: ILearnersPickerState = {
    selectedTeamOption: undefined,
    viewMode: true,
    errorMessage: '',
    searchLearnerValue: '',
  };

  public componentDidUpdate(preProps: any, prevState: ILearnersPickerState) {
    if ((prevState.viewMode && !this.state.viewMode || !prevState.selectedTeamOption?.value && this.state.selectedTeamOption?.value)
      && this.props.onExpand
    ) {
      this.props.onExpand();
    }
  }

  public render() {
    const { disabled, ignoreTeams, selectedLearners, maxLearners, learnersInfo } = this.props;
    const { viewMode, searchLearnerValue } = this.state;
    const teamLearners: ILearnerExtended[] = learnersInfo.items.filter(this.filterLearner);
    const learners: ILearnerExtended[] = searchLearnerValue ? teamLearners.filter(this.filterLearnerByName) : teamLearners;
    const canAddTeam = !maxLearners || maxLearners - selectedLearners.length >= learners.length;
   
    if (ignoreTeams) {
      return this.renderLearnersWithoutTeamsPicker();
    }

    return (
      <div className={styles.container}>
        {viewMode && !disabled && this.renderEditButton()}
        <div className={cn(styles.learnerPickerCloseBtnWrap, !canAddTeam && styles.teamListVisible)}>
          {!disabled && !viewMode && this.renderLearnersPicker()}
          {
            !viewMode && !disabled && (
              <div className={styles.buttonPanel}>
                <Button
                  btnSize={ButtonSize.MEDIUM}
                  onClick={this.toggleView}
                >
                  {TranslateMessage('MursionPortal.Close')}
                </Button>
              </div>
            )
          }
        </div>
        <div className={cn(styles.selectedLearners, !selectedLearners.length && styles.noLearnerAdded)}>
          {
            !selectedLearners.length && (
              <span>{TranslateMessage('MursionPortal.Text.NoLearnersAddedYet')}</span>
            )
          }
          <div className={styles.simSpecialisttableWrap}>
            <div className={styles.tableHeaderRow}>
              <div className={cn(styles.theadColumn, styles.width70)}>
                {TranslateMessage('Settings.ScenarioBank.Modal.Learner')}
              </div>
              <div className={cn(styles.theadColumn, styles.width30)}>
                {TranslateMessage('MursionPortal.Label.Status')}
              </div>
            </div>
            {selectedLearners.map(this.renderSelectedLearner)}
          </div>
        </div>
      </div>
    );
  }

  private renderEditButton() {
    const { intl } = this.props;
    return (
      <div className={styles.edit}>
        <IconButton
          label={intl.formatMessage({ id: 'Session.Edit.Modal.AddLearner' })}
          iconName='pencil-alt'
          onClick={this.toggleView}
        />
      </div>
    );
  }

  private onLearnerSearchChange = (value: string) => {
    this.setState({
      searchLearnerValue: value
    });
  };

  private renderLearnersWithoutTeamsPicker() {
    const { disabled, learnersInfo, intl } = this.props;
    const itemRenderer = (props: IItemPickerListItemProps) => (
      <div>{getUserName(props.item.data.user)}</div>
    );
    const keyExtractor = (item: IPickerItem) => `learner-${item.data.id}`;
    const titleExtractor = (item: IPickerItem) => getUserName(item.data.user);

    return (
      <ItemPicker
        disabled={disabled}
        ItemComponent={itemRenderer}
        items={this.wrapLearners(learnersInfo.items)}
        itemsFetchError={learnersInfo.fetchError}
        itemsFetched={learnersInfo.fetched}
        itemsFetching={learnersInfo.fetching}
        listClassName={styles.learnersList}
        emptyListMessage={intl.formatMessage({ id: 'Projects.Modal.EmptyMessage.NoLearner' })}
        keyExtractor={keyExtractor}
        max={1}
        selectedItemTitleExtractor={titleExtractor}
        onChange={this.onLearnerChange}
      />
    );
  }

  private onLearnerChange = (item: IPickerItem) => {
    const { selectedLearners } = this.props;
    const learnerIndex = selectedLearners.findIndex(l => l.id === item.data.id);

    if (learnerIndex === -1 && item.selected) {
      this.addLearner(item);
    } else if (learnerIndex > -1 && !item.selected) {
      this.removeLearner(learnerIndex);
    }
  };

  private wrapLearners = (learners: ILearnerExtended[]) => {
    const { selectedLearners } = this.props;

    return learners.map((learner: ILearnerExtended) => {
      return {
        data: learner,
        id: learner.user.id || '',
        selected: selectedLearners.findIndex(l => l.id === learner.user.id) > -1
      };
    });
  };

  private renderLearnersPicker() {
    const { learnersInfo, teams } = this.props;
    const { errorMessage, selectedTeamOption, searchLearnerValue } = this.state;
    const teamLearners: ILearnerExtended[] = learnersInfo.items.filter(this.filterLearner);
    const learners: ILearnerExtended[] = searchLearnerValue ? teamLearners.filter(this.filterLearnerByName) : teamLearners;
    const { maxLearners, selectedLearners, intl } = this.props;

    const canAddTeam = !maxLearners || maxLearners - selectedLearners.length >= learners.length;

    let options: ISelectorOption[] = [{
      value: 'all',
      label: intl.formatMessage({ id: 'MursionPortal.Label.SelectAll' })
    }];
    options = options.concat(teams
      .map((team, i) => ({
        value: team.id,
        label: team.name
      })));

    const keyExtractor = (lrn: ILearnerExtended) => lrn.user.id || '';
    const nameExtractor = (lrn: ILearnerExtended) => getUserName(lrn.user);

    return (
      <>
        {
          errorMessage &&
          <>
            <ErrorMessage>
              {errorMessage}
            </ErrorMessage>
            <Separator/>
          </>
        }

        <div className={styles.teamSelectorWrapper}>
          <Selector
            className={styles.picker}
            value={selectedTeamOption}
            onChange={this.onSelectTeam}
            options={options}
            disabled={!teams.length}
            total={teams.length}
            placeholder={intl.formatMessage({ id: 'Projects.Modal.Placeholder.ChooseATeam' })}
            searchPlaceholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.SearchTeams' })}
          />
          {
            selectedTeamOption
            && <Button
              btnSize={ButtonSize.MEDIUM}
              className={styles.teamAddBtn}
              disabled={!canAddTeam}
              onClick={this.addTeam}
            >
              <i className={cn('fas', 'fa-plus', styles.addBtnIcon)}/>
            </Button>
          }
        </div>
        {
          !canAddTeam
          && <div className={styles.note}>
            <i className={cn('fas', 'fa-exclamation-circle', styles.noteIcon)}/>
            <i
              className={styles.noteText}>{intl.formatMessage({ id: 'Projects.Modal.Text.SessionSize' }, { maxLearners })}</i>
          </div>
        }

        <TeamMembersList
          teamMembers={teamLearners}
          members={learners}
          membersInfo={learnersInfo}
          addMember={this.addLearner}
          selectedTeamOption={selectedTeamOption}
          keyExtractor={keyExtractor}
          nameExtractor={nameExtractor}
          searchPlaceholder={intl.formatMessage({ id: 'Projects.Modal.Placeholder.SearchLearners' })}
          onSearchChange={this.onLearnerSearchChange}
          className={styles.teamMembersList}
          />
      </>
    );
  }

  private toggleView = () => {
    this.setState({
      viewMode: !this.state.viewMode
    });
  };

  private renderSelectedLearner = (learner: IUser, index: number) => {
    const { intl, session, displayLearnerStatus } = this.props;
    const learnerStatus = getLearnerStatus(learner.status);
    const isSessionCompleted = (session?.status === SessionStatusType.COMPLETED);
    const attendanceStatus = isSessionCompleted
      ? getSessionAttendanceDurationForLearnerStatus(learner)
      : getSessionUserAttendanceLearnerStatus(session, learnerStatus);

// Use the attendanceStatus variable wherever you need the result

    return (
      <div className={styles.tableBodyRow}>
        <div className={cn(styles.tbodyColumn, styles.width70)}>
          {getUserName(learner)}
        </div>
        <div className={cn(styles.tbodyColumn, styles.width30)}>
          <div className={styles.statusRemoveBtnWrap}>
          {displayLearnerStatus ?
            <span className={styles.status}>
            {attendanceStatus}
              </span>
              :
              <span className={styles.status}>{`(${intl.formatMessage({ id: 'Mursion.Portal.Status.Pending' })})`}</span>
          }
            {
              !this.props.disabled &&
              <button onClick={this.removeLearner(index)} aria-label={intl.formatMessage({ id: 'MursionPortal.IconButton.Remove' })}><img src={deleteIcon} alt=
              {intl.formatMessage({id:'MursionPortal.AriaImage.Attribute.Delete'})} /></button>
            }
          </div>
        </div>
      </div>
    );
  };

  private addTeam = () => {
    const { maxLearners, selectedLearners } = this.props;
    const { learnersInfo, intl } = this.props;
    const learners = learnersInfo.items.filter(this.filterLearner).map(l => l.user);

    if (maxLearners && selectedLearners.length >= maxLearners) {
      this.setState({ errorMessage: intl.formatMessage({ id: maxLearners > 1 ? 'MursionPortal.ErrorMessage.MaxLearners' : 'MursionPortal.ErrorMessage.MaxLearner' }, { maxLearners }) });

      return;
    }

    this.props.onChange([
      ...this.props.selectedLearners,
      ...learners,
    ]);
  };

  private addLearner = (item: IPickerItem) => {
    const { maxLearners, selectedLearners, intl } = this.props;

    if (maxLearners && selectedLearners.length >= maxLearners) {
      this.setState({ errorMessage: intl.formatMessage({ id: maxLearners > 1 ? 'MursionPortal.ErrorMessage.MaxLearners' : 'MursionPortal.ErrorMessage.MaxLearner' }, { maxLearners }) });

      return;
    }

    this.props.onChange([
      ...this.props.selectedLearners,
      item.data.user,
    ]);
  };

  private removeLearner = (index: number) => () => {
    const { selectedLearners } = this.props;

    selectedLearners.splice(index, 1);
    this.setState({ errorMessage: '' });

    this.props.onChange(selectedLearners);
  };

  private filterLearner = (learner: ILearnerExtended): boolean => {
    return this.state.selectedTeamOption?.value === 'all' ? true :
      (learner.teams || []).some(team => team.id === this.state.selectedTeamOption?.value)
      && !this.props.selectedLearners.some(lrn => lrn.id === learner.user.id);
  };

  private filterLearnerByName = (learner: ILearnerExtended): boolean => {
    return `${learner.user.firstName} ${learner.user.lastName} ${learner.user.email}`
      .toLocaleLowerCase()
      .includes(this.state.searchLearnerValue.toLocaleLowerCase());
  };

  private onSelectTeam = (e: any) => {
    this.setState({
      selectedTeamOption: e,
    });
  };
}

export default injectIntl(LearnersPicker);
