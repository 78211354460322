import cn from 'classnames';
import queryString from 'query-string';
import * as React from 'react';
import { FormControl } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import getUrlParamValue from 'src/app/data/common/utils/queryParamsAccessor';
import Button, { ButtonSize } from 'src/components/Button';
import { PARAM_SORTBY, PARAM_SORTBY_ORDER } from './index';
import styles from './SortByField.css';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';
import { TLocaleId } from 'src/i18n';
import getLabelForOption from 'src/app/data/common/utils/getLabelForOption';


export interface ISortByFieldItem {
  label: TLocaleId;
  value: string;
}

export interface ISearchFieldProps extends RouteComponentProps<any>, WrappedComponentProps {
  id: string;
  fieldNames: ISortByFieldItem[];
  disableSort?: boolean;
}

interface ISortByFieldState {
  sortBy: string;
  sortOrder: 'asc' | 'desc';
}

class SortByField extends React.Component<ISearchFieldProps, ISortByFieldState> {

  public static getDerivedStateFromProps(props: ISearchFieldProps, state: ISortByFieldState) {
    const { location } = props;
    let { sortOrder } = state;
    const sortBy = getUrlParamValue(PARAM_SORTBY, location) || props.fieldNames[0].value;
    const newSortOrder = getUrlParamValue(PARAM_SORTBY_ORDER, location);

    if (newSortOrder) {
      sortOrder = newSortOrder === 'asc' ? 'asc' : 'desc';
    }

    return {
      ...state,
      sortBy,
      sortOrder,
    };
  }

  public state: ISortByFieldState = {
    sortBy: '',
    sortOrder: 'asc',
  };

  public render() {
    const { fieldNames, disableSort } = this.props;
    const { sortBy, sortOrder } = this.state;

    if (!fieldNames || !fieldNames.length) {
      return null;
    }
    const { intl } = this.props;
    return (
      <div className={styles.container}>
        <div className={cn(styles.inputGroup, styles.fieldInput)}>
          <label htmlFor="sort-by" className="visibilityHidden">{TranslateMessage('MursionPortal.SortBy')}</label>
          <FormControl
            id={'sort-by'}
            placeholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.SortBy' })}
            as="select"
            onChange={this.onChange}
            defaultValue={sortBy}
          >
            {
              fieldNames.map((fieldName, i) => (
                <option key={`${i}-sort-by-select`}
                  title={intl.formatMessage({ id: fieldName.label })}
                  value={fieldName.value}>{getLabelForOption(intl.formatMessage({ id: fieldName.label }))}</option>
              ))
            }
          </FormControl>
        </div>
        {
          !disableSort &&
          <div className={cn(styles.orderInput)}>
            {
              sortOrder === 'asc'
                ?
                <Button
                  btnSize={ButtonSize.MEDIUM}
                  onClick={this.onChangeOrder('desc')}
                  aria-label={intl.formatMessage({ id: 'MursionPortal.AriaLabel.SortByDescendingOrder' })}
                >
                  <i className={cn('fas fa-sort-amount-up', styles.sortBtnIcon)} />
                </Button>
                :
                <Button
                  btnSize={ButtonSize.MEDIUM}
                  onClick={this.onChangeOrder('asc')}
                  aria-label={intl.formatMessage({ id: 'MursionPortal.AriaLabel.SortByAscendingOrder' })}
                >
                  <i className={cn('fas fa-sort-amount-down', styles.sortBtnIcon)} />
                </Button>
            }
          </div>
        }
      </div>
    );
  }

  private onChange = (event: any) => {
    this.updateLocation(event.target.value, this.state.sortOrder);
  };

  private onChangeOrder = (order: 'asc' | 'desc') => () => {
    this.updateLocation(this.state.sortBy, order);
  };

  private updateLocation(sortBy: string, order: 'asc' | 'desc') {
    const { history, location } = this.props;
    const params = queryString.parse(location.search);

    location.search = queryString.stringify({
      ...params,
      [PARAM_SORTBY_ORDER]: order,
      [PARAM_SORTBY]: sortBy,
    });

    history.push(location);
  }
}

export default injectIntl(withRouter(SortByField));
