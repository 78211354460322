import RestService from 'src/app/services/rest/RestService';
import { IFilterItem, IItemBankList } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/filter/ItemBankFilters';
import stripHtml from 'src/app/data/common/utils/stripHtml';
import { INextGenScenarioContentData } from 'src/layouts/mursion/ScenarioBankContainer/utils/generateNextGenScenarioBankCreateData';
import { ILibraryInfoState } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioDetails/LibraryInfoContainer/LibraryInfoContainer';
import { IDeliveryDetailsUpdate, IDuplicateScenarioPlanning, INextGenChildAndOrphanScenario, INextGenScenario, generateFormDataForPartsLibraryInfoData } from 'src/app/data/nextGenScenario/interfaces/INextGenScenario';
import { IPublishReady } from 'src/layouts/mursion/ScenarioBankContainer/components/common/PublishScenarioContainer/PublishScenarioContainer';
import { IAvatarPerspectives, IAvatarPerspectivesUpdate } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioAvatarGuide/AvatarPerspectives/AvatarPerspectives';
import { IAvatarGuide } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioAvatarGuide';
import { IScenarioNextGenPlanningUpdate } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioTimeline/TimelineAndScheduling/TimelineAndScheduling';
import { IReflectionGuidePayload } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioAvatarGuide/ReflectionGuide/ReflectionGuide';
import IScenario, { IScenarioPlanningUpdate } from 'src/app/data/projects/interfaces/IScenario';
import { IParamsInfo } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioReferences/NextGenScenarioReferences';
import { IClientCustomizationsUpdate } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioTimeline/ClientCustomizations/ClientCustomizations';

const getAllActivePathwayList = (): Promise<IItemBankList[]> => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/list/pathwayShortDTO`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  }).then((pathways) => {
    return pathways.map((pathway: IFilterItem) => {
      return { id: pathway.id, name: stripHtml(pathway.title) };
    });
  });
};

const getAllActiveItemBankListByType = (itemBankType: string): Promise<IItemBankList[]> => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/list/buildingBlock/${itemBankType}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((domains) => {
    return domains.map((domain: IFilterItem) => {
      return { id: domain.id, name: stripHtml(domain.title), description: domain?.description };
    });
  });
};

const createNextGenScenario = (scenarioDraft: INextGenScenarioContentData): Promise<string> => {
  const {
    buildingBlockOutcome,
    ...restData
  } = scenarioDraft;
  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/create/content`, {
    body: JSON.stringify({...restData}),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updateNextGenScenarioLibraryInfo = (scenarioId: string, coverImageFile: File | null, thumbnailImageFile: File | null, libraryInfoData: ILibraryInfoState): Promise<INextGenScenario> => {
  const {
    coverImage,
    thumbnailImage,
    pathways,
    level,
    ...restData
  } = libraryInfoData;

  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/${scenarioId}/update/library-info`, {
    body: generateFormDataForPartsLibraryInfoData({
      libraryInfo: {
        ...restData,
      },
    }, coverImageFile, thumbnailImageFile),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const updateNextGenScenarioContent = (scenarioId: string, scenarioDraft: INextGenScenarioContentData): Promise<INextGenScenario> => {
  const {
    buildingBlockOutcome,
    ...restData
  } = scenarioDraft;
  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/${scenarioId}/updateContent`, {
    body: JSON.stringify({...restData}),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const fetchNextGenBankScenarioData = (scenarioId: string): Promise<INextGenScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/get/nextGenScenarioContent/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateNextGenScenarioAvatarPerspective = (url: string, avatarPerspective: IAvatarPerspectivesUpdate): Promise<IAvatarPerspectives> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/${url}`, {
    body: JSON.stringify(avatarPerspective),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateNextGenScenarioPrepGuide = (url: string, prepGuide: string): Promise<{id: string, prepGuide: string}> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/${url}`, {
    body: prepGuide,
    headers: RestService.generateHeaders({
      'Content-Type': 'text/plain',
    }),
    method: 'PUT',
  });
};

const updateNextGenScenarioReflectionGuide = (url: string, reflectionGuide: IReflectionGuidePayload) => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/${url}`, {
    body: JSON.stringify(reflectionGuide),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const fetchNextGenScenarioAvatarGuide = (scenarioId: string): Promise<IAvatarGuide> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/avatarGuide/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchScenarioPublishReadyDetails = (scenarioId: string): Promise<IPublishReady> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/${scenarioId}/publishReady`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const publishNextGenScenario = (scenarioId: string): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/${scenarioId}/publish`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const createNextGenBankScenarioTemplate = (scenarioDraft: INextGenScenarioContentData): Promise<string> => {
  const {
    buildingBlockOutcome,
    ...restData
  } = scenarioDraft;
  return RestService.fetch(`${RestService.REST_URL}/nextgen/template/create/content`, {
    body: JSON.stringify({...restData}),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchNextGenBankScenarioTemplate = (scenarioId: string): Promise<INextGenScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/template/get/nextGenScenarioContent/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateNextGenBankScenarioTemplateLibraryInfo = (scenarioId: string, coverImageFile: File | null, thumbnailImageFile: File | null, libraryInfoData: ILibraryInfoState): Promise<INextGenScenario> => {
  const {
    thumbnailImage,
    coverImage,
    pathways,
    level,
    ...restData
  } = libraryInfoData;

  return RestService.fetch(`${RestService.REST_URL}/nextgen/template/${scenarioId}/update/library-info`, {
    body: generateFormDataForPartsLibraryInfoData({
      libraryInfo: {
        ...restData,
      },
    }, coverImageFile, thumbnailImageFile),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const updateNextGenBankScenarioTemplateContent = (scenarioId: string, scenarioDraft: INextGenScenarioContentData): Promise<INextGenScenario> => {
  const {
    buildingBlockOutcome,
    ...restData
  } = scenarioDraft;
  return RestService.fetch(`${RestService.REST_URL}/nextgen/template/${scenarioId}/update/content`, {
    body: JSON.stringify({...restData}),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const createAndUpdateDeliveryDetails = (scenarioId: string, deliveryDetails: IDeliveryDetailsUpdate) => {

  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/${scenarioId}/deliveryDetails`, {
    body: JSON.stringify({ ...deliveryDetails }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateNextGenScenarioPlanning = (scenarioId: string, scenarioPlanning: IScenarioNextGenPlanningUpdate): Promise<INextGenScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}/planning`, {
    body: JSON.stringify(scenarioPlanning),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateNextGenScenarioBankDeliveryDetails = (templateId: string, deliveryDetails: IDeliveryDetailsUpdate): Promise<INextGenScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/template/${templateId}/deliveryDetails`, {
    body: JSON.stringify({ ...deliveryDetails }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

/** gives info whether the side menu items are filled or empty */
const fetchNextGenScenarioSideMenuItemsInfo = (scenarioId: string) => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/${scenarioId}/sectionComplete`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const createNextGenScenarioFromTemplate =
  (templateId: string, projectId: string, scenarioPlanning: IScenarioPlanningUpdate): Promise<IScenario> => {
    return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/template/${templateId}/project/${projectId}`, {
      body: JSON.stringify(scenarioPlanning),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
};

const getChildAndOrphanScenario =
  (scenarioId: string, paramsInfo: IParamsInfo): Promise<INextGenChildAndOrphanScenario> => {
    return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/get/childAndOrphanScenario/${scenarioId}?asc=${paramsInfo.asc}&order=${paramsInfo.order}`, {
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    });
  };

const createDuplicateProjectScenario = (scenarioId: string, projectId: string, scenarioPlanning: IDuplicateScenarioPlanning): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/${scenarioId}/project/${projectId}`, {
    body: JSON.stringify(scenarioPlanning),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const createDuplicateBankScenario = (templateId: string): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/nextgen/template/createDuplicate/${templateId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};
  const updateClientCustomizations = (scenarioId: string, deliveryDetails: IClientCustomizationsUpdate) => {

    return RestService.fetch(`${RestService.REST_URL}/nextgen/scenario/${scenarioId}/customize`, {
      body: JSON.stringify({ ...deliveryDetails }),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    });
  };

export default {
  getAllActivePathwayList,
  getAllActiveItemBankListByType,
  updateNextGenScenarioLibraryInfo,
  updateNextGenScenarioContent,
  createNextGenScenario,
  fetchNextGenBankScenarioData,
  updateNextGenScenarioPrepGuide,
  updateNextGenScenarioReflectionGuide,
  fetchNextGenScenarioAvatarGuide,
  fetchScenarioPublishReadyDetails,
  publishNextGenScenario,
  createNextGenBankScenarioTemplate,
  updateNextGenScenarioAvatarPerspective,
  fetchNextGenBankScenarioTemplate,
  updateNextGenBankScenarioTemplateLibraryInfo,
  updateNextGenBankScenarioTemplateContent,
  createAndUpdateDeliveryDetails,
  updateNextGenScenarioPlanning,
  updateNextGenScenarioBankDeliveryDetails,
  fetchNextGenScenarioSideMenuItemsInfo,
  createNextGenScenarioFromTemplate,
  getChildAndOrphanScenario,
  createDuplicateProjectScenario,
  createDuplicateBankScenario,
  updateClientCustomizations,
};
