import EventEmitter from 'events';

const WS_URL = (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host + '/ws';

export enum READYSTATE {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

class WebSocketService extends EventEmitter {
  public EVENTS = {
    AUTH_ERROR: 'AUTH_ERROR',
    WS_CONNECTED: 'WS_CONNECTED',
    WS_DISCONNECTED: 'WS_DISCONNECTED',
    WS_NEW_MESSAGE: 'WS_NEW_MESSAGE',
  };

  public PING_INTERVAL = 5000;
  public RECONNECT_INTERVAL = 5000;

  private socket: WebSocket;

  public connect() {
    this.disconnect();
    this.socket = new WebSocket(WS_URL);

    this.socket.onopen = () => {
      this.emit(this.EVENTS.WS_CONNECTED);
      this.heartbeat();
    };

    this.socket.onclose = () => {
      this.emit(this.EVENTS.WS_DISCONNECTED);
    };

    this.socket.addEventListener('message', this.messageListener);
  }

  public disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }

  private messageListener = (event:any) => {
    if (event.data === 'pong') {
      if (this.socket.readyState !== READYSTATE.OPEN) {
        this.emit(this.EVENTS.WS_DISCONNECTED);
        this.reconnect();
        return;
      }
      return;
    }

    this.emit(this.EVENTS.WS_NEW_MESSAGE, event.data);
  }

  private heartbeat = () => {
    if (!this.socket || (this.socket.readyState !== READYSTATE.OPEN)) {
      return;
    }

    this.socket.send('ping');
    setTimeout(this.heartbeat, this.PING_INTERVAL);
  }

  private reconnect = () => {
    this.socket.removeEventListener('message', this.messageListener);
    const that = this;
    setTimeout(() => {
      that.connect();
    }, this.RECONNECT_INTERVAL);
  }
}

export default new WebSocketService();
