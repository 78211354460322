import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import * as queryString from 'querystring';
import IScenario, {
  IScenarioDraftCreate,
  IScenarioDraftUpdate,
  IScenarioPlanningUpdate,
  IScenarioSequenceUpdate,
  IScenarioStatusResponseConfig,
  IScenarioTemplate,
  IScenarioTemplateCreate,
  IScenarioWithSequence,
  ScenarioStatus,
} from 'src/app/data/projects/interfaces/IScenario';
import RestService from 'src/app/services/rest/RestService';
import generateFormData from 'src/app/data/common/utils/generateFormData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import IEntityQuery from 'src/app/data/common/interfaces/IEntityQuery';
import stringifyEntityQuery from 'src/app/data/common/utils/stringifyEntityQuery';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { intl } from 'src/i18n/createIntl';
import { IScenarioTemplateWrapper } from 'src/layouts/mursion/ProviderSettings/components/ScenarioBank/components/ScenarioTemplateForm/ScenarioTemplateForm';
import { IItemBankQueryBasic, IItemBankResponseItem, IResourcePageQuery, ISkillPageQuery } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/ItemBankList';
import { IItemBankCreateBasic, IResourceUpdate, ISkillCreate } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankCreate/ItemBankCreate';
import { IItemBank, IItemBankUpdate } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankDetails/ItemBankDetails';
import { ITEM_BANK_PAGE } from 'src/layouts/mursion/ScenarioBankContainer/scenarioBankPageId';
import { ITEM_BANK_API_END_POINT } from 'src/layouts/mursion/ScenarioBankContainer/components/common/AssignResourcesControl/AssignResourceControl';

export interface IClientScenarioQuery {
  draft: boolean;
  repeated: boolean;
  scenarioVersion?: ScenarioVersion;
}

// =================================================================================
// rest/scenario -------------------------------------------------------------------

const createScenario = (scenarioDraft: IScenarioDraftCreate, attachments: File[] | null): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario`, {
    body: generateFormData(scenarioDraft, 'scenario', attachments),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const deleteScenario = (scenarioId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const restoreScenario = (scenarioId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/restore/${scenarioId}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchScenario = (scenarioId: string, query?: IEntityQuery): Promise<IScenario> => {
  if (!scenarioId) {
    throw new Error('fetchScenario: scenario id must be specified');
  }

  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchScenarioName = (scenarioId: string): Promise<string> => {
  return fetchScenario(scenarioId, { nameOnly: true }).then(scenario => scenario.name);
};

const fetchActiveScenarios = (page: number, size: number, training: boolean): Promise<IScenario[]> => {
  const params = queryString.stringify({ training });

  return RestService.fetch(`${RestService.REST_URL}/scenario/list/active/${page}/${size}?${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  })
    .then(res => res.content);
};

/* @deprecated */
const fetchClientScenariosList = (page: number, size: number, clientId: string, query?: IClientScenarioQuery): Promise<IScenario> => {
  const params = queryString.stringify(_omitBy(query, _isUndefined));

  return RestService.fetch(`${RestService.REST_URL}/scenario/list/client/${clientId}/${page}/${size}?${params}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchProjectScenariosList = (page: number, size: number, projectId: string): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/list/project/${projectId}/${page}/${size}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchClientScenarios = (
  pageData: IListPageData,
  clientId: string,
  filterParams?: any,
  signal: AbortSignal | null = null
): Promise<IListDataResponse<IScenario>> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/list/client/${clientId}${getPageDataUrl(pageData, filterParams)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchProjectScenarios = (pageData: IListPageData, projectId: string): Promise<IListDataResponse<IScenario>> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/list/project/${projectId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchTeamScenariosList = (page: number, size: number, teamId: string, scenarioStatusActive: boolean = false): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/list/team/${teamId}/${page}/${size}?active=${scenarioStatusActive}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchScenariosShortList = (pageData: IListPageData): Promise<IListDataResponse<INamedEntry>> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/list/short`, {
    body: JSON.stringify(pageData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updateScenarioAttachments = (scenarioId: string, attachments: File[] | null, removeFiles?: string[]): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}/draft/attachment`, {
    body: generateFormData(null, '', attachments, removeFiles),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const updateScenarioDraft = (scenarioId: string, scenarioDraft: IScenarioDraftUpdate): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}/draft`, {
    body: JSON.stringify(scenarioDraft),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateScenarioPlanning = (scenarioId: string, scenarioPlanning: IScenarioPlanningUpdate): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}/planning`, {
    body: JSON.stringify(scenarioPlanning),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateScenarioPlanningTeams = (scenarioId: string, teamIds: string[]): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}/planning/team`, {
    body: JSON.stringify(teamIds),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const repeatScenario = (scenarioId: string, projectId: string, scenarioPlanning: IScenarioPlanningUpdate): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/repeat/${scenarioId}/project/${projectId}`, {
    body: JSON.stringify(scenarioPlanning),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchScenariosWithSequence = (projectId: string ): Promise<IScenarioWithSequence[]> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/${projectId}/sequence`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  });
};

const updateScenarioSequence = (updatedScenarioSequence: IScenarioSequenceUpdate ): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/scenario/sequence`, {
    body: JSON.stringify(updatedScenarioSequence),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  });
};

const createScenarioFromTemplate =
  (templateId: string, projectId: string, scenarioPlanning: IScenarioPlanningUpdate): Promise<IScenario> => {
    return RestService.fetch(`${RestService.REST_URL}/scenario/template/${templateId}/project/${projectId}`, {
      body: JSON.stringify(scenarioPlanning),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
  };

// =================================================================================
// rest/scenarioTemplate -----------------------------------------------------------

const createScenarioTemplate = (scenarioDraft: IScenarioTemplateCreate, attachments: File[] | null): Promise<IScenarioTemplate> => {
  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate`, {
    body: generateFormData(scenarioDraft, 'scenarioTemplate', attachments),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchScenarioTemplate = (scenarioTemplateId: string): Promise<IScenarioTemplate> => {
  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/${scenarioTemplateId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const deleteScenarioTemplate = (scenarioTemplateId: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/${scenarioTemplateId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const restoreScenarioTemplate = (scenarioTemplateId: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/restore/${scenarioTemplateId}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const updateScenarioTemplate = (scenarioId: string, scenarioTemplate: IScenarioTemplateWrapper<any>): Promise<IScenarioTemplate> => {
  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/${scenarioId}`, {
    body: JSON.stringify(scenarioTemplate),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateScenarioTemplateAttachments = (scenarioId: string, attachments: File[] | null, removeFiles?: string[]): Promise<IScenario> => {
  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/${scenarioId}/attachment`, {
    body: generateFormData(null, '', attachments, removeFiles),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const fetchScenarioTemplatesList = (pageData: IListPageData, licenseeId: string, filterParams: any): Promise<IListDataResponse<IScenarioTemplate>> => {

  if (!licenseeId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/list/${licenseeId}${getPageDataUrl(pageData, filterParams)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchTemplateScenarioList = (pageData: IListPageData, licenseeId: string, filterParams: any): Promise<IListDataResponse<IScenarioTemplate>> => {

  if (!licenseeId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/listScenarios`, {
    body: JSON.stringify({ ...pageData, ...filterParams }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchScenarioTemplateAttachmentLink = (scenarioId: string, attachmentId: string): Promise<string> => {

  if (!scenarioId || !attachmentId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/scenarioTemplate/${scenarioId}/attachment/${attachmentId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchScenarioAttachmentLink = (scenarioId: string, attachmentId: string): Promise<string> => {

  if (!scenarioId || !attachmentId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}/attachment/${attachmentId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchScenarioWithOptions = (params: IAbortableRequestParams & { scenarioId: string, query?: IEntityQuery }): Promise<IScenario> => {
  const { scenarioId, query, signal } = params;

  if (!scenarioId) {
    throw new Error(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified' }));
  }

  return RestService.fetch(`${RestService.REST_URL}/scenario/${scenarioId}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchProjectScenariosWithOptions = (params: IPageRequestParams & { projectId: string }): Promise<IListDataResponse<IScenario>> => {
  const { pageData, projectId, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/scenario/list/project/${projectId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchProjectScenarioCountWithOptions = (params: IAbortableRequestParams & { projectId: string }): Promise<IScenarioStatusResponseConfig> => {
  const { projectId, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/scenario/count/project/${projectId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchScenarioNamesListWithOptions = (params: IPageRequestParams & {
  clientIds?: string[] | null,
  projectIds?: string[] | null,
  scenarioName: string,
  startDate?: number,
  endDate?: number,
  status?: ScenarioStatus[],
}): Promise<IListDataResponse<IScenario>> => {
  const { pageData, clientIds, projectIds, startDate, endDate, scenarioName, status, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/scenario/list/short`, {
    body: JSON.stringify({
      clientIds: { value: clientIds },
      projectIds: { value: projectIds },
      startDate,
      endDate,
      scenarioName,
      status,
      ...pageData,
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchItemBankList = (params: IPageRequestParams & { itemBankQuery: IItemBankQueryBasic | IResourcePageQuery | ISkillPageQuery }): Promise<IListDataResponse<IItemBankResponseItem>> => {
  const {  signal, pageData, itemBankQuery } = params;
  const { type, ...restQuery} = itemBankQuery;
  const requestPayload = { ...pageData, ...restQuery };

  const buildingBlockType = type === ITEM_BANK_PAGE.RESEARCH_SUMMARY ? ITEM_BANK_API_END_POINT.RESEARCH_SUMMARY : type.trim().toLowerCase();
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/list/buildingBlock/${buildingBlockType}`, {
    body: JSON.stringify(requestPayload),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const fetchItemBankListForBasicBlock = (params: IPageRequestParams & { itemBankQuery: IItemBankQueryBasic }): Promise<IListDataResponse<IItemBankResponseItem>> => {
  const {  signal, pageData, itemBankQuery } = params;
  const { type, ...restQuery} = itemBankQuery;
  const requestPayload = { ...pageData, ...restQuery };

  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/list/buildingBlock`, {
    body: JSON.stringify({...requestPayload, buildingBlocksType: type }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const saveItemBankData = (buildingBlockData: IItemBankCreateBasic | ISkillCreate): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/save/buildingBlock`, {
    body: JSON.stringify(buildingBlockData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const fetchItemBankDetails = (id: string): Promise<IItemBank> => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/get/buildingBlock/${id}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const updateItemBankData = (buildingBlockData: IItemBankUpdate): Promise<IItemBank> => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/save/buildingBlock`, {
    body: JSON.stringify(buildingBlockData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const fetchPublisherListWithOptions = () => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/list/buildingBlock/resource/publisher`, {
    headers: RestService.generateHeaders(),
    method: 'GET', 
  });
};

const saveResourceBuildingBlockData = (buildingBlockData: IResourceUpdate): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/save/buildingBlock/resource`, {
    body: JSON.stringify(buildingBlockData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updateResourceBuildingBlockData = (buildingBlockData: IResourceUpdate) => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/save/buildingBlock/resource`, {
    body: JSON.stringify(buildingBlockData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const fetchResourceBuildingBlockData = (id: string): Promise<IItemBank> => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/get/buildingBlock/resource/${id}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const deleteArchiveItemBankData = (blockId: string, url: string): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/${url}/${blockId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const patchUnarchiveItemBankData = (blockId: string, url: string): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/${url}/${blockId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  });
};

const fetchPathwayList = (params: IPageRequestParams & { itemBankQuery: IItemBankQueryBasic }): Promise<IListDataResponse<IItemBankResponseItem>> => {
  const {  signal, pageData, itemBankQuery } = params;
  const { type: _, ...restQuery} = itemBankQuery;
  const requestPayload = { ...pageData, ...restQuery };

  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/list/pathway`, {
    body: JSON.stringify(requestPayload),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal,
  });
};

const savePathwayData = (pathwayData: IItemBankCreateBasic): Promise<void> => {
  const { buildingBlockType: _, ...restData } = pathwayData;
  
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/save/pathway`, {
    body: JSON.stringify(restData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updatePathwayData = (pathwayData: IItemBankUpdate) => {
  const { buildingBlockType: _, ...restData } = pathwayData;
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/update/pathway`, {
    body: JSON.stringify(restData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const fetchPathwayDetails = (id: string): Promise<IItemBank> => {
  return RestService.fetch(`${RestService.REST_URL}/nextGenScenario/get/pathway/${id}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  createScenario,
  deleteScenario,
  restoreScenario,
  restoreScenarioTemplate,
  updateScenarioTemplate,
  updateScenarioTemplateAttachments,
  updateScenarioDraft,
  updateScenarioAttachments,
  updateScenarioPlanning,
  updateScenarioPlanningTeams,
  fetchClientScenariosList,
  fetchClientScenarios,
  fetchProjectScenariosList,
  fetchProjectScenarios,
  fetchTeamScenariosList,
  fetchScenario,
  fetchScenarioName,
  fetchActiveScenarios,
  repeatScenario,
  createScenarioFromTemplate,
  createScenarioTemplate,
  fetchScenarioTemplate,
  deleteScenarioTemplate,
  fetchScenarioTemplatesList,
  fetchTemplateScenarioList,
  fetchScenarioAttachmentLink,
  fetchScenarioTemplateAttachmentLink,
  fetchScenarioWithOptions,
  fetchScenariosShortList,
  fetchProjectScenariosWithOptions,
  fetchProjectScenarioCountWithOptions,
  fetchScenarioNamesListWithOptions,
  fetchScenariosWithSequence,
  updateScenarioSequence,
  fetchItemBankList,
  saveItemBankData,
  fetchItemBankDetails,
  updateItemBankData,
  fetchPublisherListWithOptions,
  saveResourceBuildingBlockData,
  updateResourceBuildingBlockData,
  fetchResourceBuildingBlockData,
  fetchItemBankListForBasicBlock,
  deleteArchiveItemBankData,
  patchUnarchiveItemBankData,
  fetchPathwayList,
  savePathwayData,
  updatePathwayData,
  fetchPathwayDetails,
};
