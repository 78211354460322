import moment from 'moment-timezone';
import * as React from 'react';
import cn from 'classnames';
import styles from './TimePicker.css';
import ITimeInterval from 'src/app/data/common/interfaces/ITimeInterval';
import Select, { components } from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import cobaltTheme from 'src/components/Selector/themes/cobaltTheme';

export interface ITimePickerProps {
  availableIntervals?: ITimeInterval[];
  autoFocus?: boolean;
  date: moment.Moment;
  time: moment.Moment;
  format: string;
  id: string;
  maxTime?: moment.Moment;
  minTime?: moment.Moment;
  stepMinutes: number;
  tzId: string;
  onChange: (dateTime: moment.Moment) => void;
  disabled?: boolean;
  className?: string;
  classNamePrefix?: string;
  placeholder?: string;
}

interface ITimePickerState {
  inputValue: string;
  edited: boolean;
}

class TimePicker extends React.Component<ITimePickerProps, ITimePickerState> {
  public state = {
    inputValue: '',
    edited: false,
  };

  private customStyles: StylesConfig<any, any> = {
    ...cobaltTheme.customStyles,
    input: (provided) => ({
      ...provided,
      color: 'inherit',
    }),
    menu: (provided) => ({
      ...provided,
      width: '150px',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 1100,
      width: '320px',
    }),
    option: (provided) => ({ ...provided }),
    control: (provided) => ({
      ...provided,
      boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      fontWeight: 600,
    }),
    container: () => ({ minWidth: '120px' }),
    valueContainer: () => ({
      lineHeight: '1.6rem',
      letterSpacing: '0px',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
      padding: '0',
      textAlign: 'start',
      flex: 1,
      paddingLeft: '10px',
      display: 'inline-block',
      overflow: 'hidden',
    }),
    dropdownIndicator: (provided) => ({ ...provided, display: this.props.disabled ? 'none' : 'flex' }),
  };

  public componentDidMount() {
    const { time, format, placeholder } = this.props;
    if (placeholder?.length) {
      this.setState({ inputValue: placeholder });
      return;
    }

    const label = time.clone().format(format);
    this.setState({ inputValue: label });
  }

  public componentDidUpdate(prevProps: ITimePickerProps) {
    const { time, format, placeholder } = this.props;
    if (placeholder?.length && placeholder !== prevProps.placeholder) {
      this.setState({ inputValue: placeholder });
      return;
    }
    if (!isNaN(prevProps.time.valueOf()) && !isNaN(time.valueOf()) && prevProps.time.valueOf() !== time.valueOf()) {
      const label = time.clone().format(format);
      this.setState({ inputValue: label });
    }
  }

  public render() {
    const { time, className, autoFocus } = this.props;
    const { inputValue } = this.state;

    const roundedValue = time.minutes(); // Math.ceil(time.minutes() / stepMinutes) * stepMinutes;
    const valueMoment = time.clone().minutes(roundedValue).startOf('minute').valueOf();

    const options = this.getOptions();
    const valueIndex = options.findIndex(option => option.value === valueMoment);

    return (
      <div className={cn(styles.container, className)}>
        <Select
          id={this.props.id}
          inputValue={inputValue}
          value={options[valueIndex]}
          onChange={this.onChange}
          options={options}
          styles={this.customStyles}
          isClearable={false}
          isDisabled={this.props.disabled}
          theme={cobaltTheme.customTheme}
          menuPlacement={'bottom'}
          menuPosition={'fixed'}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={true}
          filterOption={this.customFilter}
          autoFocus={autoFocus}
          classNamePrefix={cn(this.props.classNamePrefix, 'TimePicker')}
          components={{
            Input: this.customInput,
            SingleValue: this.customValue,
          }}
        />
      </div>
    );
  }

  private customFilter = (option: any, searchText: string) => {
    const { edited } = this.state;

    if (!edited) {
      return true;
    }

    return option.label.trim().toLowerCase().includes(searchText.trim().toLowerCase());


  };

  private customInput = (innerProps: any) => {
    if (innerProps.isHidden && this.state.inputValue.length) {
      return <span>{this.state.inputValue}</span>;
    }

    return (
      <components.Input
        {...innerProps}
        className={styles.customInput}
        value={this.state.inputValue}
        onChange={this.handleCustomInput}
      />
    );
  };

  private customValue = (innerProps: any) => {
    const { inputValue } = this.state;
    if (!inputValue.length) {
      return <span/>;
    }

    return <components.SingleValue {...innerProps} />;
  };

  private handleCustomInput = (e: any) => {
    this.setState({
      edited: true,
      inputValue: e.target.value,
    });
  };

  private getOptions() {
    const { date, format, maxTime, minTime, stepMinutes } = this.props;
    const options: any[] = [];
    const startOfDay = date.clone().startOf('day');
    const endOfDay = date.clone().endOf('day').add(1, 'ms');
    const dayDuration = endOfDay.diff(startOfDay, 'm');
    const time = startOfDay.clone();

    const isDstChangeThisDay = startOfDay.isDST() !== endOfDay.isDST();

    const currentSelectedValue = this.props.time.clone().startOf('minute').valueOf();

    for (let i = 0; i <= dayDuration; i += stepMinutes) {
      const value = time.valueOf();
      const available = this.isTimeAvailable(value);
      const skip = maxTime && time.isAfter(maxTime)
        || minTime && time.isBefore(minTime)
        || !available;

      let dstInfo = '';
      if (isDstChangeThisDay && (time.isDST() !== time.clone().subtract(1, 'hour').isDST()) || (time.isDST() !== time.clone().add(1, 'hour').isDST())) {
        dstInfo = ` GMT(${time.tz(this.props.tzId).format('Z')})`;
      }

      const timeTitle = `${time.format(format)}${dstInfo}`;

      if (!skip) {
        options.push({ value, label: timeTitle, isFocused: value === currentSelectedValue });
      } else if (this.props.time.isSame(time, 'm')) {
        options.push({ value, label: timeTitle, isDisabled: true });
      }

      time.add(stepMinutes, 'minutes');
    }

    return options;
  }

  private isTimeAvailable = (value: number) => {
    const { availableIntervals } = this.props;

    return !availableIntervals || availableIntervals.some(interval => value >= interval.startDate && value <= interval.endDate);
  };

  private onChange = ({ value, label }: { value: string, label: string }) => {
    const millis = +value;
    const resultMoment = moment.tz(millis, this.props.tzId);

    this.setState({ inputValue: label, edited: false });
    this.props.onChange(resultMoment);
  };
}

export default TimePicker;
