import rest from './rest';
import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import projectsRest from '../projects/rest';
import scenariosRest from '../scenarios/rest';

export default {
  fetchDemandBasedProjects: actionFromPromise(rest.fetchDemandBasedProjects),
  fetchDemandBasedActiveScenarios: actionFromPromise(rest.fetchDemandBasedActiveScenarios),
  fetchScenario: actionFromPromise(scenariosRest.fetchScenario),
  fetchProjectInfo: actionFromPromise(projectsRest.fetchProject),
  fetchLearnerAvailability: actionFromPromise(rest.fetchLearnerAvailability),

};
