import cn from 'classnames';
import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import styles from './SelectListItem.css';
import { useIntl } from 'react-intl';

export interface ISelectListItemProps {
  className?: string;
  contentClassName?: string;
  onClick: () => void;
  disabled?: boolean;
}

const SelectListItem: FunctionComponent<ISelectListItemProps> = (props) => {
  const {
    className,
    contentClassName,
    children,
    disabled,
  } = props;

  const intl = useIntl();
  const onClick = useCallback(() => {
    if (disabled) {
      return;
    }

    return props.onClick();
  }, [disabled, props.onClick]);

  return (
    <ListGroupItem
      className={cn(styles.container, className, disabled && styles.disabled)}
      onClick={onClick}
    >
      <div className={styles.selectListItem}>
        <div
          className={cn(styles.mainContent, contentClassName)}
          aria-label={disabled ? '' : intl.formatMessage({ id: 'MursionPortal.AriaLabel.Add' })}
        >
          {children}
        </div>

        {!disabled && (
           <span className={styles.addIcon}><i className={cn('fas', 'fa-plus', styles.icon)} /></span>
        )}
      </div>
    </ListGroupItem>
  );
};

export default SelectListItem;
