import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import styles from 'src/components/ConfirmationModal/ConfirmationModal.css';
import cn from 'classnames';
import Button, { ButtonFont, ButtonSize, ButtonType } from 'src/components/Button';
import { useIntl } from 'react-intl';
import csvInjectionProtector, { isInjected } from 'src/app/data/common/utils/csvInjectionProtector';
import FieldGroup from 'src/components/FieldGroup/FieldGroup';

export interface IConfirmationModalProps {
  show: boolean;
  headerTitle?: string;
  bodyText: string | React.ReactElement;
  onCancelModal: () => void;
  onOtherAction?: () => void;
  onConfirm?: (reason?: string) => void;
  confirmationTitle?: string;
  submitBtnTitle?: string;
  cancelBtnTitle?: string;
  buttonSize?: string;
  addReason?: boolean;
  reasonTextPlaceholder?: string;
  maxLength?: number;
  onHide?: () => void;
  className?: string;
  isClicked?: boolean;
}

const ConfirmationModal: FunctionComponent<IConfirmationModalProps> = (props) => {
  const intl = useIntl();
  const defaultProps = {
    submitBtnTitle: intl.formatMessage({ id: 'ConfirmationDialogue.Button.Submit' }),
    cancelBtnTitle: intl.formatMessage({ id: 'ConfirmationDialogue.Button.Cancel' }),
  };

  const { show, bodyText, className, headerTitle, buttonSize, onCancelModal, onOtherAction, onConfirm, confirmationTitle, submitBtnTitle, cancelBtnTitle, addReason, reasonTextPlaceholder, maxLength = 150, onHide, isClicked } =
  props;

  const [reason, setReasonText] = useState<string>('');
  const submitBtnTitleContent = isClicked ? (
    <Spinner animation='border' size='sm' role='status' />
  ) : (
    submitBtnTitle || defaultProps.submitBtnTitle
  );
  
  useEffect(() => {
    if (show) {
      setReasonText('');
    }
  }, [show]);

  const checkIfReasonIsValid = () => {
    return reason.length <= maxLength && !isInjected(reason);
  };

  const isConfirmEnabled = () => addReason ? checkIfReasonIsValid() : true;

  const handleStepOnCloseButton = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    if (onOtherAction && keyboardEvent.key === 'Enter') {
      onOtherAction();
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (onConfirm && event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onConfirm();
    }
  };

  const onConfirmation = () => {
    if (onConfirm) {
      onConfirm(reason);
    }
  };

  const getButtonType = () => isConfirmEnabled() ? ButtonType.BLUE : ButtonType.LIGHT;

  const getModalButtons = () => {
    return (
      <>
        {confirmationTitle ? (
              <Button
                btnSize={ButtonSize.MEDIUM}
                btnFont={ButtonFont.BOLD}
                btnType={ButtonType.BLUE}
                onClick={onCancelModal}
                aria-label={confirmationTitle}
              >
                {confirmationTitle}
              </Button>
            ) : (
              <>
                <Button
                  btnSize={ButtonSize.MEDIUM}
                  btnFont={ButtonFont.BOLD}
                  btnType={ButtonType.CANCEL}
                  onClick={onOtherAction}
                  tabIndex={1}
                  onKeyDown={handleStepOnCloseButton}
                  aria-label={cancelBtnTitle || defaultProps.cancelBtnTitle}
                >
                  {cancelBtnTitle || defaultProps.cancelBtnTitle}
                </Button>
                <Button
                  btnSize={ButtonSize.MEDIUM}
                  btnFont={ButtonFont.BOLD}
                  btnType={getButtonType()}
                  onClick={onConfirmation}
                  disabled={!isConfirmEnabled() || isClicked}
                  tabIndex={1}
                  onKeyDown={onKeyDown}
                  aria-label={submitBtnTitle || defaultProps.submitBtnTitle}
                >
                {submitBtnTitleContent}
                </Button>
              </>
            )}
      </>
    );
  };

  const handleReasonText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = csvInjectionProtector(event.target.value);
    setReasonText(value);
  };

  return (
    <Modal className={cn(styles.confirmationModal, buttonSize, addReason && styles.modalWithText, className)} show={show} centered={true} onHide={onHide || onCancelModal}>
      <Modal.Header closeButton={true} className={cn(headerTitle ? styles.confirmationModalHeader:`p-0  ${styles.confirmationModalHeaderHide}`)}>
        {headerTitle && <h4>{headerTitle}</h4>}
      </Modal.Header>
      <Modal.Body className={headerTitle ? styles.confirmationModalBody:'p-0'}>
        <div className={cn(styles.confirmationModalButtons, 'text-center p-3 p-sm-5 pt-5 p-sm-5')}>
          <div className={styles.confirmationModalButtonsText}>{bodyText}</div>
          {addReason ? (
            <div className={styles.texAreaWrap}>
              <FieldGroup
                id={`description-of-update`}
                type={'textarea'}
                value={reason}
                as={'textarea'}
                csvProtected={true}
                placeholder={reasonTextPlaceholder}
                maxLength={maxLength}
                onChange={handleReasonText}
                tabIndex={1}
              />
            </div>
          ) : null}
      
         {!headerTitle &&
          <div className={cn(styles.confirmationModalButtons, 'd-flex justify-content-center pt-3')}>
            {getModalButtons()}
          </div>
          }
        </div>
      </Modal.Body>
      {headerTitle &&
      <Modal.Footer className={styles.confirmationModalFooter}>
        <div className={cn(styles.confirmationModalFooterButtons)}>
            {getModalButtons()}
          </div>
      </Modal.Footer>
      }
    </Modal>
  );
};

export default ConfirmationModal;