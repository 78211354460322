import { AnyAction, Dispatch } from 'redux';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import errorsActions from 'src/app/redux/modules/errors/actions';
import getListTypes from 'src/app/redux/utils/getListTypes';
import RestError from 'src/app/services/rest/RestError';

// @ts-ignore
const createListActions = <W, T, U extends any[]>(listName: string, request: (...args: U) => Promise<W<T>>) => {
  const types = getListTypes(listName);

  // @ts-ignore
  const refreshList = (...args: U) => async (dispatch: Dispatch) => {
    dispatch(refreshingList());

    try {
      const listResponse = await request(...args);

      dispatch(receivedList(listResponse));
    } catch (e) {
      if (e instanceof RestError) {
        dispatch(refreshListError(e));
      } else {
        throw e;
      }
      dispatch(errorsActions.throwError(e));
    }
  };

  const refreshListError = (error: IRestError): AnyAction => ({
    error,
    type: types.REFRESH_LIST_ERROR,
  });

  const refreshingList = (): AnyAction => ({
    type: types.REFRESHING_LIST_TYPE,
  });

  const receivedList = (response: W): AnyAction => ({
    response,
    type: types.RECEIVED_LIST_TYPE,
  });

  return refreshList;
};

export default createListActions;
