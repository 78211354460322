import moment from 'moment-timezone';
import { AnyAction } from 'redux';
import { getNearestAvailableTimezone, TIME_RANGES } from 'src/app/data/common/utils/calendarUtils';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import createReducer, { RESET_STORE } from 'src/app/redux/utils/createReducer';
import * as types from './types';
import ITimeline from 'src/app/data/common/interfaces/ITimeline';
import IUser from 'src/app/data/common/interfaces/IUser';
import { EntityFilter } from 'src/app/data/calendar/interfaces/EntityFilter';
import { CalendarEventFilter } from 'src/layouts/common/Calendar/components/CalendarEventsFilter';
import ICalendarRange from 'src/layouts/common/Calendar/components/common/ICalendarRange';

export interface ICalendarReduxState {
  availableLearnersCount: number;
  certificateId: string;
  deliveryMode: SessionType | '';
  clientIds: string[];
  projectIds: string[];
  simspecialistIds: string[];
  filterIds: CalendarEventFilter[] | null;
  currentClientId: string;
  currentFilter: EntityFilter | null;
  entitySearchFilters: {
    [key in EntityFilter]: string;
  };
  entityIndustriesFilter: string[] | null;
  currentProjectId: string;
  currentScenarioId: string;
  currentSimSpecialistId: string;
  scenarioTimeline: ITimeline;
  selectedLearners: IUser[];
  sessionLength: number;
  schedulingBlocked: boolean;
  timezoneId: string;
  isEventViewDialogOpened: boolean;
  range: ICalendarRange;
  selectedDate: number | null;
}

const INITIAL_PROJECT_OVERVIEW = {
  availableLearnersCount: 0,
  certificateId: '',
  currentProjectId: '',
  currentScenarioId: '',
  deliveryMode: '' as '',
  scenarioTimeline: {
    endDate: 0,
    startDate: 0,
  },
  selectedLearners: [],
  sessionLength: 0,
};

const INITIAL_STATE: ICalendarReduxState = {
  ...INITIAL_PROJECT_OVERVIEW,
  clientIds: [],
  currentClientId: '',
  currentSimSpecialistId: '',
  currentFilter: null,
  entitySearchFilters: {
    [EntityFilter.PROJECT]: '',
    [EntityFilter.CLIENT]: '',
    [EntityFilter.SIMSPECIALIST]: '',
  },
  entityIndustriesFilter: null,
  filterIds: null,
  projectIds: [],
  simspecialistIds: [],
  schedulingBlocked: false,
  timezoneId: getNearestAvailableTimezone(moment.tz.guess()).id,
  isEventViewDialogOpened: false,
  range: {
    start: moment().startOf('month').valueOf(),
    step: TIME_RANGES.MONTH,
  },
  selectedDate: null,
};

export default createReducer(INITIAL_STATE, {
  [types.APPLY_CLIENTS]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    clientIds: [...action.payload],
  }),
  [types.APPLY_PROJECTS]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    projectIds: action.payload,
  }),
  [types.APPLY_SIMSPECIALIST]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    simspecialistIds: action.payload,
  }),
  [types.APPLY_FILTERS]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    filterIds: [...action.payload],
  }),
  [types.SELECT_CLIENT]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    ...INITIAL_PROJECT_OVERVIEW,
    currentClientId: action.payload,
    currentProjectId: INITIAL_STATE.currentProjectId,
  }),
  [types.SELECT_PROJECT]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    ...INITIAL_PROJECT_OVERVIEW,
    currentProjectId: action.payload,
  }),
  [types.SELECT_SCENARIO]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    currentScenarioId: action.payload,
  }),
  [types.CHANGE_ENTITY_FILTER]: (state: ICalendarReduxState, action: AnyAction) => {
    const {
      certificateId,
      currentClientId,
      currentProjectId,
      currentScenarioId,
      currentSimSpecialistId,
    } = INITIAL_STATE;

    return {
      ...state,
      certificateId,
      currentClientId,
      currentProjectId,
      currentScenarioId,
      currentSimSpecialistId,
      currentFilter: action.payload,
    };
  },
  [types.CHANGE_ENTITY_INDUSTRIES_FILTER]: (state: ICalendarReduxState, action: AnyAction) => {
    const { industriesIds } = action;

    return {
      ...state,
      entityIndustriesFilter: industriesIds
    };
  },
  [types.CLEAR_ENTITY_SEARCH_FILTERS]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    entitySearchFilters: INITIAL_STATE.entitySearchFilters
  }),
  [types.CHANGE_ENTITY_SEARCH_FILTER]: (state: ICalendarReduxState, action: AnyAction) => {
    if (!state.currentFilter) {
      return state;
    }

    return {
      ...state,
      entitySearchFilters: { ...state.entitySearchFilters, [state.currentFilter]: action.search }
    };
  },
  [types.SELECT_SIMSPECIALIST]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    currentSimSpecialistId: action.payload,
  }),
  [types.SELECT_TIMEZONE]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    timezoneId: action.payload,
  }),
  [types.SET_AVAILABLE_LEARNERS_COUNT]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    availableLearnersCount: action.payload,
  }),
  [types.SET_CERTIFICATE_ID]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    certificateId: action.payload,
  }),
  [types.SET_DELIVERY_MODE]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    deliveryMode: action.payload,
  }),
  [types.SET_SCENARIO_TIMELINE]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    scenarioTimeline: action.payload,
  }),
  [types.SET_SELECTED_LEARNERS]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    selectedLearners: action.payload,
  }),
  [types.SET_SESSION_LENGTH]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    sessionLength: action.payload,
  }),
  [types.SET_SCHEDULING_BLOCKED]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    schedulingBlocked: action.payload,
  }),
  [types.CLOSE_OVERVIEW_PANEL]: (state: ICalendarReduxState, action: AnyAction) => {
    const {
      availableLearnersCount,
      certificateId,
      currentClientId,
      currentProjectId,
      currentScenarioId,
      currentSimSpecialistId,
      deliveryMode,
      scenarioTimeline,
      schedulingBlocked,
      selectedLearners,
      sessionLength,
    } = INITIAL_STATE;

    return {
      ...state,
      availableLearnersCount,
      certificateId,
      currentClientId,
      currentProjectId,
      currentScenarioId,
      currentSimSpecialistId,
      deliveryMode,
      scenarioTimeline,
      schedulingBlocked,
      selectedLearners,
      sessionLength,
    };
  },
  [types.TOGGLE_EVENT_VIEW_DIALOG]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    isEventViewDialogOpened: action.payload,
  }),
  [types.SET_CURRENT_RANGE]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    range: { ...action.payload },
  }),
  [types.SET_CURRENT_DATE]: (state: ICalendarReduxState, action: AnyAction) => ({
    ...state,
    selectedDate: action.payload,
  }),
  [types.RESET]: () => RESET_STORE,
});
