import { AnyAction } from 'redux';
import { WSConnectionStatus } from 'src/app/data/common/interfaces/WSConnectionStatus';
import createReducer from 'src/app/redux/utils/createReducer';
import IWebSocketState from 'src/app/redux/modules/ws/interfaces/IWebSocketState';
import * as types from 'src/app/redux/modules/ws/types';

const INITIAL_STATE: IWebSocketState = {
  connectionStatus: WSConnectionStatus.WS_DISCONNECTED,
  notificationsList: [],
  entitiesList: [],
};

export default createReducer(INITIAL_STATE, {
  [types.UPDATE_CONNECTION_STATUS]: (state: IWebSocketState, action: AnyAction): IWebSocketState => ({
    ...state,
    connectionStatus: action.connectionStatus
  }),
  [types.APPEND_NOTIFICATIONS]: (state: IWebSocketState, action: AnyAction): IWebSocketState => ({
    ...state,
    notificationsList: [...state.notificationsList, ...action.notifications]
  }),
  [types.UPDATE_NOTIFICATIONS]: (state: IWebSocketState, action: AnyAction): IWebSocketState => ({
    ...state,
    notificationsList: [...action.notifications]
  }),
  [types.CLEAR_NOTIFICATIONS]: (state: IWebSocketState, action: AnyAction): IWebSocketState => ({
    ...state,
    notificationsList: action.wsAction ? state.notificationsList.filter(n => n.action !== action.wsAction) : []
  }),
  [types.UPDATE_ENTITIES]: (state: IWebSocketState, action: AnyAction): IWebSocketState => ({
    ...state,
    entitiesList: action.entities
  }),
});
