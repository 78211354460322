import IAvatar, { IAvatarUpdate } from "src/app/data/client/interfaces/IAvatar";
import RestService from "src/app/services/rest/RestService";
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import generateFormData from 'src/app/data/common/utils/generateFormData';


const createAvatar = (avatarData: IAvatarUpdate, picture: File | null): Promise<IAvatar> => {
  return RestService.fetch(`${RestService.REST_URL}/avatar/`, {
    body: generateFormData(avatarData, 'avatar', picture),
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const updateAvatar = (avatarId: string, avatarData: IAvatarUpdate, picture: File | null): Promise<IAvatar> => {
  return RestService.fetch(`${RestService.REST_URL}/avatar/${avatarId}`, {
    body: generateFormData(avatarData, 'avatar', picture),
    headers: RestService.generateHeaders(),
    method: 'PUT',
  });
};

const deleteAvatar = (avatarId: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/avatar/${avatarId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const restoreAvatar = (avatarId: string): Promise<boolean> => {
  return RestService.fetch(`${RestService.REST_URL}/avatar/restore/${avatarId}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchAvatarById = (avatarId: string): Promise<IAvatar> => {
  return RestService.fetch(`${RestService.REST_URL}/avatar/${avatarId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchAvatarsList = (pageData: IListPageData): Promise<IAvatar[]> => {
  return RestService.fetch(`${RestService.REST_URL}/avatar/list${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchAvatarsListByEnvironment = (environmentId: string): Promise<IAvatar[]> => {
  return RestService.fetch(`${RestService.REST_URL}/avatar/byEnvironment/${environmentId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  createAvatar,
  deleteAvatar,
  restoreAvatar,
  fetchAvatarById,
  fetchAvatarsList,
  fetchAvatarsListByEnvironment,
  updateAvatar,
};
