import * as React from 'react';
import { Modal } from 'react-bootstrap';
import Button, { ButtonFont, ButtonSize, ButtonType } from 'src/components/Button';
import ErrorMessage from 'src/components/ErrorMessage';
import styles from './ConfirmationDialog.css';
import Separator from 'src/components/Separator';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';
import csvInjectionProtector, { isInjected } from 'src/app/data/common/utils/csvInjectionProtector';
import cn from 'classnames';
import close from "./images/close.svg";

export enum DialogThemeType {
  Cobalt,
}
export interface IConfirmationDialogProps extends WrappedComponentProps {
  error: string | null;
  text: string;
  onConfirm: (reason?: string) => void;
  onHide: () => void;
  show: boolean;
  addReason?: boolean;

  headerTitle?: string ;
  reasonTextLabel?: string;
  reasonTextPlaceholder?: string;
  submitBtnTitle?: string;
  cancelBtnTitle?: string;
  className?: string;
  themeType?: DialogThemeType;
}

class ConfirmationDialog extends React.Component<IConfirmationDialogProps> {

  public state = {
    reason: '',
  };

  public render() {
    const {
      error,
      show,
      text,
      addReason,
      headerTitle,
      reasonTextLabel,
      reasonTextPlaceholder,
      cancelBtnTitle,
      submitBtnTitle,
      className,
      themeType,
    } = this.props;
    const { intl } = this.props;
    const defaultProps = {
      textPlaceholder: intl.formatMessage({ id: 'ConfirmationDialogue.AddReasonForChange' }),
      submitBtnTitle: intl.formatMessage({ id: 'ConfirmationDialogue.Button.Submit' }),
      cancelBtnTitle: intl.formatMessage({ id: 'ConfirmationDialogue.Button.Cancel' }),
      reasonTextLabel: intl.formatMessage({ id: 'ConfirmationDialogue.ReasonForChange' }),
    };
    if (error) {
      return this.renderErrorDialog();
    }

    const isConfirmEnabled = this.props.addReason ? this.isValidReason(this.state.reason) : true;

    return (
      <Modal className={cn(styles.dialog, className, themeType === DialogThemeType.Cobalt && styles.cobalt)} show={show} onHide={this.onClose}>
        {
          headerTitle &&
          <Modal.Header className={styles.header}>
            <h4>{headerTitle}</h4>
            <button className={styles.modalCloseBtn} onClick={this.onClose}><img src={close} alt={defaultProps.cancelBtnTitle}/></button>
          </Modal.Header>
        }
        <Modal.Body className={styles.body}>
          <div className={styles.confirmMessage}>
            {text}
          </div>
          {
            this.props.children
          }
          {
            addReason &&
            <>
              <Separator className={styles.separator}/>
              <label>{reasonTextLabel || defaultProps.reasonTextLabel}*:</label>
              <textarea
                autoFocus={true}
                value={this.state.reason}
                required={true}
                maxLength={140}
                placeholder={reasonTextPlaceholder || defaultProps.textPlaceholder}
                onChange={this.handleReasonText}
                onKeyDown={this.onKeyDown}
                className={styles.feedbackTextarea}
                tabIndex={1}
              />
            </>
          }
          {error && <ErrorMessage message={error}/>}
        </Modal.Body>

        <Modal.Footer className={styles.footer}>
          <div className={styles.confirmButtons}>
            <Button
              btnSize={ButtonSize.MEDIUM}
              btnFont={ButtonFont.LIGHT}
              onClick={this.onClose}
              tabIndex={1}
              onKeyDown={this.handleStepOnCloseButton}>
              {cancelBtnTitle || defaultProps.cancelBtnTitle}
            </Button>
            <Button
              disabled={!isConfirmEnabled}
              btnSize={ButtonSize.MEDIUM}
              btnFont={ButtonFont.LIGHT}
              btnType={ButtonType.SPECIAL_PURPLE}
              onClick={this.onConfirm}
              tabIndex={1}
              onKeyDown={this.onKeyDown}>
              {submitBtnTitle || defaultProps.submitBtnTitle}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  private onClose = () => {
    this.setState({ reason: '' });
    this.props.onHide();
  };

  private handleStepOnCloseButton = (keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyboardEvent.key === 'Enter') {
      this.onClose();
    }
  };

  private onKeyDown = (e: React.KeyboardEvent<any>): void => {

    if (!this.isValidReason(this.state.reason)) {
      return;
    }

    if (!!this.state.reason && e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onConfirm();
    }
  };

  private isValidReason(reason: string) {
    const trimmedReason = reason.replace(/\s/g, '');
    return !!trimmedReason && trimmedReason.length <= 140 && !isInjected(trimmedReason);
  }

  private handleReasonText = (e: any) => {
    const reason = csvInjectionProtector(e.target.value);

    this.setState({ reason });
  };

  private onConfirm = () => {
    this.props.onConfirm(this.state.reason);
  };

  private renderErrorDialog() {
    const { error, onHide, show } = this.props;

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Body>
          <ErrorMessage className={styles.confirmError}>
            {error}
          </ErrorMessage>
        </Modal.Body>

        <Modal.Footer>
          <div className={styles.confirmButtons}>
            <Button onClick={onHide}>
              {TranslateMessage('ConfirmationDialogue.Button.Ok')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(ConfirmationDialog);
