import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';

export default {
  createDashboardScheduleReport: actionFromPromise(rest.createDashboardScheduleReport),
  createDashboardSendReport: actionFromPromise(rest.createDashboardSendReport),
  getDashboardScheduleReport: actionFromPromise(rest.getDashboardScheduleReport, 'SCHEDULE_REPORT', true),
  scheduleReportIdConversion: actionFromPromise(rest.scheduleReportIdConversion),
  updateDashboardScheduleReport: actionFromPromise(rest.updateDashboardScheduleReport),
  deleteDashboardReport: actionFromPromise(rest.deleteDashboardScheduleReport),
};
