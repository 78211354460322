import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import createListActions from 'src/app/redux/utils/createListActions';
import rest from './rest';
import learners from './learners/actions';
import { createClearEntityErrorAction } from 'src/app/redux/utils/createClearEntityErrorAction';
import createItemActions from 'src/app/redux/utils/createItemActions';
import { AnyAction } from 'redux';
import IProject from 'src/app/data/projects/interfaces/IProject';
import getItemTypes from 'src/app/redux/utils/getItemTypes';
import * as types from './types';

const updateCurrentProject = (project: IProject | null): AnyAction => {
  const currentTypes = getItemTypes('CURRENT_PROJECT');

  return {
    item: project,
    type: currentTypes.RECEIVED_ITEM_TYPE,
  };
};

const toggleShowArchivedScenarios = (show: boolean): AnyAction => {
  return ({
    payload: show,
    type: types.TOGGLE_SHOW_ARCHIVED_SCENARIOS
  });
};

export default {
  learners,
  refreshClientsList: createListActions('CLIENT_PROJECTS', rest.fetchFullClientProjectsList),
  refreshFullList: createListActions('CLIENT_PROJECTS', rest.fetchFullProjectsList),
  refreshList: createListActions('PROJECTS', rest.fetchFullProjectsList),
  clearValidationErrors: createClearEntityErrorAction('PROJECT'),
  createProject: actionFromPromise(rest.createProject, 'PROJECT'),
  deleteProject: actionFromPromise(rest.deleteProject),
  restoreProject: actionFromPromise(rest.restoreProject),
  fetchAvatarsList: actionFromPromise(rest.fetchAvatarsList),
  fetchFullClientProjectsList: actionFromPromise(rest.fetchFullClientProjectsList),
  fetchFullProjectsList: actionFromPromise(rest.fetchFullProjectsList),
  fetchProject: createItemActions('CURRENT_PROJECT', rest.fetchProject),
  fetchProject_deprecated: actionFromPromise(rest.fetchProject),
  fetchProjectName: actionFromPromise(rest.fetchProjectName),
  fetchProjectTeams: actionFromPromise(rest.fetchProjectTeams),
  fetchProjectUtilization: actionFromPromise(rest.fetchProjectUtilizationWithOptions),
  updateProject: actionFromPromise(rest.updateProject, 'PROJECT', true),
  updateProjectAM: actionFromPromise(rest.updateProjectAM, 'PROJECT'),
  updateProjectTags: actionFromPromise(rest.updateProjectTags, 'PROJECT'),
  duplicateSocialtoMagic: actionFromPromise(rest.duplicateSocialtoMagic, 'PROJECT'),
  fetchClientProjectsListWithOptions: actionFromPromise(rest.fetchClientProjectsListWithOptions),
  fetchProjectNamesListWithOptions: actionFromPromise(rest.fetchProjectNamesListWithOptions),
  fetchProjectWithOptions: actionFromPromise(rest.fetchProjectWithOptions),
  fetchProjectTeamsList: actionFromPromise(rest.fetchProjectTeamsList),
  fetchProjectTeamsWithOptions: actionFromPromise(rest.fetchProjectTeamsWithOptions),

  fetchClientGlobalConfig: actionFromPromise(rest.fetchClientGlobalConfig),
  createGlobalConfig: actionFromPromise(rest.createGlobalConfig),
  updateGlobalConfig: actionFromPromise(rest.updateGlobalConfig),
  fetchClientProjectsScenariosListWithOptions: actionFromPromise(rest.fetchClientProjectsScenariosListWithOptions),
  updateCurrentProject,
  toggleShowArchivedScenarios,
  fetchProjectListByContract: actionFromPromise(rest.fetchProjectListByContract, 'PROJECT', true),
};
