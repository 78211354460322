import moment from 'moment-timezone';
import { AnyAction } from 'redux';
import createReducer, { RESET_STORE } from 'src/app/redux/utils/createReducer';
import * as types from './types';
import ICalendarEvent from 'src/app/data/calendar/interfaces/ICalendarEvent';
import { CreateEventType } from 'src/app/data/calendar/interfaces/EventType';

export interface ICreateEventInfo {
  date: moment.Moment | null;
  events: ICalendarEvent[]; // all events for that date
  training?: boolean;
  currentClientId?: string;
  currentProjectId?: string;
  currentScenarioId?: string;
  eventType: CreateEventType;
}

export interface ICreateEventDialogReduxState {
  showCreateEventDialog: boolean;
  showScheduleSessionDialog: boolean;
  createEventInfo: ICreateEventInfo | null;
}

const INITIAL_STATE: ICreateEventDialogReduxState = {
  showCreateEventDialog: false,
  showScheduleSessionDialog: false,
  createEventInfo: null,
};

export default createReducer(INITIAL_STATE, {
  [types.OPEN_CREATE_EVENT_DIALOG]: (state: ICreateEventDialogReduxState, action: AnyAction) => ({
    ...state,
    showCreateEventDialog: true,
    createEventInfo: { ...action.payload },
  }),
  [types.CLOSE_CREATE_EVENT_DIALOG]: (state: ICreateEventDialogReduxState, action: AnyAction) => ({
    ...state,
    showCreateEventDialog: false,
    createEventInfo: null,
  }),
  [types.OPEN_SCHEDULE_SESSION_DIALOG]: (state: ICreateEventDialogReduxState, action: AnyAction) => ({
    ...state,
    showScheduleSessionDialog: true,
    createEventInfo: { ...action.payload },
  }),
  [types.CLOSE_SCHEDULE_SESSION_DIALOG]: (state: ICreateEventDialogReduxState) => ({
    ...state,
    showScheduleSessionDialog: false,
    createEventInfo: null,
  }),
  [types.UPDATE_PREVIOUS_DIALOG]: (state: ICreateEventDialogReduxState) => ({
    ...state,
  }),
  [types.RESET]: () => RESET_STORE,
});
