import cn from 'classnames';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './ListItem.css';
import { FunctionComponent } from 'react';
import { IScenarioData } from 'src/layouts/common/Dashboard/components/LearnerDashboard/NextOnProject';

export enum IListItemType {
  LINK,
  LINK_ARCHIVED,
  BUTTON
}

export interface IListItemProps {
  className?: string;
  href?: string;
  type?: IListItemType;
  newTab?: boolean;
  onClick?: () => void;
  ariaLabel? : string;
  state?: Partial<IScenarioData>;
}

const ListItem: FunctionComponent<IListItemProps> = (props) => {
    const {type, className, href, newTab, children, onClick, state} = props;
    const history = useHistory();
    
    const containerClassName = cn([
      styles.cardBar,
      {
        [styles.cardBarMed]: type === IListItemType.LINK || type === IListItemType.LINK_ARCHIVED,
        [styles.cardBarSmall]: type === IListItemType.BUTTON,
        [styles.cardBarKo]: type === IListItemType.LINK_ARCHIVED,
      },
      className,
    ]);

    if (href) {
      const addProps = newTab ? {target: '_blank'} : {};
      const handleNavigation = () => {
        if (addProps.target){
          const win = window.open(href, "_blank");
          win?.focus();
        } else {
          state
            ? history.push({
                pathname: href,
                state,
              })
            : history.push(href);
        }
      };
      return (
        <div className={containerClassName} onClick={handleNavigation}>
          <div className={styles.childContainer}>
            {children}
          </div>
          <Link className={styles.arrow} to={href} {...addProps} aria-label={props.ariaLabel}>
            <span>
              <i className='fas fa-chevron-right' />
            </span>
          </Link>
        </div>
      );
    }

    return (
      <div className={containerClassName} onClick={onClick} role={"presentation"}>
        {children}
      </div>
    );
};

export default ListItem;
