import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';
import RestService from 'src/app/services/rest/RestService';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';

const fetchProjectLearners = (pageData: IListPageData, projectID: string): Promise<IListDataResponse<ILearnerExtended>> => {
  return RestService.fetch(`${RestService.REST_URL}/user/learner/listForProject/${projectID}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  fetchProjectLearners,
};
