exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".AssetSettingField__container___2e9JC label{font-weight:400;text-align:center}.AssetSettingField__image___lgxsp{display:block;margin:0 auto;max-height:300px;width:auto}.AssetSettingField__assetImageContainer___3ieyT{margin:0 1rem;width:72px;max-width:72px;height:36px;display:block;-ms-flex:1 0;flex:1 0}.AssetSettingField__item___2KErD{padding:0 1.15rem}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/AssetSettingField/AssetSettingField.css"],"names":[],"mappings":"AAAA,4CACE,gBAAoB,AACpB,iBAAmB,CACpB,AAED,kCACE,cAAe,AACf,cAAe,AACf,iBAAkB,AAClB,UAAY,CACb,AAED,gDACE,cAAe,AACf,WAAY,AACZ,eAAgB,AAChB,YAAa,AACb,cAAe,AACf,aAAc,AACV,QAAU,CACf,AAED,iCACE,iBAAmB,CACpB","file":"AssetSettingField.css","sourcesContent":[".container label{\n  font-weight: normal;\n  text-align: center;\n}\n\n.image{\n  display: block;\n  margin: 0 auto;\n  max-height: 300px;\n  width: auto;\n}\n\n.assetImageContainer {\n  margin: 0 1rem;\n  width: 72px;\n  max-width: 72px;\n  height: 36px;\n  display: block;\n  -ms-flex: 1 0;\n      flex: 1 0;\n}\n\n.item {\n  padding: 0 1.15rem;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "AssetSettingField__container___2e9JC",
	"image": "AssetSettingField__image___lgxsp",
	"assetImageContainer": "AssetSettingField__assetImageContainer___3ieyT",
	"item": "AssetSettingField__item___2KErD"
};