import * as React from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Select, { components } from 'react-select';
import { ContainerProps } from 'react-select/dist/declarations/src/components/containers';
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components';
import TIMEZONES, { TIMEZONE_REPLACE } from 'src/app/data/common/timezones';
import { getNearestAvailableTimezone } from 'src/app/data/common/utils/calendarUtils';
import { getDateAwareTimezoneTitle } from 'src/app/data/common/utils/dateUtil';
import styles from './TimezoneDropdown.css';
import { cobaltTheme, greyTheme, ISelectorTheme, SelectorDropdownIndicator, ValueLineContainer, } from '../Selector';

export enum TimezoneSelectorTheme {
  Default,
  Cobalt,
}

export interface ITimezoneDropdownProps extends WrappedComponentProps {
  dropUp?: boolean;
  nullable?: boolean;
  timezoneId?: string | null;
  onChange: (timezoneId: string) => void;
  disabled?: boolean;
  portalTarget?: HTMLElement | null;
  shiftDate?: moment.Moment | null;
  inline?: boolean;
  userForm?: boolean;
  className?: string;
  themeType?: TimezoneSelectorTheme;
  showValueLine?: boolean;
  required?: boolean;
  classNamePrefix?: string;
  isFromProfile?: boolean;
}

interface ITimezoneDropdownState {
  inputValue: string;
  edited: boolean;
  menuIsOpen: boolean;
}

class TimezoneDropdown extends React.Component<ITimezoneDropdownProps, ITimezoneDropdownState> {

  public static defaultProps = {
    themeType: TimezoneSelectorTheme.Default,
  };

  public state = {
    inputValue: '',
    edited: false,
    menuIsOpen: false,
  };

  public selectRef: React.Component | null = null;
  public theme: ISelectorTheme;

  get isCobalt() {
    return this.props.themeType === TimezoneSelectorTheme.Cobalt;
  }

  get showValueLine() {
    return this.props.showValueLine !== undefined
      ? this.props.showValueLine
      : this.isCobalt;
  }


  constructor(props: ITimezoneDropdownProps) {
    super(props);
    this.setTheme();
  }

  public componentDidMount(): void {
    this.setInitialValue();
  }

  public componentDidUpdate(prevProps: Readonly<ITimezoneDropdownProps>): void {
    const timezoneChanged = prevProps.timezoneId !== this.props.timezoneId;
    const shiftDateChanged = !!prevProps.shiftDate !== !!this.props.shiftDate
      || !!prevProps.shiftDate && !!this.props.shiftDate
      && prevProps.shiftDate.valueOf() !== this.props.shiftDate.valueOf();

    if (timezoneChanged || shiftDateChanged) {
      this.setInitialValue();
    }

    this.checkDefaults();
  }

  public onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.selectRef?.setState({
        // @ts-ignore
        menuIsOpen: !this.selectRef.state.menuIsOpen
      });
    }
  };

  public withValueLineContainer = (containerProps: ContainerProps<any, any>) => {
    return (
      <ValueLineContainer
        containerProps={containerProps}
        disabled={this.props.disabled}
      />
    );
  };

  public render() {
    const { timezoneId, nullable, disabled, dropUp, shiftDate, inline, userForm, intl, className } = this.props;
    const { inputValue } = this.state;

    const options = nullable
      ? [{ value: '', label: '...', }, ...this.options()]
      : this.options();

    let title = '...';
    let name = '';
    const date = shiftDate || moment();
    if (timezoneId) {
      const timezone = getNearestAvailableTimezone(timezoneId);

      // title = this.getTimezoneTitle(timezone, date);
      title = getDateAwareTimezoneTitle(timezone.id, date.valueOf());
      const tzOffset = date.tz(timezone.id).format('Z');
      name = name = `GMT(${tzOffset}) ${timezone.name}`;
    }

    const isProfile = this.props.isFromProfile || false;
    const getTitle = isProfile ? name : title;

    const value = {
      value: timezoneId || '',
      label: getTitle,
      title: getTitle
    };

    let customComponents: SelectComponentsConfig<any, any, any> = {
      DropdownIndicator: SelectorDropdownIndicator,
      Option: (optionProps: any) => (
        <div title={optionProps?.data?.title}>
          <components.Option {...optionProps} />
        </div>
      ),
      Input: this.customInput,
      SingleValue: this.customValue,
    };

    if (this.showValueLine) {
      customComponents = {
        ...customComponents,
        SelectContainer: this.withValueLineContainer,
      };
    }

    const containerClasses = cn(
      styles.timezoneDropdownContainer,
      !userForm && styles.timezoneDropdownContainerWidth,
      inline && styles.inline,
      (this.isCobalt) && styles.cobalt,
      disabled && styles.disabled,
      className
    );

    return (
      <div
        className={containerClasses}
        title={name || ''}
        role={'button'}
        aria-label={intl.formatMessage({ id: 'MursionPortal.AriaLabel.Timezone' })}>
        <Select
          value={value}
          ref={el => (this.selectRef = el)}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          inputValue={inputValue}
          options={options}
          isClearable={false}
          isDisabled={disabled}
          menuPlacement={dropUp ? 'top' : 'bottom'}
          menuPosition={'fixed'}
          menuPortalTarget={document.body}
          styles={this.theme.customStyles}
          theme={this.theme.customTheme}
          filterOption={this.customFilter}
          onMenuClose={this.onMenuClose}
          onMenuOpen={this.onMenuOpen}
          aria-label={this.props.required ? intl.formatMessage({ id: 'MursionPortal.AriaLabel.SelectTimezoneCombobox' }) + ' ' + 'required' : intl.formatMessage({ id: 'MursionPortal.AriaLabel.SelectTimezoneCombobox' })}
          components={customComponents}
          classNamePrefix={cn(this.props.classNamePrefix, 'timezoneSelect')}
        />
      </div>
    );
  }

  private setTheme = () => {
    const timezoneDefaultTheme: ISelectorTheme = {
      customStyles: {
        ...greyTheme.customStyles,
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 1100
        }),
        option: (provided) => ({
          ...provided,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '14px',
        }),
        control: (provided) => ({
          ...provided,
          borderColor: !!this.props.userForm ? 'hsl(0deg 0% 51%)' : 'rgb(204, 204, 204)',
          boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          fontWeight: !!this.props.userForm ? 400 : 600,
          fontSize: !!this.props.userForm ? '15px' : '',
          color: !!this.props.userForm ? '#5a5a5a' : '',
        }),
        container: (provided) => ({
          ...provided,
          width: !!this.props.userForm ? '100%' : '190px',
          flex: '0 1 190px',
          letterSpacing: '-1px',
          textAlign: 'center'
        }),
        valueContainer: () => ({
          lineHeight: '1.6rem',
          letterSpacing: '0px',
          whiteSpace: 'nowrap',
          fontSize: '1rem',
          padding: '0 0.59rem',
          textAlign: !!this.props.userForm ? 'left' : 'center',
          flex: 1,
          // paddingLeft: !this.props.disabled ? '10px' : 0,
          display: 'inline-block',
          overflow: 'hidden',
        }),
        dropdownIndicator: (provided) => ({ ...provided, display: this.props.disabled ? 'none' : 'flex' }),
      },
      customTheme: greyTheme.customTheme,
    };

    const timezoneCobaltTheme: ISelectorTheme = {
      customStyles: {
        ...cobaltTheme.customStyles,
        input: (provided) => ({
          ...provided,
          color: 'inherit',
          margin: 0,
          padding: 0,
          maxWidth: 'unset',
        }),
        menuList: (provided) => ({
          ...provided,
          borderRadius: '9px',
        }),
      },
      customTheme: cobaltTheme.customTheme,
    };

    switch (this.props.themeType) {
      case TimezoneSelectorTheme.Cobalt:
        this.theme = timezoneCobaltTheme;
        break;
      case TimezoneSelectorTheme.Default:
      default:
        this.theme = timezoneDefaultTheme;
    }
  };

  private onMenuOpen = () => this.setState({ menuIsOpen: true });

  private setInitialValue = () => {
    if (!!this.props.timezoneId) {
      const date = this.props.shiftDate || moment();
      const isProfile = this.props.isFromProfile || false;
      const timezone = getNearestAvailableTimezone(this.props.timezoneId);
      const tzOffset = date.tz(timezone.id).format('Z');
      const inputValue = getDateAwareTimezoneTitle(this.props.timezoneId, date.valueOf());
      const selectInputValue = isProfile ? `GMT(${tzOffset}) ${timezone.name}` : inputValue;
      this.setState({ inputValue: selectInputValue, edited: false, menuIsOpen: false });
    }
    this.checkDefaults();
  };

  private customInput = (innerProps: any) => {
    const timezoneLabel = this.props.intl.formatMessage({ id: 'Filters.TimeZone' });
    const showValueLine = this.showValueLine;
    const menuIsOpen = this.state.menuIsOpen;
    const inputValue = this.state.inputValue;
    let value;
    if (showValueLine) {
      value = menuIsOpen ? inputValue : timezoneLabel;
    } else {
      value = inputValue;
    }

    if (this.isCobalt) {
      if (innerProps.isHidden && this.state.inputValue.length) {
        return this.showValueLine ? (
          <span>{timezoneLabel}</span>
        ) : (
          <span>{timezoneLabel}:&nbsp;<b>{value}</b></span>
        );
      }

      const input = <components.Input
        {...innerProps}
        className={styles.customInput}
        value={value}
        onChange={this.handleCustomInput}
      />;

      return !this.showValueLine ? (
        <div className={styles.inputContainer}>
          {<span>{timezoneLabel}:&nbsp;</span>}
          {input}
        </div>
      ) : input;
    }

    if (innerProps.isHidden && this.state.inputValue.length) {
      return <span tabIndex={0}>{value}</span>;
    }

    return (
      <components.Input
        {...innerProps}
        className={styles.customInput}
        value={value}
        onChange={this.handleCustomInput}
      />
    );
  };

  private customValue = (innerProps: any) => {
    const { inputValue } = this.state;
    if (!inputValue.length) {
      return <span />;
    }

    return <components.SingleValue {...innerProps} />;
  };

  private handleCustomInput = (e: any) => {
    this.setState({
      edited: true,
      inputValue: e.target.value
    });
  };

  private onMenuClose = () => this.setInitialValue();

  private customFilter = (option: any, searchText: string) => {
    const { edited } = this.state;

    const searchTextModified = searchText.replace(/[()\s]/g, '').toLowerCase(); // remove bracket and spaces

    if (!edited) {
      return true;
    }

    if (!option?.data?.label || !option?.data?.searchIndex || !searchTextModified) {
      return true;
    }

    return option.data.label.toLowerCase().includes(searchTextModified) ||
      option.data.searchIndex.includes(searchTextModified);
  };

  private options = () => TIMEZONES
    .filter(zone => !TIMEZONE_REPLACE[zone.id])
    .sort((tzA, tzB) => {
      const offsetA = moment.tz(tzA.id).utcOffset();
      const offsetB = moment.tz(tzB.id).utcOffset();

      if (offsetA === offsetB) {
        return tzA.name.localeCompare(tzB.name);
      }

      return offsetA - offsetB;
    })
    .map(tz => {
      const date = this.props.shiftDate || moment();
      const tzOffset = date.tz(tz.id).format('Z');
      const tzAbbr = date.tz(tz.id).format('z');
      const label = `GMT(${tzOffset}) ${tz.name}`;
      const searchIndex = label.replace(/[()\s]/g, '') // remove brackets and spaces
        + tz.id
        + tzAbbr
        + tzOffset.replace(/0+(?=\d+:)/, ''); // remove first zero in time

      return {
        value: tz.id,
        label,
        title: label,
        searchIndex: searchIndex.toLowerCase().trim(),
      };
    });

  private onChange = ({ value, label }: { value: string, label: string }) => {
    const date = this.props.shiftDate || moment();
    const inputValue = value ? getDateAwareTimezoneTitle(value, date.valueOf()) : '';
    this.setState({ inputValue, edited: false });
    this.props.onChange(value);
  };

  private checkDefaults = () => {
    if (!this.props.nullable && !this.props.timezoneId) {
      this.props.onChange(moment.tz.guess());
    }
  };
}

export default injectIntl(TimezoneDropdown);
