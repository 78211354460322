import { Theme } from 'react-select/dist/declarations/src/types';
import { INITIAL_STYLES, INITIAL_THEME, ISelectorTheme } from 'src/components/Selector/themes/SelectorThemes';

const defaultTheme: ISelectorTheme = {

  customStyles: {
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0',
      height: '2.45rem',
    }),
    menu: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menu,
      color: '#000',
      backgroundColor: '#fff',
      borderRadius: 0,
    }),
    menuPortal: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.menuPortal,
    }),
    option: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.option,
      backgroundColor: state.isFocused ? '#ccc' : '#fff',
      color: '#000',
      fontWeight: state.isSelected ? 500 : 'normal',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.singleValue,
      backgroundColor: state.isDisabled ? '#eee' : '#fff',
      color: state.isDisabled ? 'rgb(90, 90, 90)' : '#000',
      fontWeight: 'normal',
    }),
    control: (provided, state) => ({
      ...provided,
      ...INITIAL_STYLES.control,
      backgroundColor: state.isDisabled ? '#eee' : '#fff',
      borderRadius: '2rem',
      fontWeight: 600,
      color: state.isDisabled ? 'rgb(90, 90, 90)' : '#555',
      border: '1px solid #949494',
      padding: '0 0 0 5px',

      '&:hover': {
        borderColor: '#888'
      }
    }),
    indicatorSeparator: () => INITIAL_STYLES.indicatorSeparator,
    placeholder: (provided) => ({
      ...provided,
      ...INITIAL_STYLES.placeholder,
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
    }),
  },
  customTheme: (theme: Theme): Theme => ({
    ...theme,
    ...INITIAL_THEME,
    colors: {
      ...theme.colors,
      ...INITIAL_THEME.colors,
    },
  })
};

export default defaultTheme;
