import { IAppState } from 'src/app/redux/store/reducers';
import IRestError from 'src/app/data/common/interfaces/IRestError';
import { ILearnerExtended } from 'src/app/data/client/interfaces/ILearner';

export const getList = (state: IAppState): ILearnerExtended[] => state.teams.learners.list.items;
export const getListPage = (state: IAppState): number => state.teams.learners.list.page || 0;
export const getListSizePerPage = (state: IAppState): number => state.teams.learners.list.sizePerPage || 0;
export const getListTotalSize = (state: IAppState): number => state.teams.learners.list.totalSize || 0;
export const isListFetched = (state: IAppState): boolean => state.teams.learners.list.fetched;
export const getListRefreshError = (state: IAppState): IRestError | null => state.teams.learners.list.refreshError;
export const isListRefreshing = (state: IAppState): boolean => state.teams.learners.list.refreshing;
