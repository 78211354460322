import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import actions from 'src/app/redux/store/actions';
import { usePagedListFetchingWithOptions } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { TListPageArchiveOption } from 'src/app/data/common/interfaces/IListPageData';
import { EntitySelector, IEntitySelectorBaseProps } from 'src/components/EntitySelectors';

interface IClientSelectorPropsByProvider extends IEntitySelectorBaseProps {
  active: boolean;
  archive: TListPageArchiveOption;
  licenseeIds: string[] | null;
}

export const ClientSelectorByProvider: FunctionComponent<IClientSelectorPropsByProvider> = (props) => {
  const intl = useIntl();
  const { active, archive, licenseeIds, isMulti = true } = props;

  const itemsInfo = usePagedListFetchingWithOptions(actions.clients.fetchClientNamesListWithOptions)({ licenseeIds })({
    page: 0,
    size: 9999,
    archive,
  }, 300, active);

  return <EntitySelector
    {...props}
    isSearchable={true}
    themeType={props.themeType || SelectorTheme.Filter}
    placeholder={intl.formatMessage({ id: 'Filters.Client' })}
    searchPlaceholder={intl.formatMessage({ id: 'Filters.ClientPlaceHolderHint' })}
    valuePlaceholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.ClientValue' })}
    itemsInfo={itemsInfo}
    isMulti={isMulti}
    disabled={!active}
  />;
};


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


export interface IClientSelectorPropsDefault extends IEntitySelectorBaseProps {
  active: boolean;
  archive: TListPageArchiveOption;
}

export const ClientSelectorDefault: FunctionComponent<IClientSelectorPropsDefault> = (props) => {
  const intl = useIntl();
  const { active, isMulti = true, placeholder } = props;

  const itemsInfo = usePagedListFetchingWithOptions(actions.clients.fetchClientsListWithOptions)()({
    page: 0,
    size: 9999,
    archive: props.archive,
  });

  return <EntitySelector
    {...props}
    isSearchable={true}
    themeType={props.themeType || SelectorTheme.Filter}
    placeholder={placeholder ?? intl.formatMessage({ id: 'Filters.Client' })}
    searchPlaceholder={intl.formatMessage({ id: 'Filters.ClientPlaceHolderHint' })}
    valuePlaceholder={intl.formatMessage({ id: 'MursionPortal.Placeholder.ClientValue' })}
    itemsInfo={itemsInfo}
    isMulti={isMulti}
    disabled={!active}
  />;
};
