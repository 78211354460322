import ITeam, { ITeamUpdate } from 'src/app/data/client/interfaces/ITeam';
import ITeamClientView from 'src/app/data/client/interfaces/ITeamClientView';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import RestService from 'src/app/services/rest/RestService';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import stringifyEntityQuery from 'src/app/data/common/utils/stringifyEntityQuery';
import IEntityQuery from 'src/app/data/common/interfaces/IEntityQuery';
import { IAbortableRequestParams, IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import { intl } from 'src/i18n/createIntl';

const createNewTeam = (teamData: ITeamUpdate): Promise<ITeam> => {
  return RestService.fetch(`${RestService.REST_URL}/team`, {
    body: JSON.stringify(teamData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updateTeam = (teamId: string, teamData: ITeamUpdate): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/team/${teamId}`, {
    body: JSON.stringify(teamData),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateTeamFacilitators = (teamId: string, ids: string[]): Promise<ITeam> => {
  return RestService.fetch(`${RestService.REST_URL}/team/${teamId}/facilitator`, {
    body: JSON.stringify(ids),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateTeamLearners = (teamId: string, ids: string[]): Promise<ITeam> => {
  return RestService.fetch(`${RestService.REST_URL}/team/${teamId}/learner`, {
    body: JSON.stringify(ids),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const updateTeamTags = (teamId: string, tags: string[]): Promise<ITeam> => {
  return RestService.fetch(`${RestService.REST_URL}/team/${teamId}/tag`, {
    body: JSON.stringify(tags),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};

const deleteTeam = (teamId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/team/${teamId}`, {
    headers: RestService.generateHeaders(),
    method: 'DELETE',
  });
};

const restoreTeam = (teamId: string): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/team/restore/${teamId}`, {
    headers: RestService.generateHeaders(),
    method: 'POST',
  });
};

const fetchTeam = (teamId: string, query?: IEntityQuery): Promise<ITeam> => {
  return RestService.fetch(`${RestService.REST_URL}/team/${teamId}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchTeamName = (teamId: string): Promise<string> => {
  return fetchTeam(teamId, { nameOnly: true }).then(team => team.name);
};

const fetchClientTeams = (clientId: string): Promise<ITeam[]> => {
  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  // todo: change entry point after backend implementation
  return RestService.fetch(`${RestService.REST_URL}/team/list/${clientId}/0/1000`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((listResponse) => {
    return listResponse.content || [];
  });
};

const fetchClientTeamsResponse = (pageData: IListPageData, clientId: string): Promise<IListDataResponse<ITeam>> => {
  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }
  return RestService.fetch(`${RestService.REST_URL}/team/list/${clientId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const deprecatedFetchClientViewTeams = (clientId: string = ''): Promise<ITeamClientView[]> => {
  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  // todo: change entry point after backend implementation
  return RestService.fetch(`${RestService.REST_URL}/team/listForClient/${clientId}/0/1000`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  }).then((listResponse) => {
    return listResponse.content || [];
  });
};

const fetchTeamsListForClientView = (pageData: IListPageData, clientId: string): Promise<IListDataResponse<ITeamClientView>> => {
  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/team/listForClient${clientId ? '/' + clientId : ''}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchTeamsForClientWithOptions = (params: IAbortableRequestParams & { clientId: string, query?: IEntityQuery | null }): Promise<IListDataResponse<ITeam>> => {
  const { clientId, query, signal } = params;

  if (!clientId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.ClientIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/team/list/client/${clientId}${stringifyEntityQuery(query)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal,
  });
};

const fetchTeamsForLearner = (pageData: IListPageData, learnerId: string): Promise<IListDataResponse<ITeam>> => {

  if (!learnerId) {
    throw new Error(intl().formatMessage({id:'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified'}));
  }

  return RestService.fetch(`${RestService.REST_URL}/team/list/learner/${learnerId}${getPageDataUrl(pageData)}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchTeamNamesListWithOptions = (params: IPageRequestParams & {
  clientIds?: string[] | null,
  projectIds?: string[] | null,
  scenarioIds?: string[] | null,
  teamName: string
}): Promise<IListDataResponse<ITeam>> => {
  const { pageData, clientIds, projectIds, scenarioIds, teamName, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/team/list/short`, {
    body: JSON.stringify({
      clientIds: { value: clientIds },
      projectIds: { value: projectIds },
      scenarioIds: { value: scenarioIds },
      teamName,
      ...pageData
    }),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal
  });
};

const teamInviteLink = (teamId: string) => {
  return RestService.fetch(`${RestService.REST_URL}/team/teamInviteLink/${teamId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const updateScenarioTeam = (payload: { learnerId: string; scenarioId: string }): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/user/client/teams/assigned`, {
    body: JSON.stringify(payload),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  });
};


export default {
  fetchTeamsListForClientView,
  updateTeam,
  updateTeamFacilitators,
  updateTeamLearners,
  updateTeamTags,
  createNewTeam,
  deleteTeam,
  restoreTeam,
  fetchClientTeams,
  fetchClientTeamsResponse,
  deprecatedFetchClientViewTeams,
  fetchTeam,
  fetchTeamName,
  fetchTeamsForClientWithOptions,
  fetchTeamNamesListWithOptions,
  fetchTeamsForLearner,
  teamInviteLink,
  updateScenarioTeam,
};
