import createListActions from "src/app/redux/utils/createListActions";
import rest from "./rest";
import actionFromPromise from "src/app/redux/utils/actionFromPromise";

export default {
  fetchClientCertificates: createListActions('CERTIFICATES_BY_CLIENT', rest.fetchClientCertificates),
  fetchLicenseeCertificates: createListActions('CERTIFICATES_BY_LICENSEE', rest.fetchLicenseeCertificates),
  fetchCertificate: actionFromPromise(rest.fetchCertificate),
  markSimInactiveOrDecertify: actionFromPromise(rest.markSimInactiveOrDecertify),
  fetchSimCertifications: actionFromPromise(rest.fetchSimCertifications),
  markAllSimCertificatesInactive: actionFromPromise(rest.markAllSimCertificatesInactive),
  fetchCertificateHistory: actionFromPromise(rest.fetchCertificateHistory),
};
