import { combineReducers } from 'redux';
import IClient from 'src/app/data/client/interfaces/IClient';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import createListReducer from 'src/app/redux/utils/createListReducer';
import users, { IClientUsersState } from './users/reducer';
import createItemReducer from 'src/app/redux/utils/createItemReducer';
import IRestDataInfo from 'src/app/data/common/interfaces/IRestItemInfo';
import IProjectsCount from 'src/app/data/client/interfaces/IProjectsCount';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';

export interface IClientsState {
  list: IListDataState<IClient>;
  users: IClientUsersState;
  clientForDashboard: IRestDataInfo<IClient>;
  projectsCount: IRestDataInfo<IProjectsCount>;
  error: IErrorState;
}

export default combineReducers({
  list: createListReducer('CLIENTS'),
  users,
  clientForDashboard: createItemReducer('DASHBOARD_CLIENT'),
  projectsCount: createItemReducer('CLIENT_PROJECTS_COUNT'),
  error: createEntityErrorReducer('CLIENT'),
});
