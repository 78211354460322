import React from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import styles from 'src/components/FieldGroup/FieldGroup.css';
import cn from 'classnames';
import InfoTooltip from 'src/components/InfoTooltip/InfoTooltip';
import { TooltipPlacement } from 'src/components/Tooltip/Tooltip';
import csvInjectionProtector from 'src/app/data/common/utils/csvInjectionProtector';
import getLabelForOption from 'src/app/data/common/utils/getLabelForOption';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import _omit from 'lodash/omit';
import ErrorMsgContainer from 'src/components/ErrorMsgContainer/ErrorMsgContainer';

export interface IFieldGroupProps extends React.ComponentProps<any>{
  className?: string;
  error?: string | null;
  id: string;
  fieldInfo?: string;
  help?: string;
  label?: string;
  labelMessage?: string;
  required?: boolean;
  regExp?: RegExp;
  csvProtected?: boolean;
  validationState?: 'success' | 'warning' | 'error' | null;

  // Form control props from react-bootstrap
  
  bsClass?: string;
  as?: React.ElementType;
  inputRef?: (instance: any) => void;
  type?: string;
  labelClass?: string;
  tooltip?: string;
  ariaLabel?: string;
  hideArrows?: boolean;
  showPasswordToggle?: boolean;
  onTogglePassword?: () => void;
  isCobalt?: boolean;
  showErrorMsg?: boolean;
}

const formatOptions = (childrenOption: any) =>
  childrenOption?.map((child: any) => {
    if (child?.type === 'option') {
      return {
        ...child,
        props: {
          ...child.props,
          title: child?.props?.children?.toString(),
          children: getLabelForOption(child?.props?.children?.toString())
        }
      };
    }
    if (Array.isArray(child)) {
      return formatOptions(child);
    }
    return child;
  });

class FieldGroup extends React.Component<IFieldGroupProps & WrappedComponentProps> {

  public state = {
    isFocused: false,
    isLimitExceeded: false,
    isInjected: false,
    eyeIconType: false,
    length: this.props.value ? this.props.value.length: 0,
  };

  public render() {
    const {
      className,
      intl,
      error,
      id,
      label,
      help,
      labelMessage,
      required,
      validationState,
      labelClass,
      bsClass,
      regExp,
      maxLength,
      tooltip,
      ariaLabel,
      csvProtected,
      fieldInfo,
      children,
      hideArrows,
      showPasswordToggle,
      isCobalt,
      showErrorMsg = false,
      ...props
    } = this.props;

    const requiredMark = this.getRequiredMark();

    let formControlProps: FormControlProps = _omit(props, ['onTogglePassword']);
    if (children) {
      formControlProps = { ...props, children: formatOptions(children) };
    }

    return (
      <Form.Group className={className} controlId={id}>
        <div className={styles.labelContainer}>
          {
            label &&
            <Form.Label className={styles.controlLabel}>
              <span className={labelClass}>{label}</span>
              <span aria-hidden="true">{requiredMark}</span>
              {fieldInfo && <span className={'visibilityHidden'}> {fieldInfo} </span>}
              {
                labelMessage && <span className={styles.labelMessage}>{labelMessage}</span>
              }
              {
                 this.getInfoTooltip()
              }
            </Form.Label>
          }
        </div>
        <div className={cn(styles.inputWrapper, validationState && styles[validationState], validationState, hideArrows && styles.hideArrows )}>
        <div className={cn(styles.togglePassword,this.state.isLimitExceeded && styles.formControlLimited)}>
         {
                showPasswordToggle && <i className={`fa ${this.getIcon()}`} onClick={this.onToggleIcon}/>
         }
         <Form.Control
            {...formControlProps}
            bsClass={styles.innerFormControl}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            onFocus={this.toggleFocus(true)}
            onBlur={this.toggleFocus(false)}
            aria-label={ariaLabel}
            aria-required={required}
            className={'form-control-lg'}
          />
          </div> 
          {
            <ErrorMsgContainer
              errorMsg={'MursionPortal.Scenario.Error.Msg'}
              showErrorMsg={showErrorMsg}
              isInputValid={!!this.state.length}
            />
          }
          {
            !isCobalt && this.isLimitExceeded() &&
            <span className={cn(styles.errorMessage, 'errorMsg')}>
              <i className={cn('fas fa-info-circle', styles.icon)} />
              {
                this.isInjected()
              }
            </span>
          }
          <div className={cn(styles.errorWrapper, this.isLimitExceeded() && styles.withErrorMessageWrapper)}>
            {isCobalt && this.isLimitExceeded() &&
              <div className={cn(styles.errorMessage, 'errorMsg')}>
                {this.isInjected()}
              </div>
            }
            {isCobalt && this.state.isLimitExceeded &&
              <div className={cn(styles.textLimit, this.state.isLimitExceeded && styles.errorMessage)}>
                {intl.formatMessage({ id: 'MursionPortal.Scenario.AddedCharacter.Message' }, {
                  added: intl.formatNumber(this.state.length),
                  totalChar: maxLength
                })}
              </div>
            }
          </div>
        </div>
        {help && <Form.Text>{help}</Form.Text>}
        {
          error && !this.state.isFocused &&
          <span className={cn(styles.errorMessage, 'errorMsg')}>
            <i className={cn('fas fa-info-circle', styles.icon)}/>{error}
          </span>
        }
      </Form.Group>
    );
  }

  // prevents 'e' in  numeric fields
  private onKeyDown = (event: any) => {
    const { type, onKeyDown } = this.props;

    const hasNumericE = type === 'number' && event.key === 'e';

    if (hasNumericE) {
      event.preventDefault();
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  private onToggleIcon = (): void => {
    const { onTogglePassword } = this.props;
      this.setState({eyeIconType:!this.state.eyeIconType});
      if(onTogglePassword)
      {
         onTogglePassword();
      }
  };
  
  private getIcon = (): string => {
     return this.state.eyeIconType ? "fa-eye" :"fa-eye-slash";
  };

  private toggleFocus = (isFocused: boolean) =>
    () => this.setState({ isFocused });

  private onChange = (event: any) => {
    const { regExp, maxLength, csvProtected } = this.props;
    const { value } = event.target;

    if (csvProtected) {
      event.currentTarget.value = csvInjectionProtector(event.currentTarget.value);
    }

    this.setState({
      isLimitExceeded: maxLength && value.length > maxLength,
      length: value.length,
    });

    if (!this.props.onChange) {
      return;
    }

    if (!regExp || value.match(regExp)) {
      this.props.onChange(event);
    }
  };
   
  private getRequiredMark = (): string | null => {
    const { required, disabled } = this.props;
    return (required && !disabled) ? '*' : null;
  };

  private isLimitExceeded = () => {
    const { isLimitExceeded, isInjected } = this.state;
    const { error } = this.props;
    return !error && (isLimitExceeded || isInjected);
  };

  private getInfoTooltip = () => {
    const { tooltip } = this.props;
    return tooltip && <InfoTooltip text={tooltip} placement={TooltipPlacement.BOTTOM}/>;
  };

  private isInjected = (): string | boolean => {
    const { intl, isCobalt, maxLength } = this.props;
    const {isInjected, isLimitExceeded, length } = this.state;
    const exceededCharLength =  intl.formatNumber(length - maxLength);
    const allowedCharLimitExceeded = isCobalt ? intl.formatMessage({ id: 'MursionPortal.Scenario.CharacterLimitExceeded.Message' }, { exceededCharLength }) :intl.formatMessage({ id: 'MursionPortal.ErrorMessage.CharacterLimitExceeded' }, { maximumLength: this.props.maxLength });
    const notAvailableCharacters = intl.formatMessage({ id: 'MursionPortal.ErrorMessage.InvalidCharactersDetected' });
    return isInjected ? notAvailableCharacters : isLimitExceeded && allowedCharLimitExceeded;
  }

}

export default injectIntl(FieldGroup);
