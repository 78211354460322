export const AUTHENTICATION_CHECKING = 'AUTH:AUTHENTICATION_CHECKING';
export const AUTHENTICATION_PROCESS = 'AUTH:AUTHENTICATION_PROCESS';
export const AUTHENTICATION_SUCCESSFUL = 'AUTH:AUTHENTICATION_SUCCESSFUL';
export const AUTHENTICATION_SCHEMAS_UPDATE = 'AUTH:AUTHENTICATION_SCHEMAS_UPDATE';
export const AUTHENTICATION_TOKEN_UPDATE = 'AUTH:AUTHENTICATION_TOKEN_UPDATE';
export const NOT_AUTHENTICATED = 'AUTH:NOT_AUTHENTICATED';
export const SWITCH_ROLE = 'AUTH:SWITCH_ROLE';
export const LOG_OUT = 'AUTH:LOG_OUT';
export const CLEAR_ERRORS = 'AUTH:CLEAR_ERRORS';
export const REFRESH_TIMEOUT_TOKEN = 'AUTH:REFRESH_TIMEOUT_TOKEN';
export const UPDATE_REFRESH_TIMEOUT_TOKEN = 'AUTH:UPDATE_REFRESH_TIMEOUT_TOKEN';
export const PORTAL_VERSION = 'PORTAL_VERSION';
