import { IAppState } from 'src/app/redux/store/reducers';
import INotificationMessage from 'src/app/data/common/interfaces/INotificationMessage';

export const getList = (state: IAppState): INotificationMessage[] => state.messagePanel.list.items;
export const getTotalSize = (state: IAppState): number => state.messagePanel.list.totalSize || 0;
export const getPage = (state: IAppState): number => state.messagePanel.list.page || 0;
export const getSizePerPage = (state: IAppState): number => state.messagePanel.list.sizePerPage || 0;

export const getNewList = (state: IAppState): INotificationMessage[] => state.messagePanel.newList.items;
export const getNewMessagesTotalSize = (state: IAppState): number => state.messagePanel.newList.totalSize || 0;
export const getNewPage = (state: IAppState): number => state.messagePanel.newList.page || 0;
export const getNewSizePerPage = (state: IAppState): number => state.messagePanel.newList.sizePerPage || 0;

export const isVisible = (state: IAppState): boolean => state.messagePanel.ui.show;
export const newMessageCount = (state: IAppState): number => state.messagePanel.ui.newMessageCount;
