import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import rest from './rest';

export default {
  fetchSimulationSummaries: actionFromPromise(rest.fetchSimulationSummaries),
  fetchSimulationDetails: actionFromPromise(rest.fetchSimulationDetails),
  fetchSimulationIds: actionFromPromise(rest.fetchSimulationIds),

  exportSimulationSummaries: actionFromPromise(rest.exportSimulationSummaries),
  exportSimulationDetails: actionFromPromise(rest.exportSimulationDetails),
};
