import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from 'src/components/ScenarioSelection/ScenarioSelection.css';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import NumericField from 'src/components/NumericField';
import Checkbox from 'src/components/Checkbox/Checkbox';
import { SelectorTheme } from 'src/components/Selector';
import { isCurrentUserPS, isCurrentUserPSorOps } from 'src/app/data/common/utils/userRoleUtils';
import { useSelector } from 'react-redux';
import { selectors } from 'src/app/redux';
import { MINIMUM_COMPLETION_RATE, MINIMUM_MISS_CANCELLATION_RATE, MINIMUM_SCHEDULING_LOCKOUT_TIME, MINIMUM_SCHEDULING_RATE } from 'src/app/data/common/constants';
import ErrorMessage from 'src/components/ErrorMessage';
import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import InfoTooltip from 'src/components/InfoTooltip/InfoTooltip';
import { TooltipPlacement } from 'src/components/Tooltip/Tooltip';
import { convertTimeStampToDateFormat, getCreationDate, getCurrentDate, getDateFormat, getSchedulingLockoutDate, getStartDateFormat, momentDateToTimestampMillis } from 'src/app/data/common/utils/dateUtil';
import SingleDateSelector from 'src/components/EntitySelectors/SingleDateSelector/SingleDateSelector';
import moment, { Moment } from 'moment';

export interface IScenarioSelection {
    editMode: boolean;
    showScenarioSelection?: boolean;
    className?: string;
    scenarioSettings: IClientConfig;
    onChange: (value: number | null, fieldName: string) => void;
    higerLevelSettings?: IClientConfig;
    scenarioSettingErrorMessage?: string;
    onErrorChange?: (error: string) => void;
    startDate?: number | null;
    projectSchedulingLockoutDate?: number | null;
    timezoneId?: string | null;
}

const ScenarioSelection: FunctionComponent<IScenarioSelection> = (props) => {
    const {
        editMode,
        showScenarioSelection,
        className,
        scenarioSettings,
        onChange,
        higerLevelSettings,
        scenarioSettingErrorMessage,
        onErrorChange,
        startDate,
        projectSchedulingLockoutDate,
        timezoneId,
    } = props;

    const { missOrCancelationRate, schedulingLockoutTime, completionRate, schedulingRate, schedulingLockoutDate, createdDate } = scenarioSettings;

    const userRole = useSelector(selectors.profile.getCurrentUserRole);
    const intl = useIntl();
    const [schedulingLockoutDateFocused, setSchedulingLockoutDateFocused] = useState(false);
    const [creationDate, setCreationDate] = useState<Moment | null>(null);
    const [isSchedulingLockoutDateChanged, setSchedulingLockoutDateChanged] = useState(false);
    
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [validationState, setValidationState] = useState<{ validateCompletionRate: null | 'success' | 'warning' | 'error' | undefined, validateSchedulingRate: null | 'success' | 'warning' | 'error' | undefined }>({
        validateCompletionRate: null,
        validateSchedulingRate: null,
    });
    const scheduleLockoutDate = getSchedulingLockoutDate(schedulingLockoutDate, projectSchedulingLockoutDate);
    const creatingDate = getCreationDate(createdDate, startDate);
    const currentDate = getCurrentDate(timezoneId);

    useEffect(() => {
        if (!editMode || scenarioSettingErrorMessage) {
            setErrorMessage('');
        }
    }, [editMode, scenarioSettingErrorMessage]);

    const onChangeMissOrCancellationRate = (event: React.ChangeEvent<HTMLInputElement>) => {
        let missOrCancelationRateValue = parseInt(event.target.value, 10);
        if (missOrCancelationRateValue < MINIMUM_MISS_CANCELLATION_RATE) {
            missOrCancelationRateValue = MINIMUM_MISS_CANCELLATION_RATE;
        }

        if(missOrCancelationRateValue){
            setErrorMessage('');
        }
        onChangeSettingValues(higerLevelSettings?.missOrCancelationRate, missOrCancelationRateValue, `${intl.formatMessage({ id: 'MursionPortal.Label.MissCancellationRate' })} ${intl.formatMessage({ id: 'MursionPortal.ScenarioSetting.ValidationMessage' })}`);
        onChange(missOrCancelationRateValue, 'missOrCancelationRate');
    };

    const onChangeSchedulingLockoutTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        let schedulingLockoutTimeValue = parseInt(event.target.value, 10);
        if (schedulingLockoutTimeValue < MINIMUM_SCHEDULING_LOCKOUT_TIME) {
            schedulingLockoutTimeValue = MINIMUM_SCHEDULING_LOCKOUT_TIME;
        }

        if(schedulingLockoutTimeValue){
            setErrorMessage('');
        }
        onChangeSettingValues(higerLevelSettings?.schedulingLockoutTime, schedulingLockoutTimeValue, `${intl.formatMessage({ id: 'MursionPortal.ScenarioInfo.SchedulingLockout.Time' })} ${intl.formatMessage({ id: 'MursionPortal.ScenarioSetting.ValidationMessage' })}`);
        onChange(schedulingLockoutTimeValue, 'schedulingLockoutTime');
    };

    const onChangeSchedulingRate = (schedulingRateVal: React.ChangeEvent<HTMLInputElement>) => {
        let schedulingRateValue = parseInt(schedulingRateVal.target.value, 10);
        if (schedulingRateValue < MINIMUM_SCHEDULING_RATE) {
            schedulingRateValue = MINIMUM_SCHEDULING_RATE;
        }
        setValidationState({
            ...validationState,
            validateSchedulingRate: schedulingRateValue < 1 ? 'error' : undefined,
        });

        if (isNaN(schedulingRateValue) || (completionRate === null || completionRate === undefined || isNaN(completionRate)) || (!isNaN(completionRate) && completionRate >= schedulingRateValue)) {
            setErrorMessage('');
        } else {
            setErrorMessage(intl.formatMessage({ id: 'MursionPortal.SchedulingRate.ValidationMessage' }));
        }
        onChangeSettingValues(higerLevelSettings?.schedulingRate, schedulingRateValue, `${intl.formatMessage({ id: 'MursionPortal.Label.SchedulingRate' })} ${intl.formatMessage({ id: 'MursionPortal.ScenarioSetting.ValidationMessage' })}`);
        onChange(schedulingRateValue, 'schedulingRate');
    };

    const onChangeCompletion = (completionRateVal: React.ChangeEvent<HTMLInputElement>) => {
        let completionRateValue = parseInt(completionRateVal.target.value, 10);
        if (completionRateValue < MINIMUM_COMPLETION_RATE) {
            completionRateValue = MINIMUM_COMPLETION_RATE;
        }
        setValidationState({
            ...validationState,
            validateCompletionRate: completionRateValue < 1 ? 'error' : undefined,
        });
        if (isNaN(completionRateValue) || completionRateValue >= (schedulingRate as number)) {
            setErrorMessage('');
        } else {
            setErrorMessage(intl.formatMessage({ id: 'MursionPortal.SchedulingRate.ValidationMessage' }));
        }
        onChangeSettingValues(higerLevelSettings?.completionRate, completionRateValue, `${intl.formatMessage({ id: 'MursionPortal.Label.Completion' })} ${intl.formatMessage({ id: 'MursionPortal.ScenarioSetting.ValidationMessage' })}`);
        onChange(completionRateValue, 'completionRate');
    };

    const onChangeSettingValues = (higerValue: number | null | undefined, lowerValue: number, message: string) => {
        if (onErrorChange) {
            onErrorChange('');
        }
        if (higerValue && lowerValue > higerValue && onErrorChange) {
            onErrorChange(message);
        }
    };

    const handleschedulingLockoutDate = (newDate: moment.Moment | null) => {
        setSchedulingLockoutDateChanged(true);
        if (newDate && newDate.isSameOrBefore()) {
            const newStartDate = getStartDateFormat(timezoneId, newDate);
            setCreationDate(newStartDate);
            onChange(momentDateToTimestampMillis(newStartDate), 'schedulingLockoutDate');
        } else {
            setCreationDate(newDate);
            onChange(newDate as null, 'schedulingLockoutDate');
        }
    }; 

    const isOutsideRange = (day: Moment) => {
        if (creatingDate) {
            return day.isBefore(moment(creatingDate).subtract(1, 'day')) || day.isAfter(currentDate);
        }
        return day.isBefore(currentDate.add(1, 'day')) || day.isAfter(currentDate);
    };

    const onFocusChanged = ({ focused }: { focused: boolean }) => {
        setSchedulingLockoutDateFocused(focused);
    };
    
    function getSelectedDate() {
        if (scheduleLockoutDate && !creationDate && !isSchedulingLockoutDateChanged) {
            return moment(scheduleLockoutDate);
        }
        return creationDate;
    }

    const showSchedulingLockedoutDate = isCurrentUserPSorOps(userRole) && !!creatingDate;

    return (
        <>
        {editMode ?
        <div className={cn(styles.scenarioSelectionWrap, editMode && styles.editScenarioSelection, className)}>
            {showScenarioSelection &&
                <div className={styles.showScenarioSelectionCol}>
                    <Checkbox
                        id={'ScenarioSelection'}
                        key={'ScenarioSelection'}
                        disabled={!editMode}
                        themeType={SelectorTheme.Cobalt}
                    >
                        <h4>Scenario Selection</h4>
                    </Checkbox>
                </div>
            }
            {isCurrentUserPS(userRole) &&
                <div className={styles.scenarioSelectionCol}>
                    <label>{intl.formatMessage({ id: 'MursionPortal.Label.Completion' })}
                        <InfoTooltip
                            text={intl.formatMessage({ id: 'MursionPortal.Label.CompletionRate.Tooltip' })}
                            placement={TooltipPlacement.TOP} />
                    </label>
                    <NumericField
                        disabled={!editMode}
                        id='completion'
                        min={MINIMUM_COMPLETION_RATE}
                        validationState={validationState.validateCompletionRate}
                        value={completionRate}
                        onChange={onChangeCompletion}
                    />
                </div>
            }
            {isCurrentUserPS(userRole) &&
                <div className={styles.scenarioSelectionCol}>
                    <label>{intl.formatMessage({ id: 'MursionPortal.Label.SchedulingRate' })}
                        <InfoTooltip
                            text={intl.formatMessage({ id: 'MursionPortal.Label.SchedulingRate.Tooltip' })}
                            placement={TooltipPlacement.TOP} />
                    </label>
                    <NumericField
                        disabled={!editMode}
                        id='schedulingRate'
                        min={MINIMUM_SCHEDULING_RATE}
                        validationState={validationState.validateSchedulingRate}
                        value={schedulingRate}
                        onChange={onChangeSchedulingRate}
                    />
                </div> 
            }
            {
                isCurrentUserPSorOps(userRole) &&
                    <>
                        <div className={styles.scenarioSelectionCol}>
                            <label>{intl.formatMessage({ id: 'MursionPortal.Label.MissCancellationRate' })}
                                <InfoTooltip
                                    text={intl.formatMessage({ id: 'MursionPortal.Label.MissCancellationRate.Tooltip' })}
                                    placement={TooltipPlacement.TOP} />
                            </label>
                            <NumericField
                                disabled={!editMode}
                                id='missOrCancelationRate'
                                min={MINIMUM_MISS_CANCELLATION_RATE}
                                value={missOrCancelationRate}
                                onChange={onChangeMissOrCancellationRate} />
                        </div>
                        <div className={styles.scenarioSelectionCol}>
                            <label>{intl.formatMessage({ id: 'MursionPortal.ScenarioInfo.SchedulingLockout.Time' })} {intl.formatMessage({ id: 'Users.CreateUser.Label.Hours' })}
                                <InfoTooltip
                                    text={intl.formatMessage({ id: 'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip' })}
                                    placement={TooltipPlacement.TOP} />
                            </label>
                            <NumericField
                                id='schedulingLockoutTime'
                                disabled={!editMode}
                                min={MINIMUM_SCHEDULING_LOCKOUT_TIME}
                                value={schedulingLockoutTime}
                                onChange={onChangeSchedulingLockoutTime} />
                        </div>
                    </> 
            }
            {
                showSchedulingLockedoutDate &&
                <div className={styles.scenarioSelectionCol}>
                    <label>{intl.formatMessage({ id: 'MursionPortal.Label.SchedulingLockoutDate' })}
                        <InfoTooltip
                            text={intl.formatMessage({ id: 'MursionPortal.Label.SchedulingLockoutDate.Tooltip' })}
                            placement={TooltipPlacement.TOP} />
                    </label>
                    <div className={styles.scenarioSelectionDate}>
                        <SingleDateSelector
                            date={getSelectedDate()}
                            onDateChange={handleschedulingLockoutDate}
                            focused={schedulingLockoutDateFocused}
                            id='send-date'
                            numberOfMonths={1}
                            isOutsideRange={isOutsideRange}
                            displayFormat={getDateFormat()}
                            openDirection='up'
                            anchorDirection='left'
                            block={true}
                            small={true}
                            noBorder={true}
                            onFocusChange={onFocusChanged}
                        />
                    </div>                   
                </div>
            }
            {
                errorMessage && editMode && <ErrorMessage message={errorMessage} />
            }
        </div>
        :
        <div className={cn(styles.scenarioSelectionWrap, !editMode && styles.previewScenarioSelection, className)}>
            {isCurrentUserPS(userRole) && completionRate &&
                <div className={styles.scenarioSelectionCol}>
                    <div className={cn(styles.output, styles.completion)}>
                        <div className={styles.label}>{intl.formatMessage({ id: 'MursionPortal.Label.Completion' })}
                            <InfoTooltip
                                text={intl.formatMessage({ id: 'MursionPortal.Label.CompletionRate.Tooltip' })}
                                placement={TooltipPlacement.TOP} />
                        </div>
                        {intl.formatMessage(
                            { id: 'MursionPortal.ScenarioInfo.CompletionRate' },
                            { completionRate }
                        )}
                    </div>
                </div>
            }
            {isCurrentUserPS(userRole) && schedulingRate &&
                <div className={styles.scenarioSelectionCol}>
                    <div className={cn(styles.output, styles.schedulingRate)}>
                        <div className={styles.label}>{intl.formatMessage({ id: 'MursionPortal.Label.SchedulingRate' })}
                            <InfoTooltip
                                text={intl.formatMessage({ id: 'MursionPortal.Label.SchedulingRate.Tooltip' })}
                                placement={TooltipPlacement.TOP} />
                        </div>
                        {intl.formatMessage(
                            { id: 'MursionPortal.ScenarioInfo.SchedulingRate.Text' },
                            { code: schedulingRate }
                        )}
                    </div>
                </div>
            }
            {
                isCurrentUserPSorOps(userRole) && missOrCancelationRate &&
                <div className={styles.scenarioSelectionCol}>
                    <div className={cn(styles.output, styles.missCancellationRate)}>
                        <div className={styles.label}>{intl.formatMessage({ id: 'MursionPortal.Label.MissCancellationRate' })}
                            <InfoTooltip
                                text={intl.formatMessage({ id: 'MursionPortal.Label.MissCancellationRate.Tooltip' })}
                                placement={TooltipPlacement.TOP} />
                        </div>
                        {intl.formatMessage(
                            { id: 'MursionPortal.ScenarioInfo.SchedulingRate.Text' },
                            { code: missOrCancelationRate }
                        )}
                    </div>
                </div>
            }
            {
                isCurrentUserPSorOps(userRole) && schedulingLockoutTime &&
                <div className={styles.scenarioSelectionCol}>
                    <div className={cn(styles.output, styles.schedulingLockout)}>
                        <div className={styles.label}>{intl.formatMessage({ id: 'MursionPortal.ScenarioInfo.SchedulingLockout.Time' })}
                            <InfoTooltip
                                text={intl.formatMessage({ id: 'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip' })}
                                placement={TooltipPlacement.TOP} />
                        </div>
                        {schedulingLockoutTime} {intl.formatMessage({ id: 'MursionPortal.ShortLabel.Hours' })}
                    </div>
                </div>
            }
            {
                isCurrentUserPSorOps(userRole) && scheduleLockoutDate &&
                <div className={styles.scenarioSelectionCol}>
                    <div className={cn(styles.output, styles.schedulingLockout)}>
                        <div className={styles.label}>{intl.formatMessage({ id: 'MursionPortal.Label.SchedulingLockoutDate' })}
                            <InfoTooltip
                                text={intl.formatMessage({ id: 'MursionPortal.Label.SchedulingLockoutDate.Tooltip' })}
                                placement={TooltipPlacement.TOP} />
                        </div>
                        {convertTimeStampToDateFormat(timezoneId, scheduleLockoutDate)}
                    </div>
                </div>
            }
        </div>
        }
        </>
    );
};

export default ScenarioSelection;