import { IAppState } from 'src/app/redux/store/reducers';
import { IErrorValidation, IErrorProperties } from 'src/app/data/common/interfaces/IRestError';
import { ERROR_CODE_FORBIDDEN } from 'src/app/data/common/utils/errorUtils';

export const getErrorMessage = (state: IAppState): string => state.errors.message;
export const getErrorCode = (state: IAppState): string | number => state.errors.code;
export const hasAccessError = (state: IAppState): boolean => state.errors.code === ERROR_CODE_FORBIDDEN || state.errors.code === 403;
export const getErrorValidations = (state: IAppState): IErrorValidation[] => state.errors.validations;
export const getErrorProperties = (state: IAppState): IErrorProperties | null => state.errors.properties;

