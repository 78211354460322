import React, { FunctionComponent } from 'react';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { getRoleById } from 'src/app/data/common/roles';
import { useIntl } from 'react-intl';
import { EntitySelector, IEntitySelectorBaseProps, ValueToOptionMapper } from 'src/components/EntitySelectors';
import { getOptionValue } from 'src/app/data/common/utils';

export interface IScenarioSelectorProps extends IEntitySelectorBaseProps<RoleID> {
  roleIds: RoleID[];
  onChange: (val: RoleID[] | null) => void;
  active: boolean;
}

const RoleSelector: FunctionComponent<IScenarioSelectorProps> = (props) => {

  const { active, roleIds } = props;

  const intl = useIntl();

  const valueToOption: ValueToOptionMapper<RoleID, RoleID> = (value) => ({
    value,
    label: getRoleById(value)?.name || value,
  });

  return (
    <EntitySelector
      {...props}
      themeType={props.themeType || SelectorTheme.Filter}
      isMulti={true}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'Filters.Role' })}
      disabledTitle={intl.formatMessage({ id: 'Filters.RoleDisabledTitle' })}
      items={roleIds}
      disabled={!active}
      valueToOption={valueToOption}
      optionToValue={getOptionValue}
    />
  );
};

export default React.memo(RoleSelector);
