exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".BackButton__backBtn___38RX2{color:#2741ae;font-weight:600;font-size:14px;line-height:18px;background:transparent;padding:0;border:none}.BackButton__backBtn___38RX2 img{margin-right:4px}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/BackButton/BackButton.css"],"names":[],"mappings":"AAAA,6BACE,cAAe,AACf,gBAAiB,AACjB,eAAgB,AAChB,iBAAkB,AAClB,uBAAwB,AACxB,UAAW,AACX,WAAa,CACd,AAED,iCACE,gBAAkB,CACnB","file":"BackButton.css","sourcesContent":[".backBtn{\n  color: #2741AE;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 18px;\n  background: transparent;\n  padding: 0;\n  border: none;\n}\n\n.backBtn img{\n  margin-right: 4px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"backBtn": "BackButton__backBtn___38RX2"
};