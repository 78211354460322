import IRestError, { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import { IAppState } from 'src/app/redux/store/reducers';
import { parseErrorMessage } from 'src/app/data/common/utils/errorUtils';

export const getList = (state: IAppState) => state.avatars.list.items;
export const getListPage = (state: IAppState): number => state.avatars.list.page || 0;
export const getListSizePerPage = (state: IAppState): number => state.avatars.list.sizePerPage || 0;
export const getListTotalSize = (state: IAppState): number => state.avatars.list.totalSize || 0;
export const isListFetched = (state: IAppState): boolean => state.avatars.list.fetched;
export const getListRefreshError = (state: IAppState): IRestError | null => state.avatars.list.refreshError;
export const isListRefreshing = (state: IAppState): boolean => state.avatars.list.refreshing;

export const getListByEnvironment = (state: IAppState) => state.avatars.listByEnvironment.items;
export const getListByEnvironmentPage = (state: IAppState): number => state.avatars.listByEnvironment.page || 0;
export const getListByEnvironmentSizePerPage = (state: IAppState): number => state.avatars.listByEnvironment.sizePerPage || 0;
export const getListByEnvironmentTotalSize = (state: IAppState): number => state.avatars.listByEnvironment.totalSize || 0;
export const isListByEnvironmentFetched = (state: IAppState): boolean => state.avatars.listByEnvironment.fetched;
export const getListByEnvironmentRefreshError = (state: IAppState): IRestError | null => state.avatars.listByEnvironment.refreshError;
export const isListByEnvironmentRefreshing = (state: IAppState): boolean => state.avatars.listByEnvironment.refreshing;

export const getErrorMessage = (state: IAppState): string => parseErrorMessage(state.avatars.error);
export const getValidationErrors = (state: IAppState): IErrorValidation[] => state.avatars.error.validations;
