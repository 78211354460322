import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import { getDateFormat } from 'src/app/data/common/utils/dateUtil';
import { DateRangePicker } from 'react-dates';
import styles from './DateRangeSelector.css';
import cn from 'classnames';
import Button, { ButtonSize } from 'src/components/Button';
import { useIntl } from 'react-intl';
import TranslateMessage from 'src/i18n/TranslateMessage';
import useWindowSize from 'src/hooks/useWindowSize';
import { TLocaleId } from 'src/i18n';
import { getDefaultPresets } from './getDefaultPresets';
import useDebounce from 'src/hooks/useDebounce';

export enum DateRangeSelectorTheme {
  Default,
  Cobalt,
  CobaltDropdown,
}

export interface IDateRangePreset {
  text: TLocaleId;
  start: Moment;
  end: Moment;
}

export interface IDateRange {
  startDate: number;
  endDate: number;
}

export type OnDateRangeChange = (range: IDateRange) => void;

export interface ISessionTableDateRangePicker {
  tzId: string;
  presets?: IDateRangePreset[];
  inline?: boolean;
  maxDate?: number;
  onChange: OnDateRangeChange;
  theme?: DateRangeSelectorTheme;
  value?: IDateRange;
  active?: boolean;
}

const DateRangeSelector: FunctionComponent<ISessionTableDateRangePicker> = (props) => {

  const {
    tzId,
    presets,
    inline,
    onChange,
    maxDate,
    theme = DateRangeSelectorTheme.Default,
    active = true,
    value,
  } = props;

  const initValues = (value?.startDate && value?.endDate) ? {
    start: moment.tz(value.startDate, tzId),
    end: moment.tz(value.endDate, tzId),
  } : {
    start: moment.tz(tzId).startOf('month'),
    end: moment.tz(tzId).endOf('month'),
  };

  const dateRangeRef = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<Moment>(initValues.start);
  const [endDate, setEndDate] = useState<Moment>(initValues.end);
  const [dateFocusedInput, setDateFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
  const [currentTzId, setCurrentTzId] = useState<string>(tzId);
  const [key, setKey] = useState<number>(Date.now());
  const windowSize = useWindowSize();
  const cobaltTheme = useMemo(() => theme === DateRangeSelectorTheme.Cobalt, [theme]);
  const cobaltDateRangeDropdown = useMemo(() => theme === DateRangeSelectorTheme.CobaltDropdown, [theme]);
  const presetsToShow = useMemo(() => presets?.length ? presets : getDefaultPresets(tzId), [tzId]);

  // keep the day range while changing timezone and/or maxDate
  useEffect(() => {
    const currentStartDay = moment.tz(startDate, currentTzId).format('YYYY-MM-DD');
    const currentEndDay = moment.tz(endDate, currentTzId).format('YYYY-MM-DD');
    setCurrentTzId(tzId);

    // On the session page screen reader was not announcing 'to' date and as this is a third party plugin we had to change aria-hidden true to false.
    const inputArrowWrapper = (document.getElementsByClassName('DateRangePickerInput_arrow'));
    inputArrowWrapper[0]?.setAttribute('aria-hidden', 'false');

    if (startDate) {
      const start = moment.tz(currentStartDay, tzId);

      setStartDate(start);
    }

    if (!endDate) {
      return;
    }

    const end = moment.tz(currentEndDay, tzId).endOf('d');
    if (maxDate) {
      const max = moment.tz(maxDate, tzId);
      setEndDate(max.isBefore(end) ? max : end);
    } else {
      setEndDate(end);
    }
  }, [tzId, maxDate]);

  useEffect(() => {
    if (value?.startDate && value.endDate) {
      if (!startDate.isSame(value.startDate)) {
        setStartDate(moment.tz(value.startDate, tzId));
      }
      if (!endDate.isSame(value.endDate)) {
        setEndDate(moment.tz(value.endDate, tzId));
      }
    }
  }, [value]);

  const { value: range } = useDebounce({
    startDate: startDate.valueOf(),
    endDate: endDate.valueOf(),
  }, 200);

  useEffect(() => {
    onChange(range);
  }, [range]);

  useEffect(() => {
    const inputFields = dateRangeRef.current?.getElementsByTagName('input');
    const buttons = dateRangeRef.current?.getElementsByTagName('button');
    const ariaDescribedby = dateRangeRef.current?.getElementsByTagName('p');

    if (inputFields) {
      if (cobaltTheme) {
        inputFields[0].setAttribute('tabindex', '0');
      }
      else if (cobaltDateRangeDropdown) {
        inputFields[0].setAttribute('tabindex', '0');
      }
      else {
        inputFields[0].setAttribute('tabindex', '-1');
      }
      inputFields[1].setAttribute('tabindex', '-1');
      inputFields[1].addEventListener('keydown', handleKeyPressOnDateRangeSelector);
    }
    if (buttons) {
      buttons[0].setAttribute('aria-label', intl.formatMessage({ id: 'MursionPortal.AriaLabel.DateRangeCalendarButton' }));
    }
    if (ariaDescribedby) {
      ariaDescribedby[0].innerText = intl.formatMessage({ id: 'MursionPortal.AriaDescribedby.StartDate' });
      ariaDescribedby[1].innerText = intl.formatMessage({ id: 'MursionPortal.AriaDescribedby.EndDate' });
    }
    return () => {
      const input = dateRangeRef?.current?.getElementsByTagName('input');
      if (input) {
        input[1].removeEventListener('keydown', handleKeyPressOnDateRangeSelector);
      }
    };
  }, [dateRangeRef.current]);

  const initialVisibleMonth = () => {
    return dateFocusedInput === 'endDate' && endDate ? endDate : startDate || moment();
  };

  const getVisibleMonth = () => {
    return startDate && endDate
      ? initialVisibleMonth
      : undefined;
  };

  useEffect(() => {
    const isDateChanged = startDate && endDate && !(startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year'));
    if (isDateChanged) {
      setKey(Date.now());
    }
  }, [dateFocusedInput]);

  const handleKeyPressOnDateRangeSelector = (e: any) => {
    if (e.keyCode === 9) {
      setDateFocusedInput(null);
    }
  };

  const orientation = windowSize.width <= 767 ? 'vertical' : 'horizontal';

  const onDatesChange = (datesObj: any) => {
    if (!datesObj.startDate) {
      return;
    }

    let newEndDate = datesObj.endDate;
    
    if (!newEndDate || newEndDate.isBefore(datesObj.startDate)) {
      newEndDate = datesObj.startDate.clone().add(1, 'd');
      } else {
      newEndDate = datesObj.endDate.clone().endOf('d');
    }
    setStartDate(datesObj.startDate);
    setEndDate(newEndDate);
  };

  const onDatesFocusChange = (focusedInput: 'startDate' | 'endDate' | null) => {
    setDateFocusedInput(focusedInput);
  };

  const isOutsideRange = (day: any) => {
    const dateTimestamp = day.tz(tzId).startOf('day').valueOf();

    return dateTimestamp <= 0 || (!!maxDate && dateTimestamp > maxDate);
  };

  const isSameDay = (a?: Moment, b?: Moment) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) {
      return false;
    }

    return a.date() === b.date()
      && a.month() === b.month()
      && a.year() === b.year();
  };
  const intl = useIntl();
  const renderDatePresets = () => {
    return (
      <div className={styles.timeRangeCalendarBtnWrap}>
        {
          presetsToShow.map(({ text, start, end }) => {
            const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);

            const onPresetClick = () => onDatesChange({ startDate: start, endDate: end });

            return (
              <Button
                key={intl.formatMessage({ id: text })}
                btnSize={ButtonSize.SMALL}
                className={cn(styles.presetBtn, isSelected && styles.presetBtn__selected)}
                onClick={onPresetClick}
              >
                {TranslateMessage(text)}
              </Button>
            );
          })}
      </div>
    );
  };

  const fromLabel = intl.formatMessage({ id: 'MursionPortal.AriaLabel.From' }) + ' ' + moment.tz(startDate, currentTzId).format(getDateFormat());
  const toLabel = intl.formatMessage({ id: 'MursionPortal.AriaLabel.To' }) + ' ' + moment.tz(endDate, currentTzId).format(getDateFormat());

  return (
    <div className={cn(
      styles.timeRange,
      inline && styles.inline,
      cobaltTheme && 'DateRangePickerCobalt',
      cobaltDateRangeDropdown && 'DateRangePickerCobalt DateRangePickerCustomCobalt'
    )} ref={dateRangeRef}>

      <span tabIndex={0} aria-label={fromLabel}>
        {TranslateMessage('Filters.Date.From')} &nbsp;
      </span>

      <DateRangePicker
        renderCalendarInfo={renderDatePresets}
        startDate={startDate ? startDate.clone() : null}
        startDateId="session_range_start"
        endDate={endDate ? endDate.clone() : null}
        endDateId="session_range_end"
        focusedInput={dateFocusedInput}
        startDatePlaceholderText={getDateFormat()}
        endDatePlaceholderText={getDateFormat()}
        onDatesChange={onDatesChange}
        onFocusChange={onDatesFocusChange}
        isOutsideRange={isOutsideRange}
        noBorder={true}
        customInputIcon={<i className={'fa fa-calendar-alt'} />}
        minimumNights={0}
        key={key}
        initialVisibleMonth={getVisibleMonth()}
        customArrowIcon={(
          <span aria-label={`${fromLabel} - ${toLabel}`} className={styles.labelPadding}>
            {TranslateMessage('Filters.Date.To')}
          </span>
        )}
        orientation={orientation}
        disabled={!active}
      />
    </div>
  );
};

export function makeDateRange(startDate?: number | null, endDate?: number | null) {
  return startDate && endDate ? { startDate, endDate } : undefined;
}

export default DateRangeSelector;
