import * as React from 'react';
import { ChangeEvent, FunctionComponent } from 'react';
import styles from './OnlyBillable.css';
import TranslateMessage from 'src/i18n/TranslateMessage';
import Checkbox, { ICheckboxProps } from 'src/components/Checkbox/Checkbox';

interface IOnlyBillableProps extends ICheckboxProps {
  onBillableChange: (value: true | undefined) => void;
  active?: boolean;
}

const OnlyBillable: FunctionComponent<IOnlyBillableProps> = ({ onBillableChange, active = true, ...props }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onBillableChange(event.target.checked || undefined);
  };

  return (
    <div className={styles.onBillableContainer}>
      <Checkbox
        {...props}
        onChange={handleChange}
        disabled={!active}
      >
        {TranslateMessage('Dashboard.ProjectsTable.Checkbox.OnlyBillable')}
      </Checkbox>
    </div>
  );
};

export default OnlyBillable;
