import React, { useMemo, useRef } from 'react';
import styles from './Pills.css';
import { useIntl } from 'react-intl';
import { TLocaleId } from 'src/i18n';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { Pill } from 'src/components/pills/Pill';
import useWindowSize from 'src/hooks/useWindowSize';

interface IProps {
  selectedAll?: boolean;
  values: INamedEntry[];
  maxPills?: number;
  allPlaceholder?: TLocaleId;
  morePlaceholder: TLocaleId;
  countingPlaceholder?: TLocaleId;
  disabled?: boolean;
  onClick?: (value: INamedEntry, index: number) => void;
  withButton?: boolean;
  buttonDisabled?: boolean;
  onButtonClick?: (value: INamedEntry, index: number) => void;
  maxWidth?: number;
}


export const Pills = (props: IProps) => {
  const {
    values,
    maxPills: maxPillValue,
    selectedAll,
    allPlaceholder,
    morePlaceholder,
    countingPlaceholder,
    onClick,
    onButtonClick,
    withButton,
    maxWidth = 120,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowSize().width;
  const maxPills = useMemo(() => {
    if (maxPillValue) {
      return maxPillValue;
    }

    const width = Math.max(containerRef?.current?.clientWidth || 0, maxWidth);
    return Math.floor(width / maxWidth) - 1;

  }, [windowWidth, values.length, maxPillValue]);

  const intl = useIntl();

  if (selectedAll && allPlaceholder) {
    return (
      <div className={styles.pillContainer} ref={containerRef}>
        <Pill
          value={{ name: intl.formatMessage({ id: allPlaceholder }), id: '' }}
          withButton={false}
        />
      </div>
    );
  }

  const selected = (values || []);
  const showingValues = selected.length > (maxPills + 1) ? selected.slice(0, maxPills) : selected;
  const moreCount = selected.length - maxPills;
  const moreLabel = (moreCount === selected.length && countingPlaceholder) ?
    `${intl.formatNumber(moreCount)} ${intl.formatMessage({ id: countingPlaceholder })}` :
    `${intl.formatMessage({ id: morePlaceholder })} ${intl.formatNumber(moreCount)}`;

  return (
    <div className={styles.pillContainer} ref={containerRef}>
      {showingValues.map((value, index) => {
        const handleOnClick = onClick && (() => onClick(value, index));
        const handleOnButtonClick = onButtonClick && (() => onButtonClick(value, index));
        return (
          <Pill {...props} key={index} value={value}
            onClick={handleOnClick}
            onButtonClick={handleOnButtonClick}
            withButton={withButton}
            maxWidth={maxWidth}
          />
        );
      })}

      {(moreCount > 1) && (
        <Pill
          className={styles.autoWidthPill}
          {...props}
          value={{ name: moreLabel, id: '' }}
          withButton={false}
          onClick={undefined}
          onButtonClick={undefined}
          maxWidth={200}
        />
      )}
    </div>
  );
};
