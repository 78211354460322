import React, { FunctionComponent, useMemo } from 'react';
import getScenarioVersionLabel from 'src/app/data/projects/utils/getScenarioVersionLabel';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import { EntitySelector, IEntitySelectorBaseProps, ValueToOptionMapper } from 'src/components/EntitySelectors';
import { getOptionValue } from 'src/app/data/common/utils';


export interface IScenarioVersionSelectorPropsBase extends IEntitySelectorBaseProps<ScenarioVersion> {
  active?: boolean;
}

export interface IScenarioVersionSelectorProps extends IScenarioVersionSelectorPropsBase {
  availableScenarioVersions: ScenarioVersion[] | null;
}

const scenarioVersionOptions: ScenarioVersion[] = [
  ScenarioVersion.V2,
  ScenarioVersion.V3,
  ScenarioVersion.V3z,
  ScenarioVersion.V3meet,
  ScenarioVersion.V3MAGIC,
];

const ScenarioVersionSelector: FunctionComponent<IScenarioVersionSelectorProps> = (props) => {

  const intl = useIntl();
  const { availableScenarioVersions, active = true } = props;

  const items: ScenarioVersion[] = useMemo(() => {
    const availableScenarios = (availableScenarioVersions && availableScenarioVersions.length)
      ? scenarioVersionOptions.filter(opt => availableScenarioVersions.some(sv => sv === opt))
      : scenarioVersionOptions;
    if (availableScenarios.includes(ScenarioVersion.V3) && !availableScenarios.includes(ScenarioVersion.V3MAGIC)) {
      availableScenarios.push(ScenarioVersion.V3MAGIC);
    }
    return availableScenarios;
  }, [availableScenarioVersions]);

  const valueToOption: ValueToOptionMapper<ScenarioVersion, ScenarioVersion> = (value) => ({
    value,
    label: getScenarioVersionLabel(value)
  });

  return (
    <EntitySelector
      {...props}
      isMulti={props.isMulti !== false}
      isSearchable={false}
      placeholder={intl.formatMessage({ id: 'MursionPortal.Filters.SoftwareVersion' })}
      searchPlaceholder={intl.formatMessage({ id: 'MursionPortal.Filters.PlaceHolder.SearchVersion' })}
      items={items}
      disabled={!active}
      valueToOption={valueToOption}
      optionToValue={getOptionValue}
    />
  );
};

const ScenarioVersionSelectorContainer: FunctionComponent<IScenarioVersionSelectorPropsBase> = (props) => {

  const clientConfig = useSelector(selectors.clientsConfig.getClientConfig);
  const companyScenarioConfig = useSelector(selectors.companyConfig.getScenarioConfig);
  const availableScenarioVersions = clientConfig?.allowedScenarioVersions
    || companyScenarioConfig?.allowedScenarioVersions
    || null;

  return <ScenarioVersionSelector availableScenarioVersions={availableScenarioVersions} {...props} />;
};

export default React.memo(ScenarioVersionSelectorContainer);
