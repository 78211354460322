import React, { FunctionComponent } from 'react';
import Selector, { ISelectorOption } from 'src/components/Selector/Selector';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { useIntl } from 'react-intl';

export interface IScheduleSelectorProps {
  onChange: (val: string | number) => void;
}

const ScheduleSelector: FunctionComponent<IScheduleSelectorProps> = (props) => {

  const scheduleSelectorIntl = useIntl();
  const scheduleSelectorItems = [{value:'ALL', label :scheduleSelectorIntl.formatMessage({id : 'MursionPortal.ScheduleTypeSelector.All'})},{value:'AUTO', label :scheduleSelectorIntl.formatMessage({id : 'MursionPortal.ScheduleTypeSelector.Auto'})},{value:'MANUAL', label :scheduleSelectorIntl.formatMessage({id : 'MursionPortal.ScheduleTypeSelector.Manual'})},{value:'NA', label :scheduleSelectorIntl.formatMessage({id : 'MursionPortal.ScheduleTypeSelector.NA'})}];

  const handleOnChange = (val:ISelectorOption) => {
    props.onChange(val.value);
};
  return (
    <Selector
    themeType={SelectorTheme.Filter}
    clearOnOptionsChange={true}
    isMulti={false}
    placeholder= {scheduleSelectorIntl.formatMessage({id : 'MursionPortal.ScheduleTypeSelector.All'})}
    defaultValue={[{value:'ALL', label: scheduleSelectorIntl.formatMessage({id : 'MursionPortal.ScheduleTypeSelector.All'})}]}
    isSearchable={false}
    onChange={handleOnChange}
    options={scheduleSelectorItems}
    total={scheduleSelectorItems.length}
    loading={false}
    disabledTitle={''}
    showSelectedValue={true}
    ariaLabel={scheduleSelectorIntl.formatMessage({ id: 'Calendar.Button.Schedule' })}
  />
  );
};

export default React.memo(ScheduleSelector);
