import rest from './rest';
import * as types from './types';
import scenariosRest from '../scenarios/rest';
import actionFromPromise from 'src/app/redux/utils/actionFromPromise';
import { AnyAction } from 'redux';

export default {
  fetchClientsWithActiveScenarios: actionFromPromise(rest.fetchClientsWithActiveScenarios),
  fetchProjectsWithActiveScenarios: actionFromPromise(rest.fetchProjectsWithActiveScenarios),
  fetchSessionWizardActiveScenarios: actionFromPromise(rest.fetchSessionWizardActiveScenarios),
  fetchSimSpecsByScenarioId: actionFromPromise(rest.fetchSimSpecsByScenarioId),
  fetchSimSpecsByScenarioTemplateId: actionFromPromise(rest.fetchSimSpecsByScenarioTemplateId),
  fetchAvailableLearners: actionFromPromise(rest.fetchAvailableLearners),
  fetchAvailableSsLearners: actionFromPromise(rest.fetchAvailableSsLearners),
  fetchScenarioAvailableTimeBlocks: actionFromPromise(rest.fetchScenarioAvailableTimeBlocks),
  fetchScenarioTemplateAvailableTimeBlocks: actionFromPromise(rest.fetchScenarioTemplateAvailableTimeBlocks),
  fetchScenario: actionFromPromise(scenariosRest.fetchScenario),
  fetchScenarioTemplate: actionFromPromise(scenariosRest.fetchScenarioTemplate),
  fetchActiveScenarioTemplates: actionFromPromise(rest.fetchSessionWizardActiveScenarioTemplates),
  allocateAvailability: actionFromPromise(rest.allocateAvailability),
  reAllocateAvailability: actionFromPromise(rest.reAllocateAvailability),
  releaseAvailability: actionFromPromise(rest.releaseAvailability),
  allocateTimeBlock: (timeBlock: { startDate: number, endDate: number, expired: boolean, } | null): AnyAction => ({
    payload: timeBlock,
    type: types.ALLOCATE_TIMEBLOCK,
  }),
  releaseTimeBlock: (timeBlock: { startDate: number, endDate: number }): AnyAction => ({
    payload: timeBlock,
    type: types.RELEASE_TIMEBLOCK,
  }),
};
