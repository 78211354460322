import * as React from 'react';
import IRestCollectionInfo from 'src/app/data/common/interfaces/IRestCollectionInfo';
import IUser from 'src/app/data/common/interfaces/IUser';
import getUserName from 'src/app/data/common/utils/getUserName';
import { IItemPickerListItemProps, IPickerItem } from 'src/components/ItemPicker/ItemPicker';
import styles from './SsLearnersPicker.css';
import ItemPicker from 'src/components/ItemPicker';
import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import ErrorMessage from 'src/components/ErrorMessage';
import Separator from 'src/components/Separator';

export interface ISsLearnersPickerProps {
  disabled?: boolean;
  learnersInfo: IRestCollectionInfo<ICompanyUser>;
  maxLearners?: number;
  selectedLearners: IUser[];
  onChange: (learners: IUser[]) => void;
}

interface ISsLearnersPickerState {
  viewMode: boolean;
  errorMessage: string;
}

class SsLearnersPicker extends React.Component<ISsLearnersPickerProps, ISsLearnersPickerState> {

  public state: ISsLearnersPickerState = {
    viewMode: true,
    errorMessage: '',
  };

  public render() {
    const { disabled, learnersInfo } = this.props;
    const { errorMessage } = this.state;
    const itemRenderer = (props: IItemPickerListItemProps) => (
      <div>{getUserName(props.item.data)}</div>
    );
    const keyExtractor = (item: IPickerItem) => `learner-${item.data.id}`;
    const titleExtractor = (item: IPickerItem) => getUserName(item.data);
    const searchFilter = (text: string, item: IPickerItem) => {

      const { firstName, lastName, email } = item.data;

      return `${firstName} ${lastName} ${email}`.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    };

    return (
      <>
        {
          errorMessage &&
          <>
            <ErrorMessage>
              {errorMessage}
            </ErrorMessage>
            <Separator/>
          </>
        }
        <ItemPicker
          disabled={disabled}
          ItemComponent={itemRenderer}
          items={this.wrapLearners(learnersInfo.items)}
          itemsFetchError={learnersInfo.fetchError}
          itemsFetched={learnersInfo.fetched}
          itemsFetching={learnersInfo.fetching}
          listClassName={styles.learnersList}
          emptyListMessage="No trainee"
          keyExtractor={keyExtractor}
          selectedItemTitleExtractor={titleExtractor}
          onChange={this.onLearnerChange}
          searchFilter={searchFilter}
        />
      </>
    );
  }

  private onLearnerChange = (item: IPickerItem) => {
    const { selectedLearners } = this.props;
    const learnerIndex = selectedLearners.findIndex(l => l.id === item.data.id);

    if (learnerIndex === -1 && item.selected) {
      this.addLearner(item);
    } else if (learnerIndex > -1 && !item.selected) {
      this.removeLearner(learnerIndex);
    }
  };

  private wrapLearners = (learners: ICompanyUser[]) => {
    const { selectedLearners } = this.props;

    return learners.map((learner: ICompanyUser) => {
      return {
        data: learner,
        id: learner.id || '',
        selected: selectedLearners.findIndex(l => l.id === learner.id) > -1
      };
    });
  };

  private addLearner = (item: IPickerItem) => {
    const { maxLearners, selectedLearners } = this.props;

    if (maxLearners && selectedLearners.length >= maxLearners) {
      this.setState({ errorMessage: `You can only add up to ${maxLearners} trainee${maxLearners > 1 ? 's' : ''} for this type of session.` });

      return;
    }

    this.props.onChange([
      ...this.props.selectedLearners,
      item.data,
    ]);
  };

  private removeLearner = (index: number) => {
    const { selectedLearners } = this.props;

    selectedLearners.splice(index, 1);
    this.setState({ errorMessage: '' });

    this.props.onChange(selectedLearners);
  };
}

export default SsLearnersPicker;
