exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ButtonSpinner__spinnerContainer___3qp_9{width:100%;height:1em;text-align:center;padding-left:1em}.ButtonSpinner__spinner___aAykB{width:1em;height:1em;margin:0 auto;border:1px solid #ff615c;border-radius:50%;border-left-color:transparent;border-right-color:transparent;-webkit-animation:ButtonSpinner__spin___xewmd .65s infinite linear;animation:ButtonSpinner__spin___xewmd .65s infinite linear}@-webkit-keyframes ButtonSpinner__spin___xewmd{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes ButtonSpinner__spin___xewmd{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/components/Button/ButtonSpinner/ButtonSpinner.css"],"names":[],"mappings":"AAAA,yCACE,WAAY,AACZ,WAAY,AACZ,kBAAmB,AACnB,gBAAkB,CACnB,AAED,gCACE,UAAW,AACX,WAAY,AACZ,cAAe,AACf,yBAAmC,AACnC,kBAAmB,AACnB,8BAA+B,AAC/B,+BAAgC,AAChC,mEAA8C,AACtC,0DAAsC,CAC/C,AAGD,+CACE,GACE,gCAAkC,AAC1B,uBAA0B,CACnC,CACF,AAGD,uCACE,GACE,gCAAkC,AAC1B,uBAA0B,CACnC,CACF","file":"ButtonSpinner.css","sourcesContent":[".spinnerContainer {\n  width: 100%;\n  height: 1em;\n  text-align: center;\n  padding-left: 1em;\n}\n\n.spinner {\n  width: 1em;\n  height: 1em;\n  margin: 0 auto;\n  border: 1px solid rgb(255, 97, 92);\n  border-radius: 50%;\n  border-left-color: transparent;\n  border-right-color: transparent;\n  -webkit-animation: spin 650ms infinite linear;\n          animation: spin 650ms infinite linear;\n}\n\n\n@-webkit-keyframes spin {\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg);\n  }\n}\n\n\n@keyframes spin {\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"spinnerContainer": "ButtonSpinner__spinnerContainer___3qp_9",
	"spinner": "ButtonSpinner__spinner___aAykB",
	"spin": "ButtonSpinner__spin___xewmd"
};