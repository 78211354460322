import { RefObject, useEffect } from 'react';

/**
 * Hook that handles clicks outside the passed ref
 */
const useOutsideClick = (ref: RefObject<any> | null, handler: (e: MouseEvent) => void) => {

  useEffect(() => {
    if (!ref) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
};

export default useOutsideClick;
