import RestService from 'src/app/services/rest/RestService';
import INotificationMessage from 'src/app/data/common/interfaces/INotificationMessage';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import getPageDataUrl from 'src/app/data/common/utils/getPageDataUrl';
import { IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import { IViewInvitationsQuery, IViewInvitationsResponse } from 'src/layouts/common/Engagement/components/InviteToSchedule/ViewInvitations/ViewInvitations';
import IUser from 'src/app/data/common/interfaces/IUser';

const fetchMessages = (pageData: IListPageData, read?: boolean): Promise<IListDataResponse<INotificationMessage>> => {
  return RestService.fetch(`${RestService.REST_URL}/notification/list${getPageDataUrl(pageData, { read })}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const fetchNewMessages = (pageData: IListPageData): Promise<IListDataResponse<INotificationMessage>> => {
  return fetchMessages(pageData, false);
};

const fetchArchiveMessages = (pageData: IListPageData): Promise<IListDataResponse<INotificationMessage>> => {
  return fetchMessages(pageData, true);
};

const markMessagesAsRead = (messageIds: number[]): Promise<INotificationMessage[]> => {
  return RestService.fetch(`${RestService.REST_URL}/notification/read`, {
    body: JSON.stringify(messageIds),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
};

const archiveAllMessages = (messageId: number): Promise<any> => {
  return RestService.fetch(`${RestService.REST_URL}/notification/readExpired`, {
    body: JSON.stringify(messageId),
    headers: RestService.generateHeaders({
      'Content-Type': 'text/plain',
    }),
    method: 'POST',
  });
};

const fetchMessagesWithOptions = (params: IPageRequestParams & { read?: boolean }): Promise<IListDataResponse<INotificationMessage>> => {
  const { pageData, read, signal } = params;

  return RestService.fetch(`${RestService.REST_URL}/notification/list${getPageDataUrl(pageData, { read })}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
    signal
  });
};

const fetchAllLearnerInvitations = (params: IPageRequestParams & { viewInvitationsQuery: IViewInvitationsQuery }): Promise<IListDataResponse<IViewInvitationsResponse>> => {
  const {  signal, pageData, viewInvitationsQuery } = params;
  const requestPayload = { ...pageData, ...viewInvitationsQuery };

  return RestService.fetch(`${RestService.REST_URL}/notification/viewInvitations`, {
    body: JSON.stringify(requestPayload),
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    signal
  });
};

const cancelScheduledInvitation = (invitationId: number): Promise<void> => {
  return RestService.fetch(`${RestService.REST_URL}/notification/cancelScheduledInvitation/${invitationId}`, {
    headers: RestService.generateHeaders({
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  });
};

const viewInvitationsDetailsById = (inviteId: string): Promise<IViewInvitationsResponse> => {
  return RestService.fetch(`${RestService.REST_URL}/inviteToSchedule/viewInvitationsDetails/${inviteId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

const getExcludedLearnersInfo = (inviteId: number): Promise<IUser[]> => {
  return RestService.fetch(`${RestService.REST_URL}/notification/excludedLearnersInfo/${inviteId}`, {
    headers: RestService.generateHeaders(),
    method: 'GET',
  });
};

export default {
  fetchNewMessages,
  fetchArchiveMessages,
  markMessagesAsRead,
  archiveAllMessages,
  fetchMessagesWithOptions,
  fetchAllLearnerInvitations,
  cancelScheduledInvitation,
  viewInvitationsDetailsById,
  getExcludedLearnersInfo,
};
