import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { TLocaleId } from 'src/i18n';
import Selector, { ISelectorOption } from 'src/components/Selector/Selector';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { getSessionStatusLabelI18nId, SessionStatusLabel } from 'src/app/data/session/interfaces/ISession';
import { namedEntryToSelectOption, selectOptionToNamedEntry } from 'src/app/data/common/utils';
import { useSelector } from 'react-redux';
import selectors from 'src/app/redux/selectors';
import {useRawDataFetching} from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import actions from 'src/app/redux/store/actions';


export interface ISessionStatusSelectorProps {
  labels: SESSION_FILTER_LABEL[];
  selectedLabels?: INamedEntry[] | null;
  onChange: (val: SessionStatusType[] | null, selectedLabels: INamedEntry[] | null) => void;
  theme?: SelectorTheme;
  placeholder?: string;
  searchPlaceholder?: string;
  active?: boolean;
  providerName?: string;
}

export enum SESSION_FILTER_LABEL {
  PROVIDER_PENDING = 'PROVIDER_PENDING',
  PROVIDER_PENDING_SWAP = 'PROVIDER_PENDING_SWAP',
  PROVIDER_PRE_BOOKED = 'PROVIDER_PRE_BOOKED',
  PROVIDER_BOOKED = 'PROVIDER_BOOKED',
  SIM_DASHBOARD_BOOKED = 'SIM_DASHBOARD_BOOKED',
  CLIENT_REQUESTED = 'CLIENT_REQUESTED',
  CLIENT_BOOKED = 'CLIENT_BOOKED',
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  MISSED = 'MISSED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  CANCELLED = 'CANCELLED',
  LICENSEE_CANCELLED = 'LICENSEE_CANCELLED',
  EARLY_CANCELLED = 'EARLY_CANCELLED',
  LATE_CANCELLED = 'LATE_CANCELLED',
  EARLY_RESCHEDULED = 'EARLY_RESCHEDULED',
  LATE_RESCHEDULED = 'LATE_RESCHEDULED',
}

interface ILabelStatusChild {
  name: TLocaleId;
  statuses: SessionStatusType[];
}

const LABEL_STATUS: { [key in SESSION_FILTER_LABEL]: ILabelStatusChild } = {
  [SESSION_FILTER_LABEL.PROVIDER_PENDING]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.PENDING),
    statuses: [
      SessionStatusType.ORPHAN,
      SessionStatusType.WAIF,
    ]
  },
  [SESSION_FILTER_LABEL.PROVIDER_PENDING_SWAP]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.PENDING_SWAP),
    statuses: [
      SessionStatusType.SWAP,
    ]
  },
  [SESSION_FILTER_LABEL.PROVIDER_PRE_BOOKED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.PRE_BOOKED),
    statuses: [
      SessionStatusType.RESERVED,
    ]
  },
  [SESSION_FILTER_LABEL.PROVIDER_BOOKED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.BOOKED),
    statuses: [
      SessionStatusType.UPCOMING,
      SessionStatusType.BOOKED,
    ]
  },
  [SESSION_FILTER_LABEL.SIM_DASHBOARD_BOOKED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.BOOKED),
    statuses: [
      SessionStatusType.UPCOMING,
      SessionStatusType.BOOKED,
      SessionStatusType.WAIF,
      SessionStatusType.SWAP,
    ]
  },
  [SESSION_FILTER_LABEL.CLIENT_REQUESTED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.REQUESTED),
    statuses: [
      SessionStatusType.ORPHAN,
      SessionStatusType.WAIF,
    ]
  },
  [SESSION_FILTER_LABEL.CLIENT_BOOKED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.BOOKED),
    statuses: [
      SessionStatusType.SWAP,
      SessionStatusType.RESERVED,
      SessionStatusType.UPCOMING,
      SessionStatusType.BOOKED,
    ]
  },
  [SESSION_FILTER_LABEL.ACTIVE]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.ACTIVE),
    statuses: [
      SessionStatusType.PENDING,
      SessionStatusType.RUNNING,
    ]
  },
  [SESSION_FILTER_LABEL.COMPLETE]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.COMPLETE),
    statuses: [SessionStatusType.COMPLETED]
  },
  [SESSION_FILTER_LABEL.ERROR]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.ERROR),
    statuses: [SessionStatusType.ERROR]
  },
  [SESSION_FILTER_LABEL.MISSED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.MISSED),
    statuses: [SessionStatusType.MISSED]
  },
  [SESSION_FILTER_LABEL.NEEDS_REVIEW]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.NEEDS_REVIEW),
    statuses: [SessionStatusType.NEEDS_REVIEW]
  },
  [SESSION_FILTER_LABEL.LICENSEE_CANCELLED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.LICENSEE_CANCELLED),
    statuses: [SessionStatusType.LICENSEE_CANCELLED]
  },

  [SESSION_FILTER_LABEL.LATE_CANCELLED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.LATE_CANCELLED ),
    statuses: [SessionStatusType.LATE_CANCELLED]
  },

  [SESSION_FILTER_LABEL.EARLY_CANCELLED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.EARLY_CANCELLED),
    statuses: [SessionStatusType.EARLY_CANCELLED]
  },
  [SESSION_FILTER_LABEL.CANCELLED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.CANCELLED),
    statuses: [SessionStatusType.CANCELLED]
  },
  [SESSION_FILTER_LABEL.EARLY_RESCHEDULED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.EARLY_RESCHEDULED),
    statuses: [SessionStatusType.EARLY_RESCHEDULED]
  },
  [SESSION_FILTER_LABEL.LATE_RESCHEDULED]: {
    name: getSessionStatusLabelI18nId(SessionStatusLabel.LATE_RESCHEDULED),
    statuses: [SessionStatusType.LATE_RESCHEDULED]
  },

};

const SessionStatusSelector: FunctionComponent<ISessionStatusSelectorProps> = ({ selectedLabels, ...props }) => {

  const { labels, active = false, providerName } = props;
  const intl = useIntl();

  const [input, setInput] = useState('');
  const [ companyName, setCompanyName ] = useState<string>('Mursion');
  const licenseeId = useSelector(selectors.profile.getLicenseeId);
  const { item: companyData} = useRawDataFetching(actions.company.fetchCompany)(licenseeId)(!!licenseeId);

  useEffect(() => {
    if(companyData && companyData.name){
      setCompanyName(companyData.name);
      return;
    }
    if(providerName){
      setCompanyName(providerName);
    }
  }, [companyData, providerName]);

  const onChange = (val: ISelectorOption[]) => {
    const statuses = val.reduce(
      (accum, x) => accum.concat(LABEL_STATUS[x.value].statuses),
      [],
    );

    props.onChange(statuses, val.length ? val.map(selectOptionToNamedEntry) : null);
  };

  function mapLabelStatus(value: SESSION_FILTER_LABEL){
    if(SESSION_FILTER_LABEL.LICENSEE_CANCELLED === value){
      return `${companyName} ${intl.formatMessage({ id: LABEL_STATUS[value].name })}`;
    }
    return intl.formatMessage({ id: LABEL_STATUS[value].name });
  }

  const items = useMemo(() => {
    return labels
      .map(labelId => ({
        value: labelId,
        label: mapLabelStatus(labelId),
      }))
      .filter(x => x.label.toLowerCase().includes(input.toLowerCase()))
      .sort((a, b) => {
        const textA = a.label.toUpperCase();
        const textB = b.label.toUpperCase();

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
  }, [labels, input, companyName]);

  const selectorValue: ISelectorOption[] | null | undefined = useMemo(
    () => selectedLabels && selectedLabels.map(namedEntryToSelectOption),
    [selectedLabels]
  );

  return (
    <Selector
      clearOnOptionsChange={false}
      themeType={props.theme || SelectorTheme.Filter}
      isMulti={true}
      isSearchable={false}
      placeholder={props.placeholder || intl.formatMessage({ id: 'Filters.SessionStatus' })}
      searchPlaceholder={props.searchPlaceholder || intl.formatMessage({ id: 'Filters.SessionStatusPlaceHolderHint' })}
      options={items}
      value={selectorValue ? selectorValue : []}
      onChange={onChange}
      onInputChange={setInput}
      total={items.length}
      disabled={active}
      loading={false}
    />
  );
};

export default React.memo(SessionStatusSelector);
