import classNames from 'classnames';
import * as React from 'react';
import styles from './RefreshIndicator.css';
import TranslateMessage from 'src/i18n/TranslateMessage';

export interface IRefreshIndicatorProps {
  className?: string;
  overlay?: boolean;
  text?: string | JSX.Element;
  show: boolean;
}

class RefreshIndicator extends React.Component<IRefreshIndicatorProps> {
  public render() {
    if (!this.props.show) {
      return null;
    }

    const className = classNames([
      styles.refreshIndicator,
      this.props.className,
    ]);

    return (
      <>
        <div className={className}>{this.props.text || TranslateMessage('MursionPortal.Loading')}</div>
        {this.props.overlay && <div className={styles.refreshCover}/>}
      </>
    );
  }
}

export default RefreshIndicator;
