import cn from 'classnames';
import * as React from 'react';
import { FunctionComponent } from 'react';
import styles from './Checkbox.css';
import { SelectorTheme } from "../Selector/themes";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip";
import { TooltipPlacement } from "src/components/Tooltip/Tooltip";

export interface ICheckboxProps extends React.HTMLProps<HTMLInputElement> {
  checked?: boolean;
  id?: string;
  ariaLabel?: string;
  themeType?: SelectorTheme;
  tooltipText?: string;
}

const Checkbox: FunctionComponent<ICheckboxProps> = (props) => {
  const { checked, className, children, ariaLabel, tooltipText, ...restProps } = props;

  const classNames = cn(
    styles.checkBoxLabel,
    (props.themeType === SelectorTheme.Cobalt) && styles.cobalt,
    className,
    { [styles.disabled]: props.disabled },
  );

  return (
    <label className={classNames}>
      <div className={styles.labelContent}>
        <span className={cn(styles.checkBg, 'checkBgOuterBorder')} />
        <input
          type="checkbox"
          className={cn(styles.checkBox, 'visibilityHidden')}
          checked={checked}
          aria-label={ariaLabel}
          {...restProps}
          tabIndex={0}
        />
        <div className={styles.checkMark}>
          <span className={cn(styles.checkMarkBg, 'checkMarkBg')} />
          <span className={cn(styles.checkMarkChecked, 'checkMarkChecked')} />
        </div>

        {children}
      </div>
      {tooltipText && <InfoTooltip
        text={tooltipText}
        placement={TooltipPlacement.TOP} />}
    </label>
  );
};

export default Checkbox;
