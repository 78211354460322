import { combineReducers, AnyAction } from 'redux';
import IListDataState from 'src/app/data/common/interfaces/IListDataState';
import IProject from 'src/app/data/projects/interfaces/IProject';
import createListReducer from 'src/app/redux/utils/createListReducer';
import { IProjectLearnersState } from 'src/app/redux/modules/projects/learners/reducer';
import learners from './learners/reducer';
import { IErrorState } from 'src/app/data/common/interfaces/IRestError';
import createEntityErrorReducer from 'src/app/redux/utils/createEntityErrorReducer';
import IItemDataState from 'src/app/data/common/interfaces/IItemDataState';
import createItemReducer from 'src/app/redux/utils/createItemReducer';
import * as types from './types';

export interface IProjectsReduxState {
  learners: IProjectLearnersState;
  list: IListDataState<IProject>;
  listForClient: IListDataState<IProject>;
  currentProject: IItemDataState<IProject>;
  error: IErrorState;
  showArchivedScenarios: boolean;
}

const showArchivedScenarios = (state: boolean, action: AnyAction) => {
  if (action.type === types.TOGGLE_SHOW_ARCHIVED_SCENARIOS) {
    return action.payload;
  }
  return state || false;
};

export default combineReducers({
  learners,
  list: createListReducer('PROJECTS'),
  listForClient: createListReducer('CLIENT_PROJECTS'),
  currentProject: createItemReducer('CURRENT_PROJECT'),
  error: createEntityErrorReducer('PROJECT'),
  showArchivedScenarios,
});
