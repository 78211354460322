import cn from 'classnames';
import * as React from 'react';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import btnStyles from 'src/components/Button/Button.css';
import ButtonSpinner from 'src/components/Button/ButtonSpinner/ButtonSpinner';

export enum ButtonType {
  RED = 'red',
  PURPLE = 'purple',
  SPECIAL_RED = 'specialRed',
  SPECIAL_PURPLE = 'specialPurple',
  USER = 'user',
  GHOST = 'ghost',
  TOGGLE = 'toggle',
  ARCHIVE = 'archive',
  BLUE = 'blue',
  LIGHT_BLUE = 'lightBlue',
  BLUE_SECONDARY = 'blueSecondary',
  BLUEOUTLINE = 'blueOutline',
  CANCEL = 'cancel',
  DEFAULT = 'default',
  LIGHT = 'lightColor',
  COBALT_RED = 'cobaltRed',
  PALE_PURPLE = 'palePurple',
  TEAL_GREEN = 'tealGreen',
  BLUE_OYSTER  = 'blueOyster'
}

export enum ButtonSize {
  NORMAL = 'normal',
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum ButtonFont {
  NORMAL = 'normal',
  LIGHT = 'light',
  BOLDMED = 'bold-med',
  SEMIBOLD = 'semiBold',
  BOLD = 'bold',
}

export enum ButtonStatus {
  ON = '',
  OFF = 'off'
}

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  btnType?: ButtonType;
  btnSize?: ButtonSize;
  btnFont?: ButtonFont;
  btnStatus?: ButtonStatus;
  spinner?: boolean;
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {

  const { btnType, btnSize, btnFont, btnStatus, className, spinner, loading, ...restProps } = props;

  const classNames = cn(
    btnStyles.btn,
    btnStyles[btnType || ''],
    btnStyles[btnSize || ''],
    btnStyles[btnFont || ''],
    btnStyles[btnStatus || ''],
    {
      [btnStyles.disabled]: props.disabled,
      [btnStyles.spinner]: spinner
    },
    className,
  );

  return (
    <button ref={ref} className={classNames} {...restProps}>
      {props.children}
      {
        spinner && loading && <ButtonSpinner/>
      }
    </button>
  );
});

export default Button;
