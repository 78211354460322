import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import Selector, { ISelectorBaseProps, ISelectorOption, SelectorTheme, } from 'src/components/Selector';

type Option = ISelectorOption<string>;
type OmitFields = 'value'
  | 'themeType'
  | 'placeholder'
  | 'options'
  | 'onChange'
  | 'showSelectedValue'
  | 'disabled';


export interface IUserAcceptedSelectorProps extends Omit<ISelectorBaseProps, OmitFields> {
  value?: boolean | null;
  onChange: (val?: boolean | null) => void;
  active?: boolean;
}

const UserAcceptedSelector: FunctionComponent<IUserAcceptedSelectorProps> = (props) => {
  const intl = useIntl();
  const { active = true, value } = props;

  const userAcceptedSelectorItems: Option[] = [{
    value: 'all',
    label: intl.formatMessage({ id: 'Filters.All' }),
  }, {
    value: 'yes',
    label: intl.formatMessage({ id: 'MursionPortal.Status.RegistrationAccepted' }),
  }, {
    value: 'no',
    label: intl.formatMessage({ id: 'MursionPortal.Status.RegistrationNotAccepted' }),
  }];

  const handleChange = (option: Option) => {
    const optionValue = getBooleanValue(option?.value);
    props.onChange(optionValue);
  };

  const selectorValue = props.hasOwnProperty('value')
    ? userAcceptedSelectorItems.find((option) => getBooleanValue(option.value) === value)
    : undefined;

  return (
    <Selector
      {...props}
      value={selectorValue}
      themeType={SelectorTheme.Cobalt}
      placeholder={intl.formatMessage({ id: 'MursionPortal.Status.RegistrationStatus' })}
      options={userAcceptedSelectorItems}
      onChange={handleChange}
      showSelectedValue={true}
      disabled={!active}
    />
  );
};

const booleanMap = {
  'yes': true,
  'no': false,
  'all': null,
};

const getBooleanValue = (value: string): boolean | null =>
  booleanMap.hasOwnProperty(value) ? booleanMap[value] : null;

export default UserAcceptedSelector;
