import {combineReducers} from 'redux';
import IListDataState from "src/app/data/common/interfaces/IListDataState";
import createListReducer from "src/app/redux/utils/createListReducer";
import {ICertificate} from "src/app/redux/modules/company/interfaces/ICertificate";

export interface ICertificatesReduxState {
  listByClient: IListDataState<ICertificate>;
  listByLicensee: IListDataState<ICertificate>;
}

export default combineReducers({
  listByClient: createListReducer('CERTIFICATES_BY_CLIENT'),
  listByLicensee: createListReducer('CERTIFICATES_BY_LICENSEE'),
});
