import IRestError from "src/app/data/common/interfaces/IRestError";
import { IAppState } from 'src/app/redux/store/reducers';

export const getList = (state: IAppState) => state.scenarios.templatesList.items;
export const getListPage = (state: IAppState): number => state.scenarios.templatesList.page || 0;
export const getListSizePerPage = (state: IAppState): number => state.scenarios.templatesList.sizePerPage || 0;
export const getListTotalSize = (state: IAppState): number => state.scenarios.templatesList.totalSize || 0;
export const isListFetched = (state: IAppState): boolean => state.scenarios.templatesList.fetched;
export const getListRefreshError = (state: IAppState): IRestError | null => state.scenarios.templatesList.refreshError;
export const isListRefreshing = (state: IAppState): boolean => state.scenarios.templatesList.refreshing;
